import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { AccountContext } from "./account";
import { getAwardsSponsors } from "../api/awards-sponsors";
import { AwardsSponsorResponse } from "../api/types/awards-sponsors";
import { AwardsCategoryResponseFull } from "../api/types/awards-categories";
import { AwardsContext } from "./awards";
import {
  getAwardsYearCategories,
  getAwardsYearEntries,
} from "../api/awards-years";
import { AwardsEntryResponseFull } from "../api/types/awards-entries";
import { AwardsYearEntriesQueryParams } from "../api/types/awards-years";
import { getJudgeById, getJudgeCategoryEntries } from "../api/awards-judges";
import {
  isAfterShortlistDate,
  isBeforeShortlistDate,
  isChair,
  isFirstRoundJudge,
  isSecondRoundJudge,
} from "../utils";

interface JudgesContextProps {
  categories: { [year: number]: AwardsCategoryResponseFull[] } | null;
  setCategories: React.Dispatch<
    React.SetStateAction<{
      [year: number]: AwardsCategoryResponseFull[];
    } | null>
  >;
  sponsors: AwardsSponsorResponse[];
  setSponsors: React.Dispatch<React.SetStateAction<AwardsSponsorResponse[]>>;
  getEntries: (
    year: number,
    queryParams?: AwardsYearEntriesQueryParams
  ) => Promise<{ entries: AwardsEntryResponseFull[]; total_count: number }>;
  isJudge: boolean;
  isJudgeCurrentYear: boolean;
  currentJudge: JudgeFull | null;
  currentEntries: Record<string, JudgeEntrySummary[]>;
}

const initialValue: JudgesContextProps = {
  isJudge: false,
  isJudgeCurrentYear: false,
  currentJudge: null,
  currentEntries: {},

  categories: null,
  setCategories: () => null,
  sponsors: [],
  setSponsors: () => null,
  // @ts-ignore
  getEntries: () => null,
};

export const JudgesContext = createContext<JudgesContextProps>(initialValue);

interface AccountProviderProps {
  children?: React.ReactNode;
}

const JudgesProvider = ({ children }: AccountProviderProps) => {
  const { account } = useContext(AccountContext);
  const { currentYear } = useContext(AwardsContext);

  const [isJudge, setIsJudge] = useState(initialValue.isJudge);
  const [isJudgeCurrentYear, setIsJudgeCurrentYear] = useState(
    initialValue.isJudgeCurrentYear
  );
  const [currentJudge, setCurrentJudge] = useState<JudgeFull | null>(null);
  const [currentEntries, setCurrentEntries] = useState<
    JudgesContextProps["currentEntries"]
  >({});
  const [judgeDetailsFetched, setJudgeDetailsFetched] = useState(false);

  const [categories, setCategories] = useState<{
    [year: number]: AwardsCategoryResponseFull[];
  } | null>(null);
  const [sponsors, setSponsors] = useState<AwardsSponsorResponse[]>([]);
  const [categoriesFetched, setCategoriesFetched] = useState(false);

  useEffect(() => {
    if (account && currentYear && !judgeDetailsFetched) {
      setIsJudge(!!account.judge_id);

      const fetchEntries = async (awardsCategoryId: number, title: string) => {
        const { entries } = await getJudgeCategoryEntries(awardsCategoryId);
        if (entries) {
          setCurrentEntries((e) => {
            return {
              ...e,
              [title]: entries,
            };
          });
        }
      };

      const getJudges = async () => {
        if (account.judge_id) {
          const { judge } = await getJudgeById(account.judge_id);

          setCurrentJudge({ ...judge });

          if (judge) {
            const {
              first_round_categories,
              second_round_categories,
              chair_categories,
            } = judge;

            let categoriesToJudge: number[] = [];

            if (isChair(judge)) {
              categoriesToJudge = [...chair_categories];
            } else if (
              isFirstRoundJudge(judge) &&
              isBeforeShortlistDate(currentYear)
            ) {
              categoriesToJudge = [...first_round_categories];
            } else if (
              isSecondRoundJudge(judge) &&
              isAfterShortlistDate(currentYear)
            ) {
              categoriesToJudge = [...second_round_categories];
            }

            categoriesToJudge.forEach((category_id) => {
              if (categories && Array.isArray(categories[year])) {
                const found = categories[year].find(
                  ({ awards_category_id }) => {
                    return awards_category_id === category_id;
                  }
                );
                if (found) {
                  const { title } = found;
                  fetchEntries(category_id, title);
                }
              }
            });

            setIsJudgeCurrentYear(categoriesToJudge.length > 0);

            setJudgeDetailsFetched(true);
          }
        }
      };

      const year = currentYear?.year;
      if (
        year &&
        categories &&
        Object.prototype.hasOwnProperty.call(categories, year)
      ) {
        getJudges();
      }
    }
  }, [account, currentYear, currentJudge, categories, judgeDetailsFetched]);

  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        const { sponsors } = await getAwardsSponsors();

        setSponsors([...sponsors]);
      } catch {
        // TODO: Add error handling for sponsors
        console.error("Error fetching sponsors");
      }
    };

    if (account.admin_id) {
      fetchSponsors();
    }
  }, [account]);

  // Fetch categories for current year
  useEffect(() => {
    const fetchCategories = async () => {
      if (!currentYear || categoriesFetched) return;

      const { year } = currentYear;

      const { categories: fetchedCategories } = await getAwardsYearCategories(
        year
      );

      // Merge existing categories with new categories
      setCategories((curr) => {
        if (!curr)
          return {
            [year]: fetchedCategories,
          };

        return {
          ...curr,
          [year]: fetchedCategories,
        };
      });

      setCategoriesFetched(true);
    };

    fetchCategories();
  }, [currentYear, categoriesFetched]);

  const getEntries = useCallback(
    async (year: number, queryParams?: AwardsYearEntriesQueryParams) => {
      try {
        const { entries, total_count } = await getAwardsYearEntries(
          year,
          queryParams
        );

        return { entries, total_count };
      } catch {
        return Promise.reject("Failed to get category entries");
      }
    },
    []
  );

  return (
    <JudgesContext.Provider
      value={{
        isJudge,
        isJudgeCurrentYear,
        currentJudge,
        currentEntries,

        categories,
        setCategories,
        sponsors,
        setSponsors,
        getEntries,
      }}
    >
      {children}
    </JudgesContext.Provider>
  );
};

export default JudgesProvider;
