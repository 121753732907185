import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import classNames from "classnames";

import Button from "./button";
import { FlotiqImage } from "./image";
import { logout } from "../api/auth";
import fallbackLogo from "../assets/svgs/grierson-logo-white.svg";
import { AccountContext } from "../context/account";
import useMobile from "../hooks/useMobile";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/header.module.scss";
import {
  isAdmin,
  isAfterShortlistDate,
  isBeforeShortlistDate,
  isChair,
  isFirstRoundJudge,
  isJudge,
  isSecondRoundJudge,
} from "../utils/index";
import { AwardsContext } from "../context/awards";
import { JudgesContext } from "../context/judges";

interface NavItem {
  id: string;
  link_text: string;
  link: string;
  subnav?:
    | {
        link_text: string;
        link: string;
        mobile_only?: boolean | null;
      }[]
    | null;
}

interface NavProps {
  items: NavItem[];
  children: React.ReactElement;
}

const DesktopNav = ({ items, children }: NavProps) => {
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);
  // you can access the elements with itemsRef.current[n]

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, items.length);
  }, [items]);

  return (
    <>
      <nav className={styles.desktopLinks} role="navigation">
        {items.map((navItem, index) => {
          const topNavItem = (
            <Button
              href={navItem.link}
              newTab={!/^[\/#]/.test(navItem.link[0])}
              className={styles.primaryLink}
            >
              {navItem.link_text}
            </Button>
          );

          if (!navItem.subnav || navItem.subnav.length < 1) {
            return (
              <React.Fragment key={navItem.id}>{topNavItem}</React.Fragment>
            );
          }

          return (
            <div
              key={navItem.id}
              className={styles.subNavContainer}
              ref={(el) => (itemsRef.current[index] = el)}
            >
              {topNavItem}
              <div
                className={styles.subNavOuter}
                style={{
                  left:
                    itemsRef.current && itemsRef.current[index]
                      ? itemsRef.current[index]!.offsetWidth / 2
                      : 50,
                }}
              >
                <div className={styles.subNavInner}>
                  {navItem.subnav.map((subNavItem) => {
                    if (!subNavItem || !!subNavItem.mobile_only) {
                      return null;
                    }

                    return (
                      <Button
                        key={`${navItem.id}-${subNavItem.link_text}`}
                        href={subNavItem.link}
                        newTab={!/^[\/#]/.test(subNavItem.link)}
                        className={styles.secondaryLink}
                      >
                        {subNavItem.link_text}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </nav>
      {children}
    </>
  );
};

interface MobileNavProps extends NavProps {
  logo?: ({ id: string; url: string } | null)[] | null;
}

const MobileNav = ({ items, logo, children }: MobileNavProps) => {
  const navTop = useRef<HTMLDivElement>(null);
  const navBottom = useRef<HTMLDivElement>(null);

  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [mobileSubNavActive, setMobileSubNavActive] = useState(
    Array.from(new Array(items.length), (x) => false)
  );

  const fixedNavHeight = useMemo(() => {
    if (!navTop || !navBottom || !navTop.current || !navBottom.current)
      return 250;

    return navTop.current.offsetHeight + navBottom.current.offsetHeight;
  }, [navTop, navBottom]);

  return (
    <>
      <Button
        buttonType="icon"
        className={classNames(styles.burgerIcon, {
          [styles.active]: mobileNavActive,
        })}
        onClick={() => {
          setMobileNavActive(!mobileNavActive);
        }}
      >
        <div></div>
        <div></div>
        <div></div>
      </Button>
      <div
        className={classNames(styles.mobileNavContainer, {
          [styles.active]: mobileNavActive,
        })}
      >
        <div className={styles.mobileNavTop} ref={navTop}>
          <Link to="/" className={styles.logo}>
            <FlotiqImage
              imgObj={logo}
              fallbackImg={fallbackLogo}
              alt="The Grierson Trust"
            />
          </Link>
          <Button
            buttonType="icon"
            className={styles.closeMobileNavButton}
            onClick={() => {
              setMobileNavActive(!mobileNavActive);
            }}
          >
            <div></div>
            <div></div>
          </Button>
        </div>
        <div
          className={styles.mobileNavMiddle}
          style={{ maxHeight: `calc(100% - ${fixedNavHeight + 40}px)` }}
        >
          {items.map((navItem, index) => {
            return (
              <div key={navItem.id}>
                <div
                  className={classNames(styles.mobileMainLink, {
                    [styles.active]: mobileSubNavActive[index],
                    [styles.parentLink]:
                      navItem.subnav && navItem.subnav.length > 0,
                  })}
                >
                  <Button
                    href={
                      navItem.subnav && navItem.subnav.length > 0
                        ? undefined
                        : navItem.link
                    }
                    className={styles.primaryLink}
                    onClick={
                      navItem.subnav && navItem.subnav.length > 0
                        ? (e) => {
                            e.preventDefault();

                            setMobileSubNavActive((curr) => {
                              const newSubNavStatus = Array.from(
                                curr,
                                () => false
                              );

                              newSubNavStatus[index] = !curr[index];

                              return newSubNavStatus;
                            });
                          }
                        : undefined
                    }
                  >
                    {navItem.link_text}
                  </Button>
                </div>
                {navItem.subnav &&
                  navItem.subnav.length > 0 &&
                  mobileSubNavActive[index] && (
                    <div className={styles.subNav}>
                      {navItem.subnav.map((subNavItem) => {
                        if (!subNavItem) {
                          return null;
                        }

                        return (
                          <div
                            key={`${navItem.id}-${subNavItem.link_text}`}
                            className={classNames(styles.subNavItem, {
                              [styles.active]: mobileSubNavActive[index],
                            })}
                          >
                            <Button
                              href={subNavItem.link}
                              newTab={!/^[\/#]/.test(subNavItem.link)}
                              className={styles.secondaryLink}
                            >
                              {subNavItem.link_text}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
            );
          })}
        </div>
        <div className={styles.mobileNavBottom} ref={navBottom}>
          {children}
        </div>
      </div>
    </>
  );
};

interface HeaderProps {
  extraWide?: boolean;
  transparent?: boolean;
}

export default function Header({
  extraWide = false,
  transparent = false,
}: HeaderProps) {
  const { account, setAccount } = useContext(AccountContext);
  const { currentJudge } = useContext(JudgesContext);

  const { currentYear } = useContext(AwardsContext);
  const _window = useWindow();

  const showJudgeMenuItem = useMemo(() => {
    if (account && currentYear && currentJudge) {
      if (isJudge(account)) {
        if (isChair(currentJudge)) {
          return true;
        }
        if (
          isFirstRoundJudge(currentJudge) &&
          isBeforeShortlistDate(currentYear)
        ) {
          return true;
        }
        if (
          isSecondRoundJudge(currentJudge) &&
          isAfterShortlistDate(currentYear)
        ) {
          return true;
        }
      }
    }
    return false;
  }, [account, currentYear, currentJudge]);

  const isMobile = useMobile(extraWide ? 1400 : 1200);

  const data = useStaticQuery(graphql`
    query GetNavItems {
      globalContent {
        main_logo {
          id
          url
        }
        show_action_button
        action_button_text
        action_button_url
      }
    }
  `);

  const { globalContent } = data;

  const { main_logo } = globalContent || { main_logo: null };

  const navItems: NavItem[] = [
    {
      id: "dashboard",
      link_text: "Dashboard",
      link: "/dashboard",
    },
    {
      id: "how_to_enter",
      link_text: "How to Enter",
      link: "#how-to-enter",
    },
    {
      id: "profile",
      link_text: "Profile",
      link: "/profile",
      subnav: [
        {
          link: "/profile",
          link_text: "Edit profile",
        },
        {
          link: "/billing",
          link_text: "Edit billing details",
        },
      ],
    },
  ];

  if (showJudgeMenuItem) {
    navItems.unshift({
      id: "judge",
      link_text: "Judging",
      link: "/judging",
    });
  }

  if (isAdmin(account)) {
    navItems.unshift({
      id: "admin",
      link_text: "Admin",
      link: "/admin",
      subnav: [
        {
          link: "/admin/manage-awards",
          link_text: "Manage awards",
        },
        {
          link: "/admin/manage-sponsors",
          link_text: "Manage sponsors",
        },
        {
          link: "/admin/manage-entries",
          link_text: "Manage entries",
        },
        {
          link: "/admin/manage-entrants",
          link_text: "Manage entrants",
        },
        {
          link: "/admin/manage-judges",
          link_text: "Manage judges",
        },
      ],
    });
  }

  const showNav = useMemo(() => {
    if (!account.checked || (account.checked && !account.loggedIn)) {
      return false;
    }
    return true;
  }, [account]);

  const onLogout = useCallback(() => {
    logout();

    setAccount({ loggedIn: false, checked: true });

    _window.location.reload();
  }, []);

  return (
    <header
      className={classNames({
        [styles.transparent]: transparent,
        [styles.normalWidth]: !extraWide,
      })}
    >
      <div className={styles.logo}>
        <Link to="/">
          <FlotiqImage
            imgObj={main_logo}
            fallbackImg={fallbackLogo}
            alt="The Grierson Trust"
          />
        </Link>
      </div>
      {showNav && (
        <>
          {isMobile ? (
            <MobileNav items={navItems} logo={main_logo}>
              <div className={styles.loginButtonMobile}>
                <Button buttonType="primary" onClick={onLogout}>
                  Logout
                </Button>
              </div>
            </MobileNav>
          ) : (
            <DesktopNav items={navItems}>
              {
                <div className={styles.loginButtonDesktop}>
                  <Button buttonType="primary" onClick={onLogout}>
                    Logout
                  </Button>
                </div>
              }
            </DesktopNav>
          )}
        </>
      )}
    </header>
  );
}
