import React, { useContext, useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import moment from "moment";
import numeral from "numeral";

import { sendInvoice } from "../api/awards-entries";
import Button from "../components/button";
import Breadcrumbs from "../components/CheckoutBreadcrumbs";
import { FormButtonRow } from "../components/form";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { Divider } from "../components/typeface";
import { AwardsContext } from "../context/awards";
import { EntriesContext } from "../context/entries";
import { VAT, supportEmail } from "../core/variables";
import useMobile from "../hooks/useMobile";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/checkout-payment.module.scss";

interface LocationState {
  fromReviewPage?: boolean;
}

export default function CheckoutPage() {
  const { currentYearCategories, noNewEntries } = useContext(AwardsContext);
  const { entriesToSubmit, setEntriesToSubmit } = useContext(EntriesContext);

  const isMobile = useMobile(600);

  const _window = useWindow();

  const state = useLocation().state as LocationState;

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState<string | null>(null);

  // Preserve entries to submit on page refresh for saved/duplicate entries
  useEffect(() => {
    if (!_window.sessionStorage || _window.location.search === null) return;

    // Only accessible from the Summary page
    // Or if it's the return URL after payment submission
    if (!state || !state.fromReviewPage) {
      navigate("/summary", { replace: true });
      return;
    }

    const storedEntries = _window.sessionStorage.getItem("entriesToSubmit");

    if ((!entriesToSubmit || entriesToSubmit.length === 0) && storedEntries) {
      setEntriesToSubmit(JSON.parse(storedEntries));
      return;
    }

    // Redirect if no valid entries to submit
    if (
      !entriesToSubmit ||
      entriesToSubmit.length === 0 ||
      !!entriesToSubmit.find(
        (entry) => entry.is_submitted || entry.is_paid || entry.invoice_sent
      )
    ) {
      _window.sessionStorage.removeItem("entriesToSubmit");
      navigate("/dashboard", { replace: true });
    }
  }, [state, _window, entriesToSubmit]);

  // Check if within the entry period
  // If not, redirect to dashboard
  useEffect(() => {
    if (noNewEntries === null || !_window.sessionStorage) return;

    // Should not be possible to submit entries if outside entry period
    if (noNewEntries) {
      _window.sessionStorage.removeItem("entriesToSubmit");
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [_window, noNewEntries]);

  const purchaseItems = useMemo(() => {
    if (
      !entriesToSubmit ||
      !currentYearCategories ||
      currentYearCategories.length === 0
    ) {
      return [];
    }

    return entriesToSubmit.map(
      ({ entry_id, title, category_title, awards_category_id }) => {
        const fullCategory = currentYearCategories.find(
          (category) => awards_category_id === category.awards_category_id
        );

        if (!fullCategory || !fullCategory.entry_cost) return null;

        return {
          entryID: entry_id,
          entryTitle: title,
          categoryName: category_title,
          cost: Number(fullCategory.entry_cost),
        };
      }
    );
  }, [entriesToSubmit, currentYearCategories]);

  const totalCost = useMemo(() => {
    if (purchaseItems.length === 0 || purchaseItems.find((item) => !item))
      return 0;

    return purchaseItems.reduce((acc, curr) => acc + (curr?.cost || 0), 0);
  }, [purchaseItems]);

  // This will only happen if the entry category does not have an associated cost
  // It should not be possible to pay for such entries - redirect to dashboard
  useEffect(() => {
    if (
      purchaseItems.length > 0 &&
      purchaseItems.filter((item) => !item).length > 0
    ) {
      navigate("/dashboard");
    }
  }, [purchaseItems]);

  return (
    <SiteWrapper hideStickyNav>
      <Container>
        <Row>
          {purchaseItems.length > 0 && (
            <>
              <Breadcrumbs step={1} />
              <Column gridCols={8}>
                <h1>Payment</h1>
                <Divider />
                {isMobile ? (
                  <div className={styles.mobileCostTable}>
                    <table>
                      <tbody>
                        {purchaseItems.map((entry) => {
                          if (!entry) return null;

                          const { entryID, entryTitle, categoryName, cost } =
                            entry;

                          return (
                            <tr key={entryID}>
                              <td>
                                <span>
                                  {entryTitle || "(Title not provided)"}
                                </span>
                                <span className={styles.subCopy}>
                                  {categoryName}
                                </span>
                              </td>
                              <td className={styles.priceCol}>
                                <span>
                                  £{numeral(cost.toFixed(2)).format("£0,0.00")}
                                </span>
                                <span className={styles.subCopy}>
                                  + £
                                  {numeral((cost * VAT).toFixed(2)).format(
                                    "£0,0.00"
                                  )}{" "}
                                  VAT
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <table className={styles.costTable}>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Category</th>
                        <th className={styles.priceCol}>Cost</th>
                        <th className={styles.priceCol}>VAT</th>
                        <th className={styles.priceCol}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseItems.map((entry) => {
                        if (!entry) return null;

                        const { entryID, entryTitle, categoryName, cost } =
                          entry;

                        return (
                          <tr key={entryID}>
                            <td>{entryTitle || "(Not provided)"}</td>
                            <td>{categoryName}</td>
                            <td className={styles.priceCol}>
                              £{numeral(cost.toFixed(2)).format("£0,0.00")}
                            </td>
                            <td className={styles.priceCol}>
                              £
                              {numeral((cost * VAT).toFixed(2)).format(
                                "£0,0.00"
                              )}
                            </td>
                            <td className={styles.priceCol}>
                              £
                              {numeral((cost * (1 + VAT)).toFixed(2)).format(
                                "£0,0.00"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                <Divider />
                <table className={styles.totalsTable}>
                  <tbody>
                    <tr>
                      <td>Sub-total:</td>
                      <td>
                        £{numeral(totalCost.toFixed(2)).format("£0,0.00")}
                      </td>
                    </tr>
                    <tr>
                      <td>VAT:</td>
                      <td>
                        £
                        {numeral((totalCost * VAT).toFixed(2)).format(
                          "£0,0.00"
                        )}
                      </td>
                    </tr>
                    <tr className={styles.grandTotal}>
                      <td>Total:</td>
                      <td>
                        £
                        {numeral((totalCost * (1 + VAT)).toFixed(2)).format(
                          "£0,0.00"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Divider />
                {noNewEntries === true && (
                  <p className={styles.deadlineMsg}>
                    We cannot accept payments online by card after the entry
                    deadline.
                  </p>
                )}
                <p className={styles.supportingText}>
                  <strong>
                    An invoice will be sent to your billing email address with
                    details of how to pay.
                  </strong>
                </p>
                <FormButtonRow className={styles.proceedButtons}>
                  <Button buttonType="tertiary" href="/summary">
                    Back
                  </Button>
                  <div>
                    <Button
                      buttonType="primary"
                      className={styles.submitButton}
                      disabled={loadingPayment}
                      onClick={async () => {
                        try {
                          setLoadingPayment(true);
                          setPaymentError(null);

                          const entriesToInvoice = purchaseItems
                            .filter((entry) => !!entry)
                            .map(({ entryID }) => entryID);

                          // Check that invoices haven't been sent in the last 30s
                          const previouslySentInovices =
                            _window.sessionStorage?.getItem("sentInvoices");

                          let previouslySentInvoicesObject: {
                            [entryIDs: string]: string;
                          } = {};

                          if (previouslySentInovices) {
                            previouslySentInvoicesObject = JSON.parse(
                              previouslySentInovices
                            );
                          }

                          const foundDate =
                            previouslySentInvoicesObject[
                              entriesToInvoice.join("|")
                            ];

                          // Don't allow resending invoice if triggered in last 30s
                          if (foundDate) {
                            const foundDateTime = moment(foundDate);
                            const currentTime = moment();
                            const timeDifference = currentTime.diff(
                              foundDateTime,
                              "seconds"
                            );

                            if (timeDifference < 30) {
                              setPaymentError(
                                "An invoice has already been sent for these entries. Please check your junk mail folder or wait 30 seconds before sending a new invoice."
                              );
                              setLoadingPayment(false);

                              return;
                            }
                          }

                          await sendInvoice({
                            entries: entriesToInvoice,
                          });

                          // Add timestamp to prevent multiple submissions in a short period
                          const storageItem = {
                            ...previouslySentInvoicesObject,
                            [entriesToInvoice.join("|")]: new Date(Date.now()),
                          };

                          _window.sessionStorage?.setItem(
                            "sentInvoices",
                            JSON.stringify(storageItem)
                          );

                          _window.sessionStorage?.removeItem("entriesToSubmit");

                          await navigate("/confirmation", {
                            state: {
                              fromCheckoutPage: true,
                            },
                          });
                        } catch (err: any) {
                          if (
                            err.message &&
                            err.message === "Invoice already sent"
                          ) {
                            setPaymentError(
                              `An invoice has already been sent for these entries. Please check your junk mail folder or contact ${supportEmail}.`
                            );
                          } else {
                            setPaymentError(
                              `There was an error processing your payment. Please contact ${supportEmail}.`
                            );
                          }
                        } finally {
                          setLoadingPayment(false);
                        }
                      }}
                    >
                      {loadingPayment ? "Loading..." : "Confirm"}
                    </Button>
                    {paymentError && (
                      <p className={styles.deadlineMsg}>
                        {paymentError ||
                          `There was an error processing your payment. Please contact ${supportEmail}.`}
                      </p>
                    )}
                  </div>
                </FormButtonRow>
              </Column>
            </>
          )}
        </Row>
      </Container>
    </SiteWrapper>
  );
}
