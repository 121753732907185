import { AxiosResponse } from "axios";

import { endpoint } from ".";
import {
  AwardsEntryResponseLimited,
  AwardsEntryUpdateResult,
  NewEntry,
} from "./types/awards-entries";

const basePath = "/awards/entries";

export const addAwardsEntry = async (details: NewEntry) => {
  try {
    const res: AxiosResponse<{ newEntry: AwardsEntryResponseLimited }> =
      await endpoint.post(
        `/awards/categories/${details.awards_category_id}/entries`,
        details
      );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const deleteEntry = async (entryId: number) => {
  try {
    const res: AxiosResponse<{ deletedEntry: number }> = await endpoint.delete(
      `${basePath}/${entryId}`
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getEntryByID = async (entryId: number) => {
  try {
    const res: AxiosResponse<{ entry: AwardsEntryResponseLimited }> =
      await endpoint.get(`${basePath}/${entryId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateEntryByID = async (
  entryId: number,
  details: AwardsEntryResponseLimited
) => {
  try {
    const res: AxiosResponse<{ updatedEntry: AwardsEntryResponseLimited }> =
      await endpoint.post(`${basePath}/${entryId}`, details);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateResultByID = async (
  entryId: number,
  details: AwardsEntryUpdateResult
) => {
  try {
    const res: AxiosResponse<{ updatedEntry: AwardsEntryResponseLimited }> =
      await endpoint.post(`${basePath}/${entryId}/update-result`, details);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const sendInvoice = async (details: { entries: number[] }) => {
  try {
    const res: AxiosResponse<{
      invoicedEntries: {
        entry_id: number;
        invoice_sent: boolean;
        invoice_id: string;
      }[];
    }> = await endpoint.post(`${basePath}/send-invoice`, details);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const completeEntry = async (entryId: number) => {
  try {
    const res: AxiosResponse<{
      completedEntry: {
        entry_id: string;
        title: string;
        is_paid: boolean;
      };
    }> = await endpoint.post(
      `${basePath}/${entryId.toString()}/complete-entry`
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const markEntryAsIncomplete = async (entryId: number) => {
  try {
    const res: AxiosResponse<{
      incompleteEntry: {
        entry_id: string;
        title: string;
      };
    }> = await endpoint.post(
      `${basePath}/${entryId.toString()}/mark-as-incomplete`
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const markEntryAsPaid = async (entryId: number) => {
  try {
    const res: AxiosResponse<{
      completedEntry: {
        entry_id: string;
        title: string;
        is_paid: boolean;
      };
    }> = await endpoint.post(`${basePath}/${entryId.toString()}/pay-entry`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
