export const maxSimultaneousFileUploads = 1;

export const chunkSize = 5 * 1024 * 1024; // 5MB

export const awsDownload = async (url: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = url;

  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();

  link.parentNode?.removeChild(link);
};
