// extracted by mini-css-extract-plugin
export var disabled = "EntryForm-module--disabled--91e9c";
export var entryFormContainer = "EntryForm-module--entryFormContainer--edc87";
export var error = "EntryForm-module--error--0cc94";
export var footnote = "EntryForm-module--footnote--73809";
export var form = "EntryForm-module--form--ab5a6";
export var formTable = "EntryForm-module--formTable--c98bd";
export var introMsg = "EntryForm-module--introMsg--d319f";
export var otherField = "EntryForm-module--otherField--f957c";
export var required = "EntryForm-module--required--439c5";
export var saveError = "EntryForm-module--saveError--f5212";
export var secondaryContactFields = "EntryForm-module--secondaryContactFields--59a73";
export var sponsorImgContainer = "EntryForm-module--sponsorImgContainer--c6b62";
export var tableFieldTitle = "EntryForm-module--tableFieldTitle--3a20e";
export var textField = "EntryForm-module--textField--a224c";