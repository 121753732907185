import React, { useEffect, useMemo, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Footer from "./footer";
import Header from "./header";
import HowToEnter from "./HowToEnter";
import { Modal } from "./modal";
import StickyNav from "./sticky-nav";
import TsAndCs from "./TsAndCs";
import useMobile from "../hooks/useMobile";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/site-wrapper.module.scss";
import { StickyFooterNavQuery } from "../../graphql-types";

interface SiteWrapperProps {
  hideStickyNav?: boolean;
  children: React.ReactNode;
}

export default function SiteWrapper({
  hideStickyNav = false,
  children,
}: SiteWrapperProps) {
  const data: StickyFooterNavQuery = useStaticQuery(graphql`
    query StickyFooterNav {
      stickyFooterNav(
        id: { eq: "sticky_footer_nav_sticky_footer_nav-715436" }
      ) {
        colour
        id
        nav_items {
          link_text
          link
        }
        items_per_row
      }
    }
  `);

  const stickyNav = useMemo(() => {
    if (!data) return;

    return data.stickyFooterNav;
  }, [data]);

  const isMobile = useMobile(1000);
  const _window = useWindow();

  const hash = _window.location.hash;

  const [modalOpen, setModalOpen] = useState<string | null>(null);

  useEffect(() => {
    if (hash === "#how-to-enter") setModalOpen("howToEnter");
    if (hash === "#ts-and-cs") setModalOpen("tAndCs");
  }, [hash]);

  return (
    <>
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
      {modalOpen === "tAndCs" && (
        <Modal
          closeOnBackgroundClick
          className={styles.siteWrapperModal}
          onClose={() => {
            setModalOpen(null);
            _window.location.hash = "";
          }}
        >
          <TsAndCs />
        </Modal>
      )}
      {!hideStickyNav &&
        !isMobile &&
        stickyNav &&
        stickyNav.nav_items &&
        stickyNav.items_per_row && (
          <StickyNav
            id={stickyNav.id}
            items={stickyNav.nav_items}
            color={stickyNav.colour?.toLowerCase() as SiteColors}
            itemsPerRow={stickyNav.items_per_row}
          />
        )}
      {modalOpen === "howToEnter" && (
        <Modal
          closeOnBackgroundClick
          className={styles.siteWrapperModal}
          onClose={() => {
            setModalOpen(null);
            _window.location.hash = "";
          }}
        >
          <HowToEnter />
        </Modal>
      )}
    </>
  );
}
