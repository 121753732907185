import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import classNames from "classnames";
import moment from "moment";
import _, { set } from "lodash";

import { EntrantResponse } from "../api/types/accounts";
import {
  AwardsEntrantsQueryParams,
  EntrantSortParams,
} from "../api/types/awards-years";
import { extendEntrantDeadline } from "../api/awards-entrants";
import tick from "../assets/svgs/tick-circle.svg";
import Button from "../components/button";
import { FormButtonRow } from "../components/form";
import Input from "../components/input";
import { Column, Container, Row } from "../components/layout";
import { Modal } from "../components/modal";
import SiteWrapper from "../components/SiteWrapper";
import Table from "../components/Table";
import { AdminContext } from "../context/admin";
import { AwardsContext } from "../context/awards";
import * as styles from "../styles/AdminManageEntrantsPage.module.scss";

interface ExtendDeadlineModalProps {
  entrant_id: number;
  closeModal: () => void;
}

const ExtendDeadlineModal = ({
  entrant_id,
  closeModal,
}: ExtendDeadlineModalProps) => {
  const [error, setError] = useState(false);

  return (
    <Modal className={styles.modal} onClose={closeModal}>
      <>
        <h3>Are you sure?</h3>
        <p>
          The entrant's deadline to submit entries will be extended by 4 days
          from today.
        </p>
        <FormButtonRow className={styles.modalSubmitButtons}>
          <Button buttonType="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            buttonType="primary"
            onClick={async () => {
              try {
                await extendEntrantDeadline(entrant_id);

                closeModal();
              } catch {
                setError(true);
              }
            }}
          >
            Confirm
          </Button>
        </FormButtonRow>
        {error && (
          <p className={styles.modalError}>
            Failed to extend deadline. Please try again later.
          </p>
        )}
      </>
    </Modal>
  );
};

const entrantColumnHelper = createColumnHelper<EntrantResponse>();

export default function AdminManageEntrantsPage() {
  const { currentYear } = useContext(AwardsContext);
  const { getEntrants } = useContext(AdminContext);

  const [queryConfig, setQueryConfig] = useState<AwardsEntrantsQueryParams>({
    year: currentYear?.year,
  });
  const [fetchStatus, setFetchStatus] = useState<
    "loading" | "loaded" | "error"
  >("loading");

  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  const [entrants, setEntrants] = useState<EntrantResponse[]>([]);
  const [totalEntrants, setTotalEntrants] = useState(0);
  const [extendDeadlineModal, setExtendDeadlineModal] = useState<number | null>(
    null
  );

  // Initial fetch
  useEffect(() => {
    const fetchEntrants = async (year: number) => {
      try {
        setFetchStatus("loading");

        const {
          entrants: _entrants,
          // TODO: This is returning the total entrants without filtering. Fix it!
          // * It only returns entrants with an entry in the current year. How to search for entrants without an entry?
          totalEntrants,
        } = await getEntrants({
          year,
        });

        setEntrants(_entrants);
        setTotalEntrants(_entrants.length);
        setFetchStatus("loaded");
      } catch {
        setFetchStatus("error");
      }
    };

    if (currentYear) {
      fetchEntrants(currentYear.year);
    }
  }, [currentYear]);

  const refetchEntrants = useCallback(
    async (queryParams?: AwardsEntrantsQueryParams) => {
      try {
        setFetchStatus("loading");

        console.log("Fetching entrants with query params", queryParams);

        const { entrants: refetchedEntrants, totalEntrants } =
          await getEntrants(queryParams);

        setEntrants(refetchedEntrants);
        setTotalEntrants(totalEntrants);
        setFetchStatus("loaded");
      } catch {
        setFetchStatus("error");
      }
    },
    []
  );

  const updateSort = useCallback(
    (sortId: EntrantSortParams) => {
      if (!currentYear) return;

      let sortOrder: "ASC" | "DESC" = "DESC";

      if (!queryConfig.sort && sortId === "entrant_id") {
        sortOrder = "ASC";
      } else {
        sortOrder = queryConfig.sortOrder === "DESC" ? "ASC" : "DESC";
      }

      const newQueryConfig: AwardsEntrantsQueryParams = {
        ...queryConfig,
        page: 1,
        sort: sortId,
        sortOrder,
      };

      setQueryConfig(newQueryConfig);

      refetchEntrants(newQueryConfig);
    },
    [currentYear, queryConfig, refetchEntrants]
  );

  const columnDefs: ColumnDef<EntrantResponse, any>[] = useMemo(() => {
    return [
      entrantColumnHelper.accessor("entrant_id", {
        header: () => (
          <div
            className={classNames(styles.idColumn, styles.sortableCol)}
            onClick={() => updateSort("entrant_id")}
          >
            ID
            {(!queryConfig.sort || queryConfig.sort === "entrant_id") &&
              `${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 50,
      }),
      entrantColumnHelper.accessor("email", {
        header: () => (
          <div
            className={styles.sortableCol}
            onClick={() => updateSort("email")}
          >
            Entrant
            {queryConfig.sort === "email" &&
              ` ${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 230,
        cell: ({
          row: {
            original: { first_name, last_name, email },
          },
        }) => (
          <>
            <p>
              {first_name} {last_name}
            </p>
            <span className={styles.subDetails}>{email}</span>
          </>
        ),
      }),
      entrantColumnHelper.accessor("is_confirmed", {
        header: () => (
          <div className={styles.centeredColumn}>Account confirmed?</div>
        ),
        size: 140,
        cell: ({
          row: {
            original: { is_confirmed },
          },
        }) => (
          <>
            {is_confirmed && (
              <img className={styles.tickIcon} src={tick} alt="Yes" />
            )}
          </>
        ),
      }),
      entrantColumnHelper.accessor("last_login", {
        header: "Last login",
        size: 120,
        cell: ({
          row: {
            original: { last_login },
          },
        }) =>
          last_login ? (
            moment(new Date(last_login)).format("DD/MM/YYYY")
          ) : (
            <span className={styles.subDetails}>N/A</span>
          ),
      }),
      entrantColumnHelper.accessor("entry_count", {
        header: () => <div className={styles.centeredColumn}>Entry count</div>,
        size: 80,
        cell: ({
          row: {
            original: { email, entry_count },
          },
        }) => (
          <div className={styles.centeredColumn}>
            {entry_count ? (
              <Button href={`/admin/manage-entries?email=${email}`}>
                {entry_count}
              </Button>
            ) : (
              entry_count || 0
            )}
          </div>
        ),
      }),
      entrantColumnHelper.display({
        id: "actions",
        header: "Actions",
        size: 200,
        cell: ({
          row: {
            original: { entrant_id, entry_count, completed_entry_count },
          },
        }) => (
          <div className={styles.actionButtons}>
            {(entry_count === 0 ||
              (completed_entry_count || 0) > (entry_count || 0)) && (
              <Button
                style={{ width: "100%" }}
                onClick={() => setExtendDeadlineModal(entrant_id)}
              >
                Extend deadline
              </Button>
            )}
          </div>
        ),
      }),
    ];
  }, [queryConfig]);

  if (!currentYear?.year) {
    return null;
  }

  return (
    <>
      <SiteWrapper hideStickyNav>
        <Container>
          <Row>
            <Column gridCols={12}>
              <FormButtonRow className={styles.tableControls}>
                <form
                  className={styles.searchContainer}
                  onSubmit={async (e) => {
                    e.preventDefault();

                    let newSearchTerm = currentSearchTerm;

                    if (
                      currentSearchTerm !== "" &&
                      currentSearchTerm === queryConfig.search
                    ) {
                      newSearchTerm = "";
                      setCurrentSearchTerm(newSearchTerm);
                    }

                    const newQueryConfig = {
                      ...queryConfig,
                      search: newSearchTerm || "",
                    };

                    setQueryConfig(newQueryConfig);

                    const { entrants: _entrants } = await getEntrants(
                      newQueryConfig
                    );

                    setEntrants(_entrants);
                    setTotalEntrants(_entrants.length);
                  }}
                >
                  <Input
                    className={styles.tableSearch}
                    dark
                    placeholder="Enter search term"
                    value={currentSearchTerm}
                    onChange={(e) => setCurrentSearchTerm(e.target.value)}
                  />
                  <Button
                    buttonType="tertiary"
                    className={styles.searchButton}
                    // @ts-ignore
                    submit
                    disabled={currentSearchTerm === "" && !queryConfig.search}
                  >
                    {currentSearchTerm !== "" &&
                    currentSearchTerm === queryConfig.search
                      ? "Clear"
                      : "Search"}
                  </Button>
                  <p>{totalEntrants} entrants</p>
                </form>
              </FormButtonRow>
              <Table
                tableClassName={styles.entrantsTable}
                getCellClassName={(cell) =>
                  classNames({
                    [styles.idColumn]: cell.column.id === "entrant_id",
                  })
                }
                loading={fetchStatus === "loading"}
                error={fetchStatus === "error"}
                data={entrants}
                columns={columnDefs}
                growColumnIndex={1}
              />
              {totalEntrants > entrants.length && (
                <Button
                  buttonType="tertiary"
                  style={{ display: "block", margin: "16px auto 0" }}
                  onClick={async () => {
                    const newQueryConfig = {
                      ...queryConfig,
                      page: queryConfig.page ? queryConfig.page + 1 : 2,
                    };

                    setQueryConfig(newQueryConfig);

                    const { entrants: nextEntrants } = await getEntrants(
                      newQueryConfig
                    );

                    setEntrants((curr) => [...curr, ...nextEntrants]);
                  }}
                >
                  Load more
                </Button>
              )}
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
      {extendDeadlineModal && (
        <ExtendDeadlineModal
          entrant_id={extendDeadlineModal}
          closeModal={() => setExtendDeadlineModal(null)}
        />
      )}
    </>
  );
}
