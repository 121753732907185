import React, { useContext, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";

import Button from "../components/button";
import { Column, Container, Row } from "../components/layout";
import LoadingLogo from "../components/LoadingLogo";
import SiteWrapper from "../components/SiteWrapper";
import { AccountContext } from "../context/account";
import * as styles from "../styles/site-wrapper.module.scss";

interface NotFoundPageProps extends RouteComponentProps {}

const NotFoundPage = ({}: NotFoundPageProps) => {
  const { account } = useContext(AccountContext);

  const showPrivatePages = useMemo(() => {
    if (!account.checked || (account.checked && !account.loggedIn)) {
      return false;
    }

    return true;
  }, [account]);

  if (!account.checked) {
    <LoadingLogo className={styles.fullPageLoader} />;
  }

  return (
    <SiteWrapper>
      <Container>
        <Row>
          <Column gridCols={6} textCenter>
            <h1>Page not found - 404 error.</h1>
            <Button style={{ display: "block", margin: 8 }} href="/login">
              Login
            </Button>
            <Button
              style={{ display: "block", margin: 8 }}
              href="/forgot-password"
            >
              Forgot password
            </Button>
            <Button
              style={{ display: "block", margin: 8 }}
              href="/create-account"
            >
              Create account
            </Button>
            {showPrivatePages && (
              <>
                <Button
                  style={{ display: "block", margin: 8 }}
                  href="/dashboard"
                >
                  Dashboard
                </Button>
                <Button style={{ display: "block", margin: 8 }} href="/profile">
                  Profile
                </Button>
                <Button
                  style={{ display: "block", margin: 8 }}
                  href="/uploader"
                >
                  Uploader
                </Button>
              </>
            )}
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
};

export default NotFoundPage;
