import {
  AbortMultipartUploadCommand,
  AbortMultipartUploadCommandInput,
  CompletedPart,
  CompleteMultipartUploadCommand,
  CompleteMultipartUploadCommandInput,
  CreateMultipartUploadCommand,
  CreateMultipartUploadCommandInput,
  S3Client,
  UploadPartCommand,
  UploadPartCommandInput,
} from "@aws-sdk/client-s3";

import { BUCKET_NAME } from ".";

// Returns the uploadID that must be referenced when pausing/resuming the upload
export const awsCreateMultipart = async (
  s3Client: S3Client,
  fileName: string
) => {
  const params: CreateMultipartUploadCommandInput = {
    Bucket: BUCKET_NAME,
    // ContentDisposition: "attachment",
    Key: `uploads/${fileName}`,
  };

  try {
    const data = await s3Client.send(new CreateMultipartUploadCommand(params));

    return data.UploadId;
  } catch (err) {
    console.error(err);
  }
};

// Parts must be at least 5MB in size
export const awsUploadPart = async (
  s3Client: S3Client,
  fileName: string,
  uploadId: string,
  partNumber: number,
  body: Blob
) => {
  const params: UploadPartCommandInput = {
    Bucket: BUCKET_NAME,
    Key: `uploads/${fileName}`,
    PartNumber: partNumber,
    UploadId: uploadId,
    Body: body,
  };

  try {
    const data = await s3Client.send(new UploadPartCommand(params));

    return data;
  } catch (err) {
    console.error(err);
  }
};

export const awsCompleteMpu = async (
  s3Client: S3Client,
  fileName: string,
  uploadId: string,
  parts: CompletedPart[]
) => {
  const params: CompleteMultipartUploadCommandInput = {
    Bucket: BUCKET_NAME,
    Key: `uploads/${fileName}`,
    MultipartUpload: {
      Parts: parts,
    },
    UploadId: uploadId,
  };

  try {
    const data = await s3Client.send(
      new CompleteMultipartUploadCommand(params)
    );

    return data;
  } catch (err) {
    console.error(err);
  }
};

export const awsAbortMpu = async (
  s3Client: S3Client,
  fileName: string,
  uploadId: string
) => {
  const params: AbortMultipartUploadCommandInput = {
    Bucket: BUCKET_NAME,
    Key: `uploads/${fileName}`,
    UploadId: uploadId,
  };

  try {
    const data = await s3Client.send(new AbortMultipartUploadCommand(params));
    return data;
  } catch (err) {
    console.error(err);
  }
};
