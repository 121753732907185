import React, { HTMLProps } from "react";

interface BurgerIconProps extends HTMLProps<HTMLDivElement> {
  color?: string;
}

const BurgerIcon = ({ color = "#ffffff80", ...rest }: BurgerIconProps) => {
  return (
    <div {...rest}>
      <svg
        style={{
          width: 24,
          fill: color,
        }}
        id="Layer_2"
        data-name="Layer 6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 138.17 118.96"
      >
        <title>drag-handle</title>
        <rect className="cls-1" width="138.17" height="20.94" rx="10.47" />
        <rect y="98.02" width="138.17" height="20.94" rx="10.47" />
        <rect y="50.08" width="138.17" height="20.94" rx="10.47" />
      </svg>
    </div>
  );
};

export default BurgerIcon;
