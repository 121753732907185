import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import classNames from "classnames";
import _ from "lodash";

import {
  addJudge,
  addJudgeCategories,
  removeJudge,
  removeJudgeCategories,
  updateJudge,
} from "../api/awards-judges";
import { AwardsCategoryResponseFull } from "../api/types/awards-categories";
import {
  AwardsYearJudgesQueryParams,
  BaseQueryParams,
  JudgesSortParams,
} from "../api/types/awards-years";
import Button, { DeleteButton, EditButton } from "../components/button";
import { FormButtonRow } from "../components/form";
import Input, { TextArea } from "../components/input";
import { Column, Container, Row } from "../components/layout";
import { Modal } from "../components/modal";
import SelectBox, { SelectBoxMulti } from "../components/select-box";
import SiteWrapper from "../components/SiteWrapper";
import Table from "../components/Table";
import { AdminContext } from "../context/admin";
import { AwardsContext } from "../context/awards";
import * as styles from "../styles/AdminManageJudgesPage.module.scss";

interface JudgeModalProps {
  judge: Judge;
  onSuccess: () => Promise<void>;
  closeModal: () => void;
}

interface EditableJudge extends Judge {
  hasBeenUpdated?: boolean;
}

interface EditJudgeModalProps extends JudgeModalProps {
  categories: AwardsCategoryResponseFull[];
}

interface JudgingItem {
  label: string;
  value: JudgingRoundType;
}

const judgeTypeList: JudgingItem[] = [
  {
    label: "Chair",
    value: "chair",
  },
  {
    label: "First round",
    value: "first",
  },
  {
    label: "Second round",
    value: "second",
  },
];

const judgingRoundList: JudgingItem[] = [
  {
    label: "Chair",
    value: "chair",
  },
  {
    label: "First",
    value: "first",
  },
  {
    label: "Second",
    value: "second",
  },
];

interface JudgingCategoriesProps {
  items: number[];
  allCategories: AwardsCategoryResponseFull[];
  type: JudgingRoundType;
}

const JudgingCategories = ({
  items,
  allCategories,
  type,
}: JudgingCategoriesProps) => {
  const isAllCategoriesJudge = items.length === allCategories.length;
  const categoriesList = useMemo(() => {
    return items
      .map((categoryId) => {
        return allCategories.find((item) => {
          const { awards_category_id } = item;
          return awards_category_id === categoryId;
        });
      })
      .filter((i) => i !== undefined);
  }, [items, allCategories]);

  return (
    <>
      {isAllCategoriesJudge ? (
        <div
          className={classNames(styles.bubble, {
            [styles[type]]: true,
          })}
        >
          All categories
        </div>
      ) : (
        categoriesList.map((category) => {
          if (category) {
            const { title } = category;
            return (
              <div
                className={classNames(styles.bubble, {
                  [styles[type]]: true,
                })}
              >
                {title}
              </div>
            );
          }
          return null;
        })
      )}
    </>
  );
};

const EditJudgeModal = ({
  judge,
  onSuccess,
  closeModal,
  categories,
}: EditJudgeModalProps) => {
  const [saveError, setSaveError] = useState(false);
  const [currentJudge, setCurrentJudge] = useState<EditableJudge | null>(null);

  useEffect(() => {
    setCurrentJudge({ ...judge, hasBeenUpdated: false });
  }, [judge]);

  if (currentJudge === null) return null;

  return (
    <Modal className={styles.modal} onClose={closeModal}>
      <>
        <h3>Edit judge</h3>
        <p>{`Edit ${currentJudge.first_name} ${currentJudge.last_name}'s fields or add them to judging category or categories`}</p>
        {currentJudge && (
          <>
            <TextArea
              id="description"
              name="description"
              label="Description"
              value={currentJudge.description}
              onChange={(e) => {
                setCurrentJudge((prev: any) => {
                  const useCurrent = prev || {};
                  return {
                    ...useCurrent,
                    description: e.target.value,
                    hasBeenUpdated: true,
                  };
                });
                setSaveError(false);
              }}
            />
            <SelectBoxMulti
              className={styles.categoryDropdown}
              id="first_round_categories"
              name="first_round_categories"
              label="First round categories"
              placeholder="Enter a value..."
              labelKey="title"
              valueKey="awards_category_id"
              options={categories}
              value={categories.filter(
                ({ awards_category_id }) =>
                  currentJudge.first_round_categories.indexOf(
                    awards_category_id
                  ) !== -1
              )}
              onChange={(newValue) => {
                setCurrentJudge((curr) => ({
                  ...(curr as EditableJudge),
                  first_round_categories: newValue.map(
                    ({ awards_category_id }) => awards_category_id
                  ),
                  hasBeenUpdated: true,
                }));
              }}
            />
            <SelectBoxMulti
              className={styles.categoryDropdown}
              id="second_round_categories"
              name="second_round_categories"
              label="Second round categories"
              placeholder="Enter a value..."
              labelKey="title"
              valueKey="awards_category_id"
              options={categories}
              value={categories.filter(
                ({ awards_category_id }) =>
                  currentJudge.second_round_categories.indexOf(
                    awards_category_id
                  ) !== -1
              )}
              onChange={(newValue) => {
                setCurrentJudge((curr) => ({
                  ...(curr as EditableJudge),
                  second_round_categories: newValue.map(
                    ({ awards_category_id }) => awards_category_id
                  ),
                  hasBeenUpdated: true,
                }));
              }}
            />
            <SelectBoxMulti
              className={styles.categoryDropdown}
              id="chair_categories"
              name="chair_categories"
              label="Chair categories"
              placeholder="Enter a value..."
              labelKey="title"
              valueKey="awards_category_id"
              options={categories}
              value={categories.filter(
                ({ awards_category_id }) =>
                  currentJudge.chair_categories.indexOf(awards_category_id) !==
                  -1
              )}
              onChange={(newValue) => {
                setCurrentJudge((curr) => ({
                  ...(curr as EditableJudge),
                  chair_categories: newValue.map(
                    ({ awards_category_id }) => awards_category_id
                  ),
                  hasBeenUpdated: true,
                }));
              }}
            />
          </>
        )}
        <FormButtonRow className={styles.modalSubmitButtons}>
          <Button buttonType="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            buttonType="primary"
            disabled={!currentJudge.hasBeenUpdated}
            onClick={async () => {
              try {
                const { hasBeenUpdated, description } = currentJudge;
                if (hasBeenUpdated) {
                  if (description && description !== judge.description) {
                    await updateJudge(currentJudge.judge_id, {
                      description: currentJudge.description,
                    });
                  }

                  const allCategories = {
                    first_round_categories: [
                      ...new Set([
                        ...currentJudge.first_round_categories,
                        ...judge.first_round_categories,
                      ]),
                    ],
                    second_round_categories: [
                      ...new Set([
                        ...currentJudge.second_round_categories,
                        ...judge.second_round_categories,
                      ]),
                    ],
                    chair_categories: [
                      ...new Set([
                        ...currentJudge.chair_categories,
                        ...judge.chair_categories,
                      ]),
                    ],
                  };

                  let addCategories = false;
                  const categoriesToAdd = {
                    first_round_categories: [] as number[],
                    second_round_categories: [] as number[],
                    chair_categories: [] as number[],
                  };

                  let removeCategories = false;
                  const categoriesToRemove = {
                    first_round_categories: [] as number[],
                    second_round_categories: [] as number[],
                    chair_categories: [] as number[],
                  };

                  Object.entries(allCategories).forEach(
                    ([judgingRound, categories]) => {
                      const add = categories.filter(
                        (category) =>
                          judge[
                            judgingRound as
                              | "first_round_categories"
                              | "second_round_categories"
                              | "chair_categories"
                          ].indexOf(category) === -1
                      );

                      const remove = categories.filter(
                        (category) =>
                          currentJudge[
                            judgingRound as
                              | "first_round_categories"
                              | "second_round_categories"
                              | "chair_categories"
                          ].indexOf(category) === -1
                      );

                      if (add.length > 0) {
                        categoriesToAdd[
                          judgingRound as
                            | "first_round_categories"
                            | "second_round_categories"
                            | "chair_categories"
                        ].push(...add);

                        addCategories = true;
                      }

                      if (remove.length > 0) {
                        categoriesToRemove[
                          judgingRound as
                            | "first_round_categories"
                            | "second_round_categories"
                            | "chair_categories"
                        ].push(...remove);

                        removeCategories = true;
                      }
                    }
                  );

                  if (addCategories) {
                    await addJudgeCategories(
                      currentJudge.judge_id,
                      categoriesToAdd
                    );
                  }

                  if (removeCategories) {
                    await removeJudgeCategories(
                      currentJudge.judge_id,
                      categoriesToRemove
                    );
                  }

                  await onSuccess();
                }
                closeModal();
              } catch {
                setSaveError(true);
              }
            }}
          >
            Save
          </Button>
        </FormButtonRow>
        {saveError && (
          <p className={styles.modalError}>
            Failed to update judge. Please try again later.
          </p>
        )}
      </>
    </Modal>
  );
};

interface DefaultJudge {
  first_name: string;
  last_name: string;
  description: string;
  email: string;
  hasBeenUpdated?: boolean;
  categories: number[];
  first_round_categories?: number[];
  second_round_categories?: number[];
  chair_categories?: number[];
}

const defaultJudge: DefaultJudge = {
  first_name: "",
  last_name: "",
  description: "",
  email: "",
  categories: [],
};

interface AddJudgeModalProps {
  onSuccess: () => Promise<void>;
  closeModal: () => void;
  categories: AwardsCategoryResponseFull[];
}

const AddJudgeModal = ({
  onSuccess,
  closeModal,
  categories,
}: AddJudgeModalProps) => {
  const [selectedRound, setSelectedRound] = useState<JudgingRoundType | null>(
    null
  );
  const [saveError, setSaveError] = useState(false);
  const [currentJudge, setCurrentJudge] = useState({ ...defaultJudge });

  return (
    <Modal className={styles.modal} onClose={closeModal}>
      <>
        <h3>Add new judge</h3>
        <p>New judges will get notified via email.</p>
        {currentJudge && (
          <>
            <Input
              label="First Name"
              required
              value={currentJudge.first_name}
              onChange={(e) => {
                setCurrentJudge((prev: any) => {
                  const useCurrent = prev || {};
                  return {
                    ...useCurrent,
                    first_name: e.target.value,
                  };
                });
                setSaveError(false);
              }}
            />
            <Input
              label="Last Name"
              required
              value={currentJudge.last_name}
              onChange={(e) => {
                setCurrentJudge((prev: any) => {
                  const useCurrent = prev || {};
                  return {
                    ...useCurrent,
                    last_name: e.target.value,
                  };
                });
                setSaveError(false);
              }}
            />
            <Input
              label="Email"
              required
              value={currentJudge.email}
              onChange={(e) => {
                setCurrentJudge((prev: any) => {
                  const useCurrent = prev || {};
                  return {
                    ...useCurrent,
                    email: e.target.value,
                  };
                });
                setSaveError(false);
              }}
            />
            <TextArea
              id="description"
              name="description"
              label="Description"
              value={currentJudge.description}
              onChange={(e) => {
                setCurrentJudge((prev: any) => {
                  const useCurrent = prev || {};
                  return {
                    ...useCurrent,
                    description: e.target.value,
                  };
                });
                setSaveError(false);
              }}
            />
            <>
              <SelectBox
                className={styles.categoryDropdown}
                label="Judge type"
                isClearable
                value={judgeTypeList.find(
                  (round) => round.value === selectedRound
                )}
                options={judgeTypeList}
                onChange={async (newValue) => {
                  if (newValue) {
                    setSelectedRound(newValue.value);
                  }
                }}
              />
              {selectedRound && (
                <SelectBoxMulti
                  id="categories"
                  className={styles.fullWidth}
                  name="categories"
                  label="Judging categories"
                  stacked
                  placeholder="Enter a value..."
                  labelKey="title"
                  valueKey="awards_category_id"
                  options={categories}
                  value={categories.filter(
                    ({ awards_category_id }) =>
                      currentJudge.categories.indexOf(awards_category_id) !== -1
                  )}
                  onChange={(newValue) => {
                    const updatedJudge = {
                      ...currentJudge,
                      categories: newValue.map(
                        ({ awards_category_id }) => awards_category_id
                      ),
                    };
                    setCurrentJudge(updatedJudge);
                  }}
                />
              )}
            </>
          </>
        )}
        <FormButtonRow className={styles.modalSubmitButtons}>
          <Button buttonType="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className={styles.modalSubmitButton}
            buttonType="primary"
            onClick={async () => {
              try {
                const useJudge: any = { ...currentJudge };

                const { categories: _cats } = currentJudge;

                delete useJudge.categories;
                delete useJudge.first_round_categories;
                delete useJudge.second_round_categories;
                delete useJudge.chair_categories;

                if (selectedRound === "chair") {
                  useJudge.chair_categories = _cats;
                }
                if (selectedRound === "first") {
                  useJudge.first_round_categories = _cats;
                }
                if (selectedRound === "second") {
                  useJudge.second_round_categories = _cats;
                }
                await addJudge(useJudge);
                await onSuccess();
                closeModal();
              } catch {
                setSaveError(true);
              }
            }}
          >
            Save
          </Button>
        </FormButtonRow>
        {saveError && (
          <p className={styles.modalError}>
            Failed to add judge. Make sure all required fields have been filled
            in and try again.
          </p>
        )}
      </>
    </Modal>
  );
};

const DeleteJudgeModal = ({
  judge,
  onSuccess,
  closeModal,
}: JudgeModalProps) => {
  const [deleteError, setDeleteError] = useState(false);

  return (
    <Modal className={styles.modal} onClose={closeModal}>
      <>
        <h3 className={styles.modalWarning}>WARNING</h3>
        <p>
          You are about to remove a judge{" "}
          <strong>
            "{judge.first_name} {judge.last_name}"
          </strong>
          , email: <strong>{judge.email}</strong>.
        </p>
        <p>Their user account will not be deleted.</p>
        <FormButtonRow className={styles.modalSubmitButtons}>
          <Button buttonType="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className={styles.modalSubmitButton}
            buttonType="primary"
            onClick={async () => {
              try {
                await removeJudge(judge.judge_id);
                await onSuccess();

                closeModal();
              } catch {
                setDeleteError(true);
              }
            }}
          >
            Delete
          </Button>
        </FormButtonRow>
        {deleteError && (
          <p className={styles.modalError}>
            Failed to delete judge. Please try again later.
          </p>
        )}
      </>
    </Modal>
  );
};

const judgeColumnHelper = createColumnHelper<Judge>();

export default function AdminManageJudgesPage() {
  const { currentYear } = useContext(AwardsContext);
  const { categories, getJudges } = useContext(AdminContext);

  const [queryConfig, setQueryConfig] = useState<AwardsYearJudgesQueryParams>(
    {}
  );
  const [fetchStatus, setFetchStatus] = useState<
    "loading" | "loaded" | "error"
  >("loading");

  const [judges, setJudges] = useState<Judge[]>([]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  const [totalJudges, setTotalJudges] = useState(0);
  const [actionModal, setActionModal] = useState<{
    modalType: "add" | "edit" | "delete";
    judge: Judge;
  } | null>(null);
  const [showAddModal, setShowAddModal] = useState(false);

  // Initial fetch
  useEffect(() => {
    const fetchJudges = async (year: number) => {
      try {
        setFetchStatus("loading");

        const { judges: _judges, total_count } = await getJudges(year);

        if (_judges && Array.isArray(_judges)) {
          setJudges(_judges);
          setTotalJudges(total_count);
        }

        setFetchStatus("loaded");
      } catch {
        setFetchStatus("error");
      }
    };

    if (categories && currentYear) {
      fetchJudges(currentYear.year);
    }
  }, [categories, currentYear]);

  const refetchJudges = useCallback(
    async (year: number, queryParams?: BaseQueryParams) => {
      try {
        setFetchStatus("loading");

        const { judges: refetchedJudges } = await getJudges(year, queryParams);

        setJudges(refetchedJudges);
        setFetchStatus("loaded");
      } catch {
        setFetchStatus("error");
      }
    },
    []
  );

  const updateSort = useCallback(
    (sortId: JudgesSortParams) => {
      if (!currentYear) return;

      let sortOrder: "ASC" | "DESC" = "DESC";

      if (!queryConfig.sort && sortId === "judge_id") {
        sortOrder = "ASC";
      } else {
        sortOrder = queryConfig.sortOrder === "DESC" ? "ASC" : "DESC";
      }

      const newQueryConfig: AwardsYearJudgesQueryParams = {
        ...queryConfig,
        page: 1,
        sort: sortId,
        sortOrder,
      };

      setQueryConfig(newQueryConfig);

      refetchJudges(currentYear.year, newQueryConfig);
    },
    [currentYear, queryConfig, refetchJudges]
  );

  const columnDefs: ColumnDef<Judge, any>[] = useMemo(() => {
    const currentList =
      categories && currentYear ? categories[currentYear.year] : [];

    return [
      judgeColumnHelper.accessor("judge_id", {
        header: () => (
          <div
            className={classNames(styles.idColumn, styles.sortableCol)}
            onClick={() => updateSort("judge_id")}
          >
            ID{" "}
            {(!queryConfig.sort || queryConfig.sort === "judge_id") &&
              `${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 50,
      }),
      judgeColumnHelper.accessor("first_name", {
        header: () => (
          <div
            className={styles.sortableCol}
            onClick={() => updateSort("first_name")}
          >
            First name{" "}
            {queryConfig.sort === "first_name" &&
              ` ${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 125,
      }),
      judgeColumnHelper.accessor("last_name", {
        header: () => (
          <div
            className={styles.sortableCol}
            onClick={() => updateSort("last_name")}
          >
            Last name{" "}
            {queryConfig.sort === "last_name" &&
              ` ${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 125,
      }),
      judgeColumnHelper.accessor("email", {
        header: () => (
          <div
            className={styles.sortableCol}
            onClick={() => updateSort("email")}
          >
            Email{" "}
            {queryConfig.sort === "email" &&
              ` ${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 275,
      }),
      judgeColumnHelper.accessor("last_login", {
        header: () => (
          <div
            className={styles.sortableCol}
            onClick={() => updateSort("last_login")}
          >
            Last login{" "}
            {queryConfig.sort === "last_login" &&
              ` ${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 150,
        cell: ({
          row: {
            original: { last_login },
          },
        }) => {
          const lastLogin =
            typeof last_login === "string" ? (
              moment(new Date(last_login)).format("Do MMM YYYY")
            ) : (
              <span className={styles.subDetails}>N/A</span>
            );

          return lastLogin;
        },
      }),
      judgeColumnHelper.display({
        id: "categories",
        header: "Categories",
        size: 275,
        cell: ({
          row: {
            original: {
              chair_categories,
              first_round_categories,
              second_round_categories,
            },
          },
        }) => (
          <div className={styles.judgeCategoryBubbles}>
            <JudgingCategories
              items={chair_categories}
              allCategories={currentList}
              type="chair"
            />
            <JudgingCategories
              items={first_round_categories}
              allCategories={currentList}
              type="first"
            />
            <JudgingCategories
              items={second_round_categories}
              allCategories={currentList}
              type="second"
            />
          </div>
        ),
      }),
      judgeColumnHelper.display({
        id: "actions",
        header: "Actions",
        size: 150,
        cell: ({ row: { original: judge } }) => (
          <div className={styles.actionButtons}>
            <EditButton
              onClick={() =>
                setActionModal({
                  modalType: "edit",
                  judge,
                })
              }
            />
            <DeleteButton
              onClick={() =>
                setActionModal({
                  modalType: "delete",
                  judge,
                })
              }
            />
          </div>
        ),
      }),
    ];
  }, [categories, currentYear, queryConfig]);

  if (!categories || !currentYear?.year) {
    return null;
  }

  return (
    <>
      <SiteWrapper hideStickyNav>
        <Container>
          <Row>
            <Column gridCols={12}>
              <FormButtonRow className={styles.tableControls}>
                <form
                  className={styles.searchContainer}
                  onSubmit={async (e) => {
                    e.preventDefault();

                    let newSearchTerm = currentSearchTerm;

                    if (
                      currentSearchTerm !== "" &&
                      currentSearchTerm === queryConfig.search
                    ) {
                      newSearchTerm = "";
                      setCurrentSearchTerm(newSearchTerm);
                    }

                    const newQueryConfig = {
                      ...queryConfig,
                      page: 1,
                      search: newSearchTerm || "",
                    };

                    setQueryConfig(newQueryConfig);

                    const { judges: _judges, total_count } = await getJudges(
                      currentYear.year,
                      newQueryConfig
                    );

                    setJudges(_judges);
                    setTotalJudges(total_count);
                  }}
                >
                  <Input
                    className={styles.tableSearch}
                    dark
                    placeholder="Enter search term"
                    value={currentSearchTerm}
                    onChange={(e) => setCurrentSearchTerm(e.target.value)}
                  />
                  <Button
                    buttonType="tertiary"
                    className={styles.searchButton}
                    // @ts-ignore
                    submit
                    disabled={currentSearchTerm === "" && !queryConfig.search}
                  >
                    {currentSearchTerm !== "" &&
                    currentSearchTerm === queryConfig.search
                      ? "Clear"
                      : "Search"}
                  </Button>
                  <p>{totalJudges} judges</p>
                </form>
                <Button
                  buttonType="primary"
                  onClick={() => {
                    setShowAddModal(true);
                  }}
                >
                  Add judge
                </Button>
                <SelectBox
                  className={styles.categoryDropdown}
                  label="Judging round"
                  dark
                  isClearable
                  value={judgingRoundList.find(
                    (round) => round.value === queryConfig.judgingRound
                  )}
                  options={judgingRoundList}
                  onChange={async (newValue) => {
                    const newQueryConfig = {
                      ...queryConfig,
                      page: 1,
                      judgingRound: newValue ? newValue.value : undefined,
                    };

                    setQueryConfig(newQueryConfig);

                    const { judges: _judges, total_count } = await getJudges(
                      currentYear.year,
                      newQueryConfig
                    );

                    setJudges(_judges);
                    setTotalJudges(total_count);
                  }}
                />
                <SelectBox
                  className={styles.categoryDropdown}
                  label="Category"
                  dark
                  isClearable
                  valueKey="awards_category_id"
                  labelKey="title"
                  value={categories[currentYear.year].find(
                    (category) =>
                      category.awards_category_id === queryConfig.category
                  )}
                  options={categories[currentYear.year]}
                  onChange={async (newValue) => {
                    const newQueryConfig = {
                      ...queryConfig,
                      page: 1,
                      category: newValue
                        ? newValue.awards_category_id
                        : undefined,
                    };

                    setQueryConfig(newQueryConfig);

                    const { judges: _judges, total_count } = await getJudges(
                      currentYear.year,
                      newQueryConfig
                    );

                    setJudges(_judges);
                    setTotalJudges(total_count);
                  }}
                />
              </FormButtonRow>
              <Table
                tableClassName={styles.judgesTable}
                getRowClassName={({ is_active }) =>
                  classNames({ [styles.inactive]: !is_active })
                }
                getCellClassName={(cell) =>
                  classNames({
                    [styles.idColumn]: cell.column.id === "judge_id",
                    [styles.emailColumn]: cell.column.id === "email",
                  })
                }
                growColumnIndex={5}
                loading={fetchStatus === "loading"}
                error={fetchStatus === "error"}
                data={judges}
                columns={columnDefs}
              />
              {totalJudges > judges.length && (
                <Button
                  buttonType="tertiary"
                  style={{ display: "block", margin: "16px auto 0" }}
                  onClick={async () => {
                    const newQueryConfig = {
                      ...queryConfig,
                      page: queryConfig.page ? queryConfig.page + 1 : 2,
                    };

                    setQueryConfig(newQueryConfig);

                    const { judges: nextJudges } = await getJudges(
                      currentYear.year,
                      newQueryConfig
                    );

                    setJudges((curr) => [...curr, ...nextJudges]);
                  }}
                >
                  Load more
                </Button>
              )}
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
      {showAddModal && (
        <AddJudgeModal
          categories={categories[currentYear.year]}
          closeModal={() => setShowAddModal(false)}
          onSuccess={() => refetchJudges(currentYear.year)}
        />
      )}
      {actionModal && (
        <>
          {actionModal.modalType === "delete" && (
            <DeleteJudgeModal
              judge={actionModal.judge}
              closeModal={() => setActionModal(null)}
              onSuccess={() => refetchJudges(currentYear.year)}
            />
          )}
          {actionModal.modalType === "edit" && (
            <EditJudgeModal
              categories={categories[currentYear.year]}
              judge={actionModal.judge}
              closeModal={() => setActionModal(null)}
              onSuccess={() => refetchJudges(currentYear.year)}
            />
          )}
        </>
      )}
    </>
  );
}
