import React from "react";
import classNames from "classnames";

import Button from "./button";
import * as styles from "../styles/sticky-nav.module.scss";

export interface StickyNavProps {
  id: string;
  items: ({
    link_text?: string | null;
    link?: string | null;
  } | null)[];
  color?: SiteColors;
  itemsPerRow?: number;
}

const StickyNav = ({
  id,
  items,
  color = "blue",
  itemsPerRow = 7,
}: StickyNavProps) => {
  return (
    <div className={styles.stickyNavContainer}>
      {items.map((item, index) => {
        if (!item || !item.link) return null;

        return (
          <Button
            key={`${id}-${index}`}
            className={classNames(styles.stickyNavButton, styles[color])}
            style={{ width: `calc((100% / ${itemsPerRow}) - 2px)` }}
            buttonType="stickyNav"
            href={item.link}
          >
            <span>{item.link_text}</span>
          </Button>
        );
      })}
    </div>
  );
};

export default StickyNav;
