import React, { useContext, useState, useCallback, useEffect } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import _ from "lodash";

import { updateAccountDetails } from "../api/account";
import { addEntrant, updateEntrant } from "../api/awards-entrants";
import Button from "../components/button";
import { Column, Container, Row } from "../components/layout";
import Input, { Checkbox } from "../components/input";
import { FormButtonRow, FormError, FormMessage } from "../components/form";
import SiteWrapper from "../components/SiteWrapper";
import { ResendConfirmationEmailModal } from "../components/ResendConfirmationEmailModal";
import { Divider } from "../components/typeface";
import { AccountContext } from "../context/account";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/ProfilePage.module.scss";

interface LocationState {
  newUser?: boolean;
}

const ProfilePage = () => {
  const { account, setAccount } = useContext(AccountContext);

  const _window = useWindow();

  const state = useLocation().state as LocationState;

  const [name, setName] = useState({
    hasBeenUpdated: false,
    first_name: account.first_name,
    last_name: account.last_name,
  });
  const [entrantDetails, setEntrantDetails] = useState(
    account.entrantDetails
      ? { ...account.entrantDetails, hasBeenUpdated: false }
      : { hasBeenUpdated: false }
  );
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [showResendConfirmationModal, setShowResendConfirmationModal] =
    useState(false);

  // Reset account details on save or fetch
  useEffect(() => {
    if (account.first_name || account.last_name) {
      setName({
        hasBeenUpdated: false,
        first_name: account.first_name,
        last_name: account.last_name,
      });
    }

    if (account.entrantDetails) {
      setEntrantDetails({
        ...account.entrantDetails,
        hasBeenUpdated: false,
      });
    }
  }, [account]);

  const onSubmit = useCallback(async () => {
    setSaveError(null);

    if (!name.hasBeenUpdated && !entrantDetails.hasBeenUpdated) {
      setSaveError("Please update some of your account details.");
      return;
    }

    if (
      !name.first_name ||
      !name.last_name ||
      !entrantDetails.address_1 ||
      !entrantDetails.town_city ||
      !entrantDetails.postcode ||
      !entrantDetails.country ||
      !entrantDetails.phone
    ) {
      setSaveError("Please fill in all required fields.");
      return;
    }

    try {
      if (name.hasBeenUpdated) {
        const { updatedAccount } = await updateAccountDetails(
          account.uid as number,
          name
        );

        setAccount((curr) => {
          return {
            ...curr,
            first_name: updatedAccount.first_name,
            last_name: updatedAccount.last_name,
          };
        });
      }

      if (entrantDetails.hasBeenUpdated) {
        if (!account.entrant_id) {
          const { entrant } = await addEntrant(entrantDetails);

          setAccount((curr) => {
            return {
              ...curr,
              entrant_id: entrant.entrant_id,
              entrantDetails: {
                ...entrant,
              },
            };
          });
        } else {
          const { updatedEntrant } = await updateEntrant(
            account.entrant_id,
            entrantDetails
          );

          setAccount((curr) => {
            return {
              ...curr,
              entrantDetails: {
                ...curr.entrantDetails,
                ...updatedEntrant,
              },
            };
          });
        }
      }

      setUpdateSuccess(true);
      _window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Add smooth scrolling behavior
      });
    } catch (err: any) {
      setSaveError(
        err.message || "There was an error updating your account details"
      );
    }
  }, [account, entrantDetails, name]);

  if (!account || !account.uid) return null;

  return (
    <>
      <SiteWrapper>
        <Container>
          <Row>
            <Column gridCols={6}>
              <h1>User Details</h1>
              {state?.newUser && !updateSuccess && (
                <FormMessage>
                  <p>
                    Your account has been created. Please check your inbox for a
                    confirmation email. You can only create entries once your
                    account is confirmed.
                  </p>
                  <p>
                    Please also fill in your details here. They are required
                    before you can submit entries.
                  </p>
                  <p>
                    Can't find the email?{" "}
                    <Button
                      onClick={() => setShowResendConfirmationModal(true)}
                    >
                      Click here to resend it.
                    </Button>
                  </p>
                </FormMessage>
              )}
              {!account.accountConfirmed && !state && !updateSuccess && (
                <FormMessage>
                  <p>
                    Please check your inbox for a confirmation email. You can
                    only create entries once your account is confirmed.
                  </p>
                  <p>
                    Can't find the email?{" "}
                    <Button
                      onClick={() => setShowResendConfirmationModal(true)}
                    >
                      Click here to resend it.
                    </Button>
                  </p>
                </FormMessage>
              )}
              <p>
                In order to submit entries for the awards, you need to provide
                your name, address and billing details.
              </p>
              {updateSuccess && (
                <FormMessage>
                  <p>Your details have been successfully updated.</p>
                </FormMessage>
              )}
              <div className={styles.form}>
                <Input
                  id="first_name"
                  name="first_name"
                  label="First name"
                  required
                  error={!!saveError && !name.first_name}
                  placeholder="Enter your first name"
                  value={name.first_name || ""}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setName((curr) => {
                      return {
                        ...curr,
                        first_name: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                />
                <Input
                  id="last_name"
                  name="last_name"
                  label="Last name"
                  required
                  error={!!saveError && !name.last_name}
                  placeholder="Enter your last name"
                  value={name.last_name || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setName((curr) => {
                      return {
                        ...curr,
                        last_name: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Input
                  id="organisation"
                  name="organisation"
                  label="Organisation"
                  placeholder="Enter your organisation"
                  value={entrantDetails.organisation || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        organisation: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Input
                  id="job_title"
                  name="job_title"
                  label="Job title"
                  placeholder="Enter your job title"
                  value={entrantDetails.job_title || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        job_title: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Input
                  id="address_1"
                  name="address_1"
                  label="Address Line 1"
                  required
                  error={!!saveError && !entrantDetails.address_1}
                  placeholder="Enter your first address line"
                  value={entrantDetails.address_1 || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        address_1: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Input
                  id="address_2"
                  name="address_2"
                  label="Address Line 2"
                  placeholder="Enter your second address line"
                  value={entrantDetails.address_2 || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        address_2: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Input
                  id="address_3"
                  name="address_3"
                  label="Address Line 3"
                  placeholder="Enter your third address line"
                  value={entrantDetails.address_3 || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        address_3: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Input
                  id="town_city"
                  name="town_city"
                  label="Town/City"
                  required
                  error={!!saveError && !entrantDetails.town_city}
                  placeholder="Enter your town/city"
                  value={entrantDetails.town_city || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        town_city: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Input
                  id="postcode"
                  name="postcode"
                  label="Postcode"
                  required
                  error={!!saveError && !entrantDetails.postcode}
                  placeholder="Enter your postcode"
                  value={entrantDetails.postcode || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        postcode: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Input
                  id="country"
                  name="country"
                  label="Country"
                  required
                  error={!!saveError && !entrantDetails.country}
                  placeholder="Enter your country"
                  value={entrantDetails.country || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        country: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Input
                  id="phone"
                  name="phone"
                  label="Phone number"
                  required
                  error={!!saveError && !entrantDetails.phone}
                  placeholder="Enter your contact number"
                  value={entrantDetails.phone || ""}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        phone: e.target.value,
                        hasBeenUpdated: true,
                      };
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <Divider />
                <h4 style={{ marginBottom: 12 }}>Billing Details</h4>
                <Checkbox
                  id="same_billing_details"
                  name="same_billing_details"
                  label="Same billing details"
                  checked={
                    typeof entrantDetails.same_billing_details === "boolean"
                      ? entrantDetails.same_billing_details
                      : true
                  }
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setSaveError(null);

                    const sameDetails = e.target.checked;

                    setEntrantDetails((curr) => {
                      return {
                        ...curr,
                        same_billing_details: e.target.checked,
                        billing_first_name: sameDetails
                          ? curr.billing_first_name
                          : undefined,
                        billing_last_name: sameDetails
                          ? curr.billing_last_name
                          : undefined,
                        billing_address_1: sameDetails
                          ? curr.billing_address_1
                          : undefined,
                        billing_address_2: sameDetails
                          ? curr.billing_first_name
                          : undefined,
                        billing_address_3: sameDetails
                          ? curr.billing_address_3
                          : undefined,
                        billing_town_city: sameDetails
                          ? curr.billing_town_city
                          : undefined,
                        billing_postcode: sameDetails
                          ? curr.billing_postcode
                          : undefined,
                        billing_country: sameDetails
                          ? curr.billing_country
                          : undefined,
                        billing_phone: sameDetails
                          ? curr.billing_phone
                          : undefined,
                        billing_email: sameDetails
                          ? curr.billing_email
                          : undefined,

                        hasBeenUpdated: true,
                      };
                    });
                  }}
                />
                {entrantDetails.same_billing_details === false && (
                  <Button
                    buttonType="tertiary"
                    onClick={async () => {
                      await onSubmit();

                      navigate("/billing");
                    }}
                  >
                    Edit billing details
                  </Button>
                )}
                <Divider />
                <FormButtonRow>
                  <Button href="/update-password">Change email/password</Button>
                  <div>
                    <Button
                      type="submit"
                      buttonType="primary"
                      className={styles.submitButton}
                      softDisable={
                        (!name.hasBeenUpdated &&
                          !entrantDetails.hasBeenUpdated) ||
                        !!saveError
                      }
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </FormButtonRow>
                {saveError && (
                  <FormError>
                    <p>{saveError}</p>
                  </FormError>
                )}
              </div>
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
      {showResendConfirmationModal && (
        <ResendConfirmationEmailModal
          onClose={() => setShowResendConfirmationModal(false)}
        />
      )}
    </>
  );
};

export default ProfilePage;
