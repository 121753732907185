import { AxiosResponse } from "axios";

import { endpoint } from ".";
import {
  AwardsEntryFileResponse,
  AwardsEntryFileSeed,
  LargeFileUploadResponse,
} from "./types/awards-entries";

const basePath = "/awards/entries";

export const fetchCognitoCredentials = async () => {
  try {
    const res: AxiosResponse<{ authId: string; token: string }> =
      await endpoint.get("/get-cognito-credentials");

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getEntryFiles = async (entryId: number) => {
  try {
    const res: AxiosResponse<{
      entryID: number;
      entryTitle: string;
      entryCategory: string;
      entryIsSubmitted: boolean;
      entryFiles: AwardsEntryFileResponse[];
    }> = await endpoint.get(`${basePath}/${entryId}/files`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

// TODO: Use addFileToDb instead
export const addSmallFileToEntry = async (
  entryId: string,
  year: number,
  file: File
) => {
  try {
    const formData = new FormData();
    formData.append("year", year.toString());
    formData.append("file", file);

    const res: AxiosResponse<{
      entryFile: AwardsEntryFileResponse;
    }> = await endpoint.post(
      `${basePath}/${entryId}/files/small-file`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

// TODO: Update this
// Split into separate endpoints
export const addLargeFileToEntry = async (
  entryId: string,
  year: number,
  details: {
    fileId?: string;
    name: string;
    fullSize: number;
    fileType: string;
    chunk: Blob;
    partNumber: number;
    isLastPart?: boolean;
    UploadId?: string;
    UploadedParts?: {
      ETag: string;
      PartNumber: number;
    }[];
  }
) => {
  try {
    const {
      fileId,
      name,
      fullSize,
      fileType,
      chunk,
      partNumber,
      isLastPart,
      UploadId,
      UploadedParts,
    } = details;

    const formData = new FormData();
    formData.append("year", year.toString());
    formData.append("name", name);
    formData.append("fullSize", fullSize.toString());
    formData.append("fileType", fileType);
    formData.append("filePart", chunk);
    formData.append("partNumber", partNumber.toString());
    // @ts-ignore
    formData.append("isLastPart", isLastPart);

    if (UploadId) {
      formData.append("UploadId", UploadId);
    }

    if (UploadedParts) {
      formData.append("UploadedParts", JSON.stringify(UploadedParts));
    }

    // Some parts of file already uploaded - update the DB value
    if (fileId) {
      formData.append("fileId", fileId);
    }

    const res: AxiosResponse<{
      entryFile: AwardsEntryFileResponse & LargeFileUploadResponse;
    }> = await endpoint.post(`${basePath}/${entryId}/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const deleteEntryFile = async (entryId: string, fileId: number) => {
  try {
    const res: AxiosResponse<{
      removedFile: number;
    }> = await endpoint.delete(`${basePath}/${entryId}/files/${fileId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const fetchEntryFileUrls = async (entryId: number) => {
  try {
    const res: AxiosResponse<{
      entryFiles: {
        entry_file_id: number;
        file_name: string;
        url: string;
        file_type: string;
        created_at: string;
        file_size: number;
      }[];
    }> = await endpoint.get(
      `${basePath}/${entryId.toString()}/files/get-file-urls`
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const addFileToDb = async (fileData: AwardsEntryFileSeed) => {
  try {
    const res: AxiosResponse<{
      entryFile: AwardsEntryFileResponse;
    }> = await endpoint.post(
      `${basePath}/${fileData.entry_id}/files/add-file-to-db`,
      { file: fileData }
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
