// extracted by mini-css-extract-plugin
export var actionButtons = "AdminManageJudgesPage-module--actionButtons--c782a";
export var bubble = "AdminManageJudgesPage-module--bubble--8b240";
export var categoryDropdown = "AdminManageJudgesPage-module--categoryDropdown--59a4f";
export var chair = "AdminManageJudgesPage-module--chair--52b8d";
export var emailColumn = "AdminManageJudgesPage-module--emailColumn--8bf8f";
export var first = "AdminManageJudgesPage-module--first--5c69a";
export var fullWidth = "AdminManageJudgesPage-module--fullWidth--42392";
export var idColumn = "AdminManageJudgesPage-module--idColumn--f4ab5";
export var inactive = "AdminManageJudgesPage-module--inactive--04748";
export var judgeCategoryBubbles = "AdminManageJudgesPage-module--judgeCategoryBubbles--7d3d7";
export var judgesTable = "AdminManageJudgesPage-module--judgesTable--1d2e4";
export var modal = "AdminManageJudgesPage-module--modal--ca6ff";
export var modalError = "AdminManageJudgesPage-module--modalError--6f525";
export var modalSubmitButton = "AdminManageJudgesPage-module--modalSubmitButton--73d07";
export var modalSubmitButtons = "AdminManageJudgesPage-module--modalSubmitButtons--3f2a5";
export var modalWarning = "AdminManageJudgesPage-module--modalWarning--c572f";
export var searchButton = "AdminManageJudgesPage-module--searchButton--197bd";
export var searchContainer = "AdminManageJudgesPage-module--searchContainer--0488c";
export var second = "AdminManageJudgesPage-module--second--dcf63";
export var sortableCol = "AdminManageJudgesPage-module--sortableCol--45327";
export var subDetails = "AdminManageJudgesPage-module--subDetails--74483";
export var tableControls = "AdminManageJudgesPage-module--tableControls--175c0";
export var tableSearch = "AdminManageJudgesPage-module--tableSearch--62c66";