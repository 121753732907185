export const SITE_BASE_URL =
  process.env.NODE_ENV === "production"
    ? // process.env.GATSBY_IS_STAGING_SITE
      // ? "https://staging-awards.griersontrust.org"
      // :
      "https://awards.griersontrust.org"
    : "http://localhost:8000";

export const mediaDomain = "https://api.flotiq.com";

export const VAT = 0.2;

export const supportEmail = "info@griersontrust.org";

// This should match CSS variables
export const sizes = {
  margins: {
    desktop: 80,
    tablet: 48,
    mobile: 39,
  },
  breakpoints: {
    desktopWide: 1648,
    desktop: 1200,
    laptop: 992,
    tabletLandscape: 768,
    tabletPortrait: 600,
    mobile: 416,
  },
  maxWidths: {
    desktop: 1488,
    tablet: 656,
    mobile: 336,
  },
};

// Entry form fields that are used in all categories
export const commonCategoryFields = [
  "title",
  "description",
  // British Council field should be removed (2025)
  // "british_council_screening_accepted",
  "secondary_contact_first_name",
  "secondary_contact_last_name",
  "secondary_contact_email",
];

export const britishCouncilScreeningMessage = `
#### British Council

Each year the British Council provides a series of non-commercial international screenings featuring a selection of British documentaries shortlisted for the Grierson Awards. The British Council will provide a modest fee for a fixed term licence. Producers retain the right to withdraw their film from screening in specific territories. The Grierson Trust team will be in touch to provide further information. Find out more about the British Council at [film.britishcouncil.org](film.britishcouncil.org).
`;
