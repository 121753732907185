import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import {
  fromCognitoIdentity,
  Logins,
} from "@aws-sdk/credential-provider-cognito-identity";
import { S3Client } from "@aws-sdk/client-s3";

export const BUCKET_NAME =
  process.env.NODE_ENV === "development"
    ? "test-grierson-awards-entries-2025"
    : "grierson-awards-entries-2025";
export const REGION = "eu-west-1";

export const getS3Client = (authId: string, token: string) => {
  const loginData: Logins = {};

  loginData["cognito-identity.amazonaws.com"] = token;

  const s3Client = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentity({
      client: new CognitoIdentityClient({ region: REGION }),
      identityId: authId,
      logins: loginData,
    }),
  });

  return s3Client;
};
