import React, { createRef, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useSpring, animated } from "react-spring";

import { Column, Container, Row } from "./layout";
import { StyledMarkdown } from "./typeface";
import useMobile from "../hooks/useMobile";
import * as styles from "../styles/accordion.module.scss";

export interface AccordionItem {
  id?: string;
  title: React.ReactElement;
  content: React.ReactElement;
}

interface AccordionItemProps {
  body: AccordionItem;
  index: number;
  isLast: boolean;
  isOpen: boolean;
  updateState: (index: number) => void;
  fullWidth?: boolean;
}

export function AccordionItem({
  body,
  index,
  isLast,
  isOpen,
  updateState,
  fullWidth,
}: AccordionItemProps) {
  const { title, content } = body;

  const [contentMaxHeight, setContentMaxHeight] = useState(0);

  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    if (ref && ref.current) {
      setContentMaxHeight(ref.current.scrollHeight);
    }
  }, [ref, contentMaxHeight]);

  const expandProps = useSpring({
    opacity: isOpen ? 1 : 0,
    marginTop: isOpen ? 16 : 0,
    maxHeight: isOpen ? contentMaxHeight : 0,
    config: { duration: 300 },
  });

  return (
    <Column gridCols={fullWidth ? 12 : 6}>
      <div
        className={classNames(styles.accordionItem, {
          [styles.lastRow]: isLast,
        })}
      >
        <div
          className={styles.accordionHeader}
          onClick={() => updateState(index)}
        >
          <div
            className={classNames(styles.icon, {
              [styles.isOpen]: isOpen,
            })}
          ></div>
          {title}
        </div>
        <animated.div
          ref={ref}
          style={expandProps}
          className={styles.accordionBody}
        >
          {content}
        </animated.div>
      </div>
    </Column>
  );
}

export interface AccordionProps {
  id: string;
  title?: string;
  summaryText?: string;
  colCount?: 1 | 2;
  data: AccordionItem[];
  showOne?: boolean;
  className?: string;
  fullWidth?: boolean;
}

export default function Accordion({
  id,
  title,
  summaryText,
  colCount = 1,
  data,
  showOne = true,
  className,
  fullWidth,
}: AccordionProps) {
  const isMobile = useMobile();

  const [accordionState, setAccordionState] = useState(
    Array.from(data, (_) => false)
  );

  const updateState = useCallback(
    (index: number) => {
      setAccordionState((currState) => {
        let newState: boolean[] = [];

        if (showOne && !newState[index]) {
          newState = Array.from(currState, (_) => false);
        } else {
          newState = [...currState];
        }

        newState[index] = !currState[index];

        return newState;
      });
    },
    [accordionState]
  );

  if (data.length === 0) {
    return null;
  }

  return (
    <Container className={className} fullWidth={fullWidth}>
      {(title || summaryText) && (
        <Row className={styles.accordionTitleRow}>
          <Column gridCols={fullWidth ? 12 : 6} textCenter>
            {title && <h2>{title}</h2>}
            {summaryText && <StyledMarkdown>{summaryText}</StyledMarkdown>}
          </Column>
        </Row>
      )}
      <Row colCount={colCount}>
        {data.map((body, index) => (
          <AccordionItem
            key={body.id || `${id}-${index}`}
            body={body}
            index={index}
            isLast={
              index === data.length - 1 ||
              (!isMobile && colCount === 2 && index === data.length - 2)
            }
            isOpen={accordionState[index]}
            updateState={updateState}
            fullWidth={fullWidth}
          />
        ))}
      </Row>
    </Container>
  );
}
