import React, { useContext, useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";

import Button from "../components/button";
import EntryForm from "../components/EntryForm";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import {
  AwardsEntryResponseLimited,
  NewEntry,
} from "../api/types/awards-entries";
import { getEntryByID } from "../api/awards-entries";
import { AwardsContext } from "../context/awards";
import useWindow from "../hooks/useWindow";

interface EditEntryPageProps extends RouteComponentProps {
  entryID: string;
}

const EditEntryPage = ({ entryID }: EditEntryPageProps) => {
  const { noNewEntries } = useContext(AwardsContext);

  const _window = useWindow();

  const [entryToShow, setEntryToShow] = useState<
    NewEntry | AwardsEntryResponseLimited | null
  >(null);
  const [entryFetchError, setEntryFetchError] = useState(false);

  // Get entry from URL
  useEffect(() => {
    const fetchEntry = async () => {
      try {
        if (!entryID) return;

        const { entry: savedEntry } = await getEntryByID(parseInt(entryID));

        if (!savedEntry) {
          setEntryFetchError(true);
          return;
        }

        setEntryToShow(savedEntry);
      } catch {
        setEntryFetchError(true);
      }
    };

    fetchEntry();
  }, [entryID]);

  const selectedCategory = useMemo(() => {
    if (!entryToShow) return "";

    return entryToShow.awards_category_id.toString();
  }, [entryToShow]);

  // Check if within the entry period
  // If not, redirect to dashboard
  useEffect(() => {
    if (noNewEntries === null || !_window.sessionStorage) return;

    // Should not be possible to submit entries if outside entry period
    if (noNewEntries) {
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [_window, noNewEntries]);

  // Completed entries must not be edited
  if (entryToShow && entryToShow.is_submitted) {
    navigate(`/view-entry/${entryID}`);
  }

  return (
    <SiteWrapper>
      <Container>
        <Row>
          <Column gridCols={6} textCenter={!entryToShow}>
            {entryToShow ? (
              <EntryForm
                selectedCategory={selectedCategory}
                entry={entryToShow}
                setEntry={setEntryToShow}
              />
            ) : (
              <>
                {entryFetchError && (
                  <>
                    <h1>Entry not found</h1>
                    <Button buttonType="primary" href="/">
                      Dashboard page
                    </Button>
                  </>
                )}
              </>
            )}
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
};

export default EditEntryPage;
