import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { S3Client } from "@aws-sdk/client-s3";

import { getS3Client } from "../services";
import {
  fetchCognitoCredentials,
  getEntryFiles,
} from "../../api/awards-entry-files";
import { AwardsEntryFileResponse } from "../../api/types/awards-entries";
import Button from "../../components/button";
import { FormButtonRow } from "../../components/form";
import { Column, Container, Row } from "../../components/layout";
import SiteWrapper from "../../components/SiteWrapper";
import Uploader from "../components/Uploader";
import { AccountContext } from "../../context/account";
import { AwardsContext } from "../../context/awards";
import { supportEmail } from "../../core/variables";
import useWindow from "../../hooks/useWindow";
import * as styles from "../../styles/UploaderPage.module.scss";

interface UploaderAppPageProps extends RouteComponentProps {
  entryID?: string;
}

export default function UploaderApp({ entryID }: UploaderAppPageProps) {
  const {
    account: { uid, email },
  } = useContext(AccountContext);

  const { noNewEntries } = useContext(AwardsContext);

  const _window = useWindow();

  const [entryToShow, setEntryToShow] = useState<{
    entryID: number;
    entryTitle: string;
    categoryTitle: string;
    entryIsSubmitted: boolean;
  } | null>(null);
  const [entryFetchError, setEntryFetchError] = useState(false);
  const [entryFiles, setEntryFiles] = useState<AwardsEntryFileResponse[]>([]);

  const [s3Client, setS3Client] = useState({} as S3Client);
  const [s3Error, setS3Error] = useState(false);

  // Fetch files for current entry
  // Get entry files
  useEffect(() => {
    if (!uid || !email || !entryID) return;

    const getUploadedFiles = async () => {
      try {
        const {
          entryID: _entryID,
          entryTitle,
          entryCategory,
          entryIsSubmitted,
          entryFiles: _entryFiles,
        } = await getEntryFiles(parseInt(entryID));

        setEntryToShow({
          entryID: _entryID,
          entryTitle,
          categoryTitle: entryCategory,
          entryIsSubmitted,
        });

        setEntryFiles(_entryFiles);
      } catch (err) {
        setEntryFetchError(true);
      }
    };

    getUploadedFiles();
  }, [uid, email, entryID]);

  // Initialise AWS S3 Client
  useEffect(() => {
    if (uid && entryID) {
      const getClient = async () => {
        try {
          const { authId, token } = await fetchCognitoCredentials();

          if (authId && token) {
            const newClient = getS3Client(authId, token);

            setS3Client(newClient);
          }
        } catch (err) {
          setS3Error(true);
        }
      };

      getClient();
    }
  }, [uid, entryID]);

  // Check if within the entry period
  // If not, redirect to dashboard
  useEffect(() => {
    if (noNewEntries === null || !_window.sessionStorage) return;

    // Should not be possible to submit entries if outside entry period
    if (noNewEntries) {
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [_window, noNewEntries]);

  // Completed entries must not be edited
  if (entryToShow?.entryIsSubmitted) {
    navigate(`/view-entry/${entryID}`);
  }

  return (
    <>
      <SiteWrapper>
        <Container>
          <Row>
            <Column gridCols={8} textCenter={!entryToShow}>
              {entryToShow ? (
                <>
                  <h1>Upload files for: {entryToShow.entryTitle}</h1>
                  <h4>Category: {entryToShow.categoryTitle}</h4>
                  <div className={styles.uploaderContainer}>
                    {s3Error ? (
                      <p className={styles.error}>Error fetching uploader</p>
                    ) : (
                      <>
                        {s3Client.hasOwnProperty("config") ? (
                          <Uploader
                            entry={entryToShow}
                            s3Client={s3Client}
                            filesList={entryFiles}
                            setFilesList={setEntryFiles}
                          />
                        ) : (
                          <div className={styles.loadingContainer}>
                            <div className={styles.ldsRing}>
                              <div />
                              <div />
                              <div />
                              <div />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <p>
                    If you are entering Best Documentary Series or Best
                    Returning Documentary Series, please upload{" "}
                    <strong>only two episodes of the series</strong> you are
                    entering for judges to view.
                  </p>
                  <p>
                    If you experience any problems with the uploader, please
                    reach out to support:{" "}
                    <Button buttonType="link" href={`mailto:${supportEmail}`}>
                      {supportEmail}
                    </Button>
                    .
                  </p>
                  <p>
                    <strong>
                      We recommend that you submit your files in one of the
                      following specifications:
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Maximum file size of around 4GB per file. Standard
                      Definition (SD).
                    </li>
                    <li>Video: MP4 (codec H.264)</li>
                    <li>Frame size: 16:9</li>
                  </ul>
                  <p>
                    Once your file is uploaded, go back to the Dashboard to
                    complete your entry.
                  </p>
                  <FormButtonRow>
                    <Button buttonType="tertiary" href="/dashboard">
                      Back to dashboard
                    </Button>
                    <Button
                      buttonType="primary"
                      href={`/view-entry/${entryID}`}
                    >
                      View full entry details
                    </Button>
                  </FormButtonRow>
                </>
              ) : (
                <>
                  {entryFetchError && (
                    <>
                      <h1>Entry not found</h1>
                      <Button buttonType="primary" href="/dashboard">
                        Dashboard page
                      </Button>
                    </>
                  )}
                </>
              )}
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
    </>
  );
}
