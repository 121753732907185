import React, { useContext } from "react";
import { Redirect, RouteComponentProps } from "@reach/router";

import LoadingLogo from "../components/LoadingLogo";
import { AccountContext } from "../context/account";
import * as styles from "../styles/site-wrapper.module.scss";
import { isAdmin } from "../utils";

interface ProtectedRouteProps extends RouteComponentProps {
  component: React.ComponentType<any>;
}

export const AdminOnlyRoute = ({
  component: Page,
  ...rest
}: ProtectedRouteProps) => {
  const { account } = useContext(AccountContext);

  if (!account.checked) {
    return <LoadingLogo className={styles.fullPageLoader} />;
  }

  if (account.checked && !account.loggedIn) {
    return <Redirect to="/login" noThrow />;
  }

  if (account.checked && !!account.uid && !isAdmin(account)) {
    return <Redirect to="/" noThrow />;
  }

  // Pass only route props, as additional props are not needed
  return <Page {...rest} />;
};

const ProtectedRoute = ({ component: Page, ...rest }: ProtectedRouteProps) => {
  const { account } = useContext(AccountContext);

  if (!account.checked) {
    return <LoadingLogo className={styles.fullPageLoader} />;
  }

  if (account.checked && !account.loggedIn) {
    return <Redirect to="/login" noThrow />;
  }

  // Pass only route props, as additional props are not needed
  return <Page {...rest} />;
};

export default ProtectedRoute;
