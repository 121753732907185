import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";

import { getEntryByID } from "../api/awards-entries";
import { AwardsEntryResponseLimited } from "../api/types/awards-entries";
import { fetchEntryFileUrls } from "../api/awards-entry-files";
import { getJudgeEntryById } from "../api/awards-judges";
import Button, { EditButton } from "../components/button";
import CompleteEntryModal from "../components/CompleteEntryModal";
import { EntryFileDetails, EntrySummary } from "../components/EntrySummary";
import { FormButtonRow, FormError, FormMessage } from "../components/form";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { Divider } from "../components/typeface";
import { AccountContext } from "../context/account";
import { AwardsContext } from "../context/awards";
import { JudgesContext } from "../context/judges";
import { supportEmail } from "../core/variables";
import * as styles from "../styles/ViewEntryPage.module.scss";
import {
  isAdmin,
  isAfterShortlistDate,
  isBeforeShortlistDate,
  isChair,
  isEntrant,
  isFirstRoundJudge,
  isJudge,
  isSecondRoundJudge,
} from "../utils";
import { isReadyToSubmit } from "../utils/entries ";

interface LocationState {
  entryComplete?: boolean;
}

interface ViewEntryPageProps extends RouteComponentProps {
  entryID?: string;
}

export default function ViewEntryPage({ entryID, path }: ViewEntryPageProps) {
  const state = useLocation().state as LocationState;
  const { currentJudge } = useContext(JudgesContext);

  const { account } = useContext(AccountContext);
  const { currentYear, currentYearCategories, noNewEntries } =
    useContext(AwardsContext);

  const [entryToShow, setEntryToShow] =
    useState<AwardsEntryResponseLimited | null>(null);
  const [entryFetchError, setEntryFetchError] = useState(false);
  const [showPaymentError, setShowPaymentError] = useState(false);
  const [showSubmissionError, setShowSubmissionError] = useState(false);
  const [showCompleteEntryModal, setShowCompleteEntryModal] = useState(false);
  const [completeSuccess, setCompleteSuccess] = useState(false);

  const [entryFiles, setEntryFiles] = useState<EntryFileDetails[]>([]);

  const isJudgeView = path?.indexOf("view-entry-judge") !== -1;

  // Get entry from URL
  useEffect(() => {
    const fetchEntry = async () => {
      try {
        if (!entryID) return;

        let savedEntry = null;
        const { entry: _savedEntry } = isJudgeView
          ? await getJudgeEntryById(parseInt(entryID))
          : await getEntryByID(parseInt(entryID));

        savedEntry = _savedEntry;

        if (!savedEntry) {
          setEntryFetchError(true);
          return;
        }
        setEntryToShow(savedEntry);
      } catch {
        setEntryFetchError(true);
      }
    };

    fetchEntry();
  }, [entryID, isJudgeView]);

  useEffect(() => {
    if (!entryID) return;

    const getEntryFiles = async () => {
      try {
        const { entryFiles: _entryFiles } = await fetchEntryFileUrls(
          parseInt(entryID)
        );

        setEntryFiles(_entryFiles);
      } catch {
        console.log("No files found");
      }
    };

    getEntryFiles();
  }, [entryID]);

  const categoryName = useMemo(() => {
    if (!entryToShow) return "";

    return (
      currentYearCategories.find(
        ({ awards_category_id }) =>
          awards_category_id === entryToShow.awards_category_id
      )?.title || ""
    );
  }, [entryToShow, currentYearCategories]);

  const showJudgeContent = useMemo(() => {
    if (account && currentYear && currentJudge) {
      if (isJudge(account)) {
        if (isChair(currentJudge)) {
          return true;
        }
        if (
          isFirstRoundJudge(currentJudge) &&
          isBeforeShortlistDate(currentYear)
        ) {
          return true;
        }
        if (
          isSecondRoundJudge(currentJudge) &&
          isAfterShortlistDate(currentYear)
        ) {
          return true;
        }
      }
    }
    return false;
  }, [account, currentYear, currentJudge]);

  if (isJudgeView && !showJudgeContent) {
    return (
      <>
        <SiteWrapper>
          <Container>
            <Row>
              <Column gridCols={8} textCenter>
                <h1>Judging</h1>
                <FormError>
                  <p>
                    Only Judges for the current round of judging can see this
                    content.
                  </p>
                </FormError>
              </Column>
            </Row>
          </Container>
        </SiteWrapper>
      </>
    );
  }

  return (
    <>
      <SiteWrapper>
        <Container>
          <Row>
            <Column gridCols={isJudgeView ? 8 : 6} textCenter={!entryToShow}>
              {entryToShow ? (
                <>
                  <h1>Category: {categoryName}</h1>
                  {entryToShow.is_submitted && !isJudgeView && (
                    <FormMessage>
                      <p>
                        This entry has been submitted and will be passed on to
                        the judging panel.
                      </p>
                      {entryToShow.submission_id && (
                        <p>
                          Your submission ID is {entryToShow.submission_id}.
                        </p>
                      )}
                    </FormMessage>
                  )}
                  {((state && state.entryComplete) || completeSuccess) &&
                    !isJudgeView && (
                      <FormMessage>
                        <p>
                          Your entry has been submitted and will be passed on to
                          the judging panel.
                        </p>
                      </FormMessage>
                    )}
                  {!noNewEntries &&
                    !entryToShow.is_submitted &&
                    !isJudgeView && (
                      <EditButton
                        className={styles.editBtn}
                        blueIcon
                        href={`/edit-entry/${entryID}`}
                      >
                        Edit details
                      </EditButton>
                    )}
                  <EntrySummary
                    isJudge={isJudgeView}
                    entry={entryToShow}
                    entryFiles={entryFiles}
                  />
                  {(isAdmin(account) || isEntrant(account)) &&
                    entryFiles.length > 0 &&
                    !entryToShow.is_submitted && (
                      <>
                        <Divider />
                        <FormButtonRow>
                          <Button
                            buttonType="primary"
                            className={styles.completeBtn}
                            softDisable={!entryToShow.is_paid}
                            onClick={async () => {
                              if (!entryToShow.is_paid) {
                                setShowPaymentError(true);
                                return;
                              }

                              const readyToSubmit =
                                isReadyToSubmit(entryToShow);

                              if (!readyToSubmit) {
                                setShowSubmissionError(true);
                                return;
                              }

                              setShowCompleteEntryModal(true);
                            }}
                          >
                            Complete
                          </Button>
                        </FormButtonRow>
                        {showSubmissionError && (
                          <p className={styles.submitError}>
                            Your entry is not ready to complete. Please check
                            that all required fields are filled out.
                          </p>
                        )}
                        {showPaymentError && (
                          <p className={styles.submitError}>
                            You need to pay for this entry. Please check your
                            emails for the invoice or contact{" "}
                            <Button
                              buttonType="link"
                              href={`mailto:${supportEmail}`}
                            >
                              {supportEmail}
                            </Button>
                          </p>
                        )}
                      </>
                    )}
                </>
              ) : (
                <>
                  {entryFetchError && (
                    <>
                      <h1>Entry not found</h1>
                      <Button buttonType="primary" href="/dashboard">
                        Dashboard page
                      </Button>
                    </>
                  )}
                </>
              )}
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
      {showCompleteEntryModal && entryToShow && (
        <CompleteEntryModal
          entryID={entryToShow.entry_id}
          onClose={() => setShowCompleteEntryModal(false)}
          onComplete={() => {
            setCompleteSuccess(true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      )}
    </>
  );
}
