import * as React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import classNames from "classnames";
import { NormalComponents } from "react-markdown/lib/complex-types";
import { SpecialComponents } from "react-markdown/lib/ast-to-react";

import useWindow from "../hooks/useWindow";
import * as styles from "../styles/typeface.module.scss";

export function Divider({ className }: { className?: string }) {
  return <div className={classNames(styles.divider, className)} />;
}

interface PreHeadingProps {
  color?: string;
  children: React.ReactNode;
}

export function PreHeading({ color, children }: PreHeadingProps) {
  return (
    <p className={classNames(styles.preHeading, color && styles[color])}>
      {children}
    </p>
  );
}

interface QuoteProps {
  author: string;
  children: React.ReactNode;
}

export const Quote = ({ author, children }: QuoteProps) => {
  return (
    <div className={styles.quoteContainer}>
      <div className={styles.quote}>{children}</div>
      <h4>{author}</h4>
    </div>
  );
};

interface QuoteBoxProps {
  className?: string;
  children: React.ReactNode;
}

export function QuoteBox({ className, children }: QuoteBoxProps) {
  return (
    <div className={classNames(styles.quoteBox, className)}>{children}</div>
  );
}

interface StyledMarkdownProps {
  color?: SiteColors;
  className?: string;
  components?: Partial<
    Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
  >;
  children: string;
}

export const StyledMarkdown = ({
  color,
  className,
  components,
  children,
}: StyledMarkdownProps) => {
  const _window = useWindow();

  return (
    <ReactMarkdown
      className={classNames(styles.markdown, className)}
      plugins={[gfm]}
      components={{
        blockquote: ({ node, ...props }) => <QuoteBox {...props} />,
        em: ({ node, ...props }) => (
          <em className={color ? styles[color] : undefined} {...props} />
        ),
        h1: ({ node, ...props }) => (
          <h1 {...props} style={{ textAlign: "center" }} />
        ),
        hr: () => <Divider />,
        ...components,
        a: ({ node, ...props }) => {
          const newTab =
            props.href &&
            props.href.indexOf(_window.location.origin) === -1 &&
            !/^[\/#]/.test(props.href);

          return (
            <a
              {...props}
              target={newTab ? "_blank" : undefined}
              rel={newTab ? "noreferrer" : undefined}
            />
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
