import React, { useCallback, useContext, useState } from "react";
import { navigate } from "gatsby";

import Button from "../components/button";
import { FormError, FormButtonRow, Form } from "../components/form";
import Input, { Checkbox } from "../components/input";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { isValidEmail, validatePassword } from "../utils/index";
import { AccountContext } from "../context/account";
import { registerNewAccount } from "../api/auth";

export default function NewUserPage() {
  const { setAccount } = useContext(AccountContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(async () => {
    const isValid = isValidEmail(email);

    const validated = validatePassword(password, passwordTwo);

    if (email === "") {
      setError("All fields are required.");
    } else if (!isValid) {
      setError("Please enter a valid email.");
    } else if (validated !== "valid") {
      setError(
        "Your password is not valid. Both passwords must match and be a minimum of 8 characters."
      );
    } else {
      setError(null);

      try {
        const res = await registerNewAccount({ email, password, rememberMe });

        if (res) {
          const {
            data: { account },
          } = res;

          if (account.uid && account.token) {
            localStorage.setItem("token", account.token);

            setAccount((currAccount) => {
              return {
                ...currAccount,
                loggedIn: true,
                checked: true,
                uid: account.uid,
                email: account.email,
                first_name: account.first_name,
                last_name: account.last_name,
                admin_id: account.admin_id,
                judge_id: account.judge_id,
                entrant_id: account.entrant_id,
              };
            });
          }

          navigate("/profile", { state: { newUser: true } });
        }
      } catch (err: any) {
        setError(err.message || "Cannot create new user.");
      }
    }
  }, [email, password, passwordTwo]);

  return (
    <SiteWrapper hideStickyNav>
      <Container>
        <Row>
          <Column gridCols={4}>
            <h1>Create an account</h1>
            <Form onSubmit={onSubmit}>
              <>
                <Input
                  dark
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={!!error}
                />
                <Input
                  dark
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={!!error}
                />
                <Input
                  dark
                  type="password"
                  id="password-two"
                  name="password-two"
                  placeholder="Re-type Password"
                  value={passwordTwo}
                  onChange={(e) => setPasswordTwo(e.target.value)}
                  error={!!error}
                />
                {error && (
                  <FormError>
                    <p>{error}</p>
                  </FormError>
                )}
                <Checkbox
                  dark
                  id="remember-me"
                  label="Remember me"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <FormButtonRow>
                  <Button buttonType="link" href="/login">
                    Login
                  </Button>
                  <Button buttonType="primary">Create</Button>
                </FormButtonRow>
              </>
            </Form>
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
