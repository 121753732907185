import React, { createContext, useCallback, useState } from "react";

import { getEntrantEntries } from "../api/awards-entrants";
import {
  AwardsEntryMinimal,
  AwardsEntryResponseLimited,
  NewEntry,
} from "../api/types/awards-entries";

interface EntriesContextProps {
  savedEntries: AwardsEntryMinimal[] | null;
  setSavedEntries: React.Dispatch<
    React.SetStateAction<AwardsEntryMinimal[] | null>
  >;
  fetchSavedEntries: (entrantId: number, year?: number) => Promise<void>;
  fetchError: boolean;
  currentEntry: NewEntry | AwardsEntryResponseLimited | null;
  setCurrentEntry: React.Dispatch<
    React.SetStateAction<NewEntry | AwardsEntryResponseLimited | null>
  >;
  entriesToSubmit: AwardsEntryMinimal[];
  setEntriesToSubmit: React.Dispatch<
    React.SetStateAction<AwardsEntryMinimal[]>
  >;
}

const initialValue: EntriesContextProps = {
  savedEntries: null,
  setSavedEntries: () => null,
  fetchSavedEntries: async () => {},
  fetchError: false,
  currentEntry: null,
  setCurrentEntry: () => null,
  entriesToSubmit: [],
  setEntriesToSubmit: () => [],
};

export const EntriesContext = createContext<EntriesContextProps>(initialValue);

interface EntriesProviderProps {
  children?: React.ReactNode;
}

const EntriesProvider = ({ children }: EntriesProviderProps) => {
  const [savedEntries, setSavedEntries] = useState<AwardsEntryMinimal[] | null>(
    []
  );
  const [fetchError, setFetchError] = useState(false);
  const [currentEntry, setCurrentEntry] = useState<
    NewEntry | AwardsEntryResponseLimited | null
  >(null);
  const [entriesToSubmit, setEntriesToSubmit] = useState<AwardsEntryMinimal[]>(
    []
  );

  const fetchSavedEntries = useCallback(
    async (entrantId: number, year?: number) => {
      try {
        const { entries } = await getEntrantEntries(entrantId, year);

        setSavedEntries([...entries]);
      } catch (error) {
        setFetchError(true);
      }
    },
    []
  );

  return (
    <EntriesContext.Provider
      value={{
        savedEntries,
        setSavedEntries,
        fetchSavedEntries,
        fetchError,
        currentEntry,
        setCurrentEntry,
        entriesToSubmit,
        setEntriesToSubmit,
      }}
    >
      {children}
    </EntriesContext.Provider>
  );
};

export default EntriesProvider;
