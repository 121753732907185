// extracted by mini-css-extract-plugin
export var accordionTitle = "dashboard-module--accordionTitle--4649a";
export var actionButton = "dashboard-module--actionButton--be0d5";
export var buttons = "dashboard-module--buttons--cdd56";
export var categoryName = "dashboard-module--categoryName--91fd6";
export var completeBtn = "dashboard-module--completeBtn--1a74f";
export var confirmAccountError = "dashboard-module--confirmAccountError--69d94";
export var contentBlock = "dashboard-module--contentBlock--b2177";
export var createNewEntry = "dashboard-module--createNewEntry--009b7";
export var deleteBtn = "dashboard-module--deleteBtn--d8d15";
export var deleteButton = "dashboard-module--deleteButton--e0ed2";
export var deleteButtons = "dashboard-module--deleteButtons--a77f1";
export var deleteError = "dashboard-module--deleteError--5aed3";
export var deleteModal = "dashboard-module--deleteModal--c65b7";
export var entriesAccordion = "dashboard-module--entriesAccordion--35daf";
export var entryCheckbox = "dashboard-module--entryCheckbox--c61ef";
export var entryRow = "dashboard-module--entryRow--914a6";
export var fileCount = "dashboard-module--fileCount--e4399";
export var fullPageLoader = "dashboard-module--fullPageLoader--7e11e";
export var listTitle = "dashboard-module--listTitle--2a767";
export var paidEntryTitle = "dashboard-module--paidEntryTitle--81928";
export var submitEntriesButton = "dashboard-module--submitEntriesButton--e3f6f";
export var submitError = "dashboard-module--submitError--c2ffb";