import React, { useEffect, useState } from "react";
import moment from "moment";

import Button from "./button";
import { FormButtonRow } from "./form";
import ViewFile from "./ViewFile";
import { Divider } from "./typeface";
import { AwardsEntryResponseLimited } from "../api/types/awards-entries";
import * as styles from "../styles/EntrySummary.module.scss";
import { EntryFormFieldValue } from "../api/types/awards-category-fields";
import classNames from "classnames";

export interface EntryFileDetails {
  entry_file_id: number;
  file_name: string;
  url: string;
  file_type: string;
  created_at: string;
  file_size: number;
}

interface EntrySummaryProps {
  entry: AwardsEntryResponseLimited;
  /** Includes a summary of all the entry files */
  entryFiles?: EntryFileDetails[] | null;
  children?: React.ReactElement;
  isJudge: boolean;
}

interface EntryFilesProps {
  entryFiles: EntryFileDetails[];
  isJudgeView?: boolean;
  entryId: number;
}
export const EntryFiles = ({
  entryFiles,
  isJudgeView = false,
  entryId,
}: EntryFilesProps) => {
  return (
    <>
      {isJudgeView ? <h3>Entry files</h3> : <h2>Entry files</h2>}

      {entryFiles.map((file, index) => {
        return (
          <ViewFile
            key={file.entry_file_id}
            file={file}
            last={index === entryFiles.length - 1}
          />
        );
      })}
      {!isJudgeView && entryFiles.length < 8 && (
        <FormButtonRow className={styles.uploadFilesButton}>
          <Button buttonType="tertiary" href={`/uploader/${entryId}`}>
            Upload{entryFiles.length > 0 ? " more " : " "}files
          </Button>
        </FormButtonRow>
      )}
    </>
  );
};
interface EntryFieldsProps {
  fields: EntryFormFieldValue[];
}

export const EntryFields = ({ fields }: EntryFieldsProps) => {
  return (
    <>
      {fields &&
        fields.map((field) => {
          const {
            fieldId,
            fieldTitle,
            parentField,
            value,
            valueName,
            otherValue,
            required,
          } = field;

          // Only show hidden fields if parent value allows them to
          // Parent field is always a checkbox
          if (parentField) {
            const parentFieldFull = fields.find(
              (f) => f.fieldId === parentField?.id
            );

            const parentFieldValue = parentFieldFull?.value;

            if (
              (!parentField.hidesWhenChecked &&
                (parentFieldValue === false ||
                  (!parentFieldValue && !parentField.checkedByDefault))) ||
              (parentField.hidesWhenChecked &&
                (parentFieldValue ||
                  (parentFieldValue === undefined &&
                    parentField.checkedByDefault)))
            ) {
              return null;
            }
          }

          let fieldValue: any = valueName || value;

          if (!valueName) {
            if (
              typeof value === "boolean" ||
              value === "true" ||
              value === "false"
            ) {
              fieldValue = value === true || value === "true" ? "Yes" : "No";
            } else if (otherValue) {
              fieldValue = `Other (${otherValue})`;
            } else if (Array.isArray(value)) {
              // Table fields
              fieldValue = (
                <>
                  {value.map((rowItem, rowIndex) => {
                    return (
                      <p
                        key={`${field.fieldId}-${rowIndex}`}
                        className={styles.tableField}
                      >
                        {Object.values(rowItem).map((col, colIndex) => {
                          return (
                            <>
                              <span
                                key={`${field.fieldId}-${rowIndex}-${colIndex}`}
                              >
                                {col.value}
                              </span>
                              {colIndex < Object.values(rowItem).length - 1 &&
                                ", "}
                            </>
                          );
                        })}
                      </p>
                    );
                  })}
                </>
              );
            } else if (
              isNaN(value as number) &&
              typeof value === "string" &&
              new Date(value as string | Date).toString() !== "Invalid Date" &&
              /^\d{4}-\d{2}-\d{2}.*$/.test(value as string)
            ) {
              fieldValue = moment(value as string | number | Date).format(
                "Do MMMM YYYY"
              );
            }
          }

          return (
            <tr key={`${fieldId}`}>
              <td className={styles.fieldName}>
                <>
                  <span>{fieldTitle}</span>
                  {required && <span className={styles.required}> *</span>}
                </>
              </td>
              <td className={styles.fieldValue}>
                {fieldValue ? (
                  fieldValue
                ) : (
                  <em className={styles.noValue}>Not provided</em>
                )}
              </td>
            </tr>
          );
        })}
    </>
  );
};

export const EntrySummary = ({
  entry,
  entryFiles = null,
  children,
  isJudge = false,
}: EntrySummaryProps) => {
  const { entry_form_field_values: fields } = entry;

  // Synopsis
  // Diversity & Inclusion statement
  // Trigger Warnings.
  // Plus (only for Best Series): Entry for Consideration & Series  .
  // Plus (only for Presenter) Supporting Statement

  // TODO: implement at the API side
  const judgePermittedFields = [
    "DrSLVTV7-lD-mE9jiSnQ6",
    "Dj8eKKK1oviyVjAjLBaO4",
    "uxfSOQzPaVcghkhDYwtsW",
    "1yQo_0uXHghdsyZvrOvF2",
    "BKnO4uKhM0HrB4wPtB2rW",
  ];

  if (isJudge) {
    const showFields =
      fields?.filter((field) => {
        const { fieldId, value } = field;

        return judgePermittedFields.indexOf(fieldId) !== -1 && value;
      }) || [];

    return (
      <>
        {children}

        {entryFiles && isJudge && (
          <>
            <h2>{entry.title}</h2>
            <EntryFiles
              entryFiles={entryFiles}
              isJudgeView
              entryId={entry.entry_id}
            />
          </>
        )}
        <table
          className={classNames(styles.entryTable, styles.entryTableJudge)}
        >
          <tbody>
            <tr>
              <td className={styles.fieldName}>
                <span>Synopsis</span>
              </td>
              <td className={styles.fieldValue}>{entry.description}</td>
            </tr>
            {showFields && <EntryFields fields={showFields} />}
          </tbody>
        </table>
      </>
    );
  }

  return (
    <>
      {children}
      <table className={styles.entryTable}>
        <tbody>
          <tr>
            <td className={styles.fieldName}>
              <span>Title</span>
            </td>
            <td className={styles.fieldValue}>{entry.title}</td>
          </tr>
          <tr>
            <td className={styles.fieldName}>
              <span>Synopsis</span>
            </td>
            <td className={styles.fieldValue}>{entry.description}</td>
          </tr>
          {fields && <EntryFields fields={fields} />}
          {(entry.secondary_contact_first_name ||
            entry.secondary_contact_last_name ||
            entry.secondary_contact_email) && (
            <tr>
              <td className={styles.fieldName}>
                <span>Secondary contact</span>
              </td>
              <td className={styles.fieldValue}>
                {entry.secondary_contact_first_name}{" "}
                {entry.secondary_contact_last_name}{" "}
                {entry.secondary_contact_email &&
                  `(
                ${entry.secondary_contact_email})`}
              </td>
            </tr>
          )}
          {/* // ! British Council Screening no longer used as of 2025 */}
          {/* <tr>
            <td className={styles.fieldName}>
              <span>British Council screening opt out</span>
            </td>
            <td className={styles.fieldValue}>
              {entry.british_council_screening_accepted ? "No" : "Yes"}
            </td>
          </tr> */}
        </tbody>
      </table>
      {entryFiles && (
        <>
          <Divider />
          <EntryFiles entryFiles={entryFiles} entryId={entry.entry_id} />
        </>
      )}
    </>
  );
};
