import React, { useState } from "react";
import classNames from "classnames";

import cancel from "../../assets/svgs/cancel.svg";
import Button from "../../components/button";
import { FormButtonRow } from "../../components/form";
import { Modal } from "../../components/modal";
import * as styles from "../../styles/uploadsProgress.module.scss";
import { formatBytes, truncateString } from "../../utils";

interface UploadsProgressProps {
  inProgressUploads: PendingUpload[];
  abortUpload: (fileName: string, id: string) => Promise<void>;
  suspendedUploads: SuspendedUpload[];
}

const UploadsProgress = ({
  inProgressUploads,
  abortUpload,
  suspendedUploads,
}: UploadsProgressProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [selectedFileId, setSelectedFileId] = useState<string>("");

  return (
    <>
      <p className={styles.smallCopy}>
        Busy? You can resume uploads later by coming back to this browser and
        re-uploading with the same file name and size.
      </p>
      <div className={styles.currUploads}>
        <table>
          <tbody>
            {inProgressUploads.map(({ fileName, uploadId, progress, size }) => {
              return (
                <tr key={fileName}>
                  <td>
                    {progress === 0 &&
                    !suspendedUploads.find(
                      (upload) => upload.name === fileName
                    ) ? (
                      <div className={styles.ldsRing}>
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    ) : (
                      <Button
                        buttonType="icon"
                        className={styles.cancelButton}
                        onClick={() => {
                          setSelectedFileName(fileName);
                          setSelectedFileId(uploadId);
                          setModalOpen(true);
                        }}
                      >
                        <img src={cancel} alt="Cancel upload" />
                      </Button>
                    )}
                  </td>
                  <td>
                    <p className={styles.smallCopy}>
                      {truncateString(fileName, 25)} ({formatBytes(size)})
                    </p>
                  </td>
                  <td>
                    <div className={styles.progressBar}>
                      <div
                        className={classNames({
                          [styles.loading]: progress < 100,
                          [styles.suspended]: suspendedUploads.find(
                            (entry) =>
                              entry.name === fileName && entry.size === size
                          ),
                        })}
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                    {suspendedUploads.find(
                      (entry) => entry.name === fileName && entry.size === size
                    ) && (
                      <span className={styles.smallCopy}>
                        Please re-upload the file and it will resume from this
                        point.
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {modalOpen && (
        <Modal
          className={styles.deleteModal}
          onClose={() => setModalOpen(false)}
        >
          <>
            <h3>Are you sure you want to cancel this upload?</h3>
            <p>All current upload progress will be lost.</p>
            <FormButtonRow className={styles.deleteButtons}>
              <Button
                buttonType="tertiary"
                onClick={async () => {
                  await abortUpload(selectedFileName, selectedFileId);
                  setModalOpen(false);
                }}
              >
                Cancel upload
              </Button>
              <Button buttonType="primary" onClick={() => setModalOpen(false)}>
                Continue upload
              </Button>
            </FormButtonRow>
          </>
        </Modal>
      )}
    </>
  );
};

export default UploadsProgress;
