import React, { useState } from "react";
import moment from "moment";
import classNames from "classnames";

import Button from "./button";
import { Divider } from "./typeface";
import * as styles from "../styles/ViewFile.module.scss";
import { formatBytes } from "../utils";
import { awsDownload } from "../utils/file-upload";

interface ViewFileProps {
  file: {
    entry_file_id: number;
    file_name: string;
    url: string;
    file_type: string;
    created_at: string;
    file_size: number;
  };
  last?: boolean;
}

export default function ViewFile({ file, last = false }: ViewFileProps) {
  const {
    url,
    file_type: type,
    file_name: name,
    created_at,
    file_size: size,
  } = file;

  const [focussed, setFocussed] = useState(false);

  if (!url) {
    return null;
  }

  return (
    <div className={styles.fileSummary}>
      <p>
        <strong>File name:</strong> {name} (
        <Button buttonType="link" onClick={async () => awsDownload(url, name)}>
          Download
        </Button>
        )
      </p>
      <p>
        <strong>Uploaded:</strong> {moment(created_at).format("DD-MM-YYYY")}
      </p>
      <p>
        <strong>File type:</strong> {type}
      </p>
      <p>
        <strong>Original file size:</strong> {formatBytes(size)}
      </p>
      {url &&
        (type.indexOf("video") !== -1 || type.indexOf("image") !== -1) && (
          <>
            <p>
              <strong>Preview:</strong>
            </p>
            <div
              className={classNames(styles.video, {
                [styles.focussed]: focussed,
              })}
              tabIndex={1}
              onFocus={() => setFocussed(true)}
              onBlur={() => setFocussed(false)}
            >
              <>
                {type.indexOf("video") !== -1 ? (
                  <>
                    <video className={styles.video} width="100%" controls>
                      <source src={url} type={"video/mp4"} />
                    </video>
                  </>
                ) : (
                  <img src={url} alt="" />
                )}
              </>
            </div>
          </>
        )}
      {!last && <Divider />}
    </div>
  );
}
