import * as React from "react";
import classNames from "classnames";

import Button from "./button";
// import heroVideo from "../assets/homepage-banner.mp4";
import useResize from "../hooks/useResize";
import * as styles from "../styles/layout.module.scss";

interface HeroProps {
  useVideo?: boolean;
  image?: string;
  fullHeight?: boolean;
  scrollTo?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

export function Hero({
  useVideo = false,
  image,
  fullHeight = false,
  scrollTo,
  style,
  className,
  children,
}: HeroProps) {
  // const screenWidth = useResize();
  // const screenHeight = useResize("height");

  // const screenRatio = React.useMemo(() => {
  //   if (screenHeight > 0 && screenWidth > 0) {
  //     return screenWidth / screenHeight;
  //   }

  //   return 0;
  // }, [screenWidth, screenHeight]);

  // if (useVideo && screenRatio > 1.33 && screenHeight > 600) {
  //   return (
  //     <div className={classNames(styles.hero, styles.fullHeight, className)}>
  //       <video
  //         disablePictureInPicture
  //         autoPlay
  //         muted
  //         loop
  //         className={styles.heroVideo}
  //       >
  //         <source src={heroVideo} type="video/mp4" />
  //       </video>
  //       <div className={styles.hero__contents}>{children}</div>
  //       {scrollTo && (
  //         <Button
  //           buttonType="scroll"
  //           onClick={() => {
  //             scrollTo?.current?.scrollIntoView({ behavior: "smooth" });
  //           }}
  //         >
  //           Scroll down
  //         </Button>
  //       )}
  //     </div>
  //   );
  // }

  return (
    <div
      className={classNames(styles.hero, className, {
        [styles.fullHeight]: fullHeight,
      })}
      style={{
        ...style,
        backgroundImage: image ? `url("${image}")` : undefined,
      }}
    >
      <div className={styles.hero__contents}>{children}</div>
      {scrollTo && (
        <Button
          buttonType="scroll"
          onClick={() => {
            scrollTo?.current?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Scroll down
        </Button>
      )}
    </div>
  );
}

interface ContainerProps {
  fullWidth?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ fullWidth, className, children }, ref) => {
    return (
      <div
        className={classNames(
          className,
          fullWidth ? styles.container__full : styles.container
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

interface RowProps {
  colCount?: 1 | 2;
  className?: string;
  children?: React.ReactNode;
}

export function Row({ colCount = 1, className, children }: RowProps) {
  return (
    <div
      className={classNames(styles.row, className, {
        [styles.twoCols]: colCount === 2,
      })}
    >
      {children}
    </div>
  );
}

interface ColProps {
  gridCols?: 100 | 50 | 4 | 6 | 8 | 12;
  textCenter?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export function Column({
  gridCols = 12,
  textCenter = false,
  className,
  style,
  children,
}: ColProps) {
  return (
    <div
      className={classNames(className, styles[`col_${gridCols}`], {
        [styles.textCenter]: textCenter,
      })}
      style={style}
    >
      {children}
    </div>
  );
}

interface LineTextDividerProps {
  label?: string;
  children: string | React.ReactNode;
}

export function LineTextDivider({
  children,
  label = "",
}: LineTextDividerProps) {
  // create dummy element for spacing purpose
  const chilrenLength = React.Children.count(children);
  return (
    <div className={styles.lineTextDivider}>
      <p>{label && <span>{label}</span>}</p>
      {children}
    </div>
  );
}
