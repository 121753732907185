import { AxiosResponse } from "axios";

import { endpoint } from ".";
import { AccountWithToken } from "./types/accounts";

export const getAccountDetails = async () => {
  try {
    const res: AxiosResponse<{ account: Omit<AccountWithToken, "token"> }> =
      await endpoint.get("/auth/account");

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateAccountDetails = async (
  uid: number,
  details: { first_name?: string | null; last_name?: string | null }
) => {
  try {
    const res: AxiosResponse<{
      updatedAccount: Omit<AccountWithToken, "token">;
    }> = await endpoint.post(`/auth/account/${uid}`, {
      first_name: details.first_name,
      last_name: details.last_name,
    });

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const resendConfirmationEmail = async () => {
  try {
    const res: AxiosResponse<{
      updatedAccount: Omit<AccountWithToken, "token">;
    }> = await endpoint.post(`/auth/resend-confirmation-email`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
