import classNames from "classnames";
import React from "react";

import { Column } from "./layout";
import * as styles from "../styles/checkout-breadcrumbs.module.scss";

interface BreadcrumbsProps {
  step: 0 | 1 | 2;
}

export default function Breadcrumbs({ step }: BreadcrumbsProps) {
  return (
    <Column gridCols={8}>
      <div className={styles.breadcrumbsContainer}>
        <div
          className={classNames(styles.breadcrumbItem, {
            [styles.active]: step >= 0,
          })}
        >
          1
        </div>
        <div
          className={classNames(styles.breadcrumbLine, {
            [styles.active]: step >= 1,
          })}
        />
        <div
          className={classNames(styles.breadcrumbItem, {
            [styles.active]: step >= 1,
          })}
        >
          2
        </div>
        <div
          className={classNames(styles.breadcrumbLine, {
            [styles.active]: step >= 2,
          })}
        />
        <div
          className={classNames(styles.breadcrumbItem, {
            [styles.active]: step >= 2,
          })}
        >
          3
        </div>
      </div>
    </Column>
  );
}
