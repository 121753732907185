import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import Button from "../components/button";
import { FormButtonRow } from "../components/form";
import { Column, Container, Row } from "../components/layout";
import SelectBox from "../components/select-box";
import SiteWrapper from "../components/SiteWrapper";
import { AwardsContext } from "../context/awards";
import { AccountContext } from "../context/account";
import useWindow from "../hooks/useWindow";

interface LocationState {
  duplicateCategory?: string;
}

const NewEntryPage = () => {
  const _window = useWindow();

  const {
    account: { uid, entrant_id },
  } = useContext(AccountContext);
  const { currentYearCategories, noNewEntries } = useContext(AwardsContext);

  // Used to pass entry category data
  const state = useLocation().state as LocationState;

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [error, setError] = useState(false);

  const availableCategories = useMemo(() => {
    const mappedCategories = currentYearCategories.map(
      ({ awards_category_id, title, entry_cost }) => {
        return {
          name: `${title}${entry_cost ? ` (£${entry_cost.toString()})` : ""}`,
          value: awards_category_id.toString(),
        };
      }
    );

    // Don't allow duplicate entries to have the same category as the original
    if (state && !!state.duplicateCategory) {
      const catIndex = mappedCategories.findIndex(
        (cat) => cat.value === state.duplicateCategory!.toString()
      );

      if (catIndex !== -1) mappedCategories.splice(catIndex, 1);
    }

    return mappedCategories;
  }, [currentYearCategories]);

  // Check if within the entry period
  // If not, redirect to dashboard
  useEffect(() => {
    if (noNewEntries === null || !_window.sessionStorage) return;

    // Should not be possible to submit entries if outside entry period
    if (noNewEntries) {
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [_window, noNewEntries]);

  // Accounts that have not completed at least of of their profile should not see this page
  if (uid && !entrant_id) {
    navigate("/");
  }

  return (
    <SiteWrapper>
      <Container>
        <Row>
          <Column gridCols={6}>
            <h1>Entry Form</h1>
            {state && state.duplicateCategory ? (
              <p>
                You are duplicating one of your existing entries. Select a
                category.
              </p>
            ) : (
              <>
                <p>
                  You will need to complete a new entry form for each category,
                  but entries can be duplicated from the dashboard page.
                </p>
                <p>
                  You can enter your film/programme into a maximum of two
                  categories, plus Best Documentary Presenter.
                </p>
                <p>
                  For example, you can enter the same film into Best Science
                  Documentary, Best History Documentary and Best Documentary
                  Presenter, but not Best Science Documentary, Best History
                  Documentary and Best Single Documentary - Domestic.
                </p>
                <p>
                  For information on the categories, see our{" "}
                  <Button
                    buttonType="link"
                    href="https://griersontrust.org/grierson-awards"
                  >
                    awards webpage
                  </Button>
                  .
                </p>
                <p>Select a category to enter.</p>
              </>
            )}
            <SelectBox
              labelKey="name"
              valueKey="value"
              options={availableCategories}
              value={availableCategories.find(
                (option) => option.value === selectedCategory
              )}
              onChange={(newValue) => {
                setError(false);
                setSelectedCategory(newValue?.value || null);
              }}
              error={error}
              errorMsg="Select a category to enter"
            />
            <FormButtonRow>
              <Button
                softDisable={!selectedCategory}
                buttonType="primary"
                onClick={() => {
                  if (selectedCategory) {
                    navigate(`/category/${selectedCategory}/new-entry`, {
                      state,
                    });
                  } else {
                    setError(true);
                  }
                }}
              >
                Start
              </Button>
            </FormButtonRow>
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
};

export default NewEntryPage;
