import React, { useMemo, useState } from "react";
import DatePicker, { DatePickerProps } from "react-date-picker";
import classNames from "classnames";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { StyledMarkdown } from "./typeface";
import chevron from "../assets/svgs/chevron.svg";
import * as styles from "../styles/input.module.scss";
import { wordCount } from "../utils";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  dark?: boolean;
  submit?: boolean;
  label?: string;
  numbersOnly?: boolean;
  introMsg?: string;
  stacked?: boolean;
  success?: boolean;
  successMsg?: string;
  error?: boolean;
  errorMsg?: string;
  children?: React.ReactElement;
}

export default function Input({
  dark = false,
  required,
  submit = false,
  className,
  label,
  stacked = false,
  introMsg,
  id,
  disabled,
  numbersOnly,
  success,
  successMsg,
  error,
  errorMsg,
  children,
  ...inputProps
}: InputProps) {
  const [submitActive, setSubmitActive] = useState(false);

  const inputField = (
    <>
      {submit ? (
        <div
          className={classNames(styles.inputSubmit, {
            [styles.dark]: dark,
            [styles.disabled]: disabled,
            [styles.success]: success,
            [styles.error]: error,
            [styles.active]: submitActive,
          })}
        >
          <input
            id={id}
            type={numbersOnly ? "number" : "text"}
            disabled={disabled}
            {...inputProps}
            onFocus={(e) => {
              setSubmitActive(true);

              if (inputProps.onFocus) {
                inputProps.onFocus(e);
              }
            }}
            onBlur={(e) => {
              setSubmitActive(false);

              if (inputProps.onBlur) {
                inputProps.onBlur(e);
              }
            }}
          />
          <button type="submit" disabled={disabled}>
            <img src={chevron} />
          </button>
        </div>
      ) : (
        <input
          id={id}
          type={numbersOnly ? "number" : "text"}
          className={classNames({
            [styles.dark]: dark,
            [styles.disabled]: disabled,
            [styles.success]: success,
            [styles.error]: error,
          })}
          disabled={disabled}
          {...inputProps}
        />
      )}
    </>
  );

  return (
    <div className={classNames(className, styles.container)}>
      {label ? (
        <div
          className={classNames(styles.labelContainer, {
            [styles.stacked]: stacked,
            [styles.disabled]: disabled,
          })}
        >
          <label htmlFor={id}>
            {label} {required && <span className={styles.required}>*</span>}
          </label>
          {introMsg && (
            <StyledMarkdown className={styles.introMsg}>
              {introMsg}
            </StyledMarkdown>
          )}
          {inputField}
        </div>
      ) : (
        inputField
      )}
      {success && !error && (
        <span className={styles.successMsg}>{successMsg || "Success"}</span>
      )}
      {error && errorMsg && (
        <span className={styles.errorMsg}>{errorMsg || "Error"}</span>
      )}
      {children}
    </div>
  );
}

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  resize?: boolean;
  dark?: boolean;
  label?: string;
  stacked?: boolean;
  introMsg?: string;
  minWords?: number;
  maxWords?: number;
  success?: boolean;
  successMsg?: string;
  error?: boolean;
  errorMsg?: string;
  children?: React.ReactElement;
}

export function TextArea({
  resize = false,
  dark = false,
  required,
  label,
  stacked = false,
  introMsg,
  id,
  className,
  minWords,
  maxWords,
  disabled,
  success,
  successMsg,
  error,
  errorMsg,
  value,
  onChange,
  onBlur,
  children,
  ...textAreaProps
}: TextAreaProps) {
  const [minWordLimit, setMinWordLimit] = useState(false);
  const [maxWordLimit, setMaxWordLimit] = useState(false);

  const wordLength = useMemo(() => {
    if (!value) return 0;
    if (typeof value !== "string") return 0;

    const count = wordCount(value);

    if (maxWords && count > 0 && count > maxWords) setMaxWordLimit(true);
    else if (maxWordLimit) setMaxWordLimit(false);

    return count;
  }, [value]);

  const textArea = (
    <textarea
      id={id}
      className={classNames({
        [styles.noResize]: !resize,
        [styles.dark]: dark,
        [styles.disabled]: disabled,
        [styles.success]: success && !(error || minWordLimit || maxWordLimit),
        [styles.error]: error || minWordLimit || maxWordLimit,
      })}
      value={value}
      disabled={disabled}
      onChange={(e) => {
        // Only update value if new value is shorter than current value
        if (
          maxWordLimit &&
          typeof value === "string" &&
          e.target.value.length >= value.length
        )
          return;

        if (minWordLimit && minWords && typeof value === "string") {
          const count = wordCount(e.target.value);

          if (count >= minWords || count === 0) setMinWordLimit(false);
        }

        if (onChange) onChange(e);
      }}
      onBlur={(e) => {
        if (minWords && typeof value === "string") {
          if (wordLength > 0 && wordLength < minWords) setMinWordLimit(true);
        }

        if (onBlur) onBlur(e);
      }}
      {...textAreaProps}
    />
  );

  return (
    <div className={classNames(className, styles.container)}>
      {label ? (
        <div
          className={classNames(styles.labelContainer, styles.textArea, {
            [styles.stacked]: stacked,
            [styles.disabled]: disabled,
          })}
        >
          <label htmlFor={id}>
            {label} {required && <span className={styles.required}>*</span>}
          </label>
          {introMsg && (
            <StyledMarkdown className={styles.introMsg}>
              {introMsg}
            </StyledMarkdown>
          )}
          {textArea}
        </div>
      ) : (
        textArea
      )}
      {(minWords || maxWords) && (
        <span className={styles.wordCount}>
          {maxWords &&
            !minWords &&
            `Up to ${maxWords} words (${wordLength} words)`}
          {minWords &&
            !maxWords &&
            `Minimum ${minWords} words (${wordLength} words)`}
          {minWords &&
            maxWords &&
            `Enter ${minWords}-${maxWords} words (${wordLength} words)`}
        </span>
      )}
      {maxWordLimit && (
        <span className={styles.errorMsg}>Max word count reached</span>
      )}
      {minWordLimit && (
        <span className={styles.errorMsg}>Minimum word count not reached</span>
      )}
      {success && !(error || minWordLimit || maxWordLimit) && (
        <span className={styles.successMsg}>{successMsg || "Success"}</span>
      )}
      {error && <span className={styles.errorMsg}>{errorMsg || "Error"}</span>}
      {children}
    </div>
  );
}

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  dark?: boolean;
  label: string;
  introMsg?: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  success?: boolean;
  successMsg?: string;
  error?: boolean;
  errorMsg?: string;
  children?: React.ReactElement;
}

export function Checkbox({
  dark = false,
  label,
  introMsg,
  id,
  checked = false,
  disabled,
  onChange,
  className,
  success,
  successMsg,
  error,
  errorMsg,
  children,
  ...checkboxProps
}: CheckboxProps) {
  const checkboxClasses = classNames(className, styles.checkboxContainer, {
    [styles.disabled]: disabled,
  });

  return (
    <>
      {introMsg && (
        <StyledMarkdown className={styles.introMsg}>{introMsg}</StyledMarkdown>
      )}
      <div className={checkboxClasses}>
        <input
          type="checkbox"
          id={id}
          className={classNames({
            [styles.dark]: dark,
            [styles.disabled]: disabled,
            [styles.success]: success,
            [styles.error]: error,
          })}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          {...checkboxProps}
        />
        <label htmlFor={id}>{label}</label>
      </div>
      {success && !error && (
        <span className={styles.successMsg}>{successMsg || "Success"}</span>
      )}
      {error && <span className={styles.errorMsg}>{errorMsg || "Error"}</span>}
      {children}
    </>
  );
}

interface DateFieldProps extends DatePickerProps {
  id?: string;
  dark?: boolean;
  label?: string;
  introMsg?: string;
  stacked?: boolean;
  error?: boolean;
  errorMsg?: string;
  containerClassName?: string;
  onChange?: (d: Date) => void;
  children?: React.ReactElement;
}

export function DateField({
  dark = false,
  required,
  containerClassName,
  label,
  stacked = false,
  introMsg,
  id,
  error,
  errorMsg,
  children,
  onChange,
  dayPlaceholder = "DD",
  monthPlaceholder = "MM",
  yearPlaceholder = "YYYY",
  format = "dd/MM/y",
  minDate = new Date("Jan 01 2000"),
  maxDate = new Date("Dec 31 2099"),
  disabled,
  ...dateFieldProps
}: DateFieldProps) {
  const dateFieldClasses = classNames(containerClassName, styles.container);
  const [isDisabled, setIsDisabled] = useState(false);
  const dateField = (
    <DatePicker
      className={classNames(styles.datePicker, {
        [styles.dark]: dark,
        [styles.error]: error,
        [styles.disabled]: disabled,
        [styles.hidden]: isDisabled,
      })}
      // @ts-ignore
      onClick={(e) => {
        if (e.target.matches("input, div.react-date-picker")) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      }}
      onFocus={() => {
        setIsDisabled(true);
      }}
      calendarClassName={styles.calendar}
      // tileClassName={styles.calendarTile}
      onChange={(val: Date) => {
        if (onChange) {
          onChange(val);
        }
      }}
      dayPlaceholder={dayPlaceholder}
      monthPlaceholder={monthPlaceholder}
      yearPlaceholder={yearPlaceholder}
      format={format}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      {...dateFieldProps}
    />
  );

  return (
    <div className={dateFieldClasses}>
      {label ? (
        <div
          id={id}
          className={classNames(styles.labelContainer, {
            [styles.stacked]: stacked,
            [styles.disabled]: disabled,
          })}
        >
          <label htmlFor={id}>
            {label} {required && <span className={styles.required}>*</span>}
          </label>
          {introMsg && (
            <StyledMarkdown className={styles.introMsg}>
              {introMsg}
            </StyledMarkdown>
          )}
          {dateField}
        </div>
      ) : (
        dateField
      )}
      {error && errorMsg && (
        <span className={styles.errorMsg}>{errorMsg || "Error"}</span>
      )}
      {children}
    </div>
  );
}

interface RadioGroupProps extends React.InputHTMLAttributes<HTMLInputElement> {
  dark?: boolean;
  options: {
    id: string;
    label: string;
    disabled?: boolean;
  }[];
  name: string;
  selected: string | null;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  success?: boolean;
  successMsg?: string;
  error?: boolean;
  errorMsg?: string;
  children?: React.ReactElement;
}

export function RadioGroup({
  dark = false,
  options,
  selected,
  onChange,
  name,
  className,
  success,
  successMsg,
  error,
  errorMsg,
  children,
  ...radioProps
}: RadioGroupProps) {
  const radioClasses = classNames(className, styles.radioContainer);

  return (
    <div className={radioClasses}>
      {options.map(({ label, id, disabled }) => {
        return (
          <div
            key={id}
            className={classNames(styles.radioElement, {
              [styles.disabled]: disabled,
            })}
          >
            <input
              {...radioProps}
              id={id}
              type="radio"
              name={name}
              disabled={disabled}
              className={classNames({
                [styles.dark]: dark,
                [styles.success]: success && selected === id,
                [styles.error]: error && selected === id,
              })}
              checked={selected === id}
              onChange={onChange}
            />
            <label htmlFor={id}>{label}</label>
          </div>
        );
      })}
      {children}
      {success && !error && (
        <span className={styles.successMsg}>{successMsg || "Success"}</span>
      )}
      {error && <span className={styles.errorMsg}>{errorMsg || "Error"}</span>}
    </div>
  );
}
