import React, { useContext, useState, useCallback, useEffect } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";

import Button from "../components/button";
import {
  FormMessage,
  FormError,
  FormButtonRow,
  Form,
} from "../components/form";
import Input from "../components/input";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { validatePassword, ValidationError } from "../utils";
import { AccountContext } from "../context/account";
import { setNewResetPassword } from "../api/auth";

export default function ResetPasswordPage() {
  const {
    account: { loggedIn },
  } = useContext(AccountContext);

  const [password, setPassword] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [step, setStep] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const queryString = parse(location.search);
    const { token, hash } = queryString;
    if (!token || !hash) {
      // TODO: add better handling for this
      setError("Your reset password link has expired.");
    }
  }, []);

  const onSubmit = useCallback(async () => {
    const validationStatus: ValidationError = validatePassword(
      password,
      passwordTwo
    );

    switch (validationStatus) {
      case "empty":
        setError("This field cannot be empty.");
        break;
      case "min-length":
        setError("Your password needs to be at least 6 characters long.");
        break;
      case "do-not-match":
        setError("Your passwords do not match.");
        break;
      default:
        setError(null);
        const queryString = parse(location.search);
        const { token, hash } = queryString;

        try {
          const result = await setNewResetPassword({
            password,
            token: `${token}`,
            hash: `${hash}`,
          });
          if (result) {
            setStep(1);
          } else {
            throw new Error();
          }
        } catch {
          setError("Unable to reset password. Please try again later.");
        }
        break;
    }
  }, [password, passwordTwo]);

  return (
    <SiteWrapper hideStickyNav>
      <Container>
        <Row>
          <Column gridCols={4}>
            <h1>Set a new password</h1>

            {step === 0 && (
              <Form onSubmit={onSubmit}>
                <>
                  <Input
                    dark
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    error={error !== null}
                  />
                  <Input
                    dark
                    type="password"
                    id="passwordTwo"
                    name="passwordTwo"
                    placeholder="Re-type Password"
                    value={passwordTwo}
                    onChange={(e) => {
                      setPasswordTwo(e.target.value);
                    }}
                    error={error !== null}
                  />
                  <p>
                    Your password must be at least 6 characters long and contain
                    both letters and numbers.
                  </p>
                  {error !== null && (
                    <FormError>
                      <p>{error}</p>
                    </FormError>
                  )}
                  <FormButtonRow>
                    <Button buttonType="primary">Reset password</Button>
                  </FormButtonRow>
                </>
              </Form>
            )}
            {step === 1 && (
              <>
                <FormMessage>
                  {loggedIn ? (
                    <p>Your password has been updated.</p>
                  ) : (
                    <p>Your password has been reset. You can now log in.</p>
                  )}
                </FormMessage>
                <FormButtonRow>
                  {loggedIn ? (
                    <Button buttonType="primary" href="/dashboard">
                      Dashboard
                    </Button>
                  ) : (
                    <Button buttonType="primary" href="/login">
                      Login
                    </Button>
                  )}
                </FormButtonRow>
              </>
            )}
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
