import { AxiosResponse } from "axios";

import { endpoint } from ".";
import {
  AccountRequest,
  AccountWithToken,
  ResetPasswordRequest,
  SetNewResetPasswordRequest,
  UpdatePasswordRequest,
} from "./types/accounts";

export const isLoggedIn = async () => {
  const token = localStorage.getItem("token");

  if (!token) return false;

  try {
    const res: AxiosResponse<{ loggedIn: boolean }> = await endpoint.get(
      "/auth/is-logged-in"
    );

    return res.data.loggedIn;
  } catch {
    return false;
  }
};

export const login = async ({
  email,
  password,
  rememberMe,
}: AccountRequest) => {
  try {
    const { status, data }: AxiosResponse<{ account: AccountWithToken }> =
      await endpoint.post("/auth/login", {
        email,
        password,
        rememberMe,
      });

    return { status, data };
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const logout = () => {
  localStorage.removeItem("token");
};

export const registerNewAccount = async ({
  email,
  password,
  rememberMe,
}: AccountRequest) => {
  try {
    const { status, data }: AxiosResponse<{ account: AccountWithToken }> =
      await endpoint.post("/auth/account", {
        email,
        password,
        rememberMe,
      });

    return { status, data };
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updatePassword = async ({
  oldPassword,
  newPassword,
}: UpdatePasswordRequest) => {
  try {
    await endpoint.post("/auth/update-password", {
      oldPassword,
      newPassword,
    });

    return true;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const resetPassword = async ({ email }: ResetPasswordRequest) => {
  try {
    await endpoint.post("/auth/reset-password", {
      email,
    });

    return true;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const setNewResetPassword = async ({
  token,
  hash,
  password,
}: SetNewResetPasswordRequest) => {
  try {
    await endpoint.post("/auth/set-new-reset-password", {
      token,
      hash,
      password,
    });

    return true;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
