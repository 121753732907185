import { Dispatch, SetStateAction } from "react";
import { S3Client } from "@aws-sdk/client-s3";
import _ from "lodash";

import {
  checkAborted,
  checkAndSetLocalStorage,
  chunkUint8Arrays,
  copyLocalStorage,
  deleteLocalStorageProp,
  updateLSProgress,
} from "./uploader";
import * as uploadHandlers from "./uploadHandlers";
import {
  awsCompleteMpu,
  awsCreateMultipart,
  awsUploadPart,
} from "../services/mpu-methods";
import { awsUpload } from "../services/non-mpu-methods";
import { addFileToDb } from "../../api/awards-entry-files";
import { AwardsEntryFileResponse } from "../../api/types/awards-entries";

export const checkFileExists = (
  dbEntries: AwardsEntryFileResponse[],
  fileName: string
) => !!dbEntries.find((upload) => upload.file_name === fileName);

interface SmallFileUpload {
  entryId: string;
  file: File;
  size: number;
  setFilesList: Dispatch<SetStateAction<AwardsEntryFileResponse[]>>;
  s3Client: S3Client;
}

export const smallFileUpload = async ({
  entryId,
  file,
  size,
  setFilesList,
  s3Client,
}: SmallFileUpload) => {
  const s3Id = await awsUpload(entryId, s3Client, file);

  if (!s3Id) {
    throw new Error("Failed to upload file");
  }

  const {
    entryFile: { entry_file_id, created_at },
  } = await addFileToDb({
    entry_id: parseInt(entryId),
    file_name: `${entryId} - ${file.name}`,
    file_type: file.type,
    file_size: size,
    s3_id: s3Id,
    file_status: "complete",
  });

  setFilesList((currEntries) => {
    const newList: AwardsEntryFileResponse[] = _.cloneDeep(currEntries);

    newList.push({
      entry_id: parseInt(entryId),
      entry_file_id,
      file_name: `${entryId} - ${file.name}`,
      file_size: size,
      created_at,
      file_type: file.type,
    });

    return newList;
  });
};

interface InitiateMpu {
  entryId: string;
  s3Client: S3Client;
  file: File;
  size: number;
  totalChunks: number;
  setInProgressUploads: Dispatch<SetStateAction<PendingUpload[]>>;
  setFilesList: Dispatch<SetStateAction<AwardsEntryFileResponse[]>>;
  reader: ReadableStreamDefaultReader<any>;
}

export const initiateMpu = async ({
  entryId,
  s3Client,
  file,
  size,
  totalChunks,
  setInProgressUploads,
  setFilesList,
  reader,
}: InitiateMpu) => {
  const fileName = `${entryId} - ${file.name}`;
  const fileType = file.type;

  const id = await awsCreateMultipart(s3Client, fileName);

  if (id) {
    checkAndSetLocalStorage(fileName, {
      fileType,
      uploadId: id,
      uploadedParts: [],
      nextPart: 1,
      progress: 0,
      size,
      totalChunks,
    });

    setInProgressUploads(copyLocalStorage());

    await resumeMpu({
      s3Client,
      entryId: parseInt(entryId),
      id,
      fileName,
      fileType,
      size,
      totalChunks,
      startingChunk: 1,
      setInProgressUploads,
      setFilesList,
      reader,
    });
  } else {
    await Promise.reject("Failed to get ID");
  }
};

interface ResumeMpu {
  s3Client: S3Client;
  entryId: number;
  id: string;
  fileName: string;
  fileType: string;
  size: number;
  totalChunks: number;
  startingChunk: number;
  setInProgressUploads: Dispatch<SetStateAction<PendingUpload[]>>;
  setFilesList: Dispatch<SetStateAction<AwardsEntryFileResponse[]>>;
  reader: ReadableStreamDefaultReader<any>;
  setSuspendedUploads?: Dispatch<SetStateAction<SuspendedUpload[]>>;
  remaining?: Uint8Array;
}

export const resumeMpu = async ({
  s3Client,
  entryId,
  id,
  fileName,
  fileType,
  size,
  totalChunks,
  startingChunk,
  setInProgressUploads,
  setFilesList,
  reader,
  setSuspendedUploads,
  remaining,
}: ResumeMpu) => {
  if (setSuspendedUploads) {
    setSuspendedUploads((curr) => {
      const newSuspended = [...curr];

      newSuspended.splice(
        curr.findIndex((entry) => entry.name === fileName),
        1
      );

      return newSuspended;
    });
  }

  let uploadGroup: Uint8Array[] = []; // Array for promise.all
  let heldValue = remaining ? remaining : new Uint8Array(0);
  let nextChunk = startingChunk;
  let currChunk: number;
  let finished = false;

  // Loop through to make chunks and upload 6 at a time
  while (nextChunk <= totalChunks) {
    uploadGroup = [];
    currChunk = 0;

    if (checkAborted(fileName)) {
      reader.cancel();
      await Promise.reject("Ending upload: aborted!");
      return;
    }

    while (currChunk < 6) {
      const { done, value } = await reader.read();

      if (done) {
        // Upload the final chunk that is <5MB
        uploadGroup.push(...[heldValue]);
        finished = true;
        reader.cancel();
        break;
      }

      if (heldValue.length === 0) heldValue = value;
      else {
        const { merged, remaining } = chunkUint8Arrays(heldValue, value);

        heldValue = remaining;

        if (merged.length > 0) {
          uploadGroup.push(...merged);
          currChunk++;
        }
      }
    }

    // Upload 6 parts together
    const uploadedParts = await Promise.all(
      uploadGroup.map(async (chunk, index) => {
        return await uploadHandlers.parallelUpload({
          s3Client,
          index,
          fileName,
          chunk,
        });
      })
    );

    if (uploadedParts.every((part) => part && part.hasOwnProperty("ETag"))) {
      nextChunk += currChunk;

      updateLSProgress(fileName, uploadedParts as UploadedPart[], nextChunk);

      setInProgressUploads(copyLocalStorage());

      if (finished) break;
    } else {
      await Promise.reject("Found a part that was not uploaded");
    }
  }

  // Check all chunks have been uploaded
  if (nextChunk >= totalChunks) {
    const ls = window.localStorage.getItem("inProgressUploads");

    if (ls) {
      const parsed = JSON.parse(ls) as LocalStoragePendingUploads;
      const values = parsed[fileName].uploadedParts;

      const orderedVals = values.sort((a, b) => {
        return a.PartNumber - b.PartNumber;
      });

      try {
        const s3UploadData = await awsCompleteMpu(
          s3Client,
          fileName,
          id,
          orderedVals
        );

        deleteLocalStorageProp(fileName);

        setInProgressUploads(copyLocalStorage());

        if (!s3UploadData) {
          throw new Error();
        }

        const {
          entryFile: { entry_file_id, created_at },
        } = await addFileToDb({
          entry_id: entryId,
          file_name: fileName,
          file_type: fileType,
          file_size: size,
          s3_id: s3UploadData.ETag || "",
          file_status: "complete",
        });

        setFilesList((currEntries) => {
          const newList: AwardsEntryFileResponse[] = _.cloneDeep(currEntries);

          newList.push({
            entry_id: entryId,
            entry_file_id,
            file_name: fileName,
            file_size: size,
            created_at,
            file_type: fileType,
          });

          return newList;
        });

        return orderedVals;
      } catch (err) {
        await Promise.reject(err);
      }
    }
  } else {
    await Promise.reject("Not all parts found");
  }
};

interface ParallelUpload {
  s3Client: S3Client;
  index: number;
  fileName: string;
  chunk: Uint8Array;
}

export const parallelUpload = async ({
  s3Client,
  index,
  fileName,
  chunk,
}: ParallelUpload): Promise<UploadedPart | undefined> => {
  const ls = window.localStorage.getItem("inProgressUploads");

  if (ls) {
    const parsed = JSON.parse(ls) as LocalStoragePendingUploads;
    const blob: Blob = new Blob([chunk]);

    const uploadData = await awsUploadPart(
      s3Client,
      fileName,
      parsed[fileName].uploadId,
      parsed[fileName].nextPart + index,
      blob
    );

    if (uploadData && uploadData.ETag) {
      return {
        ETag: uploadData.ETag,
        PartNumber: parsed[fileName].nextPart + index,
      };
    } else {
      await Promise.reject("ETag not found");
    }
  } else {
    await Promise.reject("Local storage not found");
  }
};
