export interface UpdatePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SetNewResetPasswordRequest {
  token: string;
  hash: string;
  password: string;
}

export interface AccountRequest {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export interface AccountWithToken {
  token: string;
  email: string;
  created_at: string;
  is_confirmed?: boolean;
  last_login?: string[];
  uid: number;
  first_name?: string;
  last_name?: string;
  admin_id?: number;
  judge_id?: number;
  entrant_id?: number;
  crm_id?: string;
  account_confirmation_email_sent?: string;
}

export interface EntrantSeed {
  uid: number;
  organisation?: string;
  job_title?: string;
  address_1?: string;
  address_2?: string;
  address_3?: string;
  town_city?: string;
  postcode?: string;
  country?: string;
  phone?: string;
  same_billing_details?: boolean;
  billing_first_name?: string;
  billing_last_name?: string;
  billing_address_1?: string;
  billing_address_2?: string;
  billing_address_3?: string;
  billing_town_city?: string;
  billing_postcode?: string;
  billing_country?: string;
  billing_phone?: string;
  billing_email?: string;
  entry_extension?: string;
}

export interface EntrantResponseFull extends EntrantSeed {
  entrant_id: number;
  first_name: string;
  last_name: string;
  email?: string;
}

export interface EntrantResponse
  extends Pick<
    EntrantResponseFull,
    "entrant_id" | "uid" | "first_name" | "last_name" | "entry_extension"
  > {
  email: string;
  is_confirmed: boolean;
  last_login: string;
  entry_count?: number;
  invoice_entry_count?: number;
  paid_entry_count?: number;
  completed_entry_count?: number;
}

export type EntrantBillingDetails = Pick<
  EntrantSeed,
  | "billing_first_name"
  | "billing_last_name"
  | "billing_email"
  | "billing_address_1"
  | "billing_address_2"
  | "billing_address_3"
  | "billing_town_city"
  | "billing_postcode"
  | "billing_country"
  | "billing_phone"
>;

export const billingDetailsMapper = {
  billing_first_name: "First name",
  billing_last_name: "Last name",
  billing_email: "Email",
  billing_address_1: "Address 1",
  billing_address_2: "Address 2",
  billing_address_3: "Address 3",
  billing_town_city: "Town/City",
  billing_postcode: "Postcode",
  billing_country: "Country",
  billing_phone: "Phone",
};
