import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";

import { AccountContext } from "../context/account";
import SiteWrapper from "../components/SiteWrapper";

import Button from "../components/button";
import { FormMessage, FormError, FormButtonRow } from "../components/form";
import { Column, Container, Row } from "../components/layout";
import { confirmEmail } from "../api/email";

export default function ConfirmEmailPage() {
  const [emailHasBeenValidated, setEmailHasBeenValidated] = useState(true);
  const {
    account: { loggedIn },
    setAccount,
  } = useContext(AccountContext);
  const location = useLocation();

  useEffect(() => {
    const queryString = parse(location.search);
    const { hash } = queryString;
    if (hash) {
      const execute = async () => {
        try {
          const res = await confirmEmail(`${hash}`);
          if (!res) {
            setEmailHasBeenValidated(false);
          } else {
            setAccount((curr) => {
              return {
                ...curr,
                accountConfirmed: true,
              };
            });
          }
        } catch (err: any) {
          setEmailHasBeenValidated(false);
        }
      };

      execute();
    }
  }, []);

  return (
    <SiteWrapper hideStickyNav>
      <Container>
        <Row>
          <Column gridCols={4}>
            <h1>Confirm email address</h1>
            <>
              {emailHasBeenValidated ? (
                <FormMessage>
                  <p>Your email has been confirmed.</p>
                </FormMessage>
              ) : (
                <FormError>
                  <p>Your email cannot be confirmed.</p>
                </FormError>
              )}
              <FormButtonRow>
                {loggedIn ? (
                  <Button buttonType="primary" href="/dashboard">
                    Dashboard
                  </Button>
                ) : (
                  <Button buttonType="primary" href="/login">
                    Login
                  </Button>
                )}
              </FormButtonRow>
            </>
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
