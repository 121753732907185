import React, { useContext, useState, useCallback, useEffect } from "react";
import _ from "lodash";

import { addEntrant, updateEntrant } from "../api/awards-entrants";
import Button from "../components/button";
import { Column, Container, Row } from "../components/layout";
import Input, { Checkbox } from "../components/input";
import { FormButtonRow, FormError, FormMessage } from "../components/form";
import SiteWrapper from "../components/SiteWrapper";
import { Divider } from "../components/typeface";
import { AccountContext } from "../context/account";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/ProfilePage.module.scss";

export default function BillingDetailsPage() {
  const { account, setAccount } = useContext(AccountContext);

  const _window = useWindow();

  const [entrantDetails, setEntrantDetails] = useState(
    account.entrantDetails
      ? { ...account.entrantDetails, hasBeenUpdated: false }
      : { hasBeenUpdated: false }
  );
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);

  // Reset account details on save or fetch
  useEffect(() => {
    if (account.entrantDetails) {
      setEntrantDetails({
        ...account.entrantDetails,
        hasBeenUpdated: false,
      });
    }
  }, [account]);

  const onSubmit = useCallback(async () => {
    setSaveError(null);

    if (!entrantDetails.hasBeenUpdated) {
      setSaveError("Please update some of your account details.");
      return;
    }

    if (
      !entrantDetails.same_billing_details &&
      (!entrantDetails.billing_address_1 ||
        !entrantDetails.billing_town_city ||
        !entrantDetails.billing_postcode ||
        !entrantDetails.billing_country ||
        !entrantDetails.billing_phone)
    ) {
      setSaveError("Please fill in all required fields.");
      return;
    }

    try {
      if (entrantDetails.hasBeenUpdated) {
        if (!account.entrant_id) {
          const { entrant } = await addEntrant(entrantDetails);

          setAccount((curr) => {
            return {
              ...curr,
              entrant_id: entrant.entrant_id,
              entrantDetails: {
                ...entrant,
              },
            };
          });
        } else {
          const { updatedEntrant } = await updateEntrant(
            account.entrant_id,
            entrantDetails
          );

          setAccount((curr) => {
            return {
              ...curr,
              entrantDetails: {
                ...curr.entrantDetails,
                ...updatedEntrant,
              },
            };
          });
        }
      }

      setUpdateSuccess(true);
      _window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Add smooth scrolling behavior
      });
    } catch (err: any) {
      setSaveError(
        err.message || "There was an error updating your account details"
      );
    }
  }, [account, entrantDetails, name]);

  if (!account || !account.uid) return null;

  return (
    <SiteWrapper>
      <Container>
        <Row>
          <Column gridCols={6}>
            <h1>Billing Details</h1>
            <p>
              In order to submit entries for the awards, you need to provide
              billing details.
            </p>
            {updateSuccess && (
              <FormMessage>
                <p>Your details have been successfully updated.</p>
              </FormMessage>
            )}
            <div className={styles.form}>
              <Checkbox
                id="same_billing_details"
                name="same_billing_details"
                label="Use profile details for billing"
                checked={
                  typeof entrantDetails.same_billing_details === "boolean"
                    ? entrantDetails.same_billing_details
                    : true
                }
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  const sameDetails = e.target.checked;

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      same_billing_details: e.target.checked,
                      billing_first_name: sameDetails
                        ? curr.billing_first_name
                        : undefined,
                      billing_last_name: sameDetails
                        ? curr.billing_last_name
                        : undefined,
                      billing_address_1: sameDetails
                        ? curr.billing_address_1
                        : undefined,
                      billing_address_2: sameDetails
                        ? curr.billing_first_name
                        : undefined,
                      billing_address_3: sameDetails
                        ? curr.billing_address_3
                        : undefined,
                      billing_town_city: sameDetails
                        ? curr.billing_town_city
                        : undefined,
                      billing_postcode: sameDetails
                        ? curr.billing_postcode
                        : undefined,
                      billing_country: sameDetails
                        ? curr.billing_country
                        : undefined,
                      billing_phone: sameDetails
                        ? curr.billing_phone
                        : undefined,
                      billing_email: sameDetails
                        ? curr.billing_email
                        : undefined,
                      hasBeenUpdated: true,
                    };
                  });
                }}
              />

              <Input
                id="billing_first_name"
                name="billing_first_name"
                label="First name"
                required={!entrantDetails.same_billing_details}
                error={!!saveError && !entrantDetails.billing_first_name}
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_first_name || ""}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_first_name: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
              />
              <Input
                id="billing_last_name"
                name="billing_last_name"
                label="Last name"
                required={!entrantDetails.same_billing_details}
                error={!!saveError && !entrantDetails.billing_last_name}
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_last_name || ""}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_last_name: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Input
                id="billing_email"
                name="billing_email"
                label="Email"
                required={!entrantDetails.same_billing_details}
                error={!!saveError && !entrantDetails.billing_email}
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_email || ""}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_email: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Input
                id="billing_address_1"
                name="billing_address_1"
                label="Address Line 1"
                required={!entrantDetails.same_billing_details}
                error={!!saveError && !entrantDetails.billing_address_1}
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_address_1 || ""}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_address_1: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Input
                id="billing_address_2"
                name="billing_address_2"
                label="Address Line 2"
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_address_2 || ""}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_address_2: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Input
                id="billing_address_3"
                name="billing_address_3"
                label="Address Line 3"
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_address_3 || ""}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_address_3: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Input
                id="billing_town_city"
                name="billing_town_city"
                label="Town/City"
                required={!entrantDetails.same_billing_details}
                error={!!saveError && !entrantDetails.billing_town_city}
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_town_city || ""}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_town_city: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Input
                id="billing_postcode"
                name="billing_postcode"
                label="Postcode"
                required={!entrantDetails.same_billing_details}
                error={!!saveError && !entrantDetails.billing_postcode}
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_postcode || ""}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_postcode: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Input
                id="billing_country"
                name="billing_country"
                label="Country"
                required={!entrantDetails.same_billing_details}
                error={!!saveError && !entrantDetails.billing_country}
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_country || ""}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_country: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Input
                id="billing_phone"
                name="billing_phone"
                label="Phone number"
                required={!entrantDetails.same_billing_details}
                error={!!saveError && !entrantDetails.billing_phone}
                disabled={entrantDetails.same_billing_details !== false}
                value={entrantDetails.billing_phone || ""}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setSaveError(null);

                  setEntrantDetails((curr) => {
                    return {
                      ...curr,
                      billing_phone: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Divider />
              <FormButtonRow>
                <Button buttonType="tertiary" href="/profile">
                  Edit profile
                </Button>
                <div className={styles.submitButton}>
                  <Button
                    type="submit"
                    buttonType="primary"
                    softDisable={!entrantDetails.hasBeenUpdated || !!saveError}
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </div>
              </FormButtonRow>
              {saveError && (
                <FormError>
                  <p>{saveError}</p>
                </FormError>
              )}
            </div>
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
