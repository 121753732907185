import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import _ from "lodash";
import moment from "moment";

import SiteWrapper from "../components/SiteWrapper";
import { Column, Container, Row } from "../components/layout";
import { Modal } from "../components/modal";
import { Divider } from "../components/typeface";
import {
  addNewAwardsYear,
  getAwardsYears,
  updateAwardsYearDetails,
  updateCurrentYear,
} from "../api/awards-years";
import {
  AwardsYearDetails,
  AwardsYearsResponse,
} from "../api/types/awards-years";
import { FormButtonRow, FormError, FormMessage } from "../components/form";
import Button, { EditButton } from "../components/button";
import Input, { Checkbox, DateField } from "../components/input";
import { AwardsContext } from "../context/awards";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/AdminManageYearsPage.module.scss";

interface UpdateAwardsYearModalProps {
  yearToEdit: AwardsYearDetails;
  onUpdate: (newYear: AwardsYearDetails) => void;
}

const UpdateAwardsYearModal = ({
  yearToEdit,
  onUpdate,
}: UpdateAwardsYearModalProps) => {
  const [currentYearToEdit, setCurrentYearToEdit] = useState({
    ...yearToEdit,
    hasBeenUpdated: false,
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  return (
    <>
      <h1>Edit year: {yearToEdit.year}</h1>
      <p>Updates will only apply when 'Save' is clicked.</p>
      <div className={styles.form}>
        <Checkbox
          id="is_current_year"
          name="is_current_year"
          label="Make this the current year"
          checked={currentYearToEdit.is_current_year || false}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentYearToEdit((curr) => {
              return {
                ...curr,
                is_current_year: e.target.checked,
                hasBeenUpdated: true,
              };
            });
          }}
        />
        {Object.keys(yearToEdit).map((item) => {
          if (item === "year" || item === "is_current_year") {
            return null;
          }

          if (item === "full_title" || item === "short_title") {
            return (
              <Input
                key={item}
                id={item}
                name={item}
                label={`${item[0].toUpperCase()}${item
                  .slice(1)
                  .replaceAll("_", " ")}`}
                value={currentYearToEdit[item]}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setUpdateError(null);

                  setCurrentYearToEdit((curr) => {
                    return {
                      ...curr,
                      [item]: e.target.value,
                      hasBeenUpdated: true,
                    };
                  });
                }}
              />
            );
          }

          return (
            <DateField
              key={item}
              id={item}
              name={item}
              label={`${item[0].toUpperCase()}${item
                .slice(1)
                .replaceAll("_", " ")}`}
              value={
                currentYearToEdit[
                  item as keyof Omit<
                    AwardsYearDetails,
                    "year" | "is_current_year"
                  >
                ]
                  ? new Date(
                      currentYearToEdit[
                        item as keyof Omit<
                          AwardsYearDetails,
                          "year" | "is_current_year"
                        >
                      ]
                    )
                  : null
              }
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentYearToEdit((curr) => {
                  return {
                    ...curr,
                    [item]: e ? moment(e).format() : null,
                    hasBeenUpdated: true,
                  };
                });
              }}
            />
          );
        })}
        <FormButtonRow>
          <Button
            buttonType="primary"
            disabled={!currentYearToEdit.hasBeenUpdated}
            onClick={async () => {
              try {
                if (
                  currentYearToEdit.is_current_year !==
                  yearToEdit.is_current_year
                ) {
                  await updateCurrentYear(yearToEdit.year);
                }

                await updateAwardsYearDetails(
                  yearToEdit.year,
                  currentYearToEdit
                );

                onUpdate(currentYearToEdit);

                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to update details.");
              }
            }}
          >
            Update
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && <FormMessage>Successfully updated.</FormMessage>}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

interface AddAwardsYearModalProps {
  onUpdate: (newYear: AwardsYearDetails) => void;
}

const AddAwardsYearModal = ({ onUpdate }: AddAwardsYearModalProps) => {
  const { currentYear } = useContext(AwardsContext);

  const [yearToAdd, setYearToAdd] = useState<AwardsYearDetails>({
    year: currentYear ? currentYear.year + 1 : 2024,
    full_title: "",
    short_title: "",
    qualifying_period_start_date: "",
    qualifying_period_end_date: "",
    entries_open_date: "",
    entries_close_date: "",
    shortlist_announcement_date: "",
    nominations_announcement_date: "",
    ceremony_date: "",
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  return (
    <>
      <h1>Add a new awards year</h1>
      <div className={styles.form}>
        <Input
          id="year"
          name="year"
          type="number"
          required
          label="New year to add"
          value={yearToAdd?.year}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setYearToAdd((curr) => {
              return {
                ...curr,
                year: parseInt(e.target.value),
              };
            });
          }}
        />
        {Object.keys(yearToAdd).map((item) => {
          if (item === "year" || item === "is_current_year") {
            return null;
          }

          if (item === "full_title" || item === "short_title") {
            return (
              <Input
                key={item}
                id={item}
                name={item}
                required
                label={`${item[0].toUpperCase()}${item
                  .slice(1)
                  .replaceAll("_", " ")}`}
                value={yearToAdd[item]}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setUpdateError(null);

                  setYearToAdd((curr) => {
                    return {
                      ...curr,
                      [item]: e.target.value,
                    };
                  });
                }}
              />
            );
          }

          return (
            <DateField
              key={item}
              id={item}
              name={item}
              required
              label={`${item[0].toUpperCase()}${item
                .slice(1)
                .replaceAll("_", " ")}`}
              value={
                yearToAdd[
                  item as keyof Omit<
                    AwardsYearDetails,
                    "year" | "is_current_year"
                  >
                ]
                  ? new Date(
                      yearToAdd[
                        item as keyof Omit<
                          AwardsYearDetails,
                          "year" | "is_current_year"
                        >
                      ]
                    )
                  : null
              }
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setYearToAdd((curr) => {
                  return {
                    ...curr,
                    [item]: e ? moment(e).format() : null,
                  };
                });
              }}
            />
          );
        })}
        <FormButtonRow>
          <Button
            buttonType="primary"
            disabled={!yearToAdd || !yearToAdd.year}
            onClick={async () => {
              try {
                if (!yearToAdd || !yearToAdd.year) return;

                const { newYear } = await addNewAwardsYear(yearToAdd);

                onUpdate(newYear);

                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to add new awards year.");
              }
            }}
          >
            Create
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && <FormMessage>Successfully added new year.</FormMessage>}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

export default function AdminManageAwardsPage() {
  const _window = useWindow();

  const { setCurrentYear } = useContext(AwardsContext);

  const [awardsYears, setAwardsYears] = useState<AwardsYearsResponse[]>([]);
  const [yearToEdit, setYearToEdit] = useState<AwardsYearDetails | null>(null);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [addYearModal, setAddYearModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchAwards = async () => {
      const { years } = await getAwardsYears();

      setAwardsYears(years);
    };

    fetchAwards();
  }, []);

  return (
    <>
      <SiteWrapper>
        <Container>
          <Row>
            <Column gridCols={8}>
              <h1>Manage awards years</h1>
              {awardsYears
                .sort((a, b) => b.year - a.year)
                .map((awardsYear, index) => {
                  return (
                    <React.Fragment key={awardsYear.year}>
                      {index !== 0 && <Divider />}
                      <FormButtonRow>
                        <div style={{ flexGrow: 1 }}>
                          {awardsYear.is_current_year && <em>Current year</em>}
                          <h3>{awardsYear.full_title}</h3>
                        </div>
                        <EditButton
                          onClick={() => {
                            setUpdateModal(true);

                            const {
                              year,
                              is_current_year,
                              full_title,
                              short_title,
                              qualifying_period_start_date,
                              qualifying_period_end_date,
                              entries_open_date,
                              entries_close_date,
                              shortlist_announcement_date,
                              nominations_announcement_date,
                              ceremony_date,
                            } = awardsYear;

                            setYearToEdit({
                              year,
                              is_current_year,
                              full_title,
                              short_title,
                              qualifying_period_start_date,
                              qualifying_period_end_date,
                              entries_open_date,
                              entries_close_date,
                              shortlist_announcement_date,
                              nominations_announcement_date,
                              ceremony_date,
                            });
                          }}
                        />
                        <Button
                          buttonType="tertiary"
                          style={{ marginLeft: 16 }}
                          onClick={() => {
                            navigate(
                              `/admin/manage-categories/${awardsYear.year}`
                            );
                          }}
                        >
                          View categories
                        </Button>
                      </FormButtonRow>
                    </React.Fragment>
                  );
                })}
              <Divider />
              <FormButtonRow>
                <Button
                  buttonType="primary"
                  onClick={() => setAddYearModal(true)}
                >
                  Add new awards year
                </Button>
              </FormButtonRow>
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
      {updateModal && yearToEdit && (
        <Modal onClose={() => setUpdateModal(false)}>
          <UpdateAwardsYearModal
            yearToEdit={yearToEdit}
            onUpdate={(newYear) => {
              setAwardsYears((curr) => {
                const yearIndex = curr.findIndex(
                  (y) => y.year === newYear.year
                );

                const cloned = _.cloneDeep(curr);

                cloned.splice(yearIndex, 1, { ...curr[yearIndex], ...newYear });

                return cloned;
              });

              if (newYear.is_current_year) {
                setCurrentYear(newYear);
              }

              _window.setTimeout(() => {
                setUpdateModal(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
      {addYearModal && (
        <Modal onClose={() => setAddYearModal(false)}>
          <AddAwardsYearModal
            onUpdate={(newYear) => {
              setAwardsYears((curr) => {
                const cloned = _.cloneDeep(curr);

                cloned.unshift({ ...newYear, categories: [] });

                return cloned;
              });

              _window.setTimeout(() => {
                setAddYearModal(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
    </>
  );
}
