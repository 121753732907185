import { AxiosResponse } from "axios";

import { endpoint } from ".";
import {
  AwardsCategoryResponseFull,
  AwardsCategorySeed,
} from "./types/awards-categories";
import { AwardsEntryResponseFull } from "./types/awards-entries";

const basePath = "/awards/categories";

export const getAwardsCategory = async (categoryId: number) => {
  try {
    const res: AxiosResponse<{ category: AwardsCategoryResponseFull }> =
      await endpoint.get(`${basePath}/${categoryId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateAwardsCategory = async (
  categoryId: number,
  details: Partial<
    Omit<
      AwardsCategorySeed,
      | "year"
      | "show_winner"
      | "show_nominations"
      | "show_shortlist"
      | "sort_position"
    >
  >
) => {
  try {
    const res: AxiosResponse<{ updatedCategory: AwardsCategoryResponseFull }> =
      await endpoint.post(`${basePath}/${categoryId}`, details);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const deleteAwardsCategory = async (categoryId: number) => {
  try {
    const res: AxiosResponse<{ removedCategory: number }> =
      await endpoint.delete(`${basePath}/${categoryId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getAwardsCategoryEntries = async (categoryId: number) => {
  try {
    const res: AxiosResponse<{ entries: AwardsEntryResponseFull[] }> =
      await endpoint.get(`${basePath}/${categoryId}/entries`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
