// extracted by mini-css-extract-plugin
export var costTable = "checkout-payment-module--costTable--17597";
export var deadlineMsg = "checkout-payment-module--deadlineMsg--6f674";
export var grandTotal = "checkout-payment-module--grandTotal--0ad07";
export var mobileCostTable = "checkout-payment-module--mobileCostTable--57642";
export var modalHeader = "checkout-payment-module--modalHeader--027f9";
export var payButton = "checkout-payment-module--payButton--386de";
export var paymentError = "checkout-payment-module--paymentError--a600e";
export var paymentModal = "checkout-payment-module--paymentModal--70219";
export var priceCol = "checkout-payment-module--priceCol--73969";
export var proceedButtons = "checkout-payment-module--proceedButtons--f122b";
export var subCopy = "checkout-payment-module--subCopy--7f4e5";
export var submitButton = "checkout-payment-module--submitButton--b2e04";
export var supportingText = "checkout-payment-module--supportingText--f1183";
export var totalsTable = "checkout-payment-module--totalsTable--e9b34";