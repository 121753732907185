import { EntryFormFieldValue } from "./awards-category-fields";

export interface AwardsEntrySeed {
  awards_category_id: number;
  entrant_id: number;
  created_at?: string;
  last_updated?: string;
  title?: string;
  description?: string;
  image?: string;
  secondary_contact_first_name?: string;
  secondary_contact_last_name?: string;
  secondary_contact_email?: string;
  invoice_sent?: boolean;
  invoice_id?: string;
  is_paid?: boolean;
  is_submitted?: boolean;
  submission_id?: number;
  is_winner?: boolean;
  is_commended?: boolean;
  is_nominated?: boolean;
  is_shortlisted?: boolean;
  british_council_screening_accepted?: boolean;
  quote?: string;
  acceptance_speech_video?: string;
  entry_form_field_values?: EntryFormFieldValue[];
}

export interface AwardsEntryResponseFull extends AwardsEntrySeed {
  year?: number;
  entry_id: number;
  entrant_email: string;
  created_at: string;
  entry_files_count: number;
  category_title?: string;
}

export type AwardsEntryResponseLimited = Omit<
  AwardsEntryResponseFull,
  | "is_winner"
  | "is_commended"
  | "is_nominated"
  | "is_shortlisted"
  | "quote"
  | "acceptance_speech_video"
>;

export interface AwardsEntryUpdateResult {
  is_winner?: boolean
  is_commended?: boolean
  is_nominated?: boolean
  is_shortlisted?: boolean
}

export type NewEntry = Omit<
  AwardsEntryResponseLimited,
  "entry_id" | "created_at"
>;

export function isNewEntry(
  entry: NewEntry | AwardsEntryResponseLimited
): entry is NewEntry {
  return (
    !Object.prototype.hasOwnProperty.call(entry, "entry_id") ||
    // @ts-ignore
    entry.entry_id === undefined
  );
}

export interface AwardsEntryMinimal
  extends Omit<AwardsEntryResponseLimited, "image"> {
  category_title: string;
}

export type EntryFileStatus =
  | "transcoded"
  | "complete"
  | "in_progress"
  | "transcoding_in_progress"
  | "transcoding_error";

export interface AwardsEntryFileDBBody {
  name: string;
  size: number;
  type: string;
  s3_id: string;
  status: EntryFileStatus;
}

export interface AwardsEntryFileSeed {
  entry_id: number;
  s3_id?: string;
  file_status?: EntryFileStatus;
  file_type?: string;
  file_name?: string;
  file_size?: number;
  created_at?: string;
  can_download?: boolean;
  is_submission_file?: boolean;
}

export interface AwardsEntryFileResponse extends AwardsEntryFileSeed {
  entry_file_id: number;
  entry_title?: string;
  category_id?: number;
  category_title?: string;
}

export interface LargeFileUploadResponse {
  UploadId?: string;
  ETag: string;
  s3Uri?: string;
  url?: string;
}
