import { useEffect, useState } from "react";

import useEvent from "./useEvent";
import useWindow from "./useWindow";

export default function useResize(type = "width"): number {
  const _window = useWindow();

  const [size, setSize] = useState(
    type === "width" ? _window.innerWidth : _window.innerHeight
  );

  // Required because window width is initially 0
  useEffect(() => {
    setSize(type === "width" ? _window.innerWidth : _window.innerHeight);
  }, [_window.innerHeight, _window.innerWidth]);

  useEvent("resize", (): void => {
    const newSize = type === "width" ? _window.innerWidth : _window.innerHeight;

    if (size !== newSize) {
      setSize(newSize);
    }
  });

  return size;
}
