import React from "react";
import { Router } from "@reach/router";

import PublicRoute from "../routes/PublicRoute";
import ProtectedRoute, { AdminOnlyRoute } from "../routes/ProtectedRoute";
import NotFoundPage from "../routes/404Page";

import CheckoutPage from "../routes/CheckoutPage";
import DashboardPage from "../routes/DashboardPage";
import EditEntryPage from "../routes/EditEntryPage";
import ForgotPasswordPage from "../routes/ForgotPasswordPage";
import LoginPage from "../routes/LoginPage";
import NewEntryCategoryPage from "../routes/NewEntryCategoryPage";
import NewEntryPage from "../routes/NewEntryPage";
import NewUserPage from "../routes/NewUserPage";
import PaymentConfirmationPage from "../routes/PaymentConfirmationPage";
import ProfilePage from "../routes/ProfilePage";
import ResetPasswordPage from "../routes/ResetPasswordPage";
import SummaryPage from "../routes/SummaryPage";
import ViewEntryPage from "../routes/ViewEntryPage";
import JudgingPage from "../routes/JudgingPage";
import AdminPage from "../routes/AdminPage";
import UpdatePasswordPage from "../routes/UpdatePasswordPage";
import AdminManageAwardsPage from "../routes/AdminManageYearsPage";
import AdminManageCategoriesPage from "../routes/AdminManageCategoriesPage";
import AdminManageCategoryFieldsPage from "../routes/AdminManageCategoryFieldsPage";
import AdminManageEntrantsPage from "../routes/AdminManageEntrantsPage";
import AdminManageSponsorsPage from "../routes/AdminManageSponsorsPage";
import ConfirmEmailPage from "../routes/ConfirmEmailPage";
import AdminManageEntriesPage from "../routes/AdminManageEntriesPage";
import AdminManageJudgesPage from "../routes/AdminManageJudgesPage";
import BillingDetailsPage from "../routes/BillingDetailsPage";
import UploaderApp from "../legacy-uploader/routes/UploaderPage";
import UploaderAppNew from "../routes/UploaderPage";

import AccountProvider from "../context/account";
import AdminProvider from "../context/admin";
import AwardsProvider from "../context/awards";
import EntriesProvider from "../context/entries";
import JudgesProvider from "../context/judges";
import { isBrowser } from "../utils";

const App = () => {
  // Prevent window undefined error during SSR
  if (!isBrowser()) return null;

  return (
    <>
      <title>The Grierson Trust - Awards Site</title>

      <AccountProvider>
        <AwardsProvider>
          <AdminProvider>
            <EntriesProvider>
              <JudgesProvider>
                <Router>
                  {["/", "/dashboard"].map((path) => (
                    <ProtectedRoute
                      key={path}
                      path={path}
                      component={DashboardPage}
                    />
                  ))}

                  {/*** Auth Routes ***/}
                  <PublicRoute path="/login" component={LoginPage} />
                  <PublicRoute
                    path="/forgot-password"
                    component={ForgotPasswordPage}
                  />
                  <PublicRoute
                    path="/confirm-email"
                    component={ConfirmEmailPage}
                    showInProtected
                  />
                  <PublicRoute
                    path="/reset-password"
                    component={ResetPasswordPage}
                    showInProtected
                  />
                  <PublicRoute path="/create-account" component={NewUserPage} />

                  {/*** Profile Routes ***/}
                  <ProtectedRoute path="/profile" component={ProfilePage} />
                  <ProtectedRoute
                    path="/billing"
                    component={BillingDetailsPage}
                  />
                  <ProtectedRoute
                    path="/update-password"
                    component={UpdatePasswordPage}
                  />

                  {/*** Entry Routes ***/}
                  <ProtectedRoute
                    path="/new-entry"
                    component={NewEntryCategoryPage}
                  />
                  <ProtectedRoute
                    path="category/:categoryID/new-entry"
                    component={NewEntryPage}
                  />
                  <ProtectedRoute
                    path="/edit-entry/:entryID"
                    component={EditEntryPage}
                  />
                  <ProtectedRoute
                    path="/view-entry/:entryID"
                    component={ViewEntryPage}
                  />
                  <ProtectedRoute
                    path="/view-entry-judge/:entryID"
                    component={ViewEntryPage}
                  />
                  <ProtectedRoute path="/summary" component={SummaryPage} />
                  <ProtectedRoute path="/checkout" component={CheckoutPage} />
                  <ProtectedRoute
                    path="/confirmation"
                    component={PaymentConfirmationPage}
                  />
                  <ProtectedRoute
                    path="/uploader/:entryID"
                    component={UploaderApp}
                  />

                  {/*** Admin Routes ***/}
                  <AdminOnlyRoute path="/admin" component={AdminPage} />
                  <AdminOnlyRoute
                    path="/admin/manage-awards"
                    component={AdminManageAwardsPage}
                  />
                  <AdminOnlyRoute
                    path="/admin/manage-sponsors"
                    component={AdminManageSponsorsPage}
                  />
                  <AdminOnlyRoute
                    path="/admin/manage-categories/:year"
                    component={AdminManageCategoriesPage}
                  />
                  <AdminOnlyRoute
                    path="/admin/manage-category-fields/:categoryId"
                    component={AdminManageCategoryFieldsPage}
                  />
                  <AdminOnlyRoute
                    path="/admin/manage-entries"
                    component={AdminManageEntriesPage}
                  />
                  <AdminOnlyRoute
                    path="/admin/manage-entrants"
                    component={AdminManageEntrantsPage}
                  />
                  <AdminOnlyRoute
                    path="/admin/manage-judges"
                    component={AdminManageJudgesPage}
                  />

                  {/*** Judging Routes ***/}
                  <ProtectedRoute path="/judging" component={JudgingPage} />

                  {/*** 404 ***/}
                  <NotFoundPage default />
                </Router>
              </JudgesProvider>
            </EntriesProvider>
          </AdminProvider>
        </AwardsProvider>
      </AccountProvider>
    </>
  );
};

export default App;
