import React, { useContext, useMemo, useState } from "react";
import moment from "moment";

import Button from "./button";
import { FormButtonRow, FormError, FormMessage } from "./form";
import { Modal } from "./modal";
import { resendConfirmationEmail } from "../api/account";
import { AccountContext } from "../context/account";
import { supportEmail } from "../core/variables";

interface ResendConfirmationEmailModalProps {
  onClose: () => void;
}

export const ResendConfirmationEmailModal = ({
  onClose,
}: ResendConfirmationEmailModalProps) => {
  const { account } = useContext(AccountContext);

  const [sendConfirmationEmailSuccess, setSendConfirmationEmailSuccess] =
    useState(false);
  const [sendConfirmationEmailError, setSendConfirmationEmailError] =
    useState(false);

  const emailConfirmationLastSentDate = useMemo(() => {
    if (!account.accountConfirmationEmailSent) return null;

    return new Date(account.accountConfirmationEmailSent);
  }, [account.accountConfirmationEmailSent]);

  return (
    <Modal onClose={onClose}>
      <>
        <h3>Resend account confirmation email</h3>
        {account.accountConfirmationEmailSent && (
          <p>
            A confirmation email was last sent to you at{" "}
            {moment(account.accountConfirmationEmailSent).format("hh:mmA")} on{" "}
            {moment(account.accountConfirmationEmailSent).format("DD-MM-YYYY")}.
            If you can't find it, check your junk mail folder.
          </p>
        )}
        {emailConfirmationLastSentDate &&
        // @ts-ignore
        new Date() - emailConfirmationLastSentDate.getTime() < 30 * 1000 ? (
          <p>
            <strong>
              Please wait 30 seconds before sending another email.
            </strong>
          </p>
        ) : (
          <>
            <p>Click below to resend.</p>
            <FormButtonRow>
              <Button
                buttonType="primary"
                onClick={async () => {
                  try {
                    await resendConfirmationEmail();

                    setSendConfirmationEmailSuccess(true);
                  } catch {
                    setSendConfirmationEmailError(true);
                  }
                }}
              >
                Send email
              </Button>
            </FormButtonRow>
          </>
        )}
        {sendConfirmationEmailSuccess && (
          <FormMessage>
            <p>Message sent. Please check your inbox.</p>
          </FormMessage>
        )}
        {sendConfirmationEmailError && (
          <FormError>
            <p>
              Failed to send email. Please try again later or contact{" "}
              <Button
                buttonType="link"
                style={{ display: "inline-block" }}
                href={`mailto:${supportEmail}`}
              >
                {supportEmail}
              </Button>
            </p>
          </FormError>
        )}
      </>
    </Modal>
  );
};
