import { useEffect, useState } from "react";

import useResize from "./useResize";

const isMobile = (width: number, maxWidth: number) => {
  return width < maxWidth;
};

export default function useMobile(maxWidth: number = 1200): boolean {
  const width = useResize();

  const [mobile, setMobile] = useState(isMobile(width, maxWidth));

  useEffect((): void => {
    const condition = width < maxWidth;
    if (condition !== mobile) {
      setMobile(condition);
    }
  }, [width, mobile, maxWidth]);

  return mobile;
}
