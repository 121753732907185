import React from "react";
import classNames from "classnames";

import Logo from "../assets/images/logo-no-text.png";
import * as styles from "../styles/LoadingLogo.module.scss";

const LoadingLogo = ({ className }: { className?: string }) => {
  return (
    <img
      className={classNames(className, styles.loadingImg)}
      src={Logo}
      alt="The Grierson Trust"
    />
  );
};

export default LoadingLogo;
