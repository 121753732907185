import { AwardsYearDetails } from "../api/types/awards-years";
import { FlotiqImageObj } from "../components/image";
import { AccountProps } from "../context/account";
import { mediaDomain } from "../core/variables";

export const isBrowser = () => typeof window !== "undefined";

export const wordCount = (value?: string) => {
  if (!value) return 0;

  const wordMatches = value.match(/\S+/g) || [];

  return wordMatches.length;
};

export const truncateString = (str: string, n: number) => {
  return str.length > n ? str.substring(0, n - 1) + "..." : str;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getImagePath = (
  obj: (FlotiqImageObj | null)[] | null | undefined
) => {
  return obj && obj.length > 0 && obj[0] ? `${mediaDomain}${obj[0].url}` : "";
};

export const isValidEmail = (email: string): boolean => {
  const isMatch = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  return isMatch !== null;
};

export type ValidationError = "do-not-match" | "min-length" | "valid" | "empty";

export const validatePassword = (
  password: string,
  passwordTwo?: string
): ValidationError => {
  if (typeof passwordTwo === "string") {
    if (password !== passwordTwo) {
      return "do-not-match";
    }
  }
  if (password === "") {
    return "empty";
  }

  const minLengthValid = password.length > 5;
  return minLengthValid ? "valid" : "min-length";
};

export const isAdmin = (account: AccountProps) => {
  return !!account.admin_id;
};

export const isJudge = (account: AccountProps) => {
  return !!account.judge_id;
};

export const isFirstRoundJudge = (currentJudge: JudgeFull) => {
  const { first_round_categories } = currentJudge;
  return (
    first_round_categories &&
    Array.isArray(first_round_categories) &&
    first_round_categories.length > 0
  );
};

export const isSecondRoundJudge = (currentJudge: JudgeFull) => {
  const { second_round_categories } = currentJudge;
  return (
    second_round_categories &&
    Array.isArray(second_round_categories) &&
    second_round_categories.length > 0
  );
};

export const isChair = (currentJudge: JudgeFull) => {
  const { chair_categories } = currentJudge;
  return (
    chair_categories &&
    Array.isArray(chair_categories) &&
    chair_categories.length > 0
  );
};

export const isBeforeShortlistDate = (currentYear: AwardsYearDetails) => {
  const { current_judging_round } = currentYear;
  return current_judging_round !== "second";
};
export const isAfterShortlistDate = (currentYear: AwardsYearDetails) => {
  return !isBeforeShortlistDate(currentYear);
};

export const isEntrant = (account: AccountProps) => {
  return !!account.entrant_id;
};
