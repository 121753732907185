import { AxiosResponse } from "axios";

import { endpoint } from ".";

export const confirmEmail = async (
  hash: string,
) => {
  try {
    const res: AxiosResponse<{
      updated: boolean;
    }> = await endpoint.post(`email/confirm-email`, {
      hash
    });

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
