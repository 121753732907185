// extracted by mini-css-extract-plugin
export var blue = "typeface-module--blue--a0783";
export var centerBtn = "typeface-module--centerBtn--e659c";
export var centerText = "typeface-module--centerText--6f5e9";
export var divider = "typeface-module--divider--7153d";
export var green = "typeface-module--green--7a6b7";
export var markdown = "typeface-module--markdown--d889b";
export var markdownBtn = "typeface-module--markdownBtn--7ad3f";
export var markdownTwoColsBtn = "typeface-module--markdownTwoColsBtn--5a1b0";
export var orange = "typeface-module--orange--b9a46";
export var pageMarkdown = "typeface-module--pageMarkdown--db8ae";
export var pageMarkdownCol = "typeface-module--pageMarkdownCol--c1c32";
export var preHeading = "typeface-module--preHeading--67e5f";
export var quote = "typeface-module--quote--0dbbd";
export var quoteBox = "typeface-module--quoteBox--bb597";
export var quoteContainer = "typeface-module--quoteContainer--5bd5d";
export var yellow = "typeface-module--yellow--f2089";