import React, { useState, useCallback } from "react";

import Button from "../components/button";
import {
  FormMessage,
  FormError,
  FormButtonRow,
  Form,
} from "../components/form";
import Input from "../components/input";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { validatePassword, ValidationError } from "../utils";
import { updatePassword } from "../api/auth";

export default function UpdatePasswordPage() {
  const [password, setPassword] = useState({ current: "", new: "", new2: "" });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(async () => {
    if (
      password.current === password.new ||
      password.current === password.new2
    ) {
      setError(
        "Your new password should be different to the current password."
      );
      return;
    }

    const validationStatus: ValidationError = validatePassword(
      password.new,
      password.new2
    );

    switch (validationStatus) {
      case "empty":
        setError("This field cannot be empty.");
        break;
      case "min-length":
        setError("Your password needs to be at least 6 characters long.");
        break;
      case "do-not-match":
        setError("Your passwords do not match.");
        break;
      default:
        setError(null);

        try {
          await updatePassword({
            oldPassword: password.current,
            newPassword: password.new,
          });

          setUpdateSuccess(true);
        } catch (err: any) {
          setError(
            err.message || "Unable to reset password. Please try again later."
          );
        }
        break;
    }
  }, [password]);

  return (
    <SiteWrapper hideStickyNav>
      <Container>
        <Row>
          <Column gridCols={4}>
            <h1>Update password</h1>

            <Form onSubmit={onSubmit}>
              <>
                <Input
                  dark
                  type="password"
                  id="current_password"
                  name="current_password"
                  placeholder="Current password"
                  value={password.current}
                  onChange={(e) => {
                    setError(null);
                    setUpdateSuccess(false);

                    setPassword((curr) => {
                      return {
                        ...curr,
                        current: e.target.value,
                      };
                    });
                  }}
                  error={!!error}
                />
                <Input
                  dark
                  type="password"
                  id="new_password"
                  name="new_password"
                  placeholder="New password"
                  value={password.new}
                  onChange={(e) => {
                    setError(null);
                    setUpdateSuccess(false);

                    setPassword((curr) => {
                      return {
                        ...curr,
                        new: e.target.value,
                      };
                    });
                  }}
                  error={!!error}
                />
                <Input
                  dark
                  type="password"
                  id="new_password_two"
                  name="new_password_two"
                  placeholder="Re-type new password"
                  value={password.new2}
                  onChange={(e) => {
                    setError(null);
                    setUpdateSuccess(false);

                    setPassword((curr) => {
                      return {
                        ...curr,
                        new2: e.target.value,
                      };
                    });
                  }}
                  error={!!error}
                />
                <p>
                  Your password must be at least 6 characters long and contain
                  both letters and numbers.
                </p>
                {!!error && (
                  <FormError>
                    <p>{error}</p>
                  </FormError>
                )}
                <FormButtonRow>
                  <Button buttonType="primary">Update password</Button>
                </FormButtonRow>
                {updateSuccess && (
                  <FormMessage>
                    <p>Your password has been updated.</p>
                  </FormMessage>
                )}
              </>
            </Form>
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
