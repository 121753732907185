import React, { useEffect } from "react";
import classNames from "classnames";

import Button from "./button";
import close from "../assets/svgs/cross.svg";
import * as styles from "../styles/modal.module.scss";

export interface ModalProps {
  closeOnBackgroundClick?: boolean;
  onClose: () => void;
  className?: string;
  children: React.ReactElement;
}

export function Modal({
  closeOnBackgroundClick = false,
  onClose,
  className,
  children,
}: ModalProps) {
  return (
    <>
      <style>
        {`
        body {
          overflow: hidden;
        }
        `}
      </style>
      <div
        className={styles.modalContainer}
        onClick={(e) => {
          e.stopPropagation();

          if (closeOnBackgroundClick) {
            onClose();
          }
        }}
      >
        <div
          className={classNames(styles.modal, className)}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalContent}>
            {children}
            <Button
              buttonType="icon"
              className={styles.closeButton}
              onClick={onClose}
            >
              <img src={close} alt="Close" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
