import axios from "axios";

export const LEGACY_API_BASE = "https://data.griersontrust.org";

export let API_BASE =
  process.env.NODE_ENV === "development"
    ? "http://localhost:9090/api/v2"
    : "https://api2.griersontrust.org/api/v2";

if (process.env.GATSBY_API_OVERRIDE === "true") {
  API_BASE = "https://api2.griersontrust.org/api/v2";
}

export const endpoint = axios.create({
  baseURL: API_BASE,
  timeout: 5000,
});

// Add auth token to headers if present
endpoint.interceptors.request.use(
  (config) => {
    // Get the token from local storage
    const token = localStorage.getItem("token");

    // If the token exists, add it to the request headers
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
