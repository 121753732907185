import React from "react";
import { useDropzone, Accept } from "react-dropzone";
import classNames from "classnames";

import { StyledMarkdown } from "./typeface";
import upload from "../assets/svgs/cloud-upload.svg";
import * as styles from "../styles/file-upload.module.scss";

interface FileUploaderProps {
  id?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  copy?: {
    upload?: string;
    dragReject?: string;
    disabled?: string;
  };
  onDrop:
    | ((acceptedFiles: File[]) => Promise<void>)
    | ((acceptedFiles: File[]) => void);
  loading?: boolean;
  maxFiles?: number;
  accept?: Accept;
  dark?: boolean;
  label?: string;
  introMsg?: string;
  stacked?: boolean;
  success?: boolean;
  successMsg?: string;
  error?: boolean;
  errorMsg?: string;
  children?: React.ReactElement;
}

const FileUploader = ({
  id,
  className,
  required,
  disabled,
  copy,
  onDrop,
  loading,
  maxFiles = 1,
  accept,
  label,
  introMsg,
  stacked,
  success,
  successMsg,
  error,
  errorMsg,
  children,
}: FileUploaderProps) => {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: async (acceptedFiles) => {
        if (loading || disabled) return;

        onDrop(acceptedFiles);
      },
      maxFiles,
      accept,
    });

  const dropzone = (
    <div className={styles.dropzoneContainer}>
      <div
        {...getRootProps({
          className: classNames(styles.dropzone, {
            [styles.accept]: isDragAccept,
            [styles.reject]: isDragReject,
            [styles.loading]: loading,
            [styles.disabled]: disabled,
          }),
        })}
      >
        <input id={id} type="file" {...getInputProps()} />
        {isDragReject ? (
          <p>{copy?.dragReject || "File type not supported"}</p>
        ) : (
          <>
            {disabled ? (
              <p>{copy?.disabled || "Disabled"}</p>
            ) : (
              <>
                {loading ? (
                  <div className={styles.ldsRing}>
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  <>
                    <p>
                      {copy?.upload ||
                        `Upload your file${maxFiles > 1 ? "s" : ""} here`}
                    </p>

                    <img src={upload} alt="upload" />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      {children}
      {success && !error && (
        <span className={styles.successMsg}>{successMsg || "Success"}</span>
      )}
      {error && errorMsg && (
        <span className={styles.errorMsg}>{errorMsg || "Error"}</span>
      )}
    </div>
  );

  return (
    <div className={classNames(className, styles.container)}>
      {label ? (
        <div
          className={classNames(styles.labelContainer, {
            [styles.stacked]: stacked,
            [styles.disabled]: disabled,
          })}
        >
          <label htmlFor={id}>
            {label} {required && <span className={styles.required}>*</span>}
          </label>
          {introMsg && (
            <StyledMarkdown className={styles.introMsg}>
              {introMsg}
            </StyledMarkdown>
          )}
          {dropzone}
        </div>
      ) : (
        dropzone
      )}
    </div>
  );
};

export default FileUploader;
