import { AxiosResponse } from "axios";

import { endpoint } from ".";
import {
  AwardsYearDetails,
  AwardsYearEntriesQueryParams,
  AwardsYearsResponse,
} from "./types/awards-years";
import {
  AwardsCategoryPublicDetails,
  AwardsCategoryResponseFull,
  AwardsCategorySeed,
} from "./types/awards-categories";
import { AwardsEntryResponseFull } from "./types/awards-entries";

const basePath = "/awards/years";

export const getCurrentYear = async () => {
  try {
    const res: AxiosResponse<{ currentYear: AwardsYearDetails }> =
      await endpoint.get(`${basePath}/current-year`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateCurrentYear = async (year: number) => {
  try {
    const res: AxiosResponse<{ currentYear: AwardsYearDetails }> =
      await endpoint.post(`${basePath}/current-year`, { year });

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getAwardsYears = async () => {
  try {
    const res: AxiosResponse<{ years: AwardsYearsResponse[] }> =
      await endpoint.get(basePath);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateAwardsYearDetails = async (
  year: number,
  details: Partial<Omit<AwardsYearDetails, "year">>
) => {
  try {
    const res: AxiosResponse<{
      year: AwardsYearDetails;
    }> = await endpoint.post(`${basePath}/${year}`, details);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const addNewAwardsYear = async (details: Partial<AwardsYearDetails>) => {
  try {
    const res: AxiosResponse<{
      newYear: AwardsYearDetails;
    }> = await endpoint.post(basePath, details);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getAwardsYearCategories = async (year: number) => {
  try {
    const res: AxiosResponse<{ categories: AwardsCategoryPublicDetails[] }> =
      await endpoint.get(`${basePath}/${year}/categories`);

    return res.data;
  } catch (error: any) {
    console.log("Error:", error);

    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const addNewAwardsCategory = async (
  year: number,
  details: Omit<AwardsCategorySeed, "year" | "sort_position">
) => {
  try {
    const res: AxiosResponse<{
      newCategory: AwardsCategoryResponseFull;
    }> = await endpoint.post(`${basePath}/${year}/categories`, details);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export type RevealTypes = "shortlist" | "nominations" | "winners";

export const revealAwards = async (year: number, type: RevealTypes) => {
  try {
    const res: AxiosResponse<{
      success: boolean;
      updatedCategories: AwardsCategoryPublicDetails[];
    }> = await endpoint.post(`${basePath}/${year}/reveal-${type}`);

    return res.data;
  } catch (error: any) {
    console.log("Error:", error);

    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateAwardsYearCategoryOrder = async (
  year: number,
  details: { sortOrder: number[] }
) => {
  try {
    const res: AxiosResponse<{
      categories: { awards_category_id: number; sortOrder: number }[];
    }> = await endpoint.post(
      `${basePath}/${year}/categories/sort-categories`,
      details
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getAwardsYearEntries = async (
  year: number,
  queryParams?: AwardsYearEntriesQueryParams
) => {
  try {
    const { limit, page, category, sort, sortOrder, search, filter } =
      queryParams || {};

    let queryString = limit ? `?limit=${limit}` : "?limit=1000";

    if (page) queryString += `&page=${page}`;
    if (category) queryString += `&category=${category}`;
    if (sort) queryString += `&sort=${sort}`;
    if (sortOrder) queryString += `&sortOrder=${sortOrder}`;
    if (search) queryString += `&search=${search}`;
    if (filter) queryString += `&filter=${filter}`;

    const res: AxiosResponse<{
      entries: AwardsEntryResponseFull[];
      total_count: number;
    }> = await endpoint.get(`${basePath}/${year}/entries${queryString}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
