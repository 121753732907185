// extracted by mini-css-extract-plugin
export var active = "header-module--active--fe680";
export var burgerIcon = "header-module--burgerIcon--97ae2";
export var closeMobileNavButton = "header-module--closeMobileNavButton--e47a7";
export var desktopLinks = "header-module--desktopLinks--de999";
export var header = "header-module--header--48f8f";
export var loginButtonDesktop = "header-module--loginButtonDesktop--46eba";
export var loginButtonMobile = "header-module--loginButtonMobile--d2a01";
export var logo = "header-module--logo--37af0";
export var mobileMainLink = "header-module--mobileMainLink--ba20a";
export var mobileNavBottom = "header-module--mobileNavBottom--27470";
export var mobileNavContainer = "header-module--mobileNavContainer--ca1e7";
export var mobileNavExpand = "header-module--mobileNavExpand--70bd6";
export var mobileNavMiddle = "header-module--mobileNavMiddle--e8346";
export var mobileNavTop = "header-module--mobileNavTop--a4a51";
export var normalWidth = "header-module--normalWidth--acb1e";
export var parentLink = "header-module--parentLink--2b443";
export var primaryLink = "header-module--primaryLink--3a6ee";
export var secondaryLink = "header-module--secondaryLink--bf714";
export var subNav = "header-module--subNav--0d649";
export var subNavContainer = "header-module--subNavContainer--dbea4";
export var subNavInner = "header-module--subNavInner--839d7";
export var subNavItem = "header-module--subNavItem--1891d";
export var subNavOuter = "header-module--subNavOuter--24e76";
export var transparent = "header-module--transparent--378f3";