import React, { useContext, useEffect, useMemo } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

import Button from "../components/button";
import Breadcrumbs from "../components/CheckoutBreadcrumbs";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { Divider } from "../components/typeface";
import { AwardsContext } from "../context/awards";
import { EntriesContext } from "../context/entries";
import useWindow from "../hooks/useWindow";
import { DashboardInvoicedEntryList } from "../components/DashboardEntryLists";

interface LocationState {
  fromCheckoutPage?: boolean;
}

export default function PaymentConfirmationPage() {
  const state = useLocation().state as LocationState;

  const _window = useWindow();

  const { noNewEntries, currentYearCategories } = useContext(AwardsContext);
  const { entriesToSubmit } = useContext(EntriesContext);

  const purchaseItems = useMemo(() => {
    if (!entriesToSubmit) return [];

    return entriesToSubmit.map(
      ({ entry_id, title, category_title, awards_category_id }) => {
        const fullCategory = currentYearCategories.find(
          (category) => awards_category_id === category.awards_category_id
        );

        if (!fullCategory || !fullCategory.entry_cost) return null;

        return {
          entryID: entry_id,
          entryTitle: title,
          categoryName: category_title,
          cost: Number(fullCategory.entry_cost),
        };
      }
    );
  }, [entriesToSubmit, currentYearCategories]);

  // Only accessible from the checkout page
  if (!state || !state.fromCheckoutPage) {
    navigate("/dashboard", { replace: true });
    return null;
  }

  // Check if within the entry period
  // If not, redirect to dashboard
  useEffect(() => {
    if (noNewEntries === null || !_window.sessionStorage) return;

    // Should not be possible to submit entries if outside entry period
    if (noNewEntries) {
      _window.sessionStorage.removeItem("entriesToSubmit");
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [_window, noNewEntries]);

  return (
    <SiteWrapper>
      <Container>
        <Row>
          <Breadcrumbs step={2} />
          <Column gridCols={8}>
            <h1>Confirmation</h1>

            <p>An invoice has been sent to your billing email address.</p>
            <p>
              You will be able to complete your entry once payment is received.
            </p>
            <p>
              In the meantime, you can begin uploading video files for your entr
              {purchaseItems.length === 1 ? "y" : "ies"}.
            </p>
            <Divider />
            <DashboardInvoicedEntryList
              includeTitle={false}
              entries={entriesToSubmit}
            />
            <Button buttonType="primary" href="/dashboard">
              Back to Dashboard
            </Button>
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
