import { useEffect } from "react";

import useWindow from "./useWindow";

export default function useEvent(
  event: any,
  handler: (e: any) => void,
  passive = false
): void {
  const _window = useWindow();

  useEffect((): (() => void) => {
    // initiate the event handler
    _window.addEventListener(event, handler, passive);

    // this will clean up the event every time the component is re-rendered
    return function cleanup(): void {
      _window.removeEventListener(event, handler);
    };
  });
}
