import {
  DeleteObjectCommand,
  GetObjectCommand,
  GetObjectCommandInput,
  ListObjectsCommand,
  PutObjectCommand,
  PutObjectCommandInput,
  S3Client,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

import { BUCKET_NAME } from ".";

export const awsList = async (
  entryId: string,
  s3Client: S3Client,
  folder = "uploads"
) => {
  try {
    const data = await s3Client.send(
      new ListObjectsCommand({
        Bucket: BUCKET_NAME,
        Prefix: `${folder}/${entryId}`,
      })
    );

    return data;
  } catch (err) {
    console.error(err);
  }
};

export const awsUpload = async (
  entryId: string,
  s3Client: S3Client,
  file: File
) => {
  // Uploads to bucket in the "/uploads" folder
  const params: PutObjectCommandInput = {
    Body: file,
    Bucket: BUCKET_NAME,
    Key: `uploads/${entryId} - ${file.name}`,
  };

  try {
    const { ETag } = await s3Client.send(new PutObjectCommand(params));

    return ETag;
  } catch (err) {
    console.error(err);
  }
};

export const awsGetUrl = async (
  entryId: string,
  s3Client: S3Client,
  fileName: string,
  fileType: string,
  isTranscoded: boolean
) => {
  const fullName = `${
    isTranscoded ? "transcoded" : "uploads"
  }/${entryId} - ${fileName}`;

  const params: GetObjectCommandInput = {
    Bucket: BUCKET_NAME,
    Key: fullName,
    ResponseContentType: fileType,
    ResponseContentDisposition: `attachment; filename="${fileName}"`,
  };

  try {
    const command = new GetObjectCommand(params);

    // @ts-ignore
    const data = await getSignedUrl(s3Client, command, {
      // Link expires after 90 mins
      expiresIn: 5400,
    });

    return data;
  } catch (err) {
    console.error(err);
  }
};

export const awsDownload = async (
  entryId: string,
  s3Client: S3Client,
  fileName: string,
  fileType: string,
  isTranscoded: boolean
) => {
  try {
    const url = await awsGetUrl(
      entryId,
      s3Client,
      fileName,
      fileType,
      isTranscoded
    );

    if (url) {
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      link.parentNode?.removeChild(link);
    }
  } catch (err) {
    console.error(err);
  }
};

export const awsDelete = async (
  entryId: string,
  s3Client: S3Client,
  fileName: string
) => {
  const nameMatch = fileName.match(/(.+)\.[^.]+$/);
  const noExt = nameMatch ? nameMatch[1] : "";

  try {
    const uploads = await awsList(entryId, s3Client, "uploads");
    const uploadsFile = uploads?.Contents?.filter(
      (file) => file.Key && file.Key.indexOf(noExt) > -1
    );

    const transcoded = await awsList(entryId, s3Client, "transcoded");
    const transcodedFile = transcoded?.Contents?.filter(
      (file) => file.Key && file.Key.indexOf(noExt) > -1
    );

    const thumbnails = await awsList(entryId, s3Client, "thumbnails");
    const thumbnailsFile = thumbnails?.Contents?.filter(
      (file) => file.Key && file.Key.indexOf(noExt) > -1
    );

    const filesToDelete = uploadsFile || [];
    if (Array.isArray(transcodedFile)) filesToDelete.push(...transcodedFile);
    if (Array.isArray(thumbnailsFile)) filesToDelete.push(...thumbnailsFile);

    await Promise.all(
      filesToDelete.map(async ({ Key }) => {
        return await s3Client.send(
          new DeleteObjectCommand({
            Bucket: BUCKET_NAME,
            Key,
          })
        );
      })
    );
  } catch (err) {
    console.error(err);
  }
};
