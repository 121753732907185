import React, { useContext, useState } from "react";
import { navigate } from "gatsby";
import _ from "lodash";

import Accordion from "./accordion";
import Button, {
  AddFilesButton,
  CopyButton,
  DeleteButton,
  EditButton,
} from "./button";
import { FormButtonRow } from "./form";
import { Checkbox } from "./input";
import { Modal } from "./modal";
import { Divider } from "./typeface";
import { deleteEntry } from "../api/awards-entries";
import { AwardsEntryMinimal, NewEntry } from "../api/types/awards-entries";
import { AccountContext } from "../context/account";
import { EntriesContext } from "../context/entries";
import { supportEmail } from "../core/variables";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/dashboard.module.scss";
import { accountIsCompleteEntrant, isReadyToSubmit } from "../utils/entries ";
import CompleteEntryModal from "./CompleteEntryModal";
import { AwardsContext } from "../context/awards";

interface DashboardPendingEntryListProps {
  entries: AwardsEntryMinimal[];
}

export const DashboardPendingEntryList = ({
  entries,
}: DashboardPendingEntryListProps) => {
  const _window = useWindow();

  const { account } = useContext(AccountContext);
  const { noNewEntries } = useContext(AwardsContext);
  const { setCurrentEntry, setSavedEntries, setEntriesToSubmit } =
    useContext(EntriesContext);

  const [submitError, setSubmitError] = useState<string | null>(null);
  const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
  const [incompleteEntries, setIncompleteEntries] = useState<number[]>([]);
  const [deleteID, setDeleteID] = useState<number | null>(null);
  const [deleteError, setDeleteError] = useState(false);

  return (
    <>
      <h2 className={styles.listTitle}>Started entries</h2>
      <p>
        Here are the entry forms you have started and saved. You still need to
        complete your forms, check them and then submit and pay.
      </p>
      <p>You will then be able to upload the files to your entries. </p>
      {entries.map((entry) => {
        const { entry_id: entryID } = entry;

        const selectedEntryIndex = selectedEntries.indexOf(entryID);

        let isSecondaryContact = false;

        if (
          entry.entrant_id !== account.entrant_id &&
          entry.secondary_contact_email &&
          account.email &&
          entry.secondary_contact_email.toLowerCase() ===
            account.email.toLowerCase()
        ) {
          isSecondaryContact = true;
        }

        return (
          <React.Fragment key={entryID}>
            <div className={styles.entryRow}>
              <div className={styles.entryCheckbox}>
                <Checkbox
                  dark
                  id={`entry-${entryID}`}
                  label={entry.title || "Untitled entry"}
                  checked={selectedEntries.indexOf(entryID) > -1}
                  error={
                    !!submitError &&
                    selectedEntryIndex > -1 &&
                    incompleteEntries.includes(entryID)
                  }
                  errorMsg="This entry is missing required fields"
                  onChange={(e) => {
                    setSubmitError(null);

                    setSelectedEntries((curr) => {
                      const newSelectedEntries = [...curr];

                      if (e.target.checked && selectedEntryIndex === -1) {
                        newSelectedEntries.push(entryID);
                      } else if (!e.target.checked && selectedEntryIndex > -1) {
                        newSelectedEntries.splice(selectedEntryIndex, 1);
                      }

                      return newSelectedEntries;
                    });
                  }}
                />
                <span className={styles.categoryName}>
                  {entry.category_title}
                  {isSecondaryContact && (
                    <>
                      <br />
                      (Secondary contact for this entry)
                    </>
                  )}
                </span>
              </div>
              <EditButton
                href={noNewEntries ? undefined : `/edit-entry/${entryID}`}
                onClick={
                  noNewEntries
                    ? () => {
                        _window.scrollTo({ top: 0, behavior: "smooth" });
                      }
                    : undefined
                }
              />
              <CopyButton
                onClick={() => {
                  if (noNewEntries) {
                    _window.scrollTo({ top: 0, behavior: "smooth" });

                    return;
                  }

                  const entryToClone = _.cloneDeep(entry);

                  const newEntry: NewEntry = {
                    ...entryToClone,
                    entry_id: undefined,
                    // @ts-ignore
                    awards_category_id: undefined,
                    is_paid: false,
                    is_submitted: false,
                    submission_id: undefined,
                  };

                  setCurrentEntry(newEntry);

                  _window.sessionStorage?.setItem(
                    "currentEntry",
                    JSON.stringify(newEntry)
                  );

                  navigate("/new-entry", {
                    state: {
                      duplicateCategory: entryToClone.awards_category_id,
                    },
                  });
                }}
              />
              <DeleteButton
                className={styles.deleteBtn}
                onClick={() => setDeleteID(entryID)}
              />
            </div>

            <Divider className={styles.entryDivider} />
          </React.Fragment>
        );
      })}
      <FormButtonRow>
        <Button
          buttonType="primary"
          className={styles.submitEntriesButton}
          softDisable={selectedEntries.length === 0}
          disabled={entries.length === 0}
          onClick={() => {
            if (selectedEntries.length === 0) {
              setSubmitError("Please select some entries to submit");
              return;
            }

            const userIsReady = accountIsCompleteEntrant(account);

            if (!userIsReady) {
              setSubmitError(
                "You need to complete your profile to submit entries. Go to the 'Profile' page."
              );

              return;
            }

            let entriesAreReady = true;

            const _incompleteEntries: number[] = [];

            // Check if entry is ready to be submitted
            selectedEntries.forEach((selectedEntry) => {
              const fullEntry = entries.find(
                (entry) => entry.entry_id === selectedEntry
              );

              if (!fullEntry) return;

              const readyToSubmit = isReadyToSubmit(fullEntry);

              if (!readyToSubmit) {
                _incompleteEntries.push(selectedEntry);
                entriesAreReady = false;
              }
            });

            setIncompleteEntries(_incompleteEntries);

            if (!entriesAreReady) {
              setSubmitError(
                "Some of your selected entries are missing required fields"
              );

              return;
            }

            const _entriesToSubmit = entries.filter(
              ({ entry_id }) =>
                entry_id && selectedEntries.indexOf(entry_id) > -1
            );

            setEntriesToSubmit(_entriesToSubmit);

            _window.sessionStorage?.setItem(
              "entriesToSubmit",
              JSON.stringify(_entriesToSubmit)
            );

            navigate("/summary");
          }}
        >
          Submit selected
        </Button>
      </FormButtonRow>
      {submitError && <p className={styles.submitError}>{submitError}</p>}
      {deleteID && (
        <Modal className={styles.deleteModal} onClose={() => setDeleteID(null)}>
          <>
            <h3>Are you sure?</h3>
            <p>You will not be able to recover this entry if you delete it.</p>
            <FormButtonRow className={styles.deleteButtons}>
              <Button buttonType="tertiary" onClick={() => setDeleteID(null)}>
                Cancel
              </Button>
              <Button
                className={styles.deleteButton}
                buttonType="primary"
                onClick={async () => {
                  try {
                    const { deletedEntry } = await deleteEntry(deleteID);

                    setSavedEntries((curr) => {
                      const newSavedEntries = _.cloneDeep(curr);

                      if (newSavedEntries) {
                        const deletedIndex = newSavedEntries.findIndex(
                          (entry) => entry.entry_id === deletedEntry
                        );

                        if (deletedIndex > -1) {
                          newSavedEntries.splice(deletedIndex, 1);
                        }

                        return newSavedEntries;
                      }

                      return null;
                    });

                    setDeleteID(null);
                  } catch {
                    setDeleteError(true);
                  }
                }}
              >
                Delete Entry
              </Button>
            </FormButtonRow>
            {deleteError && (
              <p className={styles.deleteError}>
                Failed to delete entry. Please try again later.
              </p>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

interface DashboardInvoicedEntryListProps {
  includeTitle?: boolean;
  entries: AwardsEntryMinimal[];
  showCompleteButton?: boolean;
}

export const DashboardInvoicedEntryList = ({
  includeTitle = true,
  entries,
  showCompleteButton = false,
}: DashboardInvoicedEntryListProps) => {
  const _window = useWindow();

  const { account } = useContext(AccountContext);
  const { noNewEntries } = useContext(AwardsContext);
  const { setCurrentEntry } = useContext(EntriesContext);

  const [completeID, setCompleteID] = useState<number | null>(null);
  const [showPaymentError, setShowPaymentError] = useState<number | null>(null);
  const [showSubmissionError, setShowSubmissionError] = useState<number | null>(
    null
  );

  return (
    <>
      {includeTitle && (
        <>
          <h2 className={styles.listTitle}>Submitted entries</h2>
          <p>
            Below are the entries you can add files to. You now need to upload
            the films and complete your entries.
          </p>
          <p>
            Select the blue icon next to the entry name to upload your files.
          </p>
        </>
      )}
      {entries.map((entry, index) => {
        const { entry_id, title, entry_files_count, is_paid } = entry;

        if (!entry_id) return null;

        let isSecondaryContact = false;

        if (
          entry.entrant_id !== account.entrant_id &&
          entry.secondary_contact_email &&
          account.email &&
          entry.secondary_contact_email.toLowerCase() ===
            account.email.toLowerCase()
        ) {
          isSecondaryContact = true;
        }

        return (
          <React.Fragment key={entry_id}>
            <div className={styles.entryRow}>
              <div className={styles.paidEntryTitle}>
                <h4>
                  <Button buttonType="link" href={`/view-entry/${entry_id}`}>
                    {title}
                  </Button>{" "}
                  <span className={styles.fileCount}>
                    ({entry_files_count.toString()} file
                    {entry_files_count !== 1 && "s"})
                  </span>
                </h4>
                <span className={styles.categoryName}>
                  {entry.category_title}
                  {isSecondaryContact && (
                    <>
                      <br />
                      (Secondary contact for this entry)
                    </>
                  )}
                </span>
              </div>
              {showCompleteButton && entry_files_count > 0 && (
                <Button
                  buttonType="primary"
                  className={styles.completeBtn}
                  softDisable={!is_paid}
                  onClick={async () => {
                    if (noNewEntries) {
                      _window.scrollTo({ top: 0, behavior: "smooth" });

                      return;
                    }

                    setShowSubmissionError(null);

                    if (!is_paid) {
                      setShowPaymentError(index);
                      return;
                    }

                    const fullEntry = entries.find(
                      (entry) => entry.entry_id === entry_id
                    );

                    if (!fullEntry) return;

                    const readyToSubmit = isReadyToSubmit(fullEntry);

                    if (!readyToSubmit) {
                      setShowSubmissionError(index);
                      return;
                    }

                    if (entry_id) setCompleteID(entry_id);
                  }}
                >
                  Complete
                </Button>
              )}
              <div className={styles.buttons}>
                <AddFilesButton
                  href={noNewEntries ? undefined : `/uploader/${entry_id}`}
                  onClick={
                    noNewEntries
                      ? () => {
                          _window.scrollTo({ top: 0, behavior: "smooth" });
                        }
                      : undefined
                  }
                />
                <CopyButton
                  onClick={() => {
                    if (noNewEntries) {
                      _window.scrollTo({ top: 0, behavior: "smooth" });
                      return;
                    }

                    const entryToClone = _.cloneDeep(entry);

                    const newEntry: NewEntry = {
                      ...entryToClone,
                      entry_id: undefined,
                      // @ts-ignore
                      awards_category_id: undefined,
                      invoice_sent: false,
                      invoice_id: undefined,
                      is_paid: false,
                      is_submitted: false,
                      submission_id: undefined,
                    };

                    setCurrentEntry(newEntry);

                    _window.sessionStorage?.setItem(
                      "currentEntry",
                      JSON.stringify(newEntry)
                    );

                    navigate("/new-entry", {
                      state: {
                        duplicateCategory: entryToClone.awards_category_id,
                      },
                    });
                  }}
                />
                <EditButton
                  href={noNewEntries ? undefined : `/edit-entry/${entry_id}`}
                  onClick={
                    noNewEntries
                      ? () => {
                          _window.scrollTo({ top: 0, behavior: "smooth" });
                        }
                      : undefined
                  }
                />
              </div>
            </div>
            {showSubmissionError === index && (
              <p className={styles.submitError}>
                Your entry is not ready to complete. Please check that all
                required fields are filled out.
              </p>
            )}
            {showPaymentError === index && (
              <p className={styles.submitError}>
                You need to pay for this entry. Please check your emails for the
                invoice or contact{" "}
                <Button buttonType="link" href={`mailto:${supportEmail}`}>
                  {supportEmail}
                </Button>
              </p>
            )}
            {index < entries.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
      {completeID && (
        <CompleteEntryModal
          entryID={completeID}
          onClose={() => setCompleteID(null)}
          onComplete={(completedEntry) => {
            if (completedEntry)
              navigate(`/view-entry/${completeID}`, {
                state: {
                  entryComplete: true,
                },
              });
          }}
        />
      )}
    </>
  );
};

interface DashboardFinishedEntryListProps {
  entries: AwardsEntryMinimal[];
}

export const DashboardFinishedEntryList = ({
  entries,
}: DashboardFinishedEntryListProps) => {
  const _window = useWindow();

  const { account } = useContext(AccountContext);
  const { noNewEntries } = useContext(AwardsContext);
  const { setCurrentEntry } = useContext(EntriesContext);

  const title = <h3 className={styles.accordionTitle}>Completed entries</h3>;

  const entriesContent = (
    <>
      {entries.map((entry, index) => {
        const { entry_id, title, category_title } = entry;

        let isSecondaryContact = false;

        if (
          entry.entrant_id !== account.entrant_id &&
          entry.secondary_contact_email &&
          account.email &&
          entry.secondary_contact_email.toLowerCase() ===
            account.email.toLowerCase()
        ) {
          isSecondaryContact = true;
        }

        return (
          <React.Fragment key={entry_id}>
            <div className={styles.entryRow}>
              <div className={styles.paidEntryTitle}>
                <h4>
                  <Button buttonType="link" href={`/view-entry/${entry_id}`}>
                    {title || "Untitled entry"}{" "}
                  </Button>
                </h4>
                <span className={styles.categoryName}>
                  {category_title}
                  {isSecondaryContact && (
                    <>
                      <br />
                      (Secondary contact for this entry)
                    </>
                  )}
                </span>
              </div>
              <CopyButton
                onClick={() => {
                  if (noNewEntries) {
                    _window.scrollTo({ top: 0, behavior: "smooth" });

                    return;
                  }

                  const entryToClone = _.cloneDeep(entry);

                  const newEntry: NewEntry = {
                    ...entryToClone,
                    entry_id: undefined,
                    // @ts-ignore
                    awards_category_id: undefined,
                    invoice_sent: false,
                    invoice_id: undefined,
                    is_paid: false,
                    is_submitted: false,
                    submission_id: undefined,
                  };

                  setCurrentEntry(newEntry);

                  _window.sessionStorage?.setItem(
                    "currentEntry",
                    JSON.stringify(newEntry)
                  );

                  navigate("/new-entry", {
                    state: {
                      duplicateCategory: entryToClone.awards_category_id,
                    },
                  });
                }}
              />
              <Button
                buttonType="tertiary"
                className={styles.actionButton}
                href={`/view-entry/${entry_id}`}
              >
                View
              </Button>
            </div>
            {index < entries.length - 1 && (
              <Divider className={styles.entryDivider} />
            )}
          </React.Fragment>
        );
      })}
    </>
  );

  const entriesData = [{ title, content: entriesContent }];

  return (
    <Accordion
      id="finishedEntries"
      className={styles.entriesAccordion}
      data={entriesData}
      fullWidth
    />
  );
};
