import React, { useContext, useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import moment from "moment";

import Button from "../components/button";
import {
  DashboardFinishedEntryList,
  DashboardInvoicedEntryList,
  DashboardPendingEntryList,
} from "../components/DashboardEntryLists";
import { FormError, FormMessage } from "../components/form";
import { Column, Container, Row } from "../components/layout";
import LoadingLogo from "../components/LoadingLogo";
import { ResendConfirmationEmailModal } from "../components/ResendConfirmationEmailModal";
import SiteWrapper from "../components/SiteWrapper";
import { Divider } from "../components/typeface";
import { AccountContext } from "../context/account";
import { AwardsContext } from "../context/awards";
import { EntriesContext } from "../context/entries";
import { JudgesContext } from "../context/judges";
import { supportEmail } from "../core/variables";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/dashboard.module.scss";
import {
  isAfterShortlistDate,
  isBeforeShortlistDate,
  isChair,
  isFirstRoundJudge,
  isJudge,
  isSecondRoundJudge,
} from "../utils";

interface DashboardPageProps extends RouteComponentProps {}

export default function DashboardPage({}: DashboardPageProps) {
  const _window = useWindow();

  const { currentJudge } = useContext(JudgesContext);

  const { account } = useContext(AccountContext);
  const { uid, entrant_id, first_name, accountConfirmed } = account;

  const { currentYear, noNewEntries } = useContext(AwardsContext);
  const { savedEntries, fetchSavedEntries, fetchError, setCurrentEntry } =
    useContext(EntriesContext);

  const userName = first_name ? ` ${first_name}` : "";

  const [addNewEntryBlocker, setAddNewEntryBlocker] = useState(false);
  const [showResendConfirmationModal, setShowResendConfirmationModal] =
    useState(false);

  // Refetch entries every time the dashboard loads
  useEffect(() => {
    if (!entrant_id || !currentYear) return;

    fetchSavedEntries(entrant_id, currentYear.year);
  }, [entrant_id, currentYear]);

  const { incompleteEntries, invoicedEntries, finishedEntries } =
    useMemo(() => {
      if (!savedEntries)
        return {
          incompleteEntries: [],
          invoicedEntries: [],
          finishedEntries: [],
        };

      const incompleteEntries = savedEntries.filter(
        ({ invoice_sent, invoice_id }) => !invoice_sent && !invoice_id
      );

      const invoicedEntries = savedEntries.filter(
        ({ invoice_sent, invoice_id, is_submitted }) =>
          !is_submitted && invoice_sent && invoice_id
      );

      const finishedEntries = savedEntries.filter(
        ({ is_submitted, submission_id }) => is_submitted && submission_id
      );

      return { incompleteEntries, invoicedEntries, finishedEntries };
    }, [savedEntries]);

  const submissionDeadline = useMemo(() => {
    if (!currentYear || noNewEntries) return null;

    return moment(currentYear.entries_close_date).format("dddd, D MMMM YYYY");
  }, [currentYear, noNewEntries]);

  const showJudgeMessage = useMemo(() => {
    if (account && currentYear && currentJudge) {
      if (isJudge(account)) {
        if (isChair(currentJudge)) {
          return true;
        }
        if (
          isFirstRoundJudge(currentJudge) &&
          isBeforeShortlistDate(currentYear)
        ) {
          return true;
        }
        if (
          isSecondRoundJudge(currentJudge) &&
          isAfterShortlistDate(currentYear)
        ) {
          return true;
        }
      }
    }
    return false;
  }, [account, currentYear, currentJudge]);

  return (
    <>
      <SiteWrapper>
        <Container>
          {currentYear ? (
            <>
              <Row>
                <Column gridCols={6} textCenter>
                  {showJudgeMessage && (
                    <FormMessage>
                      <p>
                        You have been added as a judge for the Grierson Awards{" "}
                        {currentYear?.full_title}.{" "}
                        <Button buttonType="link" href="/judging">
                          View the entries
                        </Button>
                      </p>
                    </FormMessage>
                  )}
                  {noNewEntries === false && (
                    <FormMessage>
                      <p>Entries are now open for {currentYear?.full_title}</p>
                    </FormMessage>
                  )}
                  <h1>Dashboard</h1>
                  {noNewEntries === true && (
                    <FormError>
                      <p>
                        We are no longer accepting new entries. Please contact{" "}
                        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
                      </p>
                    </FormError>
                  )}
                  <div className={styles.contentBlock}>
                    <p>Hi{userName},</p>
                    <p>
                      You can add and manage your Grierson Awards entries here.
                    </p>
                    <p>For each entry, you must:</p>
                    <ol>
                      <li>
                        <strong>Add your User Detail information.</strong> You
                        will not be able to start an entry without providing the
                        required information on your User Profile. Please ensure
                        you fill in the Billing Details as well.
                      </li>
                      <li>
                        <strong>Create a new entry form.</strong> Select the
                        category you would like to enter, fill in the fields on
                        the form and save it. You will then be able to see that
                        entry on your dashboard. You can continue to edit the
                        forms at a later date.
                      </li>
                      <li>
                        Check all the fields on your entry form are correct and{" "}
                        <strong>SUBMIT</strong> that entry form. You will get an
                        email confirming the form submission.
                      </li>
                      <li>
                        <strong>Pay for your entry.</strong> The Billing Contact
                        will get an automated email with instructions on how you
                        can pay.
                      </li>
                      <li>
                        <strong>Upload the relevant film files</strong> to your
                        entry on the online portal.
                      </li>
                      <li>
                        Check the video files are attached to the correct entry
                        and then <strong>COMPLETE</strong> your entry. You will
                        get an email confirming you have completed your entry.
                      </li>
                    </ol>
                    {submissionDeadline && (
                      <p>
                        <strong>
                          The deadline for completing your entries is{" "}
                          {submissionDeadline}.{" "}
                        </strong>
                      </p>
                    )}
                    <p>
                      <Button href="#ts-and-cs">Terms &amp; Conditions</Button>
                    </p>
                  </div>
                </Column>
              </Row>
              <Row>
                <Column gridCols={6}>
                  {fetchError && (
                    <>
                      <Divider />
                      <p>
                        Error loading entries. Reload the page or contact{" "}
                        <a href="media@griersontrust.org">
                          media@griersontrust.org
                        </a>
                      </p>
                    </>
                  )}
                  {!fetchError && incompleteEntries.length > 0 && (
                    <>
                      <Divider />
                      <DashboardPendingEntryList entries={incompleteEntries} />
                    </>
                  )}
                  <Divider />
                  <Button
                    buttonType="primary"
                    className={styles.createNewEntry}
                    softDisable={!!uid && (!entrant_id || !accountConfirmed)}
                    onClick={() => {
                      if (noNewEntries === true) {
                        _window.scrollTo({ top: 0, behavior: "smooth" });

                        return;
                      }

                      if (!!uid && (!entrant_id || !accountConfirmed)) {
                        setAddNewEntryBlocker(true);

                        return;
                      }

                      setCurrentEntry(null);
                      _window.sessionStorage?.removeItem("currentEntry");
                      _window.sessionStorage?.removeItem("entriesToSubmit");
                      navigate("/new-entry");
                    }}
                  >
                    Create new entry
                  </Button>
                  {addNewEntryBlocker && (
                    <p className={styles.submitError}>
                      {accountConfirmed ? (
                        <>
                          You need to enter some profile information before you
                          can create entries. Please go to the{" "}
                          <Button buttonType="link" href="/profile">
                            Profile
                          </Button>{" "}
                          page.
                        </>
                      ) : (
                        <FormError className={styles.confirmAccountError}>
                          <p>
                            You need to confirm your email address before you
                            can create entries.
                          </p>
                          <p>
                            Can't find the email?{" "}
                            <Button
                              onClick={() =>
                                setShowResendConfirmationModal(true)
                              }
                            >
                              Click here to resend it.
                            </Button>
                          </p>
                        </FormError>
                      )}
                    </p>
                  )}
                  {!fetchError && invoicedEntries.length > 0 && (
                    <>
                      <Divider />
                      <DashboardInvoicedEntryList
                        entries={invoicedEntries}
                        showCompleteButton
                      />
                    </>
                  )}
                  {!fetchError && finishedEntries.length > 0 && (
                    <>
                      <Divider />
                      <DashboardFinishedEntryList entries={finishedEntries} />
                    </>
                  )}
                </Column>
              </Row>
            </>
          ) : (
            <LoadingLogo className={styles.fullPageLoader} />
          )}
        </Container>
      </SiteWrapper>
      {showResendConfirmationModal && (
        <ResendConfirmationEmailModal
          onClose={() => setShowResendConfirmationModal(false)}
        />
      )}
    </>
  );
}
