import React, { useContext, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Button from "./button";
import { FlotiqImage } from "./image";
import Input from "./input";
import { Divider, StyledMarkdown } from "./typeface";
import fallbackLogo from "../assets/svgs/grierson-logo-white.svg";
import xIcon from "../assets/svgs/x-logo.svg";
import facebookIcon from "../assets/svgs/facebook.svg";
import instagramIcon from "../assets/svgs/instagram.svg";
import linkedinIcon from "../assets/svgs/linkedin.svg";
import vimeoIcon from "../assets/svgs/vimeo.svg";
import youtubeIcon from "../assets/svgs/youtube.svg";
import emailIcon from "../assets/svgs/envelope.svg";
import tikTokIcon from "../assets/images/TikTok_White.png";
import { AccountContext } from "../context/account";
import { supportEmail } from "../core/variables";
import * as styles from "../styles/footer.module.scss";
import { GetFooterItemsQuery } from "../../graphql-types";

export default function Footer() {
  const { account, setAccount } = useContext(AccountContext);

  const data: GetFooterItemsQuery = useStaticQuery(graphql`
    query GetFooterItems {
      globalContent {
        id
        main_logo {
          id
          url
        }
        contact_email
        contact_phone
        copyright
        charity_details
        company_details
        business_address
        linkedin
        instagram
        facebook
        twitter
        youtube
        vimeo
        tiktok
      }
      allTopNavigation {
        nodes {
          id
          position
          link_text
          link
        }
      }
    }
  `);

  const {
    globalContent,
    allTopNavigation: { nodes: topNavItems },
  } = data;

  const { main_logo } = globalContent || { main_logo: null };

  // Moved to old form component while used
  // const [newsLetterSignup, setNewsLetterSignup] = useState("");
  // const [signupLoading, setSignupLoading] = useState(false);
  // const [signupError, setSignupError] = useState(false);
  // const [signupErrorMsg, setSignupErrorMsg] = useState(
  //   "Please enter a valid email address"
  // );
  // const [signupSuccess, setSignupSuccess] = useState(false);

  const x = {
    icon: xIcon,
    name: "X",
    link: globalContent?.twitter || null,
  };
  const facebook = {
    icon: facebookIcon,

    name: "Facebook",
    link: globalContent?.facebook || null,
  };
  const instagram = {
    icon: instagramIcon,

    name: "Instagram",
    link: globalContent?.instagram || null,
  };
  const linkedin = {
    icon: linkedinIcon,

    name: "Linkedin",
    link: globalContent?.linkedin || null,
  };
  const vimeo = {
    icon: vimeoIcon,
    name: "Vimeo",
    link: globalContent?.vimeo || null,
  };
  const youtube = {
    icon: youtubeIcon,
    name: "Youtube",
    link: globalContent?.youtube || null,
  };
  const tikTok = {
    icon: tikTokIcon,
    name: "TikTok",
    link: globalContent?.tiktok || null,
  };

  const socials = [
    x,
    facebook,
    instagram,
    linkedin,
    vimeo,
    youtube,
    tikTok,
    {
      icon: emailIcon,
      name: "Email",
      link: globalContent ? `mailto:${globalContent.contact_email}` : null,
    },
  ];

  return (
    <footer>
      <div className={styles.footerContainer}>
        {account.email && (
          <div className={styles.loggedInAs}>
            <p>
              Logged in as: <strong>{account.email}</strong>
            </p>
          </div>
        )}
        <div className={styles.linksContainer}>
          <div>
            <Button buttonType="icon" href="/">
              <FlotiqImage
                className={styles.logo}
                imgObj={main_logo}
                fallbackImg={fallbackLogo}
                alt="The Grierson Trust"
              />
            </Button>
            {socials.some((social) => !!social.link) && (
              <div className={styles.socials}>
                {socials.map((socialItem) => {
                  if (!socialItem.link) return null;

                  return (
                    <Button
                      key={socialItem.name}
                      buttonType="icon"
                      newTab
                      href={socialItem.link}
                    >
                      <img
                        className={styles.socialLogo}
                        src={socialItem.icon}
                        alt={socialItem.name}
                      />
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.links}>
            <div className={styles.linksSection}>
              <p className={styles.title}>Sitemap</p>
              {topNavItems.map((navItem) => {
                return (
                  <Button
                    key={navItem.id}
                    href={navItem.link}
                    newTab={!/[\/#]/.test(navItem.link[0])}
                    className={styles.link}
                  >
                    {navItem.link_text}
                  </Button>
                );
              })}
            </div>
            <div className={styles.linksSection}>
              <p className={styles.title}>Contact</p>
              <p className={styles.copy}>
                {globalContent?.contact_email || supportEmail}
              </p>
              <p className={styles.copy}>
                {globalContent?.contact_phone || "01733 891 878"}
              </p>
            </div>
            <div className={styles.linksSection}>
              <p className={styles.title}>Address</p>
              <StyledMarkdown
                components={{
                  p: ({ node, ...props }) => (
                    <p {...props} className={styles.copy} />
                  ),
                }}
              >
                {globalContent?.business_address ||
                  `The Grierson Trust\n28 Sallows Road\nPeterborough\nPE1 4EU\nUnited Kingdom`}
              </StyledMarkdown>
            </div>
            <div className={styles.linksSection}>
              <NewsletterSignupForm />
            </div>
          </div>
        </div>
        <Divider />
        <div className={styles.infoSection}>
          <p className={styles.copy}>
            {globalContent?.copyright || `© 2022 The Grierson Trust`}
          </p>
          <div className={styles.charityInfo}>
            <p className={styles.copy}>
              {`Registered Charity No: ${
                globalContent?.charity_details || "1100784"
              }`}
            </p>
            <p className={styles.copy}>&nbsp;|&nbsp;</p>
            <p className={styles.copy}>
              {`Company Registration No: ${
                globalContent?.company_details || "04855173"
              }`}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

// Mailchimp signup form
// https://us20.admin.mailchimp.com/lists/designer/?id=67783
const NewsletterSignupForm = () => {
  const mailchimpUid = "b6c6de98a062a42996f65435c";
  const mailchimpId = "55a176f8cf";
  const [newsLetterSignup, setNewsLetterSignup] = useState("");

  return (
    <>
      <p className={styles.title}>Newsletter</p>
      <p className={styles.copy}>Sign up for the latest news</p>
      <form
        action={`https://griersontrust.us20.list-manage.com/subscribe?u=${mailchimpUid}&id=${mailchimpId}&EMAIL=${newsLetterSignup}`}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
      >
        <Input
          submit
          className={styles.newsletterSubmit}
          placeholder="email@gmail.com"
          name="EMAIL"
          id="mce-EMAIL"
          value={newsLetterSignup}
          onChange={(e) => setNewsLetterSignup(e.target.value)}
        />
      </form>
    </>
  );
};
