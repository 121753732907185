// extracted by mini-css-extract-plugin
export var accept = "file-upload-module--accept--ce87a";
export var container = "file-upload-module--container--a2eaf";
export var disabled = "file-upload-module--disabled--4ec71";
export var dropzone = "file-upload-module--dropzone--38621";
export var dropzoneContainer = "file-upload-module--dropzoneContainer--40063";
export var errorMsg = "file-upload-module--errorMsg--7f54f";
export var introMsg = "file-upload-module--introMsg--b7675";
export var labelContainer = "file-upload-module--labelContainer--6e57a";
export var ldsRing = "file-upload-module--ldsRing--e0d80";
export var loading = "file-upload-module--loading--a4411";
export var reject = "file-upload-module--reject--681d6";
export var required = "file-upload-module--required--43635";
export var stacked = "file-upload-module--stacked--e83d2";
export var successMsg = "file-upload-module--successMsg--ec852";