import _ from "lodash";

import {
  AwardsEntryMinimal,
  AwardsEntryResponseLimited,
  NewEntry,
} from "../api/types/awards-entries";
import { isValidEmail, wordCount } from ".";
import {
  EntryFormCheckboxField,
  EntryFormField,
  EntryFormFieldValue,
} from "../api/types/awards-category-fields";
import { AccountProps } from "../context/account";

export const initialiseSecondaryFieldValues = (
  secondaryFields: EntryFormField[]
): EntryFormFieldValue[] => {
  return secondaryFields.map((secondaryField) => {
    const {
      fieldId,
      fieldType,
      salesforceFieldId,
      title,
      required,
      hiddenByFieldId,
    } = secondaryField;

    let parentField = undefined;

    if (hiddenByFieldId) {
      const fullParentField = secondaryFields.find(
        (f) => f.fieldId === hiddenByFieldId
      );

      if (fullParentField) {
        const { fieldId, hiddenFields, checkedByDefault } =
          fullParentField as EntryFormCheckboxField;

        parentField = {
          id: fieldId,
          hidesWhenChecked: hiddenFields?.hideFieldsWhenChecked,
          checkedByDefault,
        };
      }
    }

    const output: EntryFormFieldValue = {
      fieldId,
      salesforceFieldId,
      fieldTitle: title,
      required,
      parentField,
    };

    if (fieldType === "select") {
      output.salesforceOtherFieldId = secondaryField.salesforceOtherFieldId;
    }

    return output;
  });
};

export const mergeSecondaryFieldValues = (
  newCategoryFields: EntryFormField[],
  existingEntryFieldValues?: EntryFormFieldValue[]
) => {
  if (!existingEntryFieldValues) {
    return initialiseSecondaryFieldValues(newCategoryFields);
  }

  return newCategoryFields.map((newCategoryField) => {
    const {
      fieldId,
      salesforceFieldId,
      fieldType,
      title,
      required,
      hiddenByFieldId,
    } = newCategoryField;

    let output: EntryFormFieldValue = {
      fieldId,
      salesforceFieldId,
      fieldTitle: title,
      required,
    };

    // Match entry to duplicate based on field title
    const valueInExistingEntryFields = existingEntryFieldValues.find(
      (existingField) => existingField.fieldTitle === title
    );

    // If a match is found, use that value
    if (valueInExistingEntryFields) {
      output = { ...valueInExistingEntryFields, fieldId, required };
    }

    let parentField = undefined;

    if (hiddenByFieldId) {
      const fullParentField = newCategoryFields.find(
        (f) => f.fieldId === hiddenByFieldId
      );

      if (fullParentField) {
        const { fieldId, hiddenFields, checkedByDefault } =
          fullParentField as EntryFormCheckboxField;

        parentField = {
          id: fieldId,
          hidesWhenChecked: hiddenFields?.hideFieldsWhenChecked,
          checkedByDefault,
        };
      }
    }

    if (fieldType === "select") {
      output.salesforceOtherFieldId = newCategoryField.salesforceOtherFieldId;
    }

    return output;
  });
};

export const entryFormHasErrors = (
  entry: NewEntry | AwardsEntryResponseLimited
) => {
  if (entry.description) {
    const count = wordCount(entry.description);

    if ((count > 0 && count < 15) || count > 75) {
      return "description";
    }
  }

  if (entry.secondary_contact_email) {
    if (!isValidEmail(entry.secondary_contact_email)) {
      return "secondary_contact_email";
    }
  }

  return false;
};

export const accountIsCompleteEntrant = (account: AccountProps) => {
  if (!account.entrant_id || !account.entrantDetails) return false;

  const {
    first_name,
    last_name,
    entrantDetails: {
      address_1,
      postcode,
      same_billing_details,
      billing_first_name,
      billing_last_name,
      billing_address_1,
      billing_postcode,
    },
  } = account;

  return !(
    !first_name ||
    !last_name ||
    !address_1 ||
    !postcode ||
    (!same_billing_details &&
      !!(
        !billing_first_name ||
        !billing_last_name ||
        !billing_address_1 ||
        !billing_postcode
      ))
  );
};

export const isReadyToSubmit = (
  entry: AwardsEntryMinimal | AwardsEntryResponseLimited
) => {
  const {
    title,
    description,
    entry_form_field_values: secondaryFields,
    secondary_contact_first_name,
    secondary_contact_last_name,
    secondary_contact_email,
  } = entry;

  // Entry must have a title and synopsis
  if (!title || !description) {
    return false;
  }

  // Secondary contact info must be complete
  if (
    (secondary_contact_email &&
      (!secondary_contact_first_name || !secondary_contact_last_name)) ||
    (secondary_contact_first_name &&
      (!secondary_contact_email || !secondary_contact_last_name)) ||
    (secondary_contact_last_name &&
      (!secondary_contact_first_name || !secondary_contact_email))
  ) {
    return false;
  }

  if (!secondaryFields || secondaryFields.length === 0) {
    return true;
  }

  let isReady = true;

  let fieldIndex = 0;

  while (fieldIndex < secondaryFields.length && isReady) {
    const { parentField, required, value } = secondaryFields[fieldIndex];

    // Check if parent field allows this to be given a value
    if (parentField) {
      const fullParentField = secondaryFields.find(
        (f) => f.fieldId === parentField.id
      );

      if (
        fullParentField &&
        ((!parentField.hidesWhenChecked && !fullParentField.value) ||
          (parentField.hidesWhenChecked &&
            (fullParentField.value === true ||
              (fullParentField.value === undefined &&
                parentField.checkedByDefault))))
      ) {
        // No need to check state of child field
        // Its parent means its value isn't used
        fieldIndex += 1;
        continue;
      }
    }

    if (required && !value && value !== false) {
      isReady = false;
      break;
    }

    // Table fields
    if (Array.isArray(value)) {
      if (required && value.length === 0) {
        isReady = false;
        break;
      }

      const incompleteRow = (value as {
        [key: string]: { required?: boolean; value: string };
      }[])!.find((row) => {
        return Object.values(row).find((val) => !!val.required && !val.value);
      });

      if (incompleteRow) {
        isReady = false;
        break;
      }
    }

    fieldIndex += 1;
  }

  return isReady;
};

// Check if the secondary form field should be hidden
export const hideBehindParentField = (
  field: EntryFormField,
  secondaryFields: EntryFormField[]
): boolean | "greyedOut" => {
  const parentFieldId = field.hiddenByFieldId;

  // Checkbox fields should not be hidden behind others
  // This is to prevent circular dependencies
  if (!parentFieldId || field.fieldType === "checkbox") {
    return false;
  }

  const parentFieldFull = secondaryFields.find(
    (f) => f.fieldId === parentFieldId
  );

  if (
    parentFieldFull &&
    (parentFieldFull as EntryFormCheckboxField).hiddenFields
  ) {
    const parentFieldConfig = (parentFieldFull as EntryFormCheckboxField)
      .hiddenFields;

    if (parentFieldConfig) {
      return parentFieldConfig?.state === "greyedOut" ? "greyedOut" : true;
    }
  }

  return false;
};
