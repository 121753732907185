import React, { useContext, useMemo, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import classNames from "classnames";
import _ from "lodash";

import {
  AwardsSponsorBody,
  AwardsSponsorResponse,
} from "../api/types/awards-sponsors";
import Input from "../components/input";
import {
  addSponsor,
  deleteSponsor,
  updateSponsor,
} from "../api/awards-sponsors";
import Button, { DeleteButton, EditButton } from "../components/button";
import FileUploader from "../components/file-upload";
import { FormButtonRow, FormError, FormMessage } from "../components/form";
import { Column, Container, Row } from "../components/layout";
import { Modal } from "../components/modal";
import SiteWrapper from "../components/SiteWrapper";
import Table from "../components/Table";
import { AdminContext } from "../context/admin";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/AdminManageSponsorsPage.module.scss";

interface AddSponsorModalProps {
  onUpdate: (newSponsor: AwardsSponsorResponse) => void;
}

export const AddSponsorModal = ({ onUpdate }: AddSponsorModalProps) => {
  const [sponsorToAdd, setSponsorToAdd] = useState<Partial<AwardsSponsorBody>>({
    name: "",
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  return (
    <>
      <h1>Add new sponsor</h1>
      <div className={styles.form}>
        <Input
          id="sponsor-name"
          name="sponsor-name"
          label="Name"
          required
          placeholder="Enter sponsor name"
          value={sponsorToAdd.name}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setSponsorToAdd((curr) => {
              return {
                ...curr,
                name: e.target.value,
              };
            });
          }}
        />
        <FileUploader
          id="sponsor-logo-bw"
          label="Logo (Monochrome)"
          required
          copy={{
            upload: "Add a black and white version of the logo",
          }}
          accept={{
            "image/*": [".jpg", ".jpeg", ".png", ".svg"],
          }}
          onDrop={(file) => {
            if (file.length !== 1) {
              return;
            }

            setUpdateSuccess(false);
            setUpdateError(null);

            setSponsorToAdd((curr) => {
              return {
                ...curr,
                logo_bw: file[0],
              };
            });
          }}
        >
          {sponsorToAdd.logo_bw && (
            <p>
              <em>{sponsorToAdd.logo_bw.name}</em>
            </p>
          )}
        </FileUploader>
        <FileUploader
          id="sponsor-logo"
          label="Logo"
          copy={{
            upload: "Add a full-colour version of the logo",
          }}
          accept={{
            "image/*": [".jpg", ".jpeg", ".png", ".svg"],
          }}
          onDrop={(file) => {
            if (file.length !== 1) {
              return;
            }

            setUpdateSuccess(false);
            setUpdateError(null);

            setSponsorToAdd((curr) => {
              return {
                ...curr,
                logo: file[0],
              };
            });
          }}
        >
          {sponsorToAdd.logo && (
            <p>
              <em>{sponsorToAdd.logo.name}</em>
            </p>
          )}
        </FileUploader>
        <FormButtonRow>
          <Button
            buttonType="primary"
            disabled={!sponsorToAdd.name || !sponsorToAdd.logo_bw}
            onClick={async () => {
              try {
                const { newSponsor } = await addSponsor(
                  sponsorToAdd as AwardsSponsorBody
                );

                onUpdate(newSponsor);
                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to add new sponsor.");
              }
            }}
          >
            Add
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && (
        <FormMessage>
          Successfully added new sponsor '{sponsorToAdd.name}'.
        </FormMessage>
      )}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

interface EditSponsorModalProps extends AddSponsorModalProps {
  sponsorToEdit: AwardsSponsorResponse;
}

export const EditSponsorModal = ({
  sponsorToEdit,
  onUpdate,
}: EditSponsorModalProps) => {
  const [currentSponsor, setCurrentSponsor] = useState<
    Partial<AwardsSponsorBody> & {
      logo_to_replace?: string;
      logo_bw_to_replace?: string;
    }
  >({
    name: sponsorToEdit.name,
  });
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  return (
    <>
      <h1>Edit sponsor</h1>
      <div className={styles.form}>
        <Input
          id="sponsor-name"
          name="sponsor-name"
          label="Name"
          required
          placeholder="Enter sponsor name"
          value={currentSponsor.name}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);
            setHasBeenEdited(true);

            setCurrentSponsor((curr) => {
              return {
                ...curr,
                name: e.target.value,
              };
            });
          }}
        />
        <FileUploader
          id="sponsor-logo-bw"
          label="Logo (Monochrome)"
          copy={{
            upload: "Upload a new logo (optional)",
          }}
          accept={{
            "image/*": [".jpg", ".jpeg", ".png", ".svg"],
          }}
          onDrop={(file) => {
            if (file.length !== 1) {
              return;
            }

            setUpdateSuccess(false);
            setUpdateError(null);
            setHasBeenEdited(true);

            setCurrentSponsor((curr) => {
              return {
                ...curr,
                logo_bw: file[0],
                logo_bw_to_replace: sponsorToEdit.logo_bw,
              };
            });
          }}
        >
          {currentSponsor.logo_bw && (
            <p>
              <em>{currentSponsor.logo_bw.name}</em>
            </p>
          )}
        </FileUploader>
        <FileUploader
          id="sponsor-logo"
          label="Logo"
          copy={{
            upload: "Upload a new logo (optional)",
          }}
          accept={{
            "image/*": [".jpg", ".jpeg", ".png", ".svg"],
          }}
          onDrop={(file) => {
            if (file.length !== 1) {
              return;
            }

            setUpdateSuccess(false);
            setUpdateError(null);
            setHasBeenEdited(true);

            setCurrentSponsor((curr) => {
              return {
                ...curr,
                logo: file[0],
                logo_to_replace: sponsorToEdit.logo,
              };
            });
          }}
        >
          {currentSponsor.logo && (
            <p>
              <em>{currentSponsor.logo.name}</em>
            </p>
          )}
        </FileUploader>
        <FormButtonRow>
          <Button
            buttonType="primary"
            disabled={!hasBeenEdited}
            onClick={async () => {
              try {
                const { updatedSponsor } = await updateSponsor(
                  sponsorToEdit.sponsor_id,
                  currentSponsor
                );

                onUpdate(updatedSponsor);
                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to add new sponsor.");
              }
            }}
          >
            Update
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && (
        <FormMessage>Successfully updated sponsor.</FormMessage>
      )}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

interface DeleteSponsorModalProps {
  sponsor: AwardsSponsorResponse;
  onUpdate: (sponsorId: string) => void;
}

export const DeleteSponsorModal = ({
  sponsor,
  onUpdate,
}: DeleteSponsorModalProps) => {
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  return (
    <>
      <h1>Delete sponsor '{sponsor.name}'</h1>
      <p>Are you sure you want to delete this sponsor?</p>
      <p>
        This action is irreversible. It will remove the sponsor from all
        categories they're associated with across all years.
      </p>
      <div className={styles.form}>
        <FormButtonRow>
          <Button
            buttonType="warning"
            onClick={async () => {
              try {
                const { removedSponsor } = await deleteSponsor(
                  sponsor.sponsor_id
                );

                onUpdate(removedSponsor);
                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to delete sponsor.");
              }
            }}
          >
            Delete
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && (
        <FormMessage>
          Successfully deleted the sponsor. They will be removed from all
          categories.
        </FormMessage>
      )}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

const sponsorColumnHelper = createColumnHelper<AwardsSponsorResponse>();

export default function AdminManageSponsorsPage() {
  const _window = useWindow();

  const { sponsors, setSponsors } = useContext(AdminContext);

  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [addSponsorModal, setAddSponsorModal] = useState(false);
  const [editSponsorModal, setEditSponsorModal] = useState(false);
  const [sponsorToEdit, setSponsorToEdit] =
    useState<AwardsSponsorResponse | null>(null);
  const [sponsorToDelete, setSponsorToDelete] =
    useState<AwardsSponsorResponse | null>(null);
  const [deleteSponsorModal, setDeleteSponsorModal] = useState<boolean>(false);

  const orderedSponsors = useMemo(() => {
    const _ordered = _.cloneDeep(sponsors).reverse();

    if (searchTerm) {
      return _ordered.filter((sponsor: AwardsSponsorResponse) => {
        return sponsor.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    return _ordered;
  }, [sponsors, searchTerm]);

  const columnData: ColumnDef<AwardsSponsorResponse, any>[] = useMemo(() => {
    return [
      sponsorColumnHelper.accessor("sponsor_id", {
        header: () => <div className={styles.idColumn}>ID</div>,
        size: 50,
      }),
      sponsorColumnHelper.accessor("name", {
        header: "Name",
        size: 200,
      }),
      sponsorColumnHelper.accessor("logo_bw", {
        header: "Logo",
        size: 200,
        cell: ({
          row: {
            original: { name, logo_bw },
          },
        }) => <img src={logo_bw} alt={name} />,
      }),
      sponsorColumnHelper.display({
        id: "actions",
        header: "Actions",
        size: 100,
        cell: ({ row: { original: sponsor } }) => (
          <>
            <FormButtonRow>
              <EditButton
                onClick={() => {
                  setEditSponsorModal(true);
                  setSponsorToEdit(sponsor);
                }}
              />
              <DeleteButton
                onClick={() => {
                  setDeleteSponsorModal(true);
                  setSponsorToDelete(sponsor);
                }}
              />
            </FormButtonRow>
          </>
        ),
      }),
    ];
  }, []);

  return (
    <>
      <SiteWrapper hideStickyNav>
        <Container>
          <Row>
            <Column gridCols={8}>
              <FormButtonRow className={styles.tableControls}>
                <Input
                  className={styles.tableSearch}
                  dark
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button
                  className={styles.addNewSponsorButton}
                  buttonType="primary"
                  onClick={() => setAddSponsorModal(true)}
                >
                  Add new sponsor
                </Button>
              </FormButtonRow>
              <Table
                tableClassName={styles.sponsorsTable}
                getCellClassName={(cell) =>
                  classNames({
                    [styles.idColumn]: cell.column.id === "sponsor_id",
                  })
                }
                growColumnIndex={2}
                data={orderedSponsors}
                columns={columnData}
              />
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
      {addSponsorModal && (
        <Modal
          className={styles.sponsorModal}
          onClose={() => setAddSponsorModal(false)}
        >
          <AddSponsorModal
            onUpdate={(newSponsor) => {
              setSponsors((curr) => {
                const newSponsors = _.cloneDeep(curr);

                newSponsors.push(newSponsor);

                return newSponsors;
              });

              _window.setTimeout(() => {
                setAddSponsorModal(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
      {editSponsorModal && sponsorToEdit && (
        <Modal
          className={styles.sponsorModal}
          onClose={() => setEditSponsorModal(false)}
        >
          <EditSponsorModal
            sponsorToEdit={sponsorToEdit}
            onUpdate={(updatedSponsor) => {
              setSponsors((curr) => {
                const newSponsors = _.cloneDeep(curr);

                const editIndex = newSponsors.findIndex(
                  (s) => s.sponsor_id === updatedSponsor.sponsor_id
                );

                newSponsors.splice(editIndex, 1, updatedSponsor);

                return newSponsors;
              });

              _window.setTimeout(() => {
                setEditSponsorModal(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
      {deleteSponsorModal && sponsorToDelete && (
        <Modal
          onClose={() => {
            setDeleteSponsorModal(false);
            setSponsorToDelete(null);
          }}
        >
          <DeleteSponsorModal
            sponsor={sponsorToDelete}
            onUpdate={(sponsorId) => {
              setSponsors((curr) => {
                const newSponsors = _.cloneDeep(curr);

                const deleteIndex = newSponsors.findIndex(
                  (s) => s.sponsor_id === parseInt(sponsorId)
                );

                newSponsors.splice(deleteIndex, 1);

                return newSponsors;
              });

              _window.setTimeout(() => {
                setDeleteSponsorModal(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
    </>
  );
}
