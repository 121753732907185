import React, { Dispatch, SetStateAction } from "react";
import { S3Client } from "@aws-sdk/client-s3";

import PreviousUploadsTable from "./PreviousUploadsTable";
import UploadsProgress from "./UploadsProgress";
import { AwardsEntryFileResponse } from "../../api/types/awards-entries";
import FileUploader from "../../components/file-upload";
import useMultipartUpload from "../hooks/useMultipartUpload";

interface UploaderProps {
  entry: {
    entryID: number;
    entryTitle: string;
    categoryTitle: string;
  };
  s3Client: S3Client;
  filesList: AwardsEntryFileResponse[];
  setFilesList: Dispatch<SetStateAction<AwardsEntryFileResponse[]>>;
}

export default function Uploader({
  entry,
  s3Client,
  filesList,
  setFilesList,
}: UploaderProps) {
  const {
    onDrop,
    inProgressUploads,
    abortUpload,
    suspendedUploads,
    genericError,
    loadingFile,
  } = useMultipartUpload({ entry, s3Client, filesList, setFilesList });

  return (
    <>
      <FileUploader
        copy={{
          upload: "Drop your files here (or click)",
          dragReject:
            "File must be an image (jpg, png, svg), video (mp4, mpeg, mov), or text file (PDF, doc, txt)",
        }}
        loading={loadingFile.some((file) => file)}
        accept={{
          "image/*": [".jpg", ".jpeg", ".png", ".svg"],
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "text/plain": [".txt"],
          "video/*": [
            ".mp4",
            ".mpeg",
            ".mov",
            ".ogv",
            ".avi",
            ".webm",
            ".wmv",
            ".flv",
            ".m3u8",
            ".ts",
            ".3gp",
          ],
          "audio/*": [
            ".aac",
            ".mid",
            ".midi",
            ".mp3",
            ".oga",
            ".opus",
            ".wav",
            ".weba",
          ],
        }}
        onDrop={onDrop}
      />
      {genericError.length > 0 &&
        genericError.map((error, index) => {
          return (
            <React.Fragment key={`error-${index}`}>{error}</React.Fragment>
          );
        })}
      {inProgressUploads.length > 0 && (
        <UploadsProgress
          inProgressUploads={inProgressUploads}
          abortUpload={abortUpload}
          suspendedUploads={suspendedUploads}
        />
      )}

      {filesList && filesList.length > 0 && (
        <PreviousUploadsTable
          entry={entry}
          s3Client={s3Client}
          filesList={filesList}
          setFilesList={setFilesList}
        />
      )}
    </>
  );
}
