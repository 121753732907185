import React, { Dispatch, SetStateAction, useState } from "react";
import { S3Client } from "@aws-sdk/client-s3";
import moment from "moment";

import { deleteEntryFile } from "../../api/awards-entry-files";
import { AwardsEntryFileResponse } from "../../api/types/awards-entries";
import Button from "../../components/button";
import { FormButtonRow } from "../../components/form";
import { Modal } from "../../components/modal";
import deleteIcon from "../../assets/svgs/delete.svg";
import * as styles from "../../styles/previous-uploads.module.scss";
import { formatBytes, truncateString } from "../../utils";

interface EntryFileRowProps {
  fileId: number;
  fileName: string;
  size: number;
  uploadDate: Date;
  setDeleteFileId: Dispatch<SetStateAction<number | null>>;
}

const EntryFileRow = ({
  fileId,
  fileName,
  size,
  uploadDate,
  setDeleteFileId,
}: EntryFileRowProps) => {
  const fullDate = new Date(uploadDate);

  return (
    <tr>
      <td className={styles.fileName}>
        <p>{truncateString(fileName, 50)}</p>
      </td>
      <td className={styles.fileSize}>
        <p>{formatBytes(size, 2)}</p>
      </td>
      <td className={styles.dateUploaded}>
        <p>{moment(fullDate).format("Do MMM 'YY")}</p>
      </td>
      <td>
        <Button
          buttonType="icon"
          className={styles.iconBtn}
          onClick={() => setDeleteFileId(fileId)}
        >
          <img src={deleteIcon} alt="Delete entry" />
        </Button>
      </td>
    </tr>
  );
};

interface PreviousUploadsTableProps {
  entry: {
    entryID: number;
    entryTitle: string;
    categoryTitle: string;
  };
  s3Client: S3Client;
  filesList: AwardsEntryFileResponse[];
  setFilesList: Dispatch<SetStateAction<AwardsEntryFileResponse[]>>;
}

const PreviousUploadsTable = ({
  entry,
  s3Client,
  filesList,
  setFilesList,
}: PreviousUploadsTableProps) => {
  const [deleteFileId, setDeleteFileId] = useState<number | null>(null);

  return (
    <div className={styles.previousUploads}>
      <h4>Previous uploads</h4>
      <div className={styles.prevUploadsTable}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Size</th>
              <th>Date uploaded</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filesList.map(
              ({ entry_file_id, file_name, file_size, created_at }) => {
                return (
                  <EntryFileRow
                    key={entry_file_id}
                    fileId={entry_file_id}
                    fileName={file_name || "fileName"}
                    size={file_size || 0}
                    uploadDate={new Date(created_at!)}
                    setDeleteFileId={setDeleteFileId}
                  />
                );
              }
            )}
          </tbody>
        </table>
      </div>

      {deleteFileId && (
        <Modal
          className={styles.deleteModal}
          onClose={() => setDeleteFileId(null)}
        >
          <>
            <h3>Are you sure?</h3>
            <p>You cannot recover this file once it is deleted.</p>
            <FormButtonRow className={styles.deleteButtons}>
              <Button
                buttonType="tertiary"
                onClick={() => setDeleteFileId(null)}
              >
                No, keep it
              </Button>
              <Button
                className={styles.deleteButton}
                buttonType="primary"
                onClick={async () => {
                  try {
                    await deleteEntryFile(
                      entry.entryID.toString(),
                      deleteFileId
                    );

                    setFilesList((curr) => {
                      const newFiles = [...curr];

                      const fileIndex = newFiles.findIndex(
                        ({ entry_file_id }) => entry_file_id === deleteFileId
                      );

                      if (fileIndex > -1) {
                        newFiles.splice(fileIndex, 1);
                      }

                      return newFiles;
                    });

                    setDeleteFileId(null);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                Yes, delete
              </Button>
            </FormButtonRow>
          </>
        </Modal>
      )}
    </div>
  );
};

export default PreviousUploadsTable;
