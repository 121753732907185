import React from "react";
import { nanoid } from "nanoid";

import Button from "./button";
import {
  EntrantBillingDetails,
  billingDetailsMapper,
} from "../api/types/accounts";
import * as styles from "../styles/address-summary.module.scss";

interface BillingAddressSummaryProps {
  billingDetails: EntrantBillingDetails | null;
}

export const BillingAddressSummary = ({
  billingDetails,
}: BillingAddressSummaryProps) => {
  if (!billingDetails) {
    return null;
  }

  return (
    <>
      <h2>Billing details</h2>
      <table className={styles.table}>
        <tbody>
          {Object.entries(billingDetails).map(([key, value]) => {
            return (
              <tr key={nanoid()}>
                <td className={styles.fieldName}>
                  <span>
                    {billingDetailsMapper[key as keyof EntrantBillingDetails]}
                  </span>
                </td>
                <td className={styles.fieldValue}>
                  {value || (
                    <span className={styles.noValue}>
                      <em>Not provided</em>
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
          <tr>
            <td className={styles.fieldName} />
            <td className={styles.fieldValue}>
              <Button buttonType="link" href="/billing">
                Edit billing address
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
