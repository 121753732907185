import React, { useContext, useMemo } from "react";
import { nanoid } from "nanoid";

import Button from "../components/button";
import { FormError } from "../components/form";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { JudgesContext } from "../context/judges";
import { AwardsContext } from "../context/awards";
import { supportEmail } from "../core/variables";
import { AccountContext } from "../context/account";
import * as styles from "../styles/JudgingPage.module.scss";
import {
  isAfterShortlistDate,
  isBeforeShortlistDate,
  isChair,
  isFirstRoundJudge,
  isJudge,
  isSecondRoundJudge,
} from "../utils";

export default function JudgingPage() {
  const { isJudgeCurrentYear, currentJudge, currentEntries } =
    useContext(JudgesContext);

  const { currentYear } = useContext(AwardsContext);
  const { account } = useContext(AccountContext);

  const showCategories = Object.keys(currentEntries);

  const showJudgingPage = useMemo(() => {
    if (account && currentYear && currentJudge) {
      if (isJudge(account)) {
        if (isChair(currentJudge)) {
          return true;
        }
        if (
          isFirstRoundJudge(currentJudge) &&
          isBeforeShortlistDate(currentYear)
        ) {
          return true;
        }
        if (
          isSecondRoundJudge(currentJudge) &&
          isAfterShortlistDate(currentYear)
        ) {
          return true;
        }
      }
    }
    return false;
  }, [account, currentYear, currentJudge]);

  if (!showJudgingPage || !isJudgeCurrentYear) {
    return (
      <SiteWrapper>
        <Container>
          <Row>
            <Column gridCols={6}>
              <h1>Judging</h1>
              <FormError>
                Sorry, but only Grierson judges can see this page...
              </FormError>
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <Container>
        <Row>
          <Column gridCols={6}>
            <h1>Judging</h1>
            <p>
              Thank you for agreeing to be a Reviewer or Juror for the{" "}
              <strong className={styles.blue}>
                {showCategories.join(", ")}
              </strong>{" "}
              category in Grierson British Documentary Awards{" "}
              {currentYear?.year}.
            </p>
            <p>
              All of the information on judging (whether reviewing or being part
              of the jury) is available in the Reviewer or Juror Briefing Pack
              sent to you via email.
            </p>

            <p>
              All of the films in your category are listed in alphabetical order
              below, with the run time included at the end of each title, to
              help you prioritise viewing.
            </p>

            <p>
              To see an entry summary page and film, click the film title in the
              list below. You will then be taken to a page where you will see
              the film file(s), plus the entry information including title,
              synopses, diversity and inclusion statements (for all categories
              aside from Best Student Documentary) and more.
            </p>
            <p>
              You can stream each video or download them to your device. We ask
              that once you have watched any downloaded materials, you
              permanently delete the files.
            </p>
            <p>
              For Best Documentary Series, please remember that you only need to
              watch one episode at the reviewing stage. The entry information
              will specify which episode you need to watch.
            </p>
            <p>
              If you need help, please contact us at{" "}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
            </p>
            <p>Thank you for being a Reviewer/Juror.</p>
          </Column>
        </Row>
        <Row>
          <Column gridCols={6}>
            {showCategories.map((cat) => {
              const useEntries = currentEntries[cat].sort((a, b) => {
                const { title: titleA } = a;
                const { title: titleB } = b;
                if (titleA < titleB) return -1;
                if (titleA > titleB) return 1;
                return 0;
              });

              return (
                <React.Fragment key={nanoid()}>
                  <h4>{cat}</h4>
                  <ol>
                    {useEntries.map((entry) => {
                      const { title, entry_id, entry_form_field_values } =
                        entry;
                      let h = 0;
                      let m = 0;

                      if (
                        entry_form_field_values &&
                        Array.isArray(entry_form_field_values)
                      ) {
                        const found = entry_form_field_values.find((field) => {
                          const { fieldId } = field;
                          return fieldId === "L6ngFbuDfHROt-r0sM7ro";
                        });

                        h = Math.floor(
                          found.value / 60 < 1 ? 0 : found.value / 60
                        );
                        m = Math.floor(found.value % 60 || 0);
                      }

                      return (
                        <li key={nanoid()}>
                          <Button
                            buttonType="link"
                            href={`/view-entry-judge/${entry_id}`}
                            style={{ display: "block" }}
                          >
                            {title}
                            {h || m
                              ? ` - ${
                                  h > 0 ? `${h}hr${h === 1 ? "" : "s"} ` : ""
                                }${m > 0 ? `${m}min${m === 1 ? "" : "s"}` : ""}`
                              : ""}
                          </Button>
                        </li>
                      );
                    })}
                  </ol>
                </React.Fragment>
              );
            })}
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
