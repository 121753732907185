import React, { useMemo, useContext, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";

import { EntrantBillingDetails } from "../api/types/accounts";
import Button from "../components/button";
import Breadcrumbs from "../components/CheckoutBreadcrumbs";
import { FormButtonRow } from "../components/form";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { Divider } from "../components/typeface";
import { BillingAddressSummary } from "../components/AddressSummary";
import { EntrySummary } from "../components/EntrySummary";
import { EntriesContext } from "../context/entries";
import { AccountContext } from "../context/account";
import { AwardsContext } from "../context/awards";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/summary-page.module.scss";

interface SummaryPageProps extends RouteComponentProps {}

export default function SummaryPage({}: SummaryPageProps) {
  const { account } = useContext(AccountContext);
  const { noNewEntries } = useContext(AwardsContext);
  const { entriesToSubmit, setEntriesToSubmit, setCurrentEntry } =
    useContext(EntriesContext);

  const billingAddressSummary: EntrantBillingDetails | null = useMemo(() => {
    if (!account || !account.uid || !account.checked) return null;

    // Page should only be visible to valid entrants
    if (!account.entrant_id) {
      navigate("/dashboard", { replace: true });

      return null;
    }

    if (!account.entrantDetails) {
      return null;
    }

    const {
      entrantDetails: { same_billing_details },
    } = account;

    if (same_billing_details !== false) {
      const {
        first_name,
        last_name,
        email,
        entrantDetails: {
          address_1,
          address_2,
          address_3,
          town_city,
          postcode,
          country,
          phone,
        },
      } = account;

      return {
        billing_first_name: first_name || undefined,
        billing_last_name: last_name || undefined,
        billing_email: email || undefined,
        billing_address_1: address_1,
        billing_address_2: address_2,
        billing_address_3: address_3,
        billing_town_city: town_city,
        billing_postcode: postcode,
        billing_country: country,
        billing_phone: phone,
      };
    }

    const {
      entrantDetails: {
        billing_address_1,
        billing_address_2,
        billing_address_3,
        billing_country,
        billing_email,
        billing_first_name,
        billing_last_name,
        billing_phone,
        billing_postcode,
        billing_town_city,
      },
    } = account;

    return {
      billing_address_1,
      billing_address_2,
      billing_address_3,
      billing_country,
      billing_email,
      billing_first_name,
      billing_last_name,
      billing_phone,
      billing_postcode,
      billing_town_city,
    };
  }, [account]);

  const _window = useWindow();

  // Preserve entries to submit on page refresh for saved/duplicate entries
  useEffect(() => {
    if (!_window.sessionStorage) return;

    const storedEntries = _window.sessionStorage.getItem("entriesToSubmit");

    // Reset entries from session storage
    if ((!entriesToSubmit || entriesToSubmit.length === 0) && storedEntries) {
      setEntriesToSubmit(JSON.parse(storedEntries));
      return;
    }

    // Redirect if no valid entries to pay for
    // Includes trying to pay for entries that are already submitted/paid
    if (
      !entriesToSubmit ||
      entriesToSubmit.length === 0 ||
      !!entriesToSubmit.find(
        (entry) => entry.is_submitted || entry.is_paid || entry.invoice_sent
      )
    ) {
      _window.sessionStorage.removeItem("entriesToSubmit");
      navigate("/dashboard", { replace: true });
    }
  }, [_window, entriesToSubmit]);

  // Check if within the entry period
  // If not, redirect to dashboard
  useEffect(() => {
    if (noNewEntries === null || !_window.sessionStorage) return;

    // Should not be possible to submit entries if outside entry period
    if (noNewEntries) {
      _window.sessionStorage.removeItem("entriesToSubmit");
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [_window, noNewEntries]);

  return (
    <SiteWrapper hideStickyNav>
      <Container>
        <Row>
          <Breadcrumbs step={0} />
          <Column gridCols={6}>
            <h1>Entries Summary</h1>
            <p>
              You are about to submit the following{" "}
              {entriesToSubmit && entriesToSubmit.length === 1
                ? "entry"
                : "entries"}
              :
            </p>
            {entriesToSubmit &&
              entriesToSubmit.map((entry) => (
                <React.Fragment key={entry.entry_id}>
                  <h3>Category: {entry.category_title}</h3>
                  <EntrySummary entry={entry}>
                    <Button
                      className={styles.editButton}
                      buttonType="link"
                      onClick={() => {
                        setCurrentEntry(entry);

                        _window.sessionStorage?.setItem(
                          "currentEntry",
                          JSON.stringify(entry)
                        );

                        navigate(`/edit-entry/${entry.entry_id}`);
                      }}
                    >
                      Edit
                    </Button>
                  </EntrySummary>
                  <Divider />
                </React.Fragment>
              ))}
            <BillingAddressSummary billingDetails={billingAddressSummary} />
            <Divider />
            <FormButtonRow>
              <Button buttonType="tertiary" href="/dashboard">
                Cancel
              </Button>
              <Button
                buttonType="primary"
                onClick={() =>
                  navigate("/checkout", {
                    state: {
                      fromReviewPage: true,
                    },
                  })
                }
              >
                Next
              </Button>
            </FormButtonRow>
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
