import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import _ from "lodash";
import numeral from "numeral";

import Button from "./button";
import EntryFormSecondaryFields from "./EntryFormSecondaryFields";
import { Form, FormButtonRow } from "./form";
import Input, { Checkbox, TextArea } from "./input";
import { Divider, StyledMarkdown } from "./typeface";
import {
  AwardsEntryResponseLimited,
  NewEntry,
  isNewEntry,
} from "../api/types/awards-entries";
import { addAwardsEntry, updateEntryByID } from "../api/awards-entries";
import { AccountContext } from "../context/account";
import useWindow from "../hooks/useWindow";
import { AwardsCategoryResponseFull } from "../api/types/awards-categories";
import { getAwardsCategory } from "../api/awards-categories";
import { AwardsSponsorResponse } from "../api/types/awards-sponsors";
import { getCategorySponsor } from "../api/awards-sponsors";
import * as styles from "../styles/EntryForm.module.scss";
import { commonCategoryFields } from "../core/variables";
import {
  entryFormHasErrors,
  initialiseSecondaryFieldValues,
  mergeSecondaryFieldValues,
} from "../utils/entries ";
import { EntryFormFieldValue } from "../api/types/awards-category-fields";
import { isValidEmail } from "../utils";

interface LocationState {
  duplicateCategory?: string;
}

interface EntryFormProps {
  selectedCategory: string;
  entry: NewEntry | AwardsEntryResponseLimited | null;
  setEntry: Dispatch<
    SetStateAction<NewEntry | AwardsEntryResponseLimited | null>
  >;
  isDuplicateEntry?: boolean;
}

export default function EntryForm({
  selectedCategory,
  entry,
  setEntry,
  isDuplicateEntry = false,
}: EntryFormProps) {
  const {
    account: { uid, entrant_id },
  } = useContext(AccountContext);

  const _window = useWindow();

  // Used to pass entry category data
  const state = useLocation().state as LocationState;

  const formRef = useRef<HTMLFormElement>(null);

  const [fullCategory, setFullCategory] =
    useState<AwardsCategoryResponseFull | null>(null);
  const [entryHasBeenCopied, setEntryHasBeenCopied] = useState(false);
  const [sponsor, setSponsor] = useState<AwardsSponsorResponse | null>(null);
  const [includeSecondaryContact, setIncludeSecondaryContact] = useState(
    !!entry?.secondary_contact_first_name ||
      !!entry?.secondary_contact_last_name ||
      !!entry?.secondary_contact_email
  );
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);

  // Fetch full category details
  useEffect(() => {
    const fetchCategories = async (categoryId: number) => {
      const { category } = await getAwardsCategory(categoryId);

      setFullCategory({ ...category });
    };

    if (selectedCategory) {
      fetchCategories(parseInt(selectedCategory));
    }
  }, [selectedCategory]);

  // Get sponsor if any
  useEffect(() => {
    const fetchSponsor = async (sponsorId: number) => {
      const { sponsor: _sponsor } = await getCategorySponsor(sponsorId);

      setSponsor(_sponsor);
    };

    if (fullCategory?.sponsor_id) {
      fetchSponsor(fullCategory.sponsor_id);
    }
  }, [fullCategory?.sponsor_id]);

  // Build the current entry fields if not already built from saved entries in DB
  useEffect(() => {
    if (!_window.sessionStorage || !entrant_id || !fullCategory) return;

    // If entry is being duplicated from another category, merge the secondary input fields
    if (!entryHasBeenCopied && entry && state && !!state.duplicateCategory) {
      const duplicatedCurrentEntry: NewEntry = {
        ...entry,
        awards_category_id: fullCategory.awards_category_id,
      };

      // Merge secondary fields
      if (fullCategory.entry_form_fields) {
        duplicatedCurrentEntry.entry_form_field_values =
          mergeSecondaryFieldValues(
            fullCategory.entry_form_fields,
            entry.entry_form_field_values
          );
      }

      setEntry(duplicatedCurrentEntry);

      _window.sessionStorage.setItem(
        "currentEntry",
        JSON.stringify(duplicatedCurrentEntry)
      );

      setEntryHasBeenCopied(true);
      setHasBeenUpdated(true);

      return;
    }

    // Entry is already built from saved entry in DB
    if (entry) {
      return;
    }

    // Brand new entry: initialise
    const initialisedCurrentEntry: NewEntry | AwardsEntryResponseLimited = {
      awards_category_id: fullCategory.awards_category_id,
      year: fullCategory.year,
      entrant_id,
    };

    // Initialise secondary fields
    if (fullCategory.entry_form_fields) {
      initialisedCurrentEntry.entry_form_field_values =
        initialiseSecondaryFieldValues(fullCategory.entry_form_fields);
    }

    setEntry(initialisedCurrentEntry);

    _window.sessionStorage.setItem(
      "currentEntry",
      JSON.stringify(initialisedCurrentEntry)
    );
  }, [_window, entrant_id, fullCategory, entry, state, entryHasBeenCopied]);

  // Updates the context value for the current entry
  // On save, the context value can be passed as a whole to the DB
  const updateEntry = useCallback(
    (
      field: string,
      value: EntryFormFieldValue["value"],
      secondaryOptions?: {
        salesforceFieldId?: string;
        fieldTitle?: string;
        required?: boolean;
        valueName?: string;
        otherValue?: string;
        salesforceOtherFieldId?: string;
        parentField?: EntryFormFieldValue["parentField"];
        formNotUpdated?: boolean;
      }
    ) => {
      setSaveError(null);

      setEntry((curr) => {
        const newObj = _.cloneDeep(curr);

        // Add common fields
        if (commonCategoryFields.includes(field)) {
          // @ts-ignore
          newObj![field] = value;
        } else {
          // Add/update secondary field value
          const existingSecondaryFields = newObj!.entry_form_field_values
            ? [...newObj!.entry_form_field_values]
            : [];

          // Check if the form already has a value for the provided field
          const existingValueIndex = existingSecondaryFields.findIndex(
            (f) => f.fieldId === field
          );

          if (existingValueIndex === -1) {
            // Set new value
            newObj!.entry_form_field_values = existingSecondaryFields.concat({
              fieldId: field,
              salesforceFieldId: secondaryOptions?.salesforceFieldId,
              salesforceOtherFieldId: secondaryOptions?.salesforceOtherFieldId,
              fieldTitle: secondaryOptions?.fieldTitle,
              required: secondaryOptions?.required,
              value,
              valueName: secondaryOptions?.valueName,
              otherValue: secondaryOptions?.otherValue,
              parentField: secondaryOptions?.parentField,
            });
          } else {
            // Replace existing value
            existingSecondaryFields.splice(existingValueIndex, 1, {
              fieldId: field,
              salesforceFieldId: secondaryOptions?.salesforceFieldId,
              salesforceOtherFieldId: secondaryOptions?.salesforceOtherFieldId,
              fieldTitle: secondaryOptions?.fieldTitle,
              required: secondaryOptions?.required,
              value,
              valueName: secondaryOptions?.valueName,
              otherValue: secondaryOptions?.otherValue,
              parentField: secondaryOptions?.parentField,
            });

            newObj!.entry_form_field_values = existingSecondaryFields;
          }
        }

        // Update current entry in local storage
        _window.sessionStorage?.setItem("currentEntry", JSON.stringify(newObj));

        return newObj;
      });

      if (!secondaryOptions || !secondaryOptions.formNotUpdated) {
        setHasBeenUpdated(true);
      }
    },
    [_window]
  );

  const onSave = useCallback(
    async (entryToSave: NewEntry | AwardsEntryResponseLimited) => {
      if (!_window.sessionStorage) return;

      if (!isDuplicateEntry && !hasBeenUpdated) {
        setSaveError("Please update the entry.");
        return;
      }

      const erroredField = entryFormHasErrors(entryToSave);

      if (erroredField) {
        setSaveError("Form contains errors.");

        if (formRef.current) {
          formRef.current
            .querySelector(`#${erroredField}`)
            ?.scrollIntoView({ behavior: "smooth" });
        }

        return;
      }

      try {
        // Remove secondary contact info if the checkbox is unchecked
        if (!includeSecondaryContact) {
          entryToSave.secondary_contact_first_name = "";
          entryToSave.secondary_contact_last_name = "";
          entryToSave.secondary_contact_email = "";
        }

        if (isNewEntry(entryToSave)) {
          const { newEntry } = await addAwardsEntry(entryToSave);

          // savedEntries is reset on dashboard page, so navigate there
          if (newEntry) {
            _window.sessionStorage.removeItem("currentEntry");
            navigate("/dashboard");
          }
        } else {
          const { updatedEntry } = await updateEntryByID(
            // @ts-ignore
            entryToSave.entry_id,
            entryToSave
          );

          // savedEntries is reset on dashboard page, so navigate there
          if (updatedEntry) {
            _window.sessionStorage.removeItem("currentEntry");
            navigate("/dashboard");
          }
        }
      } catch (err: any) {
        setSaveError(err.message || "Failed to save entry.");
      }
    },
    [
      _window,
      isDuplicateEntry,
      hasBeenUpdated,
      formRef,
      includeSecondaryContact,
    ]
  );

  // Accounts that have not completed at least of of their profile should not see this page
  if (uid && !entrant_id) {
    navigate("/");
  }

  if (!fullCategory) {
    return null;
  }

  const { title, description, entry_cost, footnote } = fullCategory;

  return (
    <div className={styles.entryFormContainer}>
      {sponsor && (
        <div className={styles.sponsorImgContainer}>
          <img src={sponsor.logo_bw} alt={sponsor.name} />
        </div>
      )}
      <h1>{title}</h1>
      {(!!description || !!entry_cost || !!footnote) && <Divider />}
      {description && <p>{description}</p>}
      {entry_cost && (
        <p>
          <span style={{ fontWeight: "bold" }}>Entry fee:</span> £
          {numeral(entry_cost).format("£0,0[.]00")}
        </p>
      )}
      {footnote && (
        <p className={styles.footnote}>
          <span style={{ fontWeight: "bold" }}>Note:</span> {footnote}
        </p>
      )}
      <Divider />
      <p>
        Please ensure that you complete all sections fully. Fields marked{" "}
        <span className={styles.required}>*</span> are required.
      </p>
      <p>You can save the form and continue next time you log in.</p>
      {!entry ? (
        <p className={styles.error}>There was an issue fetching the form.</p>
      ) : (
        <>
          <Form
            ref={formRef}
            onSubmit={() => onSave(entry)}
            className={styles.form}
          >
            {/* Entry title */}
            <Input
              id="title"
              name="title"
              stacked
              required
              label="Entry Title"
              placeholder="Enter a value..."
              value={entry.title}
              onChange={(e) => updateEntry("title", e.target.value)}
            >
              <div className={styles.introMsg}>
                <p>
                  Please provide the name of the film or series you are
                  entering.
                </p>
                <ul>
                  <li>
                    If you are entering a single episode from a series, please
                    include the series and episode name, for example Once Upon a
                    Time in Iraq - Episode 2.
                  </li>
                  <li>
                    If you are entering the Best Documentary Series or Best
                    Returning Documentary Series categories, please provide the
                    name of the series (episode details will come later in the
                    form).
                  </li>
                  <li>
                    If you are entering the Best Documentary Presenter category,
                    please enter the presenter's name and the programme title,
                    for example Hannah Fry for Making Sense of Cancer with
                    Hannah Fry.
                  </li>
                </ul>
              </div>
            </Input>
            <Divider />
            {/* Description */}
            <TextArea
              id="description"
              name="description"
              stacked
              required
              label="Short Synopsis/Billing"
              minWords={15}
              maxWords={75}
              introMsg="(maximum 75 words)"
              resize
              placeholder="Provide a short description for the entry"
              value={entry.description}
              onChange={(e) => updateEntry("description", e.target.value)}
            >
              <StyledMarkdown className={styles.introMsg}>
                Please provide a short synopsis for the entry (if entering a
                Series category, please provide the short synopsis for the whole
                series, single episode synopses will come later in the form).
              </StyledMarkdown>
            </TextArea>
            {/* Secondary fields */}
            <EntryFormSecondaryFields
              entry={entry}
              secondaryFields={fullCategory.entry_form_fields}
              updateForm={(field, value, secondaryOptions) =>
                updateEntry(field, value, secondaryOptions)
              }
            />
            <Divider />
            {/* Secondary contact */}
            <h4>Secondary Contact</h4>
            <Checkbox
              id="include-secondary-contact"
              name="include-secondary-contact"
              label="Include a secondary contact"
              introMsg="You have the option to add a second contact for this entry. This contact will be able to edit the entry form and upload video files for the entry from their own account."
              checked={includeSecondaryContact}
              onChange={(e) => {
                setIncludeSecondaryContact(e.target.checked);
                setHasBeenUpdated(true);

                if (!e.target.checked) {
                  updateEntry("secondary_contact_email", "");
                }
              }}
            />
            {includeSecondaryContact && (
              <div className={styles.secondaryContactFields}>
                <Input
                  id="secondary_contact_first_name"
                  name="secondary_contact_first_name"
                  required
                  label="First name"
                  placeholder="Enter a value..."
                  value={entry.secondary_contact_first_name}
                  onChange={(e) =>
                    updateEntry("secondary_contact_first_name", e.target.value)
                  }
                />
                <Input
                  id="secondary_contact_last_name"
                  name="secondary_contact_last_name"
                  required
                  label="Last name"
                  placeholder="Enter a value..."
                  value={entry.secondary_contact_last_name}
                  onChange={(e) =>
                    updateEntry("secondary_contact_last_name", e.target.value)
                  }
                />
                <Input
                  id="secondary_contact_email"
                  name="secondary_contact_email"
                  required
                  label="Email"
                  placeholder="Enter a value..."
                  value={entry.secondary_contact_email}
                  onChange={(e) =>
                    updateEntry("secondary_contact_email", e.target.value)
                  }
                  error={
                    saveError === "Form contains errors." &&
                    !!entry.secondary_contact_email &&
                    !isValidEmail(entry.secondary_contact_email)
                  }
                  errorMsg="Please enter a valid email address"
                />
              </div>
            )}
            {/* British Council screening */}
            {/* // ! No longer used as of 2025 */}
            {/* <Divider />
            <Checkbox
              id="british_council_screening_accepted"
              name="british_council_screening_accepted"
              label="If you are shortlisted but DO NOT wish to be considered for this screening initiative, please tick here."
              introMsg={britishCouncilScreeningMessage}
              checked={
                typeof entry.british_council_screening_accepted === "boolean"
                  ? !entry.british_council_screening_accepted
                  : false
              }
              onChange={(e) =>
                updateEntry(
                  "british_council_screening_accepted",
                  !e.target.checked
                )
              }
            /> */}
            <FormButtonRow>
              <Button
                type="submit"
                buttonType="primary"
                softDisable={
                  (!isDuplicateEntry && !hasBeenUpdated) || !!saveError
                }
                disabled={!entry}
              >
                Save
              </Button>
            </FormButtonRow>
            {!!saveError && <p className={styles.saveError}>{saveError}</p>}
          </Form>
        </>
      )}
    </div>
  );
}
