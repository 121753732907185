import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { useParams } from "@reach/router";

import Button from "../components/button";
import EntryForm from "../components/EntryForm";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { EntriesContext } from "../context/entries";
import useWindow from "../hooks/useWindow";
import { AwardsEntryResponseLimited } from "../api/types/awards-entries";
import { AwardsContext } from "../context/awards";

const NewEntryPage = () => {
  const { categoryID } = useParams();

  const { currentEntry, setCurrentEntry } = useContext(EntriesContext);
  const { noNewEntries } = useContext(AwardsContext);

  const _window = useWindow();

  // Preserve current entry on page refresh for saved/duplicate entries
  useEffect(() => {
    const storageEntry = _window.sessionStorage?.getItem("currentEntry");

    if (!currentEntry && storageEntry) {
      const storedEntryObj = JSON.parse(
        storageEntry
      ) as AwardsEntryResponseLimited;
      // Should not be possible to edit already submitted entries
      if (storedEntryObj.is_submitted) return;
      setCurrentEntry(storedEntryObj);
    }
  }, [_window]);

  // Check if within the entry period
  // If not, redirect to dashboard
  useEffect(() => {
    if (noNewEntries === null || !_window.sessionStorage) return;

    // Should not be possible to submit entries if outside entry period
    if (noNewEntries) {
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [_window, noNewEntries]);

  return (
    <SiteWrapper>
      <Container>
        <Row>
          <Column gridCols={6}>
            <div style={{ marginBottom: 16 }}>
              <Button onClick={() => navigate(-1)}>&lt; Back</Button>
            </div>
            <EntryForm
              selectedCategory={categoryID}
              entry={currentEntry}
              setEntry={setCurrentEntry}
            />
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
};

export default NewEntryPage;
