import { AxiosResponse } from "axios";

import { endpoint } from ".";
import {
  EntrantResponse,
  EntrantResponseFull,
  EntrantSeed,
} from "./types/accounts";
import { AwardsEntryMinimal } from "./types/awards-entries";
import { AwardsEntrantsQueryParams } from "./types/awards-years";

const basePath = "/awards/entrants";

export const getAwardsEntrants = async (
  queryParams?: AwardsEntrantsQueryParams
) => {
  try {
    const { year, limit, page, sort, sortOrder, search } = queryParams || {};

    let queryString = limit ? `limit=${limit}` : "limit=1000";

    if (year) queryString += `&year=${year}`;
    if (page) queryString += `&page=${page}`;
    if (sort) queryString += `&sort=${sort}`;
    if (sortOrder) queryString += `&sortOrder=${sortOrder}`;
    if (search) queryString += `&search=${search}`;

    const res: AxiosResponse<{
      entrants: EntrantResponse[];
      totalEntrants: number;
      totalEntrantsWithEntries: number;
    }> = await endpoint.get(`/awards/entrants?${queryString}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getEntrantDetails = async (entrantId: number) => {
  try {
    const res: AxiosResponse<{ entrant: EntrantResponseFull }> =
      await endpoint.get(`${basePath}/${entrantId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const addEntrant = async (entrantDetails: Omit<EntrantSeed, "uid">) => {
  try {
    const res: AxiosResponse<{ entrant: EntrantResponseFull }> =
      await endpoint.post(basePath, entrantDetails);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateEntrant = async (
  entrantId: number,
  entrantDetails: Omit<EntrantSeed, "uid">
) => {
  try {
    const res: AxiosResponse<{
      updatedEntrant: EntrantResponseFull;
    }> = await endpoint.post(`${basePath}/${entrantId}`, entrantDetails);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getEntrantEntries = async (entrantId: number, year?: number) => {
  try {
    const res: AxiosResponse<{ entries: AwardsEntryMinimal[] }> =
      await endpoint.get(
        `${basePath}/${entrantId}/entries${year ? `?year=${year}` : ""}`
      );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const extendEntrantDeadline = async (entrantId: number) => {
  try {
    const res: AxiosResponse<{ entries: AwardsEntryMinimal[] }> =
      await endpoint.post(`${basePath}/${entrantId}/extend-deadline`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
