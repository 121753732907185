import React from "react";

import Button from "./button";
import * as styles from "../styles/HowToEnter.module.scss";

export default function TsAndCs() {
  return (
    <div className={styles.howToEnterModal}>
      <h2>Terms &amp; Conditions</h2>
      <ul>
        <li>
          The decisions of the Grierson Awards organisers, reviewers and judges
          are final.
        </li>
        <li>
          The organisers, reviewers and judges reserve the right: to move an
          entry into another category when they feel that it would be judged
          more appropriately there; not to make an award in any given category;
          and not to enter into correspondence with entrants on any subject.
        </li>
        <li>
          Initial acceptance of an entry does not guarantee its eligibility in
          the category for which it is entered.
        </li>
        <li>
          The Grierson Awards welcomes entries from broadcasters, independent
          companies or individuals provided that these entries fulfil the entry
          criteria.
        </li>
        <li>
          It is the responsibility of the entrant to provide complete, accurate
          information about the entry. The Grierson Trust shall not be liable
          for errors or omissions in the entry forms nor in any subsequent
          submissions.
        </li>
        <li>
          If the entry form is inaccurate or incomplete, if the entry does not
          comply with these conditions, or if it is deemed ineligible for any
          other reason, then the entry will be disqualified. The organisers
          regret that they cannot refund the fee for any entry which is thus
          disqualified.
        </li>
        <li>
          By submitting the entry, the entrant grants and warrants that the
          entrant is entitled to grant to the Grierson Trust the rights to make
          copies of a sequence of up to two minutes' running time from the
          entry, and to include such copies and to record and use the sounds and
          images of any acceptance speech relating to the entry in any
          television broadcast, any website or any other distribution medium or
          publication in connection with the awards and/or the Grierson Trust
          including for publicity and promotional purposes, together with the
          right to authorise others to do any of the foregoing.
        </li>
        <li>
          By submitting the entry, the entrant agrees to the Grierson Trust's{" "}
          <Button
            buttonType="link"
            style={{ display: "inline-block" }}
            href="https://griersontrust.org/about-us/code-of-conduct"
          >
            code of conduct
          </Button>{" "}
          for related events.
        </li>
        <li>
          By submitting the entry, the entrant agrees that they have read and
          agree to the Grierson Trust's privacy policy.
        </li>
        <li>
          Entrants whose productions are longlisted for the second round of
          judging in the competition must be prepared to supply, within five
          working days of notification:
          <ul style={{ marginBottom: 0 }}>
            <li>
              Two rights cleared still production photographs for use in
              publicity and promotional activities in a high-resolution digital
              format such as JPG, PNG, TIFF
            </li>
            <li>
              Confirmation of credited named creatives, production companies and
              broadcasters
            </li>
            <li>Social media and other promotional information</li>
          </ul>
        </li>
        <li>
          Entrants whose productions are nominated agree to adhere to the
          Grierson Trust's event{" "}
          <Button
            buttonType="link"
            style={{ display: "inline-block" }}
            href="https://griersontrust.org/about-us/code-of-conduct"
          >
            code of conduct
          </Button>{" "}
          when attending events hosted by the Trust.
        </li>
        <li>
          Entrants whose productions are nominated for the final phase of the
          competition must be prepared to supply, within five working days of
          notification:
          <ul style={{ marginBottom: 0 }}>
            <li>Time codes for clips requested by the organisers</li>
            <li>
              Access to master tape or drives for use in the awards ceremony,
              The Grierson Trust website and promotion. Technical specifications
              for master tapes and drives will be supplied to nominated
              entrants; these tapes and drives will be made available for
              collection after the awards ceremony
            </li>
            <li>
              'Programme as Complete' or equivalent forms to assist in the
              clearance of rights for clips
            </li>
            <li>
              Entrants agree to release one digital copy of each entry, together
              with data from the entry form, to be deposited on behalf of The
              Grierson Trust for non-circulation reference
            </li>
          </ul>
        </li>
        <li>
          A complete list of longlisted entries will be published on the
          Grierson Trust website and social media channels; the final
          nominations will be published in the Grierson Awards programme, across
          social media channels and on the Grierson Trust website at
          <Button
            buttonType="link"
            style={{ display: "inline-block" }}
            href="https://griersontrust.org/"
          >
            www.griersontrust.org
          </Button>
          .
        </li>
        <li>A complete list of winners will be published on the website.</li>
      </ul>
    </div>
  );
}
