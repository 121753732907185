import React, { createContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import * as Sentry from "@sentry/gatsby";

import { isLoggedIn } from "../api/auth";
import { getAccountDetails } from "../api/account";
import { EntrantSeed } from "../api/types/accounts";
import { getEntrantDetails } from "../api/awards-entrants";

export type EntrantDetails = Omit<EntrantSeed, "uid">;

export interface AccountProps {
  loggedIn: boolean;
  checked: boolean;
  accountConfirmed?: boolean;
  accountConfirmationEmailSent?: string;
  uid?: number | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  admin_id?: number | null;
  judge_id?: number | null;
  entrant_id?: number | null;
  entrantDetails?: EntrantDetails | null;
}

const accountInitialValue: AccountProps = {
  loggedIn: false,
  checked: false,
  accountConfirmed: false,
  uid: null,
  email: null,
  first_name: null,
  last_name: null,
  admin_id: null,
  judge_id: null,
  entrant_id: null,
  entrantDetails: null,
};

interface AccountContextProps {
  account: AccountProps;
  setAccount: React.Dispatch<React.SetStateAction<AccountProps>>;
}

const initialValue: AccountContextProps = {
  account: accountInitialValue,
  setAccount: () => {},
};

export const AccountContext = createContext<AccountContextProps>(initialValue);

interface AccountProviderProps {
  children?: React.ReactNode;
}

const AccountProvider = ({ children }: AccountProviderProps) => {
  const [account, setAccount] = useState<AccountProps>(accountInitialValue);

  // On page load - check if token exists and account is logged in
  useEffect(() => {
    const checkActiveSession = async () => {
      const loggedIn = await isLoggedIn();

      setAccount((currAccount) => {
        return {
          ...currAccount,
          checked: true,
          loggedIn,
        };
      });
    };

    checkActiveSession();

    // Check still logged in every 10 minutes
    const interval = setInterval(() => {
      checkActiveSession();
    }, 1000 * 60 * 10);

    return () => clearInterval(interval);
  }, []);

  // If logged in, fetch details
  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const { account } = await getAccountDetails();

        setAccount((currAccount) => ({
          ...currAccount,
          accountConfirmed: account.is_confirmed,
          accountConfirmationEmailSent: account.account_confirmation_email_sent,
          uid: account.uid,
          email: account.email,
          first_name: account.first_name,
          last_name: account.last_name,
          admin_id: account.admin_id,
          judge_id: account.judge_id,
          entrant_id: account.entrant_id,
          entrantDetails: null,
        }));

        // For debugging in Sentry
        Sentry.setUser({
          id: account.uid,
          email: account.email,
        });
      } catch {
        console.error("Error fetching account details");

        // Clear storage to log in again
        localStorage.removeItem("token");

        navigate("/login");
      }
    };

    if (account.loggedIn && !account.uid) {
      fetchAccountDetails();
    }
  }, [account]);

  // If account is an entrant, fetch entrant details
  useEffect(() => {
    const fetchEntrantDetails = async () => {
      try {
        const { entrant } = await getEntrantDetails(
          account.entrant_id as number
        );

        setAccount((currAccount) => ({
          ...currAccount,
          entrantDetails: { ...entrant },
        }));
      } catch {
        console.error("Error fetching entrant details");
      }
    };

    if (account.entrant_id && !account.entrantDetails) {
      fetchEntrantDetails();
    }
  }, [account.entrant_id, account.entrantDetails]);

  return (
    <AccountContext.Provider
      value={{
        account,
        setAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
