import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { S3Client } from "@aws-sdk/client-s3";

import { AwardsEntryFileResponse } from "../../api/types/awards-entries";
import { deleteEntryFile } from "../../api/awards-entry-files";
import deleteObj from "../../assets/svgs/delete.svg";
import Button from "../../components/button";
import * as styles from "../../styles/uploadsProgress.module.scss";
import { initiateMpu, smallFileUpload } from "../utils/uploadHandlers";

interface OverwriteProps {
  s3Client: S3Client;
  setFilesList: Dispatch<SetStateAction<AwardsEntryFileResponse[]>>;
  entryId: string;
  fileOverwriteId?: number;
  file: File;
  smallFile?: boolean;
  size: number;
  totalChunks?: number;
  reader?: ReadableStreamDefaultReader<any>;
  setInProgressUploads: Dispatch<SetStateAction<PendingUpload[]>>;
  setError: Dispatch<SetStateAction<ReactElement[]>>;
}

export const Overwrite = ({
  s3Client,
  setFilesList,
  entryId,
  fileOverwriteId,
  file,
  smallFile = false,
  size,
  totalChunks,
  reader,
  setInProgressUploads,
  setError,
}: OverwriteProps) => {
  return (
    <>
      <span>You already have an upload named "{file.name}".</span>{" "}
      <Button
        buttonType="link"
        onClick={async () => {
          try {
            if (fileOverwriteId) {
              await deleteEntryFile(entryId, fileOverwriteId);
            }

            if (smallFile && file) {
              await smallFileUpload({
                entryId,
                file,
                size,
                setFilesList,
                s3Client,
              });
            }

            setError((current) => {
              const removedError = [...current];
              removedError.splice(
                removedError.findIndex(
                  (error) => error.toString().indexOf(file.name) > -1
                ),
                1
              );

              return removedError;
            });

            setFilesList((currEntries) => {
              const newEntries: AwardsEntryFileResponse[] = JSON.parse(
                JSON.stringify(currEntries)
              );

              const deleteIndex = newEntries.findIndex(
                (entry) => entry.file_name === file.name
              );

              newEntries.splice(deleteIndex, 1);

              return newEntries;
            });

            if (!smallFile && totalChunks && reader) {
              await initiateMpu({
                entryId,
                s3Client,
                file,
                size,
                totalChunks,
                setInProgressUploads,
                setFilesList,
                reader,
              });
            }
          } catch (err) {
            console.error(err);
          }
        }}
      >
        Overwrite
      </Button>
    </>
  );
};

interface UploaderErrorProps {
  children: ReactElement;
  setError: Dispatch<SetStateAction<ReactElement[]>>;
  fileName: string;
}

const UploaderError = ({
  children,
  setError,
  fileName,
}: UploaderErrorProps) => {
  return (
    <div className={styles.uploaderErrorMsg}>
      <Button
        buttonType="icon"
        className={styles.errorDeleteBtn}
        onClick={() => {
          setError((current) => {
            const removedError = [...current];
            removedError.splice(
              removedError.findIndex(
                (error) => error.toString().indexOf(fileName) > -1
              ),
              1
            );

            return removedError;
          });
        }}
      >
        <img src={deleteObj} alt="Delete" />
      </Button>
      <span>{children}</span>
    </div>
  );
};

export default UploaderError;
