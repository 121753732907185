// extracted by mini-css-extract-plugin
export var dateUploaded = "previous-uploads-module--dateUploaded--ec8b3";
export var deleteButton = "previous-uploads-module--deleteButton--fdbe2";
export var deleteButtons = "previous-uploads-module--deleteButtons--75983";
export var deleteModal = "previous-uploads-module--deleteModal--06b72";
export var fileName = "previous-uploads-module--fileName--0adb8";
export var fileSize = "previous-uploads-module--fileSize--130f4";
export var iconBtn = "previous-uploads-module--iconBtn--a85b2";
export var modalButtonContainer = "previous-uploads-module--modalButtonContainer--d7255";
export var modalFileContainer = "previous-uploads-module--modalFileContainer--a60ec";
export var prevUploadsTable = "previous-uploads-module--prevUploadsTable--c0bdf";
export var previousUploads = "previous-uploads-module--previousUploads--2bbaf";