import { useState, useEffect } from "react";

import { isBrowser } from "../utils";

interface DefaultWindow {
  innerWidth: number;
  innerHeight: number;
  addEventListener: () => null;
  removeEventListener: () => null;
  setTimeout: () => null;
  location: {
    href: string;
    origin: string;
    hash: string;
    search: string | null;
    reload: () => void;
  };
  sessionStorage: Storage | null;
  scrollTo: () => null;
}

const defaultWindow: DefaultWindow = {
  innerWidth: 0,
  innerHeight: 0,
  addEventListener: () => null,
  removeEventListener: () => null,
  setTimeout: () => null,
  location: {
    href:
      process.env.NODE_ENV === "development"
        ? "http://localhost:8000/"
        : "https://awards.griersontrust.org/",
    hash: "",
    origin:
      process.env.NODE_ENV === "development"
        ? "http://localhost:8000"
        : "https://awards.griersontrust.org",
    search: null,
    reload: () => null,
  },
  sessionStorage: null,
  scrollTo: () => null,
};

export default function useWindow(): Window | DefaultWindow {
  const [_window, setWindow] = useState<Window | DefaultWindow>(defaultWindow);

  useEffect(() => {
    if (isBrowser()) setWindow(window);
  }, []);

  return _window;
}
