import React, { forwardRef } from "react";
import classNames from "classnames";

import * as styles from "../styles/form.module.scss";

interface FormProps {
  className?: string;
  onSubmit?: () => void;
  children: string | React.ReactNode;
}
export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ children, className, onSubmit }, ref) => {
    return (
      <form
        ref={ref}
        className={classNames(styles.form, className)}
        onSubmit={(e) => {
          e.preventDefault();
          if (onSubmit) {
            onSubmit();
          }
        }}
      >
        {children}
      </form>
    );
  }
);

interface FormErrorProps {
  className?: string;
  children: string | React.ReactNode;
}
export function FormError({ className, children }: FormErrorProps) {
  return (
    <span className={classNames(className, styles.formErrorMsg)}>
      {children}
    </span>
  );
}

interface FormMessageProps {
  children: string | React.ReactNode;
}
export function FormMessage({ children }: FormMessageProps) {
  return <span className={styles.formMessage}>{children}</span>;
}

interface FormButtonRowProps {
  className?: string;
  children: string | React.ReactNode;
}

export function FormButtonRow({ className, children }: FormButtonRowProps) {
  // create dummy element for spacing purpose
  const chilrenLength = React.Children.count(children);

  return (
    <div className={classNames(styles.formButtonRow, className)}>
      {chilrenLength < 2 && <div />}
      {children}
    </div>
  );
}
