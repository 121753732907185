import React, { useContext, useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { useParams } from "@reach/router";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";

import {
  deleteAwardsCategory,
  getAwardsCategory,
  getAwardsCategoryEntries,
  updateAwardsCategory,
} from "../api/awards-categories";
import {
  RevealTypes,
  addNewAwardsCategory,
  getAwardsYearCategories,
  revealAwards,
  updateAwardsYearCategoryOrder,
} from "../api/awards-years";
import {
  AwardsCategoryResponseFull,
  AwardsCategorySeed,
} from "../api/types/awards-categories";
import BurgerIcon from "../components/burger-icon";
import Input, { Checkbox, TextArea } from "../components/input";
import SelectBox from "../components/select-box";
import SiteWrapper from "../components/SiteWrapper";
import { Column, Container, Row } from "../components/layout";
import { Divider } from "../components/typeface";
import { FormButtonRow, FormError, FormMessage } from "../components/form";
import Button, {
  CopyButton,
  DeleteButton,
  EditButton,
} from "../components/button";
import { AdminContext } from "../context/admin";
import { Modal } from "../components/modal";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/AdminManageCategoriesPage.module.scss";

interface UpdateAwardsCategoryModalProps {
  categoryToEdit: AwardsCategoryResponseFull;
  onUpdate: (newCategory: AwardsCategoryResponseFull) => void;
}

const UpdateAwardsCategoryModal = ({
  categoryToEdit,
  onUpdate,
}: UpdateAwardsCategoryModalProps) => {
  const { sponsors } = useContext(AdminContext);

  const [currentCategoryToEdit, setCurrentCategoryToEdit] = useState<
    AwardsCategoryResponseFull & { hasBeenUpdated: boolean }
  >({ ...categoryToEdit, hasBeenUpdated: false });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  // Fetch full category details
  useEffect(() => {
    const fetchCategory = async (categoryId: number) => {
      const { category } = await getAwardsCategory(categoryId);

      // Merge existing categories with new categories
      setCurrentCategoryToEdit((curr) => {
        if (!curr)
          return {
            ...category,
            hasBeenUpdated: false,
          };

        return {
          ...curr,
          ...category,
        };
      });
    };

    if (currentCategoryToEdit) {
      fetchCategory(currentCategoryToEdit.awards_category_id);
    }
  }, []);

  return (
    <>
      <h1>Edit category: {categoryToEdit.title}</h1>
      <p>Updates will only apply when 'Save' is clicked.</p>
      <div className={styles.form}>
        <SelectBox
          label="Sponsor"
          labelKey="name"
          valueKey="sponsor_id"
          placeholder="Select a sponsor..."
          isClearable
          options={sponsors}
          value={sponsors.find(
            (option) => option.sponsor_id === currentCategoryToEdit.sponsor_id
          )}
          onChange={(newValue) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentCategoryToEdit((curr) => {
              return {
                ...curr,
                sponsor_id: newValue?.sponsor_id || null,
                hasBeenUpdated: true,
              };
            });
          }}
        />
        <Input
          id="category-title"
          name="category-title"
          label="Title"
          value={currentCategoryToEdit.title}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentCategoryToEdit((curr) => {
              return {
                ...curr,
                title: e.target.value,
                hasBeenUpdated: true,
              };
            });
          }}
        />
        <Input
          id="category-entry-cost"
          name="category-entry-cost"
          label="Entry cost (£)"
          type="number"
          value={currentCategoryToEdit.entry_cost}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentCategoryToEdit((curr) => {
              return {
                ...curr,
                entry_cost: parseInt(e.target.value),
                hasBeenUpdated: true,
              };
            });
          }}
        />
        <TextArea
          id="category-description"
          name="category-description"
          label="Description"
          value={currentCategoryToEdit.description}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentCategoryToEdit((curr) => {
              return {
                ...curr,
                description: e.target.value,
                hasBeenUpdated: true,
              };
            });
          }}
        />
        <TextArea
          id="category-footnote"
          name="category-footnote"
          label="Footnote"
          value={currentCategoryToEdit.footnote}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentCategoryToEdit((curr) => {
              return {
                ...curr,
                footnote: e.target.value,
                hasBeenUpdated: true,
              };
            });
          }}
        />
        <FormButtonRow>
          <Button
            buttonType="primary"
            disabled={!currentCategoryToEdit.hasBeenUpdated}
            onClick={async () => {
              try {
                await updateAwardsCategory(
                  currentCategoryToEdit.awards_category_id,
                  currentCategoryToEdit
                );

                onUpdate(currentCategoryToEdit);
                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to update details.");
              }
            }}
          >
            Update
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && <FormMessage>Successfully updated.</FormMessage>}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

interface AddAwardsCategoryModalProps {
  year: number;
  onUpdate: (newCategory: AwardsCategoryResponseFull) => void;
  categoryToCopy?: AwardsCategoryResponseFull | null;
}

const AddAwardsCategoryModal = ({
  year,
  onUpdate,
  categoryToCopy,
}: AddAwardsCategoryModalProps) => {
  const { sponsors } = useContext(AdminContext);

  const [categoryToAdd, setCategoryToAdd] = useState<AwardsCategorySeed>({
    year,
    title: "",
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  // Fetch secondary fields for the category to copy
  useEffect(() => {
    const fetchCategory = async (categoryId: number, title: string) => {
      const { category } = await getAwardsCategory(categoryId);

      // Merge existing categories with new categories
      setCategoryToAdd((curr) => {
        const copiedCategory = {
          ..._.cloneDeep(category),
          awards_category_id: undefined,
          title: `Copy of ${title}`,
        };

        return copiedCategory;
      });
    };

    if (categoryToCopy) {
      fetchCategory(categoryToCopy.awards_category_id, categoryToCopy.title);
    }
  }, [categoryToCopy]);

  return (
    <>
      <h1>Add new category</h1>
      <p>You can add custom fields to the category once it's been created.</p>
      <div className={styles.form}>
        <SelectBox
          label="Sponsor"
          labelKey="name"
          valueKey="sponsor_id"
          placeholder="Select a sponsor..."
          isClearable
          options={sponsors}
          value={sponsors.find(
            (option) => option.sponsor_id === categoryToAdd.sponsor_id
          )}
          onChange={(newValue) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCategoryToAdd((curr) => {
              return {
                ...curr,
                sponsor_id: newValue?.sponsor_id || null,
              };
            });
          }}
        />
        <Input
          id="category-title"
          name="category-title"
          label="Title"
          required
          value={categoryToAdd.title}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCategoryToAdd((curr) => {
              return {
                ...curr,
                title: e.target.value,
              };
            });
          }}
        />
        <Input
          id="category-entry-cost"
          name="category-entry-cost"
          label="Entry cost (£)"
          type="number"
          value={categoryToAdd.entry_cost}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCategoryToAdd((curr) => {
              return {
                ...curr,
                entry_cost: parseInt(e.target.value),
              };
            });
          }}
        />
        <TextArea
          id="category-description"
          name="category-description"
          label="Description"
          value={categoryToAdd.description}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCategoryToAdd((curr) => {
              return {
                ...curr,
                description: e.target.value,
              };
            });
          }}
        />
        <TextArea
          id="category-footnote"
          name="category-footnote"
          label="Footnote"
          value={categoryToAdd.footnote}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCategoryToAdd((curr) => {
              return {
                ...curr,
                footnote: e.target.value,
                hasBeenUpdated: true,
              };
            });
          }}
        />
        <FormButtonRow>
          <Button
            buttonType="primary"
            disabled={!categoryToAdd.title}
            onClick={async () => {
              try {
                const { newCategory } = await addNewAwardsCategory(
                  year,
                  categoryToAdd
                );

                onUpdate(newCategory);
                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to add new category.");
              }
            }}
          >
            Add
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && (
        <FormMessage>Successfully added new category.</FormMessage>
      )}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

interface DeleteCategoryModalProps {
  category: AwardsCategoryResponseFull;
  onUpdate: (removedCategory: number) => void;
}

export const DeleteCategoryModal = ({
  category,
  onUpdate,
}: DeleteCategoryModalProps) => {
  const [categoryHasEntries, setCategoryHasEntries] = useState(false);
  const [loadingEntries, setLoadingEntries] = useState(true);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [deleteBlockerValue, setDeleteBlockerValue] = useState("");

  useEffect(() => {
    const fetchCategoryEntries = async () => {
      try {
        const { entries } = await getAwardsCategoryEntries(
          category.awards_category_id
        );

        if (Array.isArray(entries) && entries.length > 0) {
          setCategoryHasEntries(true);
        }

        setLoadingEntries(false);
      } catch (err: any) {
        setUpdateError(err.message || "Unable to fetch category entries.");
      }
    };

    fetchCategoryEntries();
  }, []);

  return (
    <>
      <h1>Delete category '{category.title}'</h1>
      <p>
        This action is irreversible. Are you sure you want to delete this
        category?
      </p>
      {categoryHasEntries && (
        <FormError>
          <p>
            <strong>Warning!</strong>
          </p>
          <p>
            This category has entries associated with it. Deleting the category
            will also delete the entries.
          </p>
          <p>Type 'permanently delete category' to confirm.</p>
        </FormError>
      )}
      <div className={styles.form}>
        {categoryHasEntries && (
          <Input
            id="delete-category"
            name="delete-category"
            placeholder="permanently delete category"
            value={deleteBlockerValue}
            onChange={(e) => setDeleteBlockerValue(e.target.value)}
          />
        )}
        <FormButtonRow>
          <Button
            buttonType="warning"
            disabled={
              loadingEntries ||
              (categoryHasEntries &&
                deleteBlockerValue !== "permanently delete category")
            }
            onClick={async () => {
              try {
                const { removedCategory } = await deleteAwardsCategory(
                  category.awards_category_id
                );

                onUpdate(removedCategory);
                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to update field.");
              }
            }}
          >
            Delete
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && (
        <FormMessage>
          Successfully delete category '{category.title}'.
        </FormMessage>
      )}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

export default function AdminManageCategoriesPage() {
  const _window = useWindow();

  const { year } = useParams();

  const { categories, setCategories } = useContext(AdminContext);

  const [categoryToEdit, setCategoryToEdit] =
    useState<AwardsCategoryResponseFull | null>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [categoryToCopy, setCategoryToCopy] =
    useState<AwardsCategoryResponseFull | null>(null);
  const [categoryToDelete, setCategoryToDelete] =
    useState<AwardsCategoryResponseFull | null>(null);
  const [showRevealModal, setShowRevealModal] = useState<{
    show: boolean;
    reveal: RevealTypes | null;
    state: boolean;
  }>({
    show: false,
    reveal: null,
    state: false,
  });
  const [updateError, setUpdateError] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  const awardsYearCategories = useMemo(() => {
    if (!categories || !categories[year]) return [];

    return categories[year];
  }, [year, categories]);

  const categorySortOrder = useMemo(() => {
    return awardsYearCategories.map(
      ({ awards_category_id }) => awards_category_id
    );
  }, [awardsYearCategories]);

  // Fetch categories for year
  useEffect(() => {
    const fetchCategories = async () => {
      const { categories: fetchedCategories } = await getAwardsYearCategories(
        year
      );

      // Merge existing categories with new categories
      setCategories((curr) => {
        if (!curr)
          return {
            [year]: fetchedCategories,
          };

        return {
          ...curr,
          [year]: fetchedCategories,
        };
      });
    };

    fetchCategories();
  }, []);

  return (
    <>
      <SiteWrapper>
        <Container>
          <Row>
            <Column gridCols={8}>
              <Button onClick={() => navigate(-1)}>&lt; Back</Button>
              <h1>Categories for {year}</h1>
              {awardsYearCategories.length > 0 ? (
                <>
                  <FormButtonRow>
                    <Checkbox
                      id="reveal-shortlist"
                      name="reveal-shortlist"
                      className={styles.revealCheckbox}
                      label="Show shortlist"
                      checked={awardsYearCategories[0].show_shortlist || false}
                      onChange={(e) => {
                        setShowRevealModal({
                          show: true,
                          reveal: "shortlist",
                          state: e.target.checked,
                        });
                      }}
                    />
                    <Checkbox
                      id="reveal-nominations"
                      name="reveal-nominations"
                      className={styles.revealCheckbox}
                      label="Show nominations"
                      checked={
                        awardsYearCategories[0].show_nominations || false
                      }
                      onChange={(e) => {
                        setShowRevealModal({
                          show: true,
                          reveal: "nominations",
                          state: e.target.checked,
                        });
                      }}
                    />
                    <Checkbox
                      id="reveal-winner"
                      name="reveal-winner"
                      className={styles.revealCheckbox}
                      label="Show winner"
                      checked={awardsYearCategories[0].show_winner || false}
                      onChange={(e) => {
                        setShowRevealModal({
                          show: true,
                          reveal: "winners",
                          state: e.target.checked,
                        });
                      }}
                    />
                  </FormButtonRow>
                  <Divider />
                  {updateSuccess && (
                    <FormMessage>
                      <p>Category order updated.</p>
                    </FormMessage>
                  )}
                  {updateError && (
                    <FormError>
                      <p>Unable to re-sort categories.</p>
                    </FormError>
                  )}
                  <DragDropContext
                    onDragEnd={async (result) => {
                      try {
                        const { destination, source, draggableId } = result;

                        if (source && destination) {
                          const newSortOrder = [...categorySortOrder];

                          newSortOrder.splice(source.index, 1);
                          newSortOrder.splice(
                            destination.index,
                            0,
                            parseInt(draggableId)
                          );

                          await updateAwardsYearCategoryOrder(year, {
                            sortOrder: newSortOrder,
                          });

                          // Replicate update in local context
                          setCategories((curr) => {
                            if (!curr) return null;

                            const newCategories = _.cloneDeep(curr);

                            newCategories[year].splice(source.index, 1);
                            newCategories[year].splice(
                              destination.index,
                              0,
                              curr[year].find(
                                ({ awards_category_id }) =>
                                  awards_category_id === parseInt(draggableId)
                              )!
                            );

                            return newCategories;
                          });
                        }
                      } catch (err: any) {
                        setUpdateError(true);
                      }
                    }}
                  >
                    <Droppable droppableId="paramSorter">
                      {(droppableProvided) => {
                        return (
                          <div
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                          >
                            {awardsYearCategories.map(
                              (awardsYearCategory, index) => {
                                return (
                                  <Draggable
                                    key={awardsYearCategory.awards_category_id}
                                    draggableId={awardsYearCategory.awards_category_id.toString()}
                                    index={index}
                                  >
                                    {(draggableProvided) => {
                                      return (
                                        <div
                                          ref={draggableProvided.innerRef}
                                          {...draggableProvided.draggableProps}
                                        >
                                          {index !== 0 && <Divider />}
                                          <FormButtonRow
                                            className={styles.fieldRow}
                                          >
                                            <BurgerIcon
                                              {...draggableProvided.dragHandleProps}
                                            />
                                            <div
                                              className={
                                                styles.fieldNameContainer
                                              }
                                            >
                                              <h3 style={{ margin: 0 }}>
                                                {awardsYearCategory.title}
                                              </h3>
                                            </div>
                                            <div className={styles.actionBtns}>
                                              <Button
                                                style={{ marginLeft: 16 }}
                                                onClick={() => {
                                                  navigate(
                                                    `/admin/manage-category-fields/${awardsYearCategory.awards_category_id}`
                                                  );
                                                }}
                                              >
                                                View/edit form fields
                                              </Button>
                                              <EditButton
                                                onClick={() => {
                                                  setCategoryToEdit(
                                                    awardsYearCategory
                                                  );
                                                  setUpdateModal(true);
                                                }}
                                              />
                                              <CopyButton
                                                onClick={() => {
                                                  setCategoryToCopy(
                                                    awardsYearCategory
                                                  );
                                                  setAddCategoryModal(true);
                                                }}
                                              />
                                              <DeleteButton
                                                onClick={() => {
                                                  setDeleteCategoryModal(true);
                                                  setCategoryToDelete(
                                                    awardsYearCategory
                                                  );
                                                }}
                                              />
                                            </div>
                                          </FormButtonRow>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              }
                            )}
                            {droppableProvided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </>
              ) : (
                <p>
                  <em>No categories found for {year}.</em>
                </p>
              )}
              <Divider />
              <FormButtonRow>
                <Button
                  buttonType="primary"
                  onClick={() => setAddCategoryModal(true)}
                >
                  Add new category
                </Button>
              </FormButtonRow>
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
      {updateModal && categoryToEdit && (
        <Modal
          onClose={() => {
            setCategoryToEdit(null);
            setUpdateModal(false);
          }}
        >
          <UpdateAwardsCategoryModal
            categoryToEdit={categoryToEdit}
            onUpdate={(newCategory) => {
              setCategories((curr) => {
                if (!curr) {
                  return {
                    [year]: [newCategory],
                  };
                }

                const categoryIndex = curr[year].findIndex(
                  (c) => c.awards_category_id === newCategory.awards_category_id
                );

                const cloned = _.cloneDeep(curr);

                cloned[year].splice(categoryIndex, 1, {
                  ...curr[categoryIndex],
                  ...newCategory,
                });

                return cloned;
              });

              _window.setTimeout(() => {
                setUpdateModal(false);
                setCategoryToEdit(null);
              }, 2000);
            }}
          />
        </Modal>
      )}
      {addCategoryModal && (
        <Modal
          onClose={() => {
            setAddCategoryModal(false);
            setCategoryToCopy(null);
          }}
        >
          <AddAwardsCategoryModal
            year={year}
            onUpdate={(newCategory) => {
              setCategories((curr) => {
                const cloned = _.cloneDeep(curr);

                cloned![year].push(newCategory);

                return cloned;
              });

              _window.setTimeout(() => {
                setAddCategoryModal(false);
                setCategoryToCopy(null);
              }, 2000);
            }}
            categoryToCopy={categoryToCopy}
          />
        </Modal>
      )}
      {deleteCategoryModal && categoryToDelete && (
        <Modal
          onClose={() => {
            setDeleteCategoryModal(false);
            setCategoryToDelete(null);
          }}
        >
          <DeleteCategoryModal
            category={categoryToDelete}
            onUpdate={(removedCategoryID) => {
              setCategories((curr) => {
                const cloned = _.cloneDeep(curr) as {
                  [year: number]: AwardsCategoryResponseFull[];
                };

                const categoryIndex = cloned[year].findIndex(
                  (c) => c.awards_category_id === removedCategoryID
                );

                cloned[year].splice(categoryIndex, 1);

                return cloned;
              });

              _window.setTimeout(() => {
                setDeleteCategoryModal(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
      {showRevealModal.show && showRevealModal.reveal && (
        <Modal
          onClose={() =>
            setShowRevealModal({
              show: false,
              reveal: null,
              state: false,
            })
          }
        >
          <>
            <h1>
              {`${showRevealModal.state ? "Reveal" : "Hide"} ${
                showRevealModal.reveal
              }`}
              ?
            </h1>
            <p>
              This will make the {showRevealModal.reveal} visible to the public.
            </p>
            <FormButtonRow>
              <Button
                buttonType="warning"
                onClick={async () => {
                  const { success } = await revealAwards(
                    year,
                    showRevealModal.reveal!
                  );

                  // Set local context to match new BE state
                  if (success) {
                    setCategories((curr) => {
                      const updatedYear = curr![year].map((c) => ({
                        ...c,
                        [`show_${
                          showRevealModal.reveal === "winners"
                            ? "winner"
                            : showRevealModal.reveal
                        }`]: showRevealModal.state,
                      }));

                      return {
                        ...curr,
                        [year]: updatedYear,
                      };
                    });
                  }

                  setShowRevealModal({
                    show: false,
                    reveal: null,
                    state: false,
                  });
                }}
              >
                Confirm
              </Button>
            </FormButtonRow>
          </>
        </Modal>
      )}
    </>
  );
}
