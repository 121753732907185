import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import addFile from "../assets/svgs/add-file.svg";
import addFileWhite from "../assets/svgs/add-file-white.svg";
import copyIcon from "../assets/svgs/copy.svg";
import deleteIcon from "../assets/svgs/delete.svg";
import editIcon from "../assets/svgs/edit.svg";
import editIconBlue from "../assets/svgs/edit-blue.svg";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/button.module.scss";

type ButtonTypes =
  | "primary"
  | "secondary"
  | "tertiary"
  | "filter"
  | "scroll"
  | "link"
  | "icon"
  | "carouselPrev"
  | "carouselNext"
  | "stickyNav"
  | "warning"
  | "copy"
  | "listItem";

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  buttonType?: ButtonTypes;
  href?: string;
  newTab?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  disabled?: boolean;
  softDisable?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Button = ({
  buttonType = "link",
  href,
  newTab,
  onClick,
  disabled,
  softDisable,
  className,
  style,
  children,
  onMouseUp,
  ...buttonProps
}: ButtonProps) => {
  const _window = useWindow();

  const buttonClassNames = classNames(className, styles[buttonType]);

  if (href) {
    const externalDomain =
      href.indexOf(_window.location.origin) === -1 && !/^[\/#]/.test(href);

    const openInNewTab = newTab || typeof newTab === "undefined";

    return externalDomain ? (
      <a
        className={classNames(buttonClassNames, {
          [styles.disabled]: disabled || softDisable,
        })}
        style={style}
        href={href}
        target={openInNewTab ? "_blank" : undefined}
        rel={openInNewTab ? "noreferrer" : undefined}
        onClick={onClick}
        onMouseUp={onMouseUp}
        {...buttonProps}
      >
        {buttonType === "primary" ? <span>{children}</span> : <>{children}</>}
      </a>
    ) : (
      <Link
        className={classNames(buttonClassNames, {
          [styles.disabled]: disabled || softDisable,
        })}
        style={style}
        to={href}
        activeClassName={styles.active}
        onClick={onClick}
        onMouseUp={onMouseUp}
        {...buttonProps}
      >
        {buttonType === "primary" ? <span>{children}</span> : <>{children}</>}
      </Link>
    );
  }

  return (
    <button
      className={classNames(buttonClassNames, {
        [styles.disabled]: disabled || softDisable,
      })}
      style={style}
      onClick={onClick}
      onMouseUp={onMouseUp}
      disabled={disabled}
      {...buttonProps}
    >
      {buttonType === "primary" ? <span>{children}</span> : <>{children}</>}
    </button>
  );
};

export default Button;

interface ColorButtonProps extends Omit<ButtonProps, "buttonType"> {
  blueIcon?: boolean;
}

export const EditButton = ({
  blueIcon = false,
  children,
  ...props
}: ColorButtonProps) => {
  return (
    <Button
      buttonType="icon"
      {...props}
      className={classNames(styles.editBtn, props.className)}
    >
      <img src={blueIcon ? editIconBlue : editIcon} alt="Edit" />
      {!!children && <span>{children}</span>}
    </Button>
  );
};

export const DeleteButton = ({
  ...props
}: Omit<ButtonProps, "buttonType" | "children">) => {
  return (
    <Button
      buttonType="icon"
      {...props}
      className={classNames(styles.deleteBtn, props.className)}
    >
      <img src={deleteIcon} alt="Delete" />
    </Button>
  );
};

export const CopyButton = ({
  ...props
}: Omit<ButtonProps, "buttonType" | "children">) => {
  return (
    <Button
      buttonType="icon"
      {...props}
      className={classNames(styles.copyBtn, props.className)}
    >
      <img src={copyIcon} alt="Copy" />
    </Button>
  );
};

export const AddFilesButton = ({
  blueIcon = true,
  ...props
}: ColorButtonProps) => {
  return (
    <Button
      buttonType="icon"
      {...props}
      className={classNames(styles.addFilesBtn, props.className)}
    >
      <img src={blueIcon ? addFile : addFileWhite} alt="Add files" />
    </Button>
  );
};
