import React, { useContext } from "react";

import Button from "../components/button";
import { Divider } from "../components/typeface";
import { Column, Container, Row } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { AwardsContext } from "../context/awards";
import * as styles from "../styles/AdminPage.module.scss";

export default function AdminPage() {
  const { currentYear } = useContext(AwardsContext);

  return (
    <SiteWrapper>
      <Container>
        <h1>Admin</h1>
        {currentYear && (
          <Row>
            <Column gridCols={6}>
              <h3>Current awards year: {currentYear.year}</h3>
              <ul>
                <li>
                  <strong>Title:</strong> {currentYear.full_title}
                </li>
                <li>
                  <strong>Entries open:</strong>{" "}
                  {new Date(currentYear.entries_open_date).toDateString()} -{" "}
                  {new Date(currentYear.entries_close_date).toDateString()}
                </li>
                <li>
                  <strong>Qualifying period:</strong>{" "}
                  {new Date(
                    currentYear.qualifying_period_start_date
                  ).toDateString()}{" "}
                  -{" "}
                  {new Date(
                    currentYear.qualifying_period_end_date
                  ).toDateString()}
                </li>
                <li>
                  <strong>Shortlist announcement date:</strong>{" "}
                  {new Date(
                    currentYear.shortlist_announcement_date
                  ).toDateString()}
                </li>
                <li>
                  <strong>Nominations announcement date:</strong>{" "}
                  {new Date(
                    currentYear.nominations_announcement_date
                  ).toDateString()}
                </li>
                <li>
                  <strong>Ceremony date:</strong>{" "}
                  {new Date(currentYear.ceremony_date).toDateString()}
                </li>
              </ul>
            </Column>
          </Row>
        )}
        <Divider />
        <Row>
          <Column gridCols={6}>
            <Button
              className={styles.adminButton}
              buttonType="secondary"
              href="/admin/manage-awards"
            >
              Add/update/remove awards years and categories
            </Button>
            <Button
              className={styles.adminButton}
              buttonType="secondary"
              href="/admin/manage-entries"
            >
              Manage entries for the current awards year
            </Button>
            <Button
              className={styles.adminButton}
              buttonType="secondary"
              href="/admin/manage-entrants"
            >
              Manage basic information for entrants and judges
            </Button>
            <Button
              className={styles.adminButton}
              buttonType="secondary"
              href="/"
            >
              View accounts as another user
            </Button>
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
