export const chunkUint8Arrays = (
  arr1: Uint8Array,
  arr2: Uint8Array,
  mergedArrays: Uint8Array[] = []
): { merged: Uint8Array[]; remaining: Uint8Array } => {
  const maxSize = 5 * 1024 * 1024;
  let remainingLength = arr1.length + arr2.length;

  let remainingArray: Uint8Array | null = null;

  if (remainingLength < maxSize) {
    remainingArray = new Uint8Array(remainingLength);
    remainingArray.set(arr1);
    remainingArray.set(arr2, arr1.length);
  } else if (arr1.length >= maxSize) {
    mergedArrays.push(new Uint8Array(maxSize));

    mergedArrays[mergedArrays.length - 1].set(arr1.slice(0, maxSize));
    const carryOver = arr1.slice(maxSize);

    remainingArray = new Uint8Array(remainingLength - maxSize);
    remainingArray.set(carryOver);
    remainingArray.set(arr2, carryOver.length);
  } else {
    const offset = maxSize - arr1.length;

    mergedArrays.push(new Uint8Array(maxSize));
    mergedArrays[mergedArrays.length - 1].set(arr1);
    mergedArrays[mergedArrays.length - 1].set(
      arr2.slice(0, offset),
      arr1.length
    );

    const carryOver = arr2.slice(offset);

    remainingArray = new Uint8Array(arr2.length - offset);
    remainingArray.set(carryOver);
  }

  remainingLength = remainingArray.length;

  if (remainingLength >= maxSize) {
    return chunkUint8Arrays(remainingArray, new Uint8Array(0), mergedArrays);
  } else {
    return {
      merged: mergedArrays,
      remaining: remainingArray,
    };
  }
};

export const checkAborted = (fileName: string) => {
  const ls = window.localStorage.getItem("inProgressUploads");

  if (ls) {
    const parsed: LocalStoragePendingUploads = JSON.parse(ls);
    return !parsed.hasOwnProperty(fileName);
  }

  return true;
};

export const checkAndSetLocalStorage = (
  fileName: string,
  value: LocalStoragePendingUpload
) => {
  const item = window.localStorage.getItem("inProgressUploads");

  const newProp = {
    [fileName]: value,
  };

  if (item) {
    const json: LocalStoragePendingUploads = JSON.parse(item);
    window.localStorage.setItem(
      "inProgressUploads",
      JSON.stringify({
        ...json,
        ...newProp,
      })
    );
  } else {
    window.localStorage.setItem("inProgressUploads", JSON.stringify(newProp));
  }
};

export const updateLSProgress = (
  fileName: string,
  values: UploadedPart[],
  nextChunk: number
) => {
  const item = window.localStorage.getItem("inProgressUploads");

  if (item) {
    const json: LocalStoragePendingUploads = JSON.parse(item);

    if (json.hasOwnProperty(fileName)) {
      // Remove duplicates
      let uniques: UploadedPart[] = JSON.parse(
        JSON.stringify(json[fileName].uploadedParts)
      );

      values.forEach((value) => {
        uniques = uniques.filter(
          (part) => part.PartNumber !== value.PartNumber
        );
      });

      uniques.push(...values);

      json[fileName].uploadedParts = uniques;
      json[fileName].progress = +(
        (json[fileName].uploadedParts.length / json[fileName].totalChunks) *
        100
      ).toFixed(2);
      json[fileName].nextPart = nextChunk;

      window.localStorage.setItem("inProgressUploads", JSON.stringify(json));
    }
  }
};

export const deleteLocalStorageProp = (fileName: string) => {
  const item = window.localStorage.getItem("inProgressUploads");

  if (item) {
    const json: LocalStoragePendingUploads = JSON.parse(item);
    delete json[fileName];
    window.localStorage.setItem("inProgressUploads", JSON.stringify(json));
  }
};

export const copyLocalStorage = (): PendingUpload[] => {
  const ls = window.localStorage.getItem("inProgressUploads");

  const outArr: PendingUpload[] = [];

  if (ls) {
    const parsed: LocalStoragePendingUploads = JSON.parse(ls);

    for (const fileName in parsed) {
      outArr.push({
        fileName,
        fileType: parsed[fileName].fileType,
        uploadId: parsed[fileName].uploadId,
        progress: parsed[fileName].progress,
        size: parsed[fileName].size,
      });
    }
  }

  return outArr;
};
