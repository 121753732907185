import React from "react";

import { getImagePath } from "../utils";

export interface FlotiqImageObj {
  id: string;
  url: string;
}

interface FlotiqImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  imgObj?: (FlotiqImageObj | null)[] | null;
  fallbackImg?: string;
  className?: string;
}

export function FlotiqImage({
  imgObj,
  fallbackImg,
  className,
  ...props
}: FlotiqImageProps) {
  const img = getImagePath(imgObj);

  return img || fallbackImg ? (
    <img src={img || fallbackImg} className={className} {...props} />
  ) : (
    <></>
  );
}
