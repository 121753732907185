import { EntryFormField } from "../api/types/awards-category-fields";

/** Checks if a submission field is complete based on its type */
export const submissionFieldIsComplete = (field: EntryFormField) => {
  const { title, fieldType, required } = field;

  let isComplete = title && fieldType && typeof required === "boolean";

  if (fieldType === "select") {
    isComplete =
      isComplete && field.dropdownOptions && field.dropdownOptions.length > 0;
  }

  if (fieldType === "table") {
    isComplete =
      isComplete && field.tableFields && field.tableFields.length === 2;
  }

  return isComplete;
};
