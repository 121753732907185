// extracted by mini-css-extract-plugin
export var cancelButton = "uploadsProgress-module--cancelButton--6f044";
export var currUploads = "uploadsProgress-module--currUploads--8c88b";
export var deleteButtons = "uploadsProgress-module--deleteButtons--b6655";
export var deleteModal = "uploadsProgress-module--deleteModal--9c0f6";
export var errorDeleteBtn = "uploadsProgress-module--errorDeleteBtn--0486f";
export var ldsRing = "uploadsProgress-module--ldsRing--0554e";
export var loading = "uploadsProgress-module--loading--0449e";
export var move = "uploadsProgress-module--move--42207";
export var progressBar = "uploadsProgress-module--progressBar--cf393";
export var smallCopy = "uploadsProgress-module--smallCopy--7f92e";
export var suspended = "uploadsProgress-module--suspended--c030f";
export var uploaderErrorMsg = "uploadsProgress-module--uploaderErrorMsg--d258a";