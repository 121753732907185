import React, { useState, useCallback } from "react";
import { RouteComponentProps } from "@reach/router";

import Button from "../components/button";
import {
  FormMessage,
  FormError,
  FormButtonRow,
  Form,
} from "../components/form";
import Input from "../components/input";
import { Column, Container, Row, LineTextDivider } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { isValidEmail } from "../utils/index";
import { resetPassword } from "../api/auth";

interface ForgotPasswordPageProps extends RouteComponentProps {}

export default function ForgotPasswordPage({}: ForgotPasswordPageProps) {
  const [username, setUsename] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(0);

  const onSubmit = useCallback(async () => {
    const isValid = !isValidEmail(username);
    if (username === "") setError("This field is required.");
    else if (isValid) setError("Please enter a valid email.");
    else {
      setError("");

      try {
        await resetPassword({ email: username });
        setStep(1);
      } catch {
        setError("Unable to reset password. Please try again later.");
      }
    }
  }, [username]);

  return (
    <SiteWrapper hideStickyNav>
      <Container>
        <Row>
          <Column gridCols={4}>
            <h1>Forgotten password</h1>
            <p>
              Enter the email address of your account to receive your password.
            </p>

            {step === 0 && (
              <>
                <Form onSubmit={onSubmit}>
                  <>
                    <Input
                      dark
                      id="username"
                      name="username"
                      placeholder="User name"
                      value={username}
                      onChange={(e) => {
                        setUsename(e.target.value);
                      }}
                      error={error !== ""}
                    />
                    {error !== "" && (
                      <FormError>
                        <p>{error}</p>
                      </FormError>
                    )}
                    <FormButtonRow>
                      <Button buttonType="link" href="/login">
                        Login
                      </Button>
                      <Button buttonType="primary">Recover password</Button>
                    </FormButtonRow>
                  </>
                </Form>
                <LineTextDivider label="OR">
                  <Button buttonType="link" href="/create-account">
                    Create a new account
                  </Button>
                </LineTextDivider>
              </>
            )}
            {step === 1 && (
              <>
                <FormMessage>
                  <p>
                    New password has been sent to specified email. Please follow
                    instructions carefully.
                  </p>
                </FormMessage>
                <FormButtonRow>
                  <Button buttonType="primary" href="/login">
                    Login
                  </Button>
                </FormButtonRow>
              </>
            )}
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
