import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { AccountContext } from "./account";
import { getAwardsSponsors } from "../api/awards-sponsors";
import { AwardsSponsorResponse } from "../api/types/awards-sponsors";
import { AwardsCategoryResponseFull } from "../api/types/awards-categories";
import { AwardsContext } from "./awards";
import {
  getAwardsYearCategories,
  getAwardsYearEntries,
} from "../api/awards-years";
import { AwardsEntryResponseFull } from "../api/types/awards-entries";
import {
  AwardsEntrantsQueryParams,
  AwardsYearEntriesQueryParams,
  AwardsYearJudgesQueryParams,
} from "../api/types/awards-years";
import { getJudgesByYear } from "../api/awards-judges";
import { getAwardsEntrants } from "../api/awards-entrants";
import { EntrantResponse } from "../api/types/accounts";

interface AdminContextProps {
  categories: { [year: number]: AwardsCategoryResponseFull[] } | null;
  setCategories: React.Dispatch<
    React.SetStateAction<{
      [year: number]: AwardsCategoryResponseFull[];
    } | null>
  >;
  sponsors: AwardsSponsorResponse[];
  setSponsors: React.Dispatch<React.SetStateAction<AwardsSponsorResponse[]>>;
  getEntries: (
    year: number,
    queryParams?: AwardsYearEntriesQueryParams
  ) => Promise<{ entries: AwardsEntryResponseFull[]; total_count: number }>;
  getEntrants: (queryParams?: AwardsEntrantsQueryParams) => Promise<{
    entrants: EntrantResponse[];
    totalEntrants: number;
    totalEntrantsWithEntries: number;
  }>;
  getJudges: (
    year: number,
    queryParams?: AwardsYearJudgesQueryParams
  ) => Promise<{ judges: Judge[]; total_count: number }>;
}

const initialValue: AdminContextProps = {
  categories: null,
  setCategories: () => null,
  sponsors: [],
  setSponsors: () => null,
  // @ts-ignore
  getEntries: () => null,
  // @ts-ignore
  getEntrants: () => null,
  // @ts-ignore
  getJudges: () => null,
};

export const AdminContext = createContext<AdminContextProps>(initialValue);

interface AccountProviderProps {
  children?: React.ReactNode;
}

const AdminProvider = ({ children }: AccountProviderProps) => {
  const { account } = useContext(AccountContext);
  const { currentYear } = useContext(AwardsContext);

  const [categories, setCategories] = useState<{
    [year: number]: AwardsCategoryResponseFull[];
  } | null>(null);
  const [sponsors, setSponsors] = useState<AwardsSponsorResponse[]>([]);
  const [categoriesFetched, setCategoriesFetched] = useState(false);

  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        const { sponsors } = await getAwardsSponsors();

        setSponsors([...sponsors]);
      } catch {
        // TODO: Add error handling for sponsors
        console.error("Error fetching sponsors");
      }
    };

    if (account.admin_id) {
      fetchSponsors();
    }
  }, [account]);

  // Fetch categories for current year
  useEffect(() => {
    const fetchCategories = async () => {
      if (!currentYear || categoriesFetched) return;

      const { year } = currentYear;

      const { categories: fetchedCategories } = await getAwardsYearCategories(
        year
      );

      // Merge existing categories with new categories
      setCategories((curr) => {
        if (!curr)
          return {
            [year]: fetchedCategories,
          };

        return {
          ...curr,
          [year]: fetchedCategories,
        };
      });

      setCategoriesFetched(true);
    };

    fetchCategories();
  }, [currentYear, categoriesFetched]);

  const getEntries = useCallback(
    async (year: number, queryParams?: AwardsYearEntriesQueryParams) => {
      try {
        const { entries, total_count } = await getAwardsYearEntries(
          year,
          queryParams
        );

        return { entries, total_count };
      } catch {
        return Promise.reject("Failed to get category entries");
      }
    },
    []
  );

  const getEntrants = useCallback(
    async (queryParams?: AwardsEntrantsQueryParams) => {
      try {
        const { entrants, totalEntrants, totalEntrantsWithEntries } =
          await getAwardsEntrants(queryParams);

        return { entrants, totalEntrants, totalEntrantsWithEntries };
      } catch {
        return Promise.reject("Failed to get category entries");
      }
    },
    []
  );

  const getJudges = useCallback(
    async (year: number, queryParams?: AwardsYearJudgesQueryParams) => {
      try {
        const { judges, total_count } = await getJudgesByYear(
          year,
          queryParams
        );

        return { judges, total_count };
      } catch {
        return Promise.reject("Failed to get list of judges");
      }
    },
    []
  );

  return (
    <AdminContext.Provider
      value={{
        categories,
        setCategories,
        sponsors,
        setSponsors,
        getEntries,
        getEntrants,
        getJudges,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
