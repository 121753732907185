import React, { useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { useParams } from "@reach/router";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import { nanoid } from "nanoid";

import BurgerIcon from "../components/burger-icon";
import Button, {
  CopyButton,
  DeleteButton,
  EditButton,
} from "../components/button";
import { Column, Container, Row } from "../components/layout";
import { FormButtonRow, FormError, FormMessage } from "../components/form";
import Input, { Checkbox, DateField, TextArea } from "../components/input";
import { Modal } from "../components/modal";
import SelectBox from "../components/select-box";
import SiteWrapper from "../components/SiteWrapper";
import { Divider } from "../components/typeface";
import {
  getAwardsCategory,
  updateAwardsCategory,
} from "../api/awards-categories";
import { AwardsCategoryResponseFull } from "../api/types/awards-categories";
import {
  EntryFormCheckboxField,
  EntryFormField,
  EntryFormSelectField,
  EntryFormTableField,
} from "../api/types/awards-category-fields";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/AdminManageCategoryFieldsPage.module.scss";
import { submissionFieldIsComplete } from "../utils/admin";

const fieldTypeOptions = [
  { name: "Input", value: "input" },
  { name: "Number", value: "number" },
  { name: "Text area", value: "textarea" },
  { name: "Date", value: "date" },
  { name: "Dropdown", value: "select" },
  { name: "Checkbox", value: "checkbox" },
  { name: "Table", value: "table" },
];

interface AddCategoryFieldModalProps {
  category: AwardsCategoryResponseFull;
  onUpdate: (updatedCategory: AwardsCategoryResponseFull) => void;
  fieldToCopy?: EntryFormField | null;
}

export const AddCategoryFieldModal = ({
  category,
  onUpdate,
  fieldToCopy,
}: AddCategoryFieldModalProps) => {
  const [fieldToAdd, setFieldToAdd] = useState<EntryFormField>(
    fieldToCopy
      ? {
          ..._.cloneDeep(fieldToCopy),
          fieldId: nanoid(),
          title: `Copy of ${fieldToCopy!.title}`,
        }
      : {
          fieldId: nanoid(),
          title: "",
          required: false,
          fieldType: "input",
        }
  );
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  // Used for hiding them behind this one
  const otherFields = useMemo(() => {
    if (
      !category.entry_form_fields ||
      category.entry_form_fields.length === 0
    ) {
      // This is the only field, and it hasn't been added yet
      return [];
    }

    return category.entry_form_fields;
  }, [category]);

  return (
    <>
      <h1>Add new form field</h1>
      <p>
        The field will appear in the entry form for this category (
        {category.title}).
      </p>
      {fieldToAdd.hiddenByFieldId && (
        <p>
          <em>
            *This field shows depending on the value of the checkbox field{" "}
            {category.entry_form_fields!.find(
              (f) => f.fieldId === fieldToAdd.hiddenByFieldId
            )?.title || fieldToAdd.hiddenByFieldId}
          </em>
        </p>
      )}
      <div className={styles.form}>
        <Input
          id="field-title"
          name="field-title"
          label={
            fieldToAdd.fieldType === "checkbox" ? "Checkbox label" : "Title"
          }
          required
          placeholder="Enter field title"
          value={fieldToAdd.title}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setFieldToAdd((curr) => {
              return {
                ...curr,
                title: e.target.value,
              };
            });
          }}
        />
        {fieldToAdd.fieldType !== "table" && (
          <Input
            id="field-crm-id"
            name="field-crm-id"
            label="CRM field ID"
            required
            placeholder="The field's unique ID in Salesforce"
            value={fieldToAdd.salesforceFieldId}
            onChange={(e) => {
              setUpdateSuccess(false);
              setUpdateError(null);

              setFieldToAdd((curr) => {
                return {
                  ...curr,
                  salesforceFieldId: e.target.value,
                };
              });
            }}
          />
        )}
        <Checkbox
          id="field-required"
          name="field-required"
          label="Required (Entrant must include this field to submit)"
          required
          checked={fieldToAdd.required}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setFieldToAdd((curr) => {
              return {
                ...curr,
                required: e.target.checked,
              };
            });
          }}
        />
        <TextArea
          id="field-intro"
          name="field-intro"
          label="Intro"
          placeholder="This will appear above the field in the entry form"
          value={fieldToAdd.intro}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setFieldToAdd((curr) => {
              return {
                ...curr,
                intro: e.target.value,
              };
            });
          }}
        />
        <TextArea
          id="field-footnote"
          name="field-footnote"
          label="Footnote"
          placeholder="This will appear below the field in the entry form"
          value={fieldToAdd.footnote}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setFieldToAdd((curr) => {
              return {
                ...curr,
                footnote: e.target.value,
              };
            });
          }}
        />
        <SelectBox
          label="Field type"
          required
          labelKey="name"
          valueKey="value"
          options={fieldTypeOptions}
          value={fieldTypeOptions.find(
            (option) => option.value === fieldToAdd.fieldType
          )}
          onChange={(newValue) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            // Reset type-dependent values
            // @ts-ignore
            setFieldToAdd((curr) => {
              return {
                fieldId: curr.fieldId,
                title: curr.title,
                required: curr.required,
                intro: curr.intro,
                footnote: curr.footnote,
                fieldType: newValue?.value as EntryFormField["fieldType"],
              };
            });
          }}
        />
        {fieldToAdd.fieldType === "textarea" && (
          <>
            <Input
              id="field-min-words"
              name="field-min-words"
              type="number"
              min={0}
              label="Minimum word count"
              placeholder="Enter value > 0"
              value={fieldToAdd.minWords}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setFieldToAdd((curr) => {
                  return {
                    ...curr,
                    minWords: parseInt(e.target.value),
                  };
                });
              }}
            />
            <Input
              id="field-max-words"
              name="field-max-words"
              type="number"
              label="Maximum word count"
              placeholder="Enter value > 0"
              value={fieldToAdd.maxWords}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setFieldToAdd((curr) => {
                  return {
                    ...curr,
                    maxWords: parseInt(e.target.value),
                  };
                });
              }}
            />
          </>
        )}
        {fieldToAdd.fieldType === "date" && (
          <>
            <DateField
              id="field-earliest-date"
              name="field-earliest-date"
              label="Earliest date"
              value={fieldToAdd.earliestDate}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setFieldToAdd((curr) => {
                  return {
                    ...curr,
                    earliestDate: e,
                  };
                });
              }}
            />
            <DateField
              id="field-latest-date"
              name="field-latest-date"
              label="Larliest date"
              value={fieldToAdd.latestDate}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setFieldToAdd((curr) => {
                  return {
                    ...curr,
                    latestDate: e,
                  };
                });
              }}
            />
          </>
        )}
        {fieldToAdd.fieldType === "select" && (
          <>
            <Divider />
            <h4>Dropdown options</h4>
            <p>Add some options for the field</p>
            <div className={styles.dropdownFields}>
              {fieldToAdd.dropdownOptions &&
                fieldToAdd.dropdownOptions.map((option, optionIndex) => {
                  // 'Other' option is special and should not show here
                  if (option.value === "other") {
                    return null;
                  }

                  return (
                    <div className={styles.dropdownOption}>
                      <Input
                        key={optionIndex}
                        id={option.value}
                        name={option.value}
                        value={option.name}
                        placeholder="Add value"
                        onChange={(e) => {
                          setFieldToAdd((curr) => {
                            const newOptions = _.cloneDeep(
                              (curr as EntryFormSelectField).dropdownOptions
                            );

                            newOptions.splice(optionIndex, 1, {
                              name: e.target.value,
                              value: e.target.value
                                .toLowerCase()
                                .replaceAll(/\s/g, "-"),
                            });

                            return {
                              ...curr,
                              dropdownOptions: newOptions,
                            };
                          });
                        }}
                      />
                      <DeleteButton
                        onClick={() => {
                          setFieldToAdd((curr) => {
                            const newOptions = _.cloneDeep(
                              (curr as EntryFormSelectField).dropdownOptions
                            );

                            newOptions.splice(optionIndex, 1);

                            return {
                              ...curr,
                              dropdownOptions: newOptions,
                            };
                          });
                        }}
                      />
                    </div>
                  );
                })}
              <FormButtonRow>
                <Button
                  buttonType="tertiary"
                  onClick={() => {
                    setFieldToAdd((curr) => {
                      const optionsExcludingOther = (
                        curr as EntryFormSelectField
                      ).dropdownOptions
                        ? (curr as EntryFormSelectField).dropdownOptions.filter(
                            (option) => option.value !== "other"
                          )
                        : [];

                      optionsExcludingOther.push({
                        name: "",
                        value: "",
                      });

                      // 'Other' option should always be at the end
                      if ((curr as EntryFormSelectField).includeOther) {
                        optionsExcludingOther.push({
                          name: "Other",
                          value: "other",
                        });
                      }

                      return {
                        ...curr,
                        dropdownOptions: optionsExcludingOther,
                      };
                    });
                  }}
                >
                  Add option
                </Button>
              </FormButtonRow>
            </div>
            <Checkbox
              id="field-order-alphabetically"
              name="field-order-alphabetically"
              label="Order alphabetically (will be ordered as above if unchecked)"
              required
              checked={fieldToAdd.orderAlphabetically || false}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setFieldToAdd((curr) => {
                  return {
                    ...curr,
                    orderAlphabetically: e.target.checked,
                  };
                });
              }}
            />
            <Checkbox
              id="field-allow-multi"
              name="field-allow-multi"
              label="Allow multiple selections"
              required
              checked={fieldToAdd.allowMultiple || false}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setFieldToAdd((curr) => {
                  return {
                    ...curr,
                    allowMultiple: e.target.checked,
                  };
                });
              }}
            />
            {fieldToAdd.allowMultiple && (
              <Input
                id="field-max-selections"
                name="field-max-selections"
                type="number"
                label="Maximum number of selections"
                placeholder="Enter value > 0"
                value={fieldToAdd.multipleLimit}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setUpdateError(null);

                  setFieldToAdd((curr) => {
                    return {
                      ...curr,
                      multipleLimit: parseInt(e.target.value),
                    };
                  });
                }}
              />
            )}
            {!fieldToAdd.allowMultiple && (
              <>
                <Checkbox
                  id="field-include-other"
                  name="field-include-other"
                  label="Include 'Other' option (adds input field when selected)"
                  required
                  checked={fieldToAdd.includeOther || false}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setUpdateError(null);

                    setFieldToAdd((curr) => {
                      const existingDropdownOptions =
                        (curr as EntryFormSelectField).dropdownOptions || [];

                      if (e.target.checked) {
                        existingDropdownOptions.push({
                          name: "Other",
                          value: "other",
                        });
                      } else {
                        existingDropdownOptions.splice(
                          existingDropdownOptions.length - 1,
                          1
                        );
                      }

                      return {
                        ...curr,
                        dropdownOptions: existingDropdownOptions,
                        includeOther: e.target.checked,
                      };
                    });
                  }}
                />
                {fieldToAdd.includeOther && (
                  <Input
                    id="field-crm-other-id"
                    name="field-crm-other-id"
                    label="Field ID for 'Other' option"
                    required
                    placeholder="The field's unique ID in Salesforce"
                    value={fieldToAdd.salesforceOtherFieldId}
                    onChange={(e) => {
                      setUpdateSuccess(false);
                      setUpdateError(null);

                      setFieldToAdd((curr) => {
                        return {
                          ...curr,
                          salesforceOtherFieldId: e.target.value,
                        };
                      });
                    }}
                  />
                )}
              </>
            )}
            <Divider />
          </>
        )}
        {fieldToAdd.fieldType === "checkbox" && (
          <>
            <Input
              id="field-checkbox-header"
              name="field-checkbox-header"
              label="Field header (shown above the checkbox)"
              placeholder="Enter field header"
              value={fieldToAdd.heading}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setFieldToAdd((curr) => {
                  return {
                    ...curr,
                    heading: e.target.value,
                  };
                });
              }}
            />
            <Checkbox
              id="field-checked-by-default"
              name="field-checked-by-default"
              label="Checked by default"
              checked={fieldToAdd.checkedByDefault || false}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setFieldToAdd((curr) => {
                  return {
                    ...curr,
                    checkedByDefault: e.target.checked,
                  };
                });
              }}
            />
            {category.entry_form_fields &&
            category.entry_form_fields.length > 1 ? (
              <Checkbox
                id="field-include-hidden-fields"
                name="field-include-hidden-fields"
                label="Hide fields behind checkbox"
                checked={!!fieldToAdd.hiddenFields || false}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setUpdateError(null);

                  setFieldToAdd((curr) => {
                    return {
                      ...curr,
                      hiddenFields: e.target.checked
                        ? {
                            ids: [],
                            state: "hidden",
                          }
                        : undefined,
                    };
                  });
                }}
              />
            ) : (
              <p>
                <em>
                  <strong>Note:</strong> You can hide other fields behind this
                  one once you've defined them.
                </em>
              </p>
            )}
            {!!fieldToAdd.hiddenFields && (
              <>
                <Divider />
                <h4>Hidden fields</h4>
                <p>
                  The below fields will only show based on whether this field is
                  checked or not
                </p>
                <Checkbox
                  id={`hidden-field-show-state`}
                  name={`hidden-field-show-state`}
                  label="Hide fields when checked"
                  checked={!!fieldToAdd.hiddenFields!.hideFieldsWhenChecked}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setUpdateError(null);

                    setFieldToAdd((curr) => {
                      return {
                        ...curr,
                        hiddenFields: {
                          ...(curr as EntryFormCheckboxField).hiddenFields!,
                          hideFieldsWhenChecked: e.target.checked,
                        },
                      };
                    });
                  }}
                />
                <SelectBox
                  id="field-hidden-fields-state"
                  name="field-hidden-fields-state"
                  label="Hidden fields state"
                  required
                  labelKey="name"
                  valueKey="value"
                  options={[
                    { name: "Hidden", value: "hidden" },
                    { name: "Greyed out", value: "greyedOut" },
                  ]}
                  defaultValue={{ name: "Hidden", value: "hidden" }}
                  value={[
                    { name: "Hidden", value: "hidden" },
                    { name: "Greyed out", value: "greyedOut" },
                  ].find(
                    (option) => option.value === fieldToAdd.hiddenFields!.state
                  )}
                  onChange={(newValue) => {
                    setUpdateSuccess(false);
                    setUpdateError(null);

                    // @ts-ignore
                    setFieldToAdd((curr) => {
                      return {
                        ...curr,
                        hiddenFields: {
                          ...(curr as EntryFormCheckboxField).hiddenFields!,
                          state: newValue?.value,
                        },
                      };
                    });
                  }}
                />
                {otherFields.map((otherField) => {
                  return (
                    <Checkbox
                      key={otherField.fieldId}
                      id={`hidden-field-${otherField.fieldId}`}
                      name={`hidden-field-${otherField.fieldId}`}
                      label={otherField.title}
                      checked={
                        !!fieldToAdd.hiddenFields!.ids.includes(
                          otherField.fieldId
                        )
                      }
                      onChange={(e) => {
                        setFieldToAdd((curr) => {
                          const newIds = [
                            ...(curr as EntryFormCheckboxField).hiddenFields!
                              .ids,
                          ];

                          if (e.target.checked) {
                            // Add
                            newIds.push(otherField.fieldId);
                          } else {
                            // Remove
                            const existingIndex = newIds.indexOf(
                              otherField.fieldId
                            );

                            if (existingIndex > -1) {
                              newIds.splice(existingIndex, 1);
                            }
                          }

                          return {
                            ...curr,
                            hiddenFields: {
                              ...(curr as EntryFormCheckboxField).hiddenFields!,
                              ids: newIds,
                            },
                          };
                        });
                      }}
                    />
                  );
                })}
                <Divider />
              </>
            )}
          </>
        )}
        {fieldToAdd.fieldType === "table" && (
          <>
            <Input
              id="field-max-rows"
              name="field-max-rows"
              type="number"
              min={0}
              label="Max rows/values the entrant can input"
              placeholder="Enter value > 0"
              value={fieldToAdd.maxTableFields || 6}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setFieldToAdd((curr) => {
                  return {
                    ...curr,
                    maxTableFields: parseInt(e.target.value),
                  };
                });
              }}
            />
            <Divider />
            <h4>Table fields</h4>
            <p>
              Each input field below represents a column in the input table.
              Tables can only have two columns of inputs.
            </p>
            <p>
              Table fields in Salesforce will have incremental IDs (starting
              from 1) that can look like 'tableField_6_value'. To integrate,
              these values are built per row according to the prefix and suffix
              provided below. For the given example, the prefix would be
              'tableField_' and the suffix '_value'
            </p>
            <div className={styles.tableFields}>
              <>
                {fieldToAdd.tableFields &&
                  fieldToAdd.tableFields.map((tableField, tableFieldIndex) => {
                    return (
                      <React.Fragment key={tableFieldIndex}>
                        {tableFieldIndex > 0 && <Divider />}
                        <Input
                          id={`${tableFieldIndex}-${tableField.tableFieldId}`}
                          name={`${tableFieldIndex}-${tableField.tableFieldId}`}
                          required
                          label="Field name (column header)"
                          placeholder="Enter field name"
                          value={tableField.tableFieldName}
                          onChange={(e) => {
                            setUpdateSuccess(false);
                            setUpdateError(null);

                            setFieldToAdd((curr) => {
                              const newTableFields = _.cloneDeep(
                                (curr as EntryFormTableField).tableFields
                              );

                              newTableFields.splice(tableFieldIndex, 1, {
                                tableFieldId: e.target.value
                                  .toLowerCase()
                                  .replaceAll(/\s/g, "-"),
                                tableFieldName: e.target.value,
                                salesforceTableFieldIdPrefix:
                                  newTableFields[tableFieldIndex]
                                    .salesforceTableFieldIdPrefix,
                                salesforceTableFieldIdSuffix:
                                  newTableFields[tableFieldIndex]
                                    .salesforceTableFieldIdSuffix,
                                tableFieldRequired:
                                  newTableFields[tableFieldIndex]
                                    .tableFieldRequired,
                              });

                              return {
                                ...curr,
                                tableFields: newTableFields,
                              };
                            });
                          }}
                        />
                        <Input
                          id={`${tableFieldIndex}-${tableField.salesforceTableFieldIdPrefix}`}
                          name={`${tableFieldIndex}-${tableField.salesforceTableFieldIdPrefix}`}
                          label="CRM field ID prefix"
                          placeholder="Text before the ID's incrementor..."
                          value={tableField.salesforceTableFieldIdPrefix}
                          onChange={(e) => {
                            setUpdateSuccess(false);
                            setUpdateError(null);

                            setFieldToAdd((curr) => {
                              const newTableFields = _.cloneDeep(
                                (curr as EntryFormTableField).tableFields
                              );

                              newTableFields.splice(tableFieldIndex, 1, {
                                tableFieldId:
                                  newTableFields[tableFieldIndex].tableFieldId,
                                tableFieldName:
                                  newTableFields[tableFieldIndex]
                                    .tableFieldName,
                                salesforceTableFieldIdPrefix: e.target.value,
                                salesforceTableFieldIdSuffix:
                                  newTableFields[tableFieldIndex]
                                    .salesforceTableFieldIdSuffix,
                                tableFieldRequired:
                                  newTableFields[tableFieldIndex]
                                    .tableFieldRequired,
                              });

                              return {
                                ...curr,
                                tableFields: newTableFields,
                              };
                            });
                          }}
                        />
                        <Input
                          id={`${tableFieldIndex}-${tableField.salesforceTableFieldIdPrefix}`}
                          name={`${tableFieldIndex}-${tableField.salesforceTableFieldIdPrefix}`}
                          label="CRM field ID suffix"
                          placeholder="Text after the ID's incrementor..."
                          value={tableField.salesforceTableFieldIdSuffix}
                          onChange={(e) => {
                            setUpdateSuccess(false);
                            setUpdateError(null);

                            setFieldToAdd((curr) => {
                              const newTableFields = _.cloneDeep(
                                (curr as EntryFormTableField).tableFields
                              );

                              newTableFields.splice(tableFieldIndex, 1, {
                                tableFieldId:
                                  newTableFields[tableFieldIndex].tableFieldId,
                                tableFieldName:
                                  newTableFields[tableFieldIndex]
                                    .tableFieldName,
                                salesforceTableFieldIdPrefix:
                                  newTableFields[tableFieldIndex]
                                    .salesforceTableFieldIdPrefix,
                                salesforceTableFieldIdSuffix: e.target.value,
                                tableFieldRequired:
                                  newTableFields[tableFieldIndex]
                                    .tableFieldRequired,
                              });

                              return {
                                ...curr,
                                tableFields: newTableFields,
                              };
                            });
                          }}
                        />
                        <Checkbox
                          id={`${tableFieldIndex}-${tableField.tableFieldId}-required`}
                          name={`${tableFieldIndex}-${tableField.tableFieldId}-required`}
                          label="Required (Must have a value)"
                          checked={tableField.tableFieldRequired}
                          onChange={(e) => {
                            setUpdateSuccess(false);
                            setUpdateError(null);

                            setFieldToAdd((curr) => {
                              const newTableFields = _.cloneDeep(
                                (curr as EntryFormTableField).tableFields
                              );

                              newTableFields.splice(tableFieldIndex, 1, {
                                tableFieldId:
                                  newTableFields[tableFieldIndex].tableFieldId,
                                tableFieldName:
                                  newTableFields[tableFieldIndex]
                                    .tableFieldName,
                                salesforceTableFieldIdPrefix:
                                  newTableFields[tableFieldIndex]
                                    .salesforceTableFieldIdPrefix,
                                salesforceTableFieldIdSuffix:
                                  newTableFields[tableFieldIndex]
                                    .salesforceTableFieldIdSuffix,
                                tableFieldRequired: e.target.checked,
                              });

                              return {
                                ...curr,
                                tableFields: newTableFields,
                              };
                            });
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
                {(!fieldToAdd.tableFields ||
                  fieldToAdd.tableFields.length < 2) && (
                  <FormButtonRow>
                    <Button
                      buttonType="tertiary"
                      onClick={() => {
                        setFieldToAdd((curr) => {
                          const newTableFields =
                            (curr as EntryFormTableField).tableFields || [];

                          newTableFields.push({
                            tableFieldId: "",
                            tableFieldName: "",
                            salesforceTableFieldIdPrefix: "",
                            salesforceTableFieldIdSuffix: "",
                            tableFieldRequired: false,
                          });

                          return {
                            ...curr,
                            tableFields: newTableFields,
                          };
                        });
                      }}
                    >
                      Add input/column
                    </Button>
                  </FormButtonRow>
                )}
              </>
            </div>
            <Divider />
          </>
        )}
        <FormButtonRow>
          <Button
            buttonType="primary"
            disabled={!submissionFieldIsComplete(fieldToAdd)}
            onClick={async () => {
              try {
                const { updatedCategory } = await updateAwardsCategory(
                  category.awards_category_id,
                  {
                    entry_form_fields: category.entry_form_fields
                      ? [...category.entry_form_fields, fieldToAdd]
                      : [fieldToAdd],
                  }
                );

                onUpdate(updatedCategory);
                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to add new field.");
              }
            }}
          >
            Add
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && (
        <FormMessage>
          Successfully added new field to category '{category.title}'.
        </FormMessage>
      )}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

interface EditCategoryFieldModalProps extends AddCategoryFieldModalProps {
  fieldToEdit: EntryFormField;
}

export const EditCategoryFieldModal = ({
  category,
  fieldToEdit,
  onUpdate,
}: EditCategoryFieldModalProps) => {
  const [currentField, setCurrentField] = useState<EntryFormField>(fieldToEdit);
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  // Used for hiding them behind this one
  const otherFields = useMemo(() => {
    if (
      !category.entry_form_fields ||
      category.entry_form_fields.length === 0
    ) {
      // This is the only field, and it hasn't been added yet
      return [];
    }

    // Block checkbox fields from being selected as hidden behind other checkbox fields
    // This prevents circular dependencies
    return category.entry_form_fields.filter(
      (field) =>
        field.fieldId !== fieldToEdit.fieldId && field.fieldType !== "checkbox"
    );
  }, [category, fieldToEdit]);

  return (
    <>
      <h1>Update form field '{fieldToEdit.title}'</h1>
      <p>
        The field will appear in the entry form for this category (
        {category.title}).
      </p>
      {currentField.hiddenByFieldId && (
        <p>
          <em>
            *This field shows depending on the value of the checkbox field{" "}
            {category.entry_form_fields!.find(
              (f) => f.fieldId === currentField.hiddenByFieldId
            )?.title || currentField.hiddenByFieldId}
          </em>
        </p>
      )}
      <div className={styles.form}>
        <Input
          id="field-title"
          name="field-title"
          label={
            currentField.fieldType === "checkbox" ? "Checkbox label" : "Title"
          }
          required
          placeholder="Enter field title"
          value={currentField.title}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentField((curr) => {
              return {
                ...curr,
                title: e.target.value,
              };
            });
            setHasBeenEdited(true);
          }}
        />
        {currentField.fieldType !== "table" && (
          <Input
            id="field-crm-id"
            name="field-crm-id"
            label="CRM field ID"
            required
            placeholder="The field's unique ID in Salesforce"
            value={currentField.salesforceFieldId}
            onChange={(e) => {
              setUpdateSuccess(false);
              setUpdateError(null);

              setCurrentField((curr) => {
                return {
                  ...curr,
                  salesforceFieldId: e.target.value,
                };
              });
              setHasBeenEdited(true);
            }}
          />
        )}
        <Checkbox
          id="field-required"
          name="field-required"
          label="Required (Entrant must include this field to submit)"
          required
          checked={currentField.required}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentField((curr) => {
              return {
                ...curr,
                required: e.target.checked,
              };
            });
            setHasBeenEdited(true);
          }}
        />
        <TextArea
          id="field-intro"
          name="field-intro"
          label="Intro"
          placeholder="This will appear above the field in the entry form"
          value={currentField.intro}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentField((curr) => {
              return {
                ...curr,
                intro: e.target.value,
              };
            });
            setHasBeenEdited(true);
          }}
        />
        <TextArea
          id="field-footnote"
          name="field-footnote"
          label="Footnote"
          placeholder="This will appear below the field in the entry form"
          value={currentField.footnote}
          onChange={(e) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            setCurrentField((curr) => {
              return {
                ...curr,
                footnote: e.target.value,
              };
            });
            setHasBeenEdited(true);
          }}
        />
        <SelectBox
          label="Field type"
          required
          labelKey="name"
          valueKey="value"
          options={fieldTypeOptions}
          value={fieldTypeOptions.find(
            (option) => option.value === currentField.fieldType
          )}
          onChange={(newValue) => {
            setUpdateSuccess(false);
            setUpdateError(null);

            // Reset type-dependent values
            // @ts-ignore
            setCurrentField((curr) => {
              return {
                fieldId: curr.fieldId,
                title: curr.title,
                required: curr.required,
                intro: curr.intro,
                footnote: curr.footnote,
                fieldType: newValue?.value as EntryFormField["fieldType"],
              };
            });
            setHasBeenEdited(true);
          }}
        />
        {currentField.fieldType === "textarea" && (
          <>
            <Input
              id="field-min-words"
              name="field-min-words"
              type="number"
              min={0}
              label="Minimum word count"
              placeholder="Enter value > 0"
              value={currentField.minWords}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentField((curr) => {
                  return {
                    ...curr,
                    minWords: parseInt(e.target.value),
                  };
                });
                setHasBeenEdited(true);
              }}
            />
            <Input
              id="field-max-words"
              name="field-max-words"
              type="number"
              label="Maximum word count"
              placeholder="Enter value > 0"
              value={currentField.maxWords}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentField((curr) => {
                  return {
                    ...curr,
                    maxWords: parseInt(e.target.value),
                  };
                });
                setHasBeenEdited(true);
              }}
            />
          </>
        )}
        {currentField.fieldType === "date" && (
          <>
            <DateField
              id="field-earliest-date"
              name="field-earliest-date"
              label="Earliest date"
              value={
                currentField?.earliestDate
                  ? new Date(currentField?.earliestDate)
                  : null
              }
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentField((curr) => {
                  return {
                    ...curr,
                    earliestDate: e,
                  };
                });
                setHasBeenEdited(true);
              }}
            />
            <DateField
              id="field-latest-date"
              name="field-latest-date"
              label="Larliest date"
              value={
                currentField?.latestDate
                  ? new Date(currentField?.latestDate)
                  : null
              }
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentField((curr) => {
                  return {
                    ...curr,
                    latestDate: e,
                  };
                });
                setHasBeenEdited(true);
              }}
            />
          </>
        )}
        {currentField.fieldType === "select" && (
          <>
            <Divider />
            <h4>Dropdown options</h4>
            <p>Add some options for the field</p>
            <div className={styles.dropdownFields}>
              {currentField.dropdownOptions &&
                currentField.dropdownOptions.map((option, optionIndex) => {
                  if (option.value === "other") {
                    return null;
                  }

                  return (
                    <div className={styles.dropdownOption}>
                      <Input
                        key={optionIndex}
                        id={option.value}
                        name={option.value}
                        value={option.name}
                        placeholder="Add value"
                        onChange={(e) => {
                          setCurrentField((curr) => {
                            const newOptions = _.cloneDeep(
                              (curr as EntryFormSelectField).dropdownOptions
                            );

                            newOptions.splice(optionIndex, 1, {
                              name: e.target.value,
                              value: e.target.value
                                .toLowerCase()
                                .replaceAll(/\s/g, "-"),
                            });

                            return {
                              ...curr,
                              dropdownOptions: newOptions,
                            };
                          });
                          setHasBeenEdited(true);
                        }}
                      />
                      <DeleteButton
                        onClick={() => {
                          setCurrentField((curr) => {
                            const newOptions = _.cloneDeep(
                              (curr as EntryFormSelectField).dropdownOptions
                            );

                            newOptions.splice(optionIndex, 1);

                            return {
                              ...curr,
                              dropdownOptions: newOptions,
                            };
                          });
                          setHasBeenEdited(true);
                        }}
                      />
                    </div>
                  );
                })}
              <FormButtonRow>
                <Button
                  buttonType="tertiary"
                  onClick={() => {
                    setCurrentField((curr) => {
                      const optionsExcludingOther = (
                        curr as EntryFormSelectField
                      ).dropdownOptions
                        ? (curr as EntryFormSelectField).dropdownOptions.filter(
                            (option) => option.value !== "other"
                          )
                        : [];

                      optionsExcludingOther.push({
                        name: "",
                        value: "",
                      });

                      // 'Other' option should always be at the end
                      if ((curr as EntryFormSelectField).includeOther) {
                        optionsExcludingOther.push({
                          name: "Other",
                          value: "other",
                        });
                      }

                      return {
                        ...curr,
                        dropdownOptions: optionsExcludingOther,
                      };
                    });
                  }}
                >
                  Add option
                </Button>
              </FormButtonRow>
            </div>
            <Checkbox
              id="field-order-alphabetically"
              name="field-order-alphabetically"
              label="Order alphabetically (will be ordered as above if unchecked)"
              required
              checked={currentField.orderAlphabetically || false}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentField((curr) => {
                  return {
                    ...curr,
                    orderAlphabetically: e.target.checked,
                  };
                });
                setHasBeenEdited(true);
              }}
            />
            <Checkbox
              id="field-allow-multi"
              name="field-allow-multi"
              label="Allow multiple selections"
              required
              checked={currentField.allowMultiple || false}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentField((curr) => {
                  return {
                    ...curr,
                    allowMultiple: e.target.checked,
                  };
                });
                setHasBeenEdited(true);
              }}
            />
            {currentField.allowMultiple && (
              <Input
                id="field-max-selections"
                name="field-max-selections"
                type="number"
                label="Maximum number of selections"
                placeholder="Enter value > 0"
                value={currentField.multipleLimit}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setUpdateError(null);

                  setCurrentField((curr) => {
                    return {
                      ...curr,
                      multipleLimit: parseInt(e.target.value),
                    };
                  });
                  setHasBeenEdited(true);
                }}
              />
            )}
            {!currentField.allowMultiple && (
              <>
                <Checkbox
                  id="field-include-other"
                  name="field-include-other"
                  label="Include 'Other' option (adds input field when selected)"
                  required
                  checked={currentField.includeOther || false}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setUpdateError(null);

                    setCurrentField((curr) => {
                      const existingDropdownOptions =
                        (curr as EntryFormSelectField).dropdownOptions || [];

                      if (e.target.checked) {
                        existingDropdownOptions.push({
                          name: "Other",
                          value: "other",
                        });
                      } else {
                        existingDropdownOptions.splice(
                          existingDropdownOptions.length - 1,
                          1
                        );
                      }

                      return {
                        ...curr,
                        dropdownOptions: existingDropdownOptions,
                        includeOther: e.target.checked,
                      };
                    });
                    setHasBeenEdited(true);
                  }}
                />
                {currentField.includeOther && (
                  <Input
                    id="field-crm-other-id"
                    name="field-crm-other-id"
                    label="Field ID for 'Other' option"
                    required
                    placeholder="The field's unique ID in Salesforce"
                    value={currentField.salesforceOtherFieldId}
                    onChange={(e) => {
                      setUpdateSuccess(false);
                      setUpdateError(null);

                      setCurrentField((curr) => {
                        return {
                          ...curr,
                          salesforceOtherFieldId: e.target.value,
                        };
                      });
                      setHasBeenEdited(true);
                    }}
                  />
                )}
              </>
            )}
            <Divider />
          </>
        )}
        {currentField.fieldType === "checkbox" && (
          <>
            <Input
              id="field-checkbox-header"
              name="field-checkbox-header"
              label="Field header (shown above the checkbox)"
              placeholder="Enter field header"
              value={currentField.heading}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentField((curr) => {
                  return {
                    ...curr,
                    heading: e.target.value,
                  };
                });
                setHasBeenEdited(true);
              }}
            />
            <Checkbox
              id="field-checked-by-default"
              name="field-checked-by-default"
              label="Checked by default"
              checked={currentField.checkedByDefault || false}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentField((curr) => {
                  return {
                    ...curr,
                    checkedByDefault: e.target.checked,
                  };
                });
                setHasBeenEdited(true);
              }}
            />
            {category.entry_form_fields &&
            category.entry_form_fields.length > 1 ? (
              <Checkbox
                id="field-include-hidden-fields"
                name="field-include-hidden-fields"
                label="Hide fields behind checkbox"
                checked={!!currentField.hiddenFields || false}
                onChange={(e) => {
                  setUpdateSuccess(false);
                  setUpdateError(null);

                  setCurrentField((curr) => {
                    return {
                      ...curr,
                      hiddenFields: e.target.checked
                        ? {
                            ids: [],
                            state: "hidden",
                          }
                        : undefined,
                    };
                  });
                  setHasBeenEdited(true);
                }}
              />
            ) : (
              <p>
                <em>
                  <strong>Note:</strong> You can hide other fields behind this
                  one once you've defined them.
                </em>
              </p>
            )}
            {!!currentField.hiddenFields && (
              <>
                <Divider />
                <h4>Hidden fields</h4>
                <p>
                  The below fields will only show based on whether this field is
                  checked or not
                </p>
                <Checkbox
                  id={`hidden-field-show-state`}
                  name={`hidden-field-show-state`}
                  label="Hide fields when checked"
                  checked={!!currentField.hiddenFields!.hideFieldsWhenChecked}
                  onChange={(e) => {
                    setUpdateSuccess(false);
                    setUpdateError(null);

                    setCurrentField((curr) => {
                      return {
                        ...curr,
                        hiddenFields: {
                          ...(curr as EntryFormCheckboxField).hiddenFields!,
                          hideFieldsWhenChecked: e.target.checked,
                        },
                      };
                    });
                    setHasBeenEdited(true);
                  }}
                />
                <SelectBox
                  id="field-hidden-fields-state"
                  name="field-hidden-fields-state"
                  label="Hidden fields state"
                  required
                  labelKey="name"
                  valueKey="value"
                  options={[
                    { name: "Hidden", value: "hidden" },
                    { name: "Greyed out", value: "greyedOut" },
                  ]}
                  defaultValue={{ name: "Hidden", value: "hidden" }}
                  value={[
                    { name: "Hidden", value: "hidden" },
                    { name: "Greyed out", value: "greyedOut" },
                  ].find(
                    (option) =>
                      option.value === currentField.hiddenFields!.state
                  )}
                  onChange={(newValue) => {
                    setUpdateSuccess(false);
                    setUpdateError(null);

                    // @ts-ignore
                    setCurrentField((curr) => {
                      return {
                        ...curr,
                        hiddenFields: {
                          ...(curr as EntryFormCheckboxField).hiddenFields!,
                          state: newValue?.value,
                        },
                      };
                    });
                    setHasBeenEdited(true);
                  }}
                />
                {otherFields.map((otherField) => {
                  return (
                    <Checkbox
                      key={otherField.fieldId}
                      id={`hidden-field-${otherField.fieldId}`}
                      name={`hidden-field-${otherField.fieldId}`}
                      label={otherField.title}
                      checked={
                        !!currentField.hiddenFields!.ids.includes(
                          otherField.fieldId
                        )
                      }
                      onChange={(e) => {
                        setCurrentField((curr) => {
                          const newIds = [
                            ...(curr as EntryFormCheckboxField).hiddenFields!
                              .ids,
                          ];

                          if (e.target.checked) {
                            // Add
                            newIds.push(otherField.fieldId);
                          } else {
                            // Remove
                            const existingIndex = newIds.indexOf(
                              otherField.fieldId
                            );

                            if (existingIndex > -1) {
                              newIds.splice(existingIndex, 1);
                            }
                          }

                          return {
                            ...curr,
                            hiddenFields: {
                              ...(curr as EntryFormCheckboxField).hiddenFields!,
                              ids: newIds,
                            },
                          };
                        });
                        setHasBeenEdited(true);
                      }}
                    />
                  );
                })}
                <Divider />
              </>
            )}
          </>
        )}
        {currentField.fieldType === "table" && (
          <>
            <Input
              id="field-max-rows"
              name="field-max-rows"
              type="number"
              min={0}
              label="Max rows/values the entrant can input"
              placeholder="Enter value > 0"
              value={currentField.maxTableFields || 6}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(null);

                setCurrentField((curr) => {
                  return {
                    ...curr,
                    maxTableFields: parseInt(e.target.value),
                  };
                });
                setHasBeenEdited(true);
              }}
            />
            <Divider />
            <h4>Table fields</h4>
            <p>
              Each input field below represents a column in the input table.
              Tables can only have two columns of inputs.
            </p>
            <p>
              Table fields in Salesforce will have incremental IDs (starting
              from 1) that can look like 'tableField_6_value'. To integrate,
              these values are built per row according to the prefix and suffix
              provided below. For the given example, the prefix would be
              'tableField_' and the suffix '_value'
            </p>
            <div className={styles.tableFields}>
              <>
                {currentField.tableFields &&
                  currentField.tableFields.map(
                    (tableField, tableFieldIndex) => {
                      return (
                        <React.Fragment key={tableFieldIndex}>
                          {tableFieldIndex > 0 && <Divider />}
                          <Input
                            id={`${tableFieldIndex}-${tableField.tableFieldId}`}
                            name={`${tableFieldIndex}-${tableField.tableFieldId}`}
                            required
                            label="Field name (column header)"
                            placeholder="Enter field name"
                            value={tableField.tableFieldName}
                            onChange={(e) => {
                              setUpdateSuccess(false);
                              setUpdateError(null);

                              setCurrentField((curr) => {
                                const newTableFields = _.cloneDeep(
                                  (curr as EntryFormTableField).tableFields
                                );

                                newTableFields.splice(tableFieldIndex, 1, {
                                  tableFieldId: e.target.value
                                    .toLowerCase()
                                    .replaceAll(/\s/g, "-"),
                                  tableFieldName: e.target.value,
                                  salesforceTableFieldIdPrefix:
                                    newTableFields[tableFieldIndex]
                                      .salesforceTableFieldIdPrefix,
                                  salesforceTableFieldIdSuffix:
                                    newTableFields[tableFieldIndex]
                                      .salesforceTableFieldIdSuffix,
                                  tableFieldRequired:
                                    newTableFields[tableFieldIndex]
                                      .tableFieldRequired,
                                });

                                return {
                                  ...curr,
                                  tableFields: newTableFields,
                                };
                              });
                              setHasBeenEdited(true);
                            }}
                          />
                          <Input
                            id={`${tableFieldIndex}-${tableField.salesforceTableFieldIdPrefix}`}
                            name={`${tableFieldIndex}-${tableField.salesforceTableFieldIdPrefix}`}
                            label="CRM field ID prefix"
                            placeholder="Text before the ID's incrementor..."
                            value={tableField.salesforceTableFieldIdPrefix}
                            onChange={(e) => {
                              setUpdateSuccess(false);
                              setUpdateError(null);

                              setCurrentField((curr) => {
                                const newTableFields = _.cloneDeep(
                                  (curr as EntryFormTableField).tableFields
                                );

                                newTableFields.splice(tableFieldIndex, 1, {
                                  tableFieldId:
                                    newTableFields[tableFieldIndex]
                                      .tableFieldId,
                                  tableFieldName:
                                    newTableFields[tableFieldIndex]
                                      .tableFieldName,
                                  salesforceTableFieldIdPrefix: e.target.value,
                                  salesforceTableFieldIdSuffix:
                                    newTableFields[tableFieldIndex]
                                      .salesforceTableFieldIdSuffix,
                                  tableFieldRequired:
                                    newTableFields[tableFieldIndex]
                                      .tableFieldRequired,
                                });

                                return {
                                  ...curr,
                                  tableFields: newTableFields,
                                };
                              });
                              setHasBeenEdited(true);
                            }}
                          />
                          <Input
                            id={`${tableFieldIndex}-${tableField.salesforceTableFieldIdPrefix}`}
                            name={`${tableFieldIndex}-${tableField.salesforceTableFieldIdPrefix}`}
                            label="CRM field ID suffix"
                            placeholder="Text after the ID's incrementor..."
                            value={tableField.salesforceTableFieldIdSuffix}
                            onChange={(e) => {
                              setUpdateSuccess(false);
                              setUpdateError(null);

                              setCurrentField((curr) => {
                                const newTableFields = _.cloneDeep(
                                  (curr as EntryFormTableField).tableFields
                                );

                                newTableFields.splice(tableFieldIndex, 1, {
                                  tableFieldId:
                                    newTableFields[tableFieldIndex]
                                      .tableFieldId,
                                  tableFieldName:
                                    newTableFields[tableFieldIndex]
                                      .tableFieldName,
                                  salesforceTableFieldIdPrefix:
                                    newTableFields[tableFieldIndex]
                                      .salesforceTableFieldIdPrefix,
                                  salesforceTableFieldIdSuffix: e.target.value,
                                  tableFieldRequired:
                                    newTableFields[tableFieldIndex]
                                      .tableFieldRequired,
                                });

                                return {
                                  ...curr,
                                  tableFields: newTableFields,
                                };
                              });
                              setHasBeenEdited(true);
                            }}
                          />
                          <Checkbox
                            id={`${tableFieldIndex}-${tableField.tableFieldId}-required`}
                            name={`${tableFieldIndex}-${tableField.tableFieldId}-required`}
                            label="Required (Must have a value)"
                            checked={tableField.tableFieldRequired}
                            onChange={(e) => {
                              setUpdateSuccess(false);
                              setUpdateError(null);

                              setCurrentField((curr) => {
                                const newTableFields = _.cloneDeep(
                                  (curr as EntryFormTableField).tableFields
                                );

                                newTableFields.splice(tableFieldIndex, 1, {
                                  tableFieldId:
                                    newTableFields[tableFieldIndex]
                                      .tableFieldId,
                                  tableFieldName:
                                    newTableFields[tableFieldIndex]
                                      .tableFieldName,
                                  salesforceTableFieldIdPrefix:
                                    newTableFields[tableFieldIndex]
                                      .salesforceTableFieldIdPrefix,
                                  salesforceTableFieldIdSuffix:
                                    newTableFields[tableFieldIndex]
                                      .salesforceTableFieldIdSuffix,
                                  tableFieldRequired: e.target.checked,
                                });

                                return {
                                  ...curr,
                                  tableFields: newTableFields,
                                };
                              });
                              setHasBeenEdited(true);
                            }}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                {(!currentField.tableFields ||
                  currentField.tableFields.length < 2) && (
                  <FormButtonRow>
                    <Button
                      buttonType="tertiary"
                      onClick={() => {
                        setCurrentField((curr) => {
                          const newTableFields =
                            (curr as EntryFormTableField).tableFields || [];

                          newTableFields.push({
                            tableFieldId: "",
                            tableFieldName: "",
                            tableFieldRequired: false,
                          });

                          return {
                            ...curr,
                            tableFields: newTableFields,
                          };
                        });
                      }}
                    >
                      Add input/column
                    </Button>
                  </FormButtonRow>
                )}
              </>
            </div>
            <Divider />
          </>
        )}
        <FormButtonRow>
          <Button
            buttonType="primary"
            disabled={
              !hasBeenEdited || !submissionFieldIsComplete(currentField)
            }
            onClick={async () => {
              try {
                let newSubmissionFields = [currentField];

                // Replace the full list of secondary submission fields
                if (category.entry_form_fields) {
                  newSubmissionFields = _.cloneDeep(category.entry_form_fields);

                  newSubmissionFields.splice(
                    category.entry_form_fields.findIndex(
                      (field) => field.fieldId === currentField.fieldId
                    ),
                    1,
                    currentField
                  );
                }

                const { updatedCategory } = await updateAwardsCategory(
                  category.awards_category_id,
                  {
                    entry_form_fields: newSubmissionFields,
                  }
                );

                onUpdate(updatedCategory);
                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to update field.");
              }
            }}
          >
            Update
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && (
        <FormMessage>
          Successfully updated field for category '{category.title}'.
        </FormMessage>
      )}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

interface DeleteFieldModalProps extends AddCategoryFieldModalProps {
  field: {
    fieldId: string;
    title: string;
  };
}

export const DeleteFieldModal = ({
  category,
  field,
  onUpdate,
}: DeleteFieldModalProps) => {
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  return (
    <>
      <h1>Delete field '{field.title}'</h1>
      <p>
        This action is irreversible. Are you sure you want to delete this field?
      </p>
      <div className={styles.form}>
        <FormButtonRow>
          <Button
            buttonType="warning"
            onClick={async () => {
              try {
                const newSubmissionFields = _.cloneDeep(
                  category.entry_form_fields
                );

                const deleteIndex = newSubmissionFields!.findIndex(
                  (f) => f.fieldId === field.fieldId
                );

                newSubmissionFields!.splice(deleteIndex, 1);

                const { updatedCategory } = await updateAwardsCategory(
                  category.awards_category_id,
                  {
                    entry_form_fields: newSubmissionFields,
                  }
                );

                onUpdate(updatedCategory);
                setUpdateSuccess(true);
              } catch (err: any) {
                setUpdateError(err.message || "Unable to update field.");
              }
            }}
          >
            Delete
          </Button>
        </FormButtonRow>
      </div>
      {updateSuccess && (
        <FormMessage>
          Successfully delete field '{field.title}' for category '
          {category.title}'.
        </FormMessage>
      )}
      {updateError && <FormError>{updateError}</FormError>}
    </>
  );
};

export default function AdminManageCategoryFieldsPage() {
  const _window = useWindow();

  const { categoryId } = useParams();

  const [category, setCategory] = useState<AwardsCategoryResponseFull | null>(
    null
  );
  const [fieldToEdit, setFieldToEdit] = useState<EntryFormField | null>(null);
  const [fieldToDelete, setFieldToDelete] = useState<{
    fieldId: string;
    title: string;
  } | null>(null);
  const [deleteFieldModal, setDeleteFieldModal] = useState(false);
  const [addFieldModal, setAddFieldModal] = useState(false);
  const [fieldToCopy, setFieldToCopy] = useState<EntryFormField | null>(null);
  const [editFieldModal, setEditFieldModal] = useState(false);
  const [updateError, setUpdateError] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    const fetchCategory = async () => {
      const { category } = await getAwardsCategory(categoryId);
      setCategory(category);
    };

    fetchCategory();
  }, []);

  return (
    <>
      <SiteWrapper>
        <Container>
          <Row>
            <Column gridCols={8}>
              <Button onClick={() => navigate(-1)}>&lt; Back</Button>
              {category ? (
                <>
                  <h1>
                    Entry form fields for {category.title} ({category.year})
                  </h1>
                  <p>
                    Entry forms for all categories share the following fields:
                  </p>
                  <ol>
                    <li>Title of the entry</li>
                    <li>Short synopsis/Billing</li>
                    {/* // ! No longer used as of 2025 */}
                    {/* <li>Checkbox: Accept British Film Council screening</li> */}
                    <li>
                      Secondary contact information (checkbox for adding, then
                      first name, last name and email fields)
                    </li>
                  </ol>
                  <p>
                    <strong>
                      You do not need to add these fields on this page.
                    </strong>
                  </p>
                  <p>
                    The rest of the entry form for this category is built from
                    the below fields, in the order shown.
                  </p>
                  {updateSuccess && (
                    <FormMessage>
                      <p>Form fields order updated.</p>
                    </FormMessage>
                  )}
                  {updateError && (
                    <FormError>
                      <p>Unable to re-sort fields.</p>
                    </FormError>
                  )}
                  {category.entry_form_fields &&
                  category.entry_form_fields.length > 0 ? (
                    <DragDropContext
                      onDragEnd={async (result) => {
                        const { destination, source } = result;

                        if (
                          !destination ||
                          (destination.droppableId === source.droppableId &&
                            destination.index === source.index)
                        ) {
                          return;
                        }

                        setUpdateSuccess(false);
                        setUpdateError(false);

                        try {
                          const newFieldsOrdered = _.cloneDeep(
                            category.entry_form_fields!
                          );

                          const fieldToMove = newFieldsOrdered.splice(
                            source.index,
                            1
                          )[0];

                          newFieldsOrdered.splice(
                            destination.index,
                            0,
                            fieldToMove
                          );

                          const { updatedCategory } =
                            await updateAwardsCategory(
                              category.awards_category_id,
                              {
                                entry_form_fields: newFieldsOrdered,
                              }
                            );

                          setCategory(updatedCategory);
                          setUpdateSuccess(true);
                        } catch (err: any) {
                          setUpdateError(true);
                        }
                      }}
                    >
                      <Droppable droppableId="paramSorter">
                        {(droppableProvided) => {
                          return (
                            <div
                              ref={droppableProvided.innerRef}
                              {...droppableProvided.droppableProps}
                            >
                              {category.entry_form_fields!.map(
                                (field, fieldIndex) => {
                                  return (
                                    <Draggable
                                      key={field.fieldId}
                                      draggableId={field.fieldId}
                                      index={fieldIndex}
                                    >
                                      {(draggableProvided) => {
                                        return (
                                          <div
                                            ref={draggableProvided.innerRef}
                                            {...draggableProvided.draggableProps}
                                          >
                                            {fieldIndex !== 0 && <Divider />}
                                            <FormButtonRow
                                              className={styles.fieldRow}
                                            >
                                              <BurgerIcon
                                                {...draggableProvided.dragHandleProps}
                                              />
                                              <div
                                                className={
                                                  styles.fieldNameContainer
                                                }
                                              >
                                                <h3 style={{ margin: 0 }}>
                                                  {field.title}
                                                </h3>
                                                {field.required && (
                                                  <span>
                                                    <em>(required)</em>
                                                  </span>
                                                )}
                                                {field.hiddenByFieldId && (
                                                  <p
                                                    style={{ marginBottom: 0 }}
                                                  >
                                                    <em>
                                                      *This field shows
                                                      depending on the value of
                                                      the checkbox field{" "}
                                                      {category.entry_form_fields!.find(
                                                        (f) =>
                                                          f.fieldId ===
                                                          field.hiddenByFieldId
                                                      )?.title ||
                                                        field.hiddenByFieldId}
                                                    </em>
                                                  </p>
                                                )}
                                              </div>
                                              <div
                                                className={styles.actionBtns}
                                              >
                                                <EditButton
                                                  onClick={() => {
                                                    setFieldToEdit(field);
                                                    setEditFieldModal(true);
                                                  }}
                                                />
                                                <CopyButton
                                                  onClick={() => {
                                                    setFieldToCopy(field);
                                                    setAddFieldModal(true);
                                                  }}
                                                />
                                                <DeleteButton
                                                  onClick={() => {
                                                    setDeleteFieldModal(true);
                                                    setFieldToDelete({
                                                      fieldId: field.fieldId,
                                                      title: field.title,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </FormButtonRow>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                }
                              )}
                              {droppableProvided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    <p>
                      <em>No fields found.</em>
                    </p>
                  )}
                  <Divider />
                  <FormButtonRow>
                    <Button
                      buttonType="primary"
                      onClick={() => setAddFieldModal(true)}
                    >
                      Add new field
                    </Button>
                  </FormButtonRow>
                </>
              ) : (
                <p>Failed to fetch category.</p>
              )}
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
      {category && deleteFieldModal && fieldToDelete && (
        <Modal
          onClose={() => {
            setDeleteFieldModal(false);
            setFieldToDelete(null);
          }}
        >
          <DeleteFieldModal
            category={category}
            field={fieldToDelete}
            onUpdate={(updatedCategory) => {
              setCategory(updatedCategory);

              _window.setTimeout(() => {
                setDeleteFieldModal(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
      {category && editFieldModal && fieldToEdit && (
        <Modal
          onClose={() => {
            setEditFieldModal(false);
            setFieldToEdit(null);
          }}
        >
          <EditCategoryFieldModal
            category={category}
            fieldToEdit={fieldToEdit}
            onUpdate={(updatedCategory) => {
              setCategory(updatedCategory);

              _window.setTimeout(() => {
                setEditFieldModal(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
      {category && addFieldModal && (
        <Modal
          onClose={() => {
            setAddFieldModal(false);
            setFieldToCopy(null);
          }}
        >
          <AddCategoryFieldModal
            category={category}
            onUpdate={(updatedCategory) => {
              setCategory(updatedCategory);

              _window.setTimeout(() => {
                setAddFieldModal(false);
                setFieldToCopy(null);
              }, 2000);
            }}
            fieldToCopy={fieldToCopy}
          />
        </Modal>
      )}
    </>
  );
}
