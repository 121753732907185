import { S3Client } from "@aws-sdk/client-s3";
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import UploaderError, { Overwrite } from "../components/UploaderError";
import { awsAbortMpu } from "../services/mpu-methods";
import {
  chunkUint8Arrays,
  copyLocalStorage,
  deleteLocalStorageProp,
} from "../utils/uploader";
import {
  checkFileExists,
  initiateMpu,
  resumeMpu,
  smallFileUpload,
} from "../utils/uploadHandlers";
import { AwardsEntryFileResponse } from "../../api/types/awards-entries";
import { supportEmail } from "../../core/variables";

interface UseMpuProps {
  entry: {
    entryID: number;
    entryTitle: string;
    categoryTitle: string;
  };
  s3Client: S3Client;
  filesList: AwardsEntryFileResponse[];
  setFilesList: Dispatch<SetStateAction<AwardsEntryFileResponse[]>>;
}

const useMultipartUpload = ({
  entry,
  s3Client,
  filesList,
  setFilesList,
}: UseMpuProps) => {
  const [inProgressUploads, setInProgressUploads] = useState<PendingUpload[]>(
    []
  );
  const [suspendedUploads, setSuspendedUploads] = useState<SuspendedUpload[]>(
    []
  );
  const [genericError, setGenericError] = useState<ReactElement[]>([]);
  const [loadingFile, setLoadingFile] = useState<boolean[]>([]);

  useEffect(() => {
    setInProgressUploads(copyLocalStorage());

    // Trigger resuming of uploads in a new session here
    const ls = window.localStorage.getItem("inProgressUploads");

    if (ls) {
      const parsed: LocalStoragePendingUploads = JSON.parse(ls);

      setSuspendedUploads(
        Object.entries(parsed).map(([name, item]) => ({
          id: item.uploadId,
          name,
          size: item.size,
          lastUploadedPart: item.nextPart,
        }))
      );
    }
  }, [s3Client]);

  const abortUpload = useCallback(
    async (fileName: string, uploadId: string) => {
      await awsAbortMpu(s3Client, fileName, uploadId);

      deleteLocalStorageProp(fileName);

      setInProgressUploads(copyLocalStorage());
    },
    [entry, s3Client]
  );

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach(async (file: File, index) => {
      if (inProgressUploads.length > 4 || index > 4) {
        // Limit to 5 uploads at a time
        setGenericError((curr) => {
          const newErrors = [...curr];

          const newError = (
            <UploaderError
              setError={setGenericError}
              fileName={`${entry?.entryID} - ${file.name}`}
            >
              <>
                Can only upload 5 files at a time. Please wait until one of your
                uploads has finished, and then try to re-upload "{file.name}".
              </>
            </UploaderError>
          );

          newErrors.push(newError);

          return newErrors;
        });

        // Remove this error after a short while
        window.setTimeout(() => {
          setGenericError((current) => {
            const removedError = [...current];
            removedError.splice(
              removedError.findIndex(
                (error) =>
                  error.toString().indexOf(`${entry?.entryID} - ${file.name}`) >
                  -1
              ),
              1
            );

            return removedError;
          });
        }, 4000);
      } else {
        try {
          const size = file.size;

          if (size > 5 * 1024 * 1024) {
            // Large file (> 5MB): upload in parts
            const totalChunks = Math.ceil(size / (5 * 1024 * 1024));

            const stream = file.stream() as unknown as ReadableStream;

            const reader = stream.getReader();

            const suspendedUpload = suspendedUploads.find(
              (suspendedUpload) =>
                suspendedUpload.name === `${entry?.entryID} - ${file.name}` &&
                suspendedUpload.size === file.size
            );

            if (suspendedUpload) {
              // Existing file: read all previously written chunks before uploading
              setLoadingFile((curr) => {
                const out = [...curr];

                out.push(true);

                return out;
              });

              let heldValue = new Uint8Array(0);
              let partCount = 1;

              while (partCount < suspendedUpload.lastUploadedPart) {
                const { done, value } = await reader.read();

                if (done) break;

                if (heldValue.length === 0) heldValue = value;
                else {
                  const { merged, remaining } = chunkUint8Arrays(
                    heldValue,
                    value
                  );

                  heldValue = remaining;

                  if (merged.length > 0) {
                    partCount++;
                  }
                }
              }

              setLoadingFile((curr) => {
                const out = [...curr];

                out.splice(out.length + (index - 2), 1);

                return out;
              });

              await resumeMpu({
                s3Client,
                entryId: entry.entryID,
                id: suspendedUpload.id,
                fileName: `${entry?.entryID} - ${file.name}`,
                fileType: file.type,
                size: file.size,
                totalChunks,
                startingChunk: partCount,
                setInProgressUploads,
                setFilesList,
                reader,
                setSuspendedUploads,
                remaining: heldValue,
              });
            } else if (
              checkFileExists(filesList, `${entry?.entryID} - ${file.name}`)
            ) {
              const existingFileId = filesList.find(
                (f) => f.file_name === `${entry?.entryID} - ${file.name}`
              )?.entry_file_id;

              // Warning message to overwrite existing file
              setGenericError((curr) => {
                const newErrors = [...curr];

                const newError = (
                  <UploaderError
                    setError={setGenericError}
                    fileName={`${entry?.entryID} - ${file.name}`}
                  >
                    <Overwrite
                      s3Client={s3Client}
                      setFilesList={setFilesList}
                      entryId={entry?.entryID?.toString() || ""}
                      fileOverwriteId={existingFileId}
                      file={file}
                      size={size}
                      totalChunks={totalChunks}
                      reader={reader}
                      setInProgressUploads={setInProgressUploads}
                      setError={setGenericError}
                    />
                  </UploaderError>
                );

                newErrors.push(newError);

                return newErrors;
              });
            } else {
              // New file: begin separate upload
              await initiateMpu({
                entryId: entry?.entryID?.toString() || "",
                s3Client,
                file,
                size,
                totalChunks,
                setInProgressUploads,
                setFilesList,
                reader,
              });
            }
          } else {
            // Small file (< 5MB): Simple upload
            if (
              checkFileExists(filesList, `${entry?.entryID} - ${file.name}`)
            ) {
              const existingFileId = filesList.find(
                (f) => f.file_name === `${entry?.entryID} - ${file.name}`
              )?.entry_file_id;

              // Warning message to overwrite existing file
              setGenericError((curr) => {
                const newErrors = [...curr];

                const newError = (
                  <UploaderError
                    setError={setGenericError}
                    fileName={`${entry?.entryID} - ${file.name}`}
                  >
                    <Overwrite
                      s3Client={s3Client}
                      setFilesList={setFilesList}
                      entryId={entry?.entryID?.toString() || ""}
                      fileOverwriteId={existingFileId}
                      file={file}
                      size={size}
                      smallFile
                      setInProgressUploads={setInProgressUploads}
                      setError={setGenericError}
                    />
                  </UploaderError>
                );

                newErrors.push(newError);

                return newErrors;
              });
            } else {
              await smallFileUpload({
                entryId: entry?.entryID?.toString() || "",
                file,
                size,
                setFilesList,
                s3Client,
              });
            }
          }
        } catch (err) {
          if (err !== "Ending upload: aborted!") {
            console.error(err);

            setGenericError((curr) => {
              const newErrors = [...curr];

              const newError = (
                <UploaderError
                  setError={setGenericError}
                  fileName={`${entry?.entryID} - ${file.name}`}
                >
                  <>
                    There was a problem uploading {file.name}. Please reload and
                    try again, or contact{" "}
                    <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                  </>
                </UploaderError>
              );

              newErrors.push(newError);

              return newErrors;
            });
          }
        }
      }
    });
  };

  return {
    inProgressUploads,
    suspendedUploads,
    setSuspendedUploads,
    genericError,
    loadingFile,
    abortUpload,
    onDrop,
  };
};

export default useMultipartUpload;
