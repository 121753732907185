import React from "react";
import Select, { GroupBase, Props } from "react-select";
import classNames from "classnames";
import _ from "lodash";

import { StyledMarkdown } from "./typeface";
import * as styles from "../styles/select-box.module.scss";

export interface SelectItem {
  name?: string;
  value: string;
}

interface OptionTypeBase {
  label?: string;
  value?: string;
  [key: string]: any;
}

interface GroupTypeBase<T> extends GroupBase<T> {
  icon?: string;
}

interface SelectBoxProps<
  Option extends OptionTypeBase,
  IsMulti extends boolean = boolean,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
> extends Props<Option, IsMulti, Group> {
  label?: string;
  dark?: boolean;
  stacked?: boolean;
  introMsg?: string;
  error?: boolean;
  errorMsg?: string;
  /** The key to be used as the option label */
  labelKey?: string;
  /** The key to be used as the option value */
  valueKey?: string;
  children?: React.ReactNode;
}

/** Documentation: https://react-select.com/props#select-props */
const SelectBox = <
  Option extends OptionTypeBase,
  IsMulti extends boolean = false
>({
  id,
  className,
  label,
  dark = false,
  stacked = false,
  introMsg,
  error,
  errorMsg,
  labelKey = "label",
  valueKey = "value",
  isSearchable = false,
  children,
  ...props
}: SelectBoxProps<Option, IsMulti, GroupTypeBase<Option>>) => {
  const selectContainer = (
    <Select
      blurInputOnSelect={!props.isMulti}
      closeMenuOnSelect={!props.isMulti}
      isSearchable={isSearchable}
      hideSelectedOptions={false}
      {...props}
      getOptionLabel={(option) => (labelKey ? option[labelKey] : option.label)}
      getOptionValue={(option) => (valueKey ? option[valueKey] : option.value)}
      // Adds specific classNames to each part of the dropdown
      classNames={{
        container: () => styles.selectContainer,
        control: (state) =>
          classNames(styles.control, {
            [styles.isFocused]: state.isFocused,
            [styles.dark]: dark,
            [styles.disabled]: props.isDisabled,
            [styles.error]: error,
          }),
        placeholder: () =>
          classNames(styles.placeholder, {
            [styles.dark]: dark,
            [styles.disabled]: props.isDisabled,
          }),
        input: () => styles.input,
        valueContainer: () => styles.valueContainer,
        dropdownIndicator: () =>
          classNames(styles.dropdownIndicator, {
            [styles.disabled]: props.isDisabled,
          }),
        clearIndicator: () => styles.clearIndicator,
        indicatorSeparator: () =>
          classNames(styles.indicatorSeparator, {
            [styles.disabled]: props.isDisabled,
          }),
        menu: () =>
          classNames(styles.menu, {
            [styles.dark]: dark,
          }),
        singleValue: () =>
          classNames(styles.singleValue, {
            [styles.dark]: dark,
          }),
        option: (state) =>
          classNames(styles.option, {
            [styles.dark]: dark,
            [styles.isSelected]: state.isSelected,
            [styles.isFocused]: state.isFocused,
          }),
        multiValueLabel: () => styles.multiValueLabel,
        ...props.classNames,
      }}
    />
  );

  return (
    <div className={classNames(className, styles.fieldContainer)}>
      {label ? (
        <div
          className={classNames(styles.labelContainer, {
            [styles.stacked]: stacked,
            [styles.disabled]: props.isDisabled,
          })}
        >
          <label htmlFor={id}>
            {label}{" "}
            {props.required && <span className={styles.required}>*</span>}
          </label>
          {introMsg && (
            <StyledMarkdown className={styles.introMsg}>
              {introMsg}
            </StyledMarkdown>
          )}
          {selectContainer}
        </div>
      ) : (
        selectContainer
      )}
      {error && <span className={styles.errorMsg}>{errorMsg || "Error"}</span>}
      {children}
    </div>
  );
};

export const SelectBoxMulti = <Option extends OptionTypeBase>({
  ...props
}: Omit<
  SelectBoxProps<Option, true, GroupTypeBase<Option>>,
  | "isMulti"
  | "closeMenuOnSelect"
  | "tabSelectsValue"
  | "blurOnInputSelect"
  | "getOptionLabel"
  | "getValueLabel"
  | "hideSelectedOptions"
>) => {
  return <SelectBox {...props} isMulti />;
};

export default SelectBox;
