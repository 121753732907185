import React, { useCallback, useState, useContext } from "react";

import Button from "../components/button";
import { FormError, FormButtonRow, Form } from "../components/form";
import Input, { Checkbox } from "../components/input";
import { Column, Container, Row, LineTextDivider } from "../components/layout";
import SiteWrapper from "../components/SiteWrapper";
import { AccountContext } from "../context/account";
import { isValidEmail } from "../utils/index";
import { login } from "../api/auth";

export default function LoginPage() {
  const { setAccount } = useContext(AccountContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(async () => {
    const isValid = isValidEmail(email);

    if (email === "" || password === "") {
      setError("Both fields are required.");
    } else if (!isValid) {
      setError("Please enter a valid email.");
    } else {
      setError(null);

      try {
        const res = await login({ email, password, rememberMe });

        if (res) {
          const {
            data: { account },
          } = res;

          if (account.uid && account.token) {
            localStorage.setItem("token", account.token);

            setAccount((currAccount) => {
              return {
                ...currAccount,
                loggedIn: true,
                checked: true,
                accountConfirmed: account.is_confirmed,
                accountConfirmationEmailSent:
                  account.account_confirmation_email_sent,
                uid: account.uid,
                email: account.email,
                first_name: account.first_name,
                last_name: account.last_name,
                admin_id: account.admin_id,
                judge_id: account.judge_id,
                entrant_id: account.entrant_id,
              };
            });
          }
        }
      } catch (err: any) {
        setError(err.message || "Error logging in.");
      }
    }
  }, [email, password]);

  return (
    <SiteWrapper hideStickyNav>
      <Container>
        <Row>
          <Column gridCols={4}>
            <h1>Login</h1>
            <Form onSubmit={onSubmit}>
              <>
                <Input
                  dark
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!error}
                />
                <Input
                  dark
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={!!error}
                />
                {error && (
                  <FormError>
                    <p>{error}</p>
                  </FormError>
                )}
                <Checkbox
                  dark
                  id="remember-me"
                  label="Remember me"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <FormButtonRow>
                  <Button buttonType="link" href="/forgot-password">
                    Forgot my password
                  </Button>
                  <Button buttonType="primary">Login</Button>
                </FormButtonRow>
              </>
            </Form>
            <LineTextDivider label="OR">
              <Button buttonType="link" href="/create-account">
                Create a new account
              </Button>
            </LineTextDivider>
          </Column>
        </Row>
      </Container>
    </SiteWrapper>
  );
}
