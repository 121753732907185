import { AxiosResponse } from "axios";

import { endpoint } from ".";
import {
  AwardsSponsorBody,
  AwardsSponsorResponse,
} from "./types/awards-sponsors";

const basePath = "/awards/sponsors";

export const getAwardsSponsors = async () => {
  try {
    const res: AxiosResponse<{ sponsors: AwardsSponsorResponse[] }> =
      await endpoint.get(basePath);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getCategorySponsor = async (sponsorId: number) => {
  try {
    const res: AxiosResponse<{ sponsor: AwardsSponsorResponse }> =
      await endpoint.get(`${basePath}/${sponsorId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const addSponsor = async (details: AwardsSponsorBody) => {
  try {
    const formData = new FormData();
    formData.append("name", details.name);

    if (details.logo) {
      formData.append("logo", details.logo);
    }

    if (details.logo_bw) {
      formData.append("logo_bw", details.logo_bw);
    }

    const res: AxiosResponse<{ newSponsor: AwardsSponsorResponse }> =
      await endpoint.post(basePath, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateSponsor = async (
  sponsorId: number,
  details: Partial<AwardsSponsorBody> & {
    logo_to_replace?: string;
    logo_bw_to_replace?: string;
  }
) => {
  try {
    const formData = new FormData();

    if (details.name) {
      formData.append("name", details.name);
    }

    if (details.logo_to_replace) {
      formData.append("logo_to_replace", details.logo_to_replace);
    }

    if (details.logo_bw_to_replace) {
      formData.append("logo_bw_to_replace", details.logo_bw_to_replace);
    }

    if (details.logo) {
      formData.append("logo", details.logo);
    }

    if (details.logo_bw) {
      formData.append("logo_bw", details.logo_bw);
    }

    const res: AxiosResponse<{ updatedSponsor: AwardsSponsorResponse }> =
      await endpoint.post(`${basePath}/${sponsorId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const deleteSponsor = async (sponsorId: number) => {
  try {
    const res: AxiosResponse<{ removedSponsor: string }> =
      await endpoint.delete(`${basePath}/${sponsorId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
