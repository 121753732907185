// extracted by mini-css-extract-plugin
export var charityInfo = "footer-module--charityInfo--e0e83";
export var copy = "footer-module--copy--72809";
export var footerContainer = "footer-module--footerContainer--619cb";
export var infoSection = "footer-module--infoSection--6e6aa";
export var link = "footer-module--link--89f6d";
export var links = "footer-module--links--043ff";
export var linksContainer = "footer-module--linksContainer--d3805";
export var linksSection = "footer-module--linksSection--2f813";
export var loggedInAs = "footer-module--loggedInAs--2b0bd";
export var logo = "footer-module--logo--c7477";
export var newsletterSubmit = "footer-module--newsletterSubmit--a5919";
export var socialLogo = "footer-module--socialLogo--94f3e";
export var socials = "footer-module--socials--3ae2b";
export var title = "footer-module--title--2625c";