import { AxiosResponse } from "axios";

import { endpoint } from ".";
import { AwardsEntryResponseLimited } from "./types/awards-entries";
import { AwardsYearJudgesQueryParams } from "./types/awards-years";

const basePath = "/awards/judges";

export const getJudgesByYear = async (
  year?: number,
  queryParams?: AwardsYearJudgesQueryParams
) => {
  try {
    const { limit, page, sort, sortOrder, search, category, judgingRound } =
      queryParams || {};

    let queryString = limit ? `&limit=${limit}` : "&limit=1000";

    if (page) queryString += `&page=${page}`;
    if (category) queryString += `&category=${category}`;
    if (sort) queryString += `&sort=${sort}`;
    if (sortOrder) queryString += `&sortOrder=${sortOrder}`;
    if (search) queryString += `&search=${search}`;
    if (judgingRound) queryString += `&judgingRound=${judgingRound}`;

    const res: AxiosResponse<{ judges: Judge[]; total_count: number }> =
      await endpoint.get(
        `${basePath}${year ? `?year=${year}${queryString}` : ""}`
      );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getJudgeById = async (judgeId: Judge["judge_id"]) => {
  try {
    const res: AxiosResponse<{ judge: JudgeFull }> = await endpoint.get(
      `${basePath}/${judgeId}`
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getJudgeEntryById = async (
  entryId: JudgeEntrySummary["entry_id"]
) => {
  try {
    const res: AxiosResponse<{ entry: AwardsEntryResponseLimited }> =
      await endpoint.get(`${basePath}/entries/${entryId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const getJudgeCategoryEntries = async (
  categoryId: JudgeCategory["awards_category_id"]
) => {
  try {
    const res: AxiosResponse<{ entries: JudgeEntrySummary[] }> =
      await endpoint.get(`${basePath}/categories/${categoryId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const addJudge = async (details: {
  description: Judge["description"];
}) => {
  try {
    const res: AxiosResponse<{ newJudge: Judge["judge_id"] }> =
      await endpoint.post(`${basePath}`, details);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const removeJudge = async (judgeId: Judge["judge_id"]) => {
  try {
    const res: AxiosResponse<{ deletedJudge: Judge["judge_id"] }> =
      await endpoint.delete(`${basePath}/${judgeId}`);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const updateJudge = async (
  judgeId: Judge["judge_id"],
  details: { description: Judge["description"] }
) => {
  try {
    const res: AxiosResponse<{ updatedJudge: Judge["judge_id"] }> =
      await endpoint.post(`${basePath}/${judgeId}`, details);

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const addJudgeCategories = async (
  judgeId: Judge["judge_id"],
  details: {
    first_round_categories: number[];
    second_round_categories: number[];
    chair_categories: number[];
  }
) => {
  try {
    const res: AxiosResponse<{ categories: number[] }> = await endpoint.post(
      `${basePath}/${judgeId}/add-categories`,
      details
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};

export const removeJudgeCategories = async (
  judgeId: Judge["judge_id"],
  details: {
    first_round_categories: number[];
    second_round_categories: number[];
    chair_categories: number[];
  }
) => {
  try {
    const res: AxiosResponse<{ categories: number[] }> = await endpoint.post(
      `${basePath}/${judgeId}/remove-categories`,
      details
    );

    return res.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    } else {
      throw error;
    }
  }
};
