import React from "react";

import Button from "./button";
import { Divider } from "./typeface";
import { supportEmail } from "../core/variables";
import * as styles from "../styles/HowToEnter.module.scss";

export default function HowToEnter() {
  return (
    <div className={styles.howToEnterModal}>
      <h2>How to Enter and Entry Form</h2>
      <ul>
        <li>
          Make sure you read the{" "}
          <Button
            buttonType="link"
            href="https://griersontrust.org/grierson-awards/terms-and-conditions"
          >
            terms and conditions
          </Button>{" "}
          and{" "}
          <Button
            buttonType="link"
            href="https://griersontrust.org/grierson-awards"
          >
            awards categories
          </Button>{" "}
          before completing your entry.
        </li>
        <li>You must log in and complete an entry form for each entry.</li>
        <li>
          Once you have logged in, information is saved so you can go back to
          your entry to edit it before submitting. You will not be able to make
          any amendments to the information after you have submitted your entry,
          so please ensure that all the information is accurate before you
          submit.
        </li>
        <li>
          At the end of the entry process, you will be required to select a
          payment option - please see below for information on{" "}
          <strong>Entry Fees and Payment</strong>.
        </li>
        <li>
          Once you have submitted your entry you will receive an email
          confirmation from us which includes a link to enable you to upload
          your film or episodes to the Grierson Awards portal. For recommended
          formats see the section, <strong>Uploading Your Digital Files</strong>{" "}
          at the bottom of this page.
        </li>
        <li>
          Once you have uploaded your digital files, you will then need to
          complete your entry. You will get a final email confirmation of
          completion.
        </li>
      </ul>
      <Divider />
      <h2>Entry Qualification</h2>
      <ul>
        <li>
          All entries must have had their first UK screening between 1 June 2024
          and 31 May 2025. This includes television broadcast, scheduled
          screenings and screenings within recognised film, television and
          documentary festivals, theatrical releases and entries will be
          accepted which have screened on digital platforms only - e.g. Netflix,
          iPlayer, Amazon Prime - as long as the screening was accessible to a
          UK-based audience. Digital screenings must be on a credible,
          recognised platform and not just uploaded to the filmmakers' own
          YouTube or Vimeo channel. Screenings at Universities and Colleges are
          acceptable for the Best Student Documentary category.
        </li>
        <li>
          Entries for the Best Documentary Series and Best Returning Documentary
          Series categories must provide <strong>only two episodes</strong> from
          the series for judging.
        </li>
        <li>
          We will accept entries into the Series categories if one or more
          episodes are screened within the qualifying period.
        </li>
        <li>
          In the case of entries into the Best Cinema Documentary category, the
          film must have had a theatrical release in the UK within the
          qualifying period. For the purposes of this award, a theatrical
          release is a screening in a UK cinema, open to the general public, for
          a period of at least seven days. The screenings can be in different
          cinemas and do not have to be on consecutive days. If you have any
          questions about preview screenings and the impact on eligibility,
          email{" "}
          <Button buttonType="link" href="mailto:awards@griersontrust.org">
            awards@griersontrust.org
          </Button>{" "}
          and we can advise.
        </li>
        <li>
          For the Single Documentary Categories - Domestic and International -
          only single documentaries will be accepted, not episodes from a
          series; though episodes from strands are acceptable, e.g. Panorama,
          Dispatches, etc.
        </li>
        <li>
          Fully dramatised programmes or drama docs are not eligible for the
          awards.
        </li>
        <li>
          You may submit your entry into a maximum of two categories plus the
          Best Documentary Presenter category. So, for example you may enter the
          same programme or series into the Best Arts and Music Documentary
          category, Best Documentary Series and Best Documentary Presenter but
          NOT in Arts and Music, Series and Best Popular Culture Documentary.
        </li>
        <li>
          For each individual entry into each individual category, you must
          provide a separate online entry form and pay a separate entry fee.
        </li>
        <li>
          Entrants will need to provide a short statement (maximum 500 words)
          about the steps that were taken to ensure diversity and inclusion
          (D&I) practices were embedded in the making of the film or series. We
          are interested to know what efforts have been made to include
          under-represented groups both on and off screen; as well as any themes
          and narratives that address diverse subjects and themes. You may also
          provide information on the makeup of your team but do not give the
          names of individuals in your answer. This is a compulsory question,
          and judges will be taking into account all D&I statements in their
          selection of longlists and final nominations. Entrants to the Student
          Category are not required to provide a D&I statement, but can provide
          their own short personal statement to support their entry.
        </li>
        <li>
          Entrants to the Crime and Justice category will need to provide a
          short statement (maximum 500 words) about the steps that were taken to
          ensure duty of care practices were embedded in the making of the film
          or series for contributors and members of the production team. We are
          interested to know what efforts have been made to ethical
          considerations both on and off screen, including (but not limited to)
          informed consent, safety measures, privacy and anonymity, aftercare
          support, and ethical journalism standards. This is a compulsory
          question, and judges will be considering all duty of care statements
          in their selection of longlist and final nominations.
        </li>
        <li>
          Entrants can provide the names of up to six key creatives who are
          responsible for the production. These people will be credited at the
          final nominations stage on the Grierson Trust website and in the
          printed programme given to all guests at the awards ceremony.
          Alternatively, entrants can opt for 'The Production Team' instead of
          named individuals.
        </li>
        <li>
          <Button
            buttonType="link"
            href="https://griersontrust.org/grierson-awards/judging-process"
          >
            Read about the judging process
          </Button>{" "}
          and broad criteria for judging.
        </li>
      </ul>
      <Divider />
      <h2>Entry Fees and Payment</h2>
      <p>
        Payment can be made online via PayPal, either with a debit or credit
        card or via your PayPal account, when you submit your entry. An
        automated email will be sent to the Billing Contact with an invoice and
        link to pay. You can pay by Visa/Delta/Electron, MasterCard/Eurocard,
        Maestro or American Express. Alternatively, you can call us with your
        credit card details (Visa and MasterCard only) or you can pay by bank
        transfer.
      </p>
      <p>
        <strong>The fees per entry are:</strong>
      </p>
      <ul>
        <li>
          All main categories: <strong>£350 + VAT @ 20% (£420)</strong>
        </li>
        <li>
          Best Documentary Short: <strong>£140 + VAT @ 20% (£168)</strong>.
          Shorts that have been produced independently (i.e. self-funded), have
          not received funding from external sources (including
          grants/foundations), and which are not attached to a distributor,
          platform or broadcaster will be eligible for a discounted rate of
          £99+VAT per entry. For more information on how to obtain the discount,
          freelancers can contact{" "}
          <Button buttonType="link" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </Button>
          .
        </li>
        <li>
          Best Student Documentary: <strong>£35 + VAT @ 20% (£42)</strong>
        </li>
      </ul>
      <p>
        Entries will not be accepted into the competition until payment has been
        received and this should be no later than <strong>4 June 2025</strong>.
        Uploads of entered programmes must be made by{" "}
        <strong>4 June 2025</strong>.
      </p>
      <Divider />
      <h2>Uploading Your Digital Files for Judging</h2>
      <p>
        Once the submission is made on the website you will receive a
        confirmation email. You will then be able to upload your files for
        judging. We recommend that you submit your files in one of the following
        specifications:
      </p>
      <ul>
        <li>
          Maximum file size of around <strong>4GB</strong> per file
        </li>
        <li>
          Video <strong>SD (Standard Definition)</strong>
        </li>
        <li>
          <strong>MP4</strong> (codec <strong>H.264</strong>)
        </li>
        <li>
          Aspect ratio <strong>16:9</strong>
        </li>
      </ul>
    </div>
  );
}
