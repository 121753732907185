import React, { useState } from "react";

import { completeEntry } from "../api/awards-entries";
import Button from "./button";
import { FormButtonRow } from "./form";
import { Modal } from "./modal";
import { supportEmail } from "../core/variables";
import * as styles from "../styles/CompleteEntryModal.module.scss";

interface CompleteEntryModalProps {
  entryID: number;
  onComplete?: (completedEntry: {
    entry_id: string;
    title: string;
    is_paid: boolean;
  }) => void;
  onClose: () => void;
}

export default function CompleteEntryModal({
  entryID,
  onComplete,
  onClose,
}: CompleteEntryModalProps) {
  const [completeError, setCompleteError] = useState<string | null>(null);

  return (
    <Modal className={styles.completeModal} onClose={onClose}>
      <>
        <h3>Are you sure?</h3>
        <p>
          You will not be able to edit or add more files to this entry once you
          have submitted it.
        </p>
        <FormButtonRow className={styles.completeButtons}>
          <Button buttonType="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            buttonType="primary"
            onClick={async () => {
              try {
                const { completedEntry } = await completeEntry(entryID);

                if (onComplete) {
                  onComplete(completedEntry);
                }

                onClose();
              } catch (err: any) {
                setCompleteError(err.message || "");
              }
            }}
          >
            Confirm
          </Button>
        </FormButtonRow>
        {completeError !== null && (
          <p className={styles.completeError}>
            {completeError !== "" ? (
              <>{completeError}</>
            ) : (
              <>
                Failed to complete entry. Please try again later or contact{" "}
                <Button buttonType="link" href={`mailto:${supportEmail}`}>
                  {supportEmail}
                </Button>
                .
              </>
            )}
          </p>
        )}
      </>
    </Modal>
  );
}
