import React, { useEffect } from "react";
import classNames from "classnames";
import _ from "lodash";

import Input, { Checkbox, DateField, TextArea } from "./input";
import SelectBox, { SelectBoxMulti, SelectItem } from "./select-box";
import { Divider, StyledMarkdown } from "./typeface";
import {
  EntryFormCheckboxField,
  EntryFormField,
  EntryFormFieldValue,
} from "../api/types/awards-category-fields";
import {
  AwardsEntryResponseLimited,
  NewEntry,
} from "../api/types/awards-entries";
import * as styles from "../styles/EntryForm.module.scss";
import { hideBehindParentField } from "../utils/entries ";

interface EntryFormSecondaryFieldsProps {
  entry: NewEntry | AwardsEntryResponseLimited;
  secondaryFields?: EntryFormField[];
  updateForm: (
    field: string,
    value: EntryFormFieldValue["value"],
    secondaryOptions?: {
      salesforceFieldId?: string;
      fieldTitle?: string;
      required?: boolean;
      valueName?: string;
      otherValue?: string;
      salesforceOtherFieldId?: string;
      parentField?: EntryFormFieldValue["parentField"];
      formNotUpdated?: boolean;
    }
  ) => void;
  parentField?: EntryFormField;
  /** Used in recursion within checkbox fields only. It's used to keep fields visible even when parent checkbox blocks their input (greyedOut). */
  isDisabled?: boolean;
}

const EntryFormSecondaryFields = ({
  entry,
  secondaryFields,
  updateForm,
  parentField,
  isDisabled = false,
}: EntryFormSecondaryFieldsProps) => {
  if (!secondaryFields || secondaryFields.length === 0) {
    return null;
  }

  const { entry_form_field_values: fieldValues } = entry;

  // Special use case ensures value is set so it can be sent to CRM
  useEffect(() => {
    const checkedByDefaultFields = secondaryFields.filter(
      (field) =>
        field.fieldType === "checkbox" &&
        field.salesforceFieldId &&
        field.checkedByDefault &&
        !field.hiddenByFieldId
    );

    if (checkedByDefaultFields.length > 0) {
      checkedByDefaultFields.forEach((field) => {
        const fieldValue = fieldValues?.find(
          (f) => f.fieldId === field.fieldId
        );

        if (fieldValue && fieldValue.value === undefined) {
          updateForm(field.fieldId, true, {
            salesforceFieldId: field.salesforceFieldId,
            fieldTitle: field.title,
            required: field.required,
            formNotUpdated: true,
          });
        }
      });
    }
  }, [fieldValues, secondaryFields]);

  return (
    <>
      {secondaryFields.map((field) => {
        const value = fieldValues
          ? fieldValues.find((f) => f.fieldId === field.fieldId)?.value
          : undefined;

        const { salesforceFieldId } = field;

        let isGreyedOut = isDisabled;

        //Check if has a parent field (which can only be a checkbox)
        // And whether it is visible/accessible
        const shouldHide = hideBehindParentField(field, secondaryFields);

        // Fields hidden behind checkboxes should not be shown at the top level
        // They should render within checkbox fields, as per the recursive step in case "checkbox" below
        if (!parentField && shouldHide !== false) {
          return null;
        }

        if (shouldHide === "greyedOut") {
          isGreyedOut = true;
        }

        switch (field.fieldType) {
          case "input":
          case "number":
            return (
              <React.Fragment key={field.fieldId}>
                {!parentField && <Divider />}
                <Input
                  id={field.fieldId}
                  name={field.title}
                  required={field.required}
                  numbersOnly={field.fieldType === "number"}
                  label={field.title}
                  disabled={isGreyedOut}
                  stacked
                  introMsg={field.intro}
                  placeholder="Enter a value..."
                  value={value as string | number}
                  onChange={(e) => {
                    const _parentField =
                      parentField ||
                      secondaryFields.find(
                        (f) => f.fieldId === field.hiddenByFieldId
                      );

                    updateForm(field.fieldId, e.target.value, {
                      salesforceFieldId,
                      fieldTitle: field.title,
                      required: field.required,
                      parentField: _parentField
                        ? {
                            id: _parentField.fieldId,
                            hidesWhenChecked: (
                              _parentField as EntryFormCheckboxField
                            ).hiddenFields?.hideFieldsWhenChecked,
                            checkedByDefault: (
                              _parentField as EntryFormCheckboxField
                            ).checkedByDefault,
                          }
                        : undefined,
                    });
                  }}
                >
                  <>
                    {field.footnote && (
                      <StyledMarkdown className={styles.footnote}>
                        {field.footnote}
                      </StyledMarkdown>
                    )}
                  </>
                </Input>
              </React.Fragment>
            );
          case "textarea":
            return (
              <React.Fragment key={field.fieldId}>
                {!parentField && <Divider />}
                <TextArea
                  id={field.fieldId}
                  name={field.fieldId}
                  required={field.required}
                  label={field.title}
                  disabled={isGreyedOut}
                  stacked
                  introMsg={field.intro}
                  placeholder="Enter a value..."
                  value={value as string}
                  onChange={(e) => {
                    const _parentField =
                      parentField ||
                      secondaryFields.find(
                        (f) => f.fieldId === field.hiddenByFieldId
                      );

                    updateForm(field.fieldId, e.target.value, {
                      salesforceFieldId,
                      fieldTitle: field.title,
                      required: field.required,
                      parentField: _parentField
                        ? {
                            id: _parentField.fieldId,
                            hidesWhenChecked: (
                              _parentField as EntryFormCheckboxField
                            ).hiddenFields?.hideFieldsWhenChecked,
                            checkedByDefault: (
                              _parentField as EntryFormCheckboxField
                            ).checkedByDefault,
                          }
                        : undefined,
                    });
                  }}
                  maxWords={field.maxWords || Infinity}
                  minWords={field.minWords}
                  resize
                >
                  <>
                    {field.footnote && (
                      <StyledMarkdown className={styles.introMsg}>
                        {field.footnote}
                      </StyledMarkdown>
                    )}
                  </>
                </TextArea>
              </React.Fragment>
            );
          case "date":
            const date = value ? new Date(value as string | Date) : null;

            const { earliestDate, latestDate } = field;

            return (
              <React.Fragment key={field.fieldId}>
                {!parentField && <Divider />}
                <DateField
                  id={field.fieldId}
                  name={field.fieldId}
                  required={field.required}
                  label={field.title}
                  disabled={isGreyedOut}
                  stacked
                  introMsg={field.intro}
                  value={date}
                  onChange={(val) => {
                    const _parentField =
                      parentField ||
                      secondaryFields.find(
                        (f) => f.fieldId === field.hiddenByFieldId
                      );

                    updateForm(field.fieldId, val, {
                      salesforceFieldId,
                      fieldTitle: field.title,
                      required: field.required,
                      parentField: _parentField
                        ? {
                            id: _parentField.fieldId,
                            hidesWhenChecked: (
                              _parentField as EntryFormCheckboxField
                            ).hiddenFields?.hideFieldsWhenChecked,
                            checkedByDefault: (
                              _parentField as EntryFormCheckboxField
                            ).checkedByDefault,
                          }
                        : undefined,
                    });
                  }}
                  minDate={earliestDate ? new Date(earliestDate) : undefined}
                  maxDate={latestDate ? new Date(latestDate) : undefined}
                >
                  <>
                    {field.footnote && (
                      <StyledMarkdown className={styles.introMsg}>
                        {field.footnote}
                      </StyledMarkdown>
                    )}
                  </>
                </DateField>
              </React.Fragment>
            );
          case "select":
            const items = _.cloneDeep(field.dropdownOptions) as SelectItem[];

            if (field.orderAlphabetically) {
              let otherField: SelectItem | null = null;

              if (field.includeOther) {
                otherField = items.pop() as SelectItem;
              }

              items.sort((a, b) => {
                if (!a.name || !b.name) {
                  return a.value
                    .toLowerCase()
                    .localeCompare(b.value.toLowerCase());
                }

                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
              });

              if (otherField) {
                items.push(otherField);
              }
            }

            return (
              <React.Fragment key={field.fieldId}>
                {!parentField && <Divider />}
                {field.allowMultiple ? (
                  <SelectBoxMulti
                    id={field.fieldId}
                    name={field.fieldId}
                    required={field.required}
                    label={field.title}
                    stacked
                    isDisabled={isGreyedOut}
                    introMsg={field.intro}
                    placeholder="Enter a value..."
                    isClearable={!field.required}
                    labelKey="name"
                    valueKey="value"
                    options={items}
                    value={items.filter(
                      (option) =>
                        value &&
                        (value as string).split("|").includes(option.value)
                    )}
                    onChange={(newValue) => {
                      const multiLimit = field.multipleLimit || 2;

                      if (newValue.length > multiLimit) {
                        // Don't allow new values
                        return;
                      }

                      const _parentField =
                        parentField ||
                        secondaryFields.find(
                          (f) => f.fieldId === field.hiddenByFieldId
                        );

                      updateForm(
                        field.fieldId,
                        newValue.map(({ value }) => value).join("|"),
                        {
                          salesforceFieldId,
                          fieldTitle: field.title,
                          required: field.required,
                          valueName: newValue.map(({ name }) => name).join("|"),
                          parentField: _parentField
                            ? {
                                id: _parentField.fieldId,
                                hidesWhenChecked: (
                                  _parentField as EntryFormCheckboxField
                                ).hiddenFields?.hideFieldsWhenChecked,
                                checkedByDefault: (
                                  _parentField as EntryFormCheckboxField
                                ).checkedByDefault,
                              }
                            : undefined,
                        }
                      );
                    }}
                  >
                    {field.footnote && (
                      <StyledMarkdown className={styles.introMsg}>
                        {field.footnote}
                      </StyledMarkdown>
                    )}
                  </SelectBoxMulti>
                ) : (
                  <SelectBox
                    id={field.fieldId}
                    name={field.fieldId}
                    required={field.required}
                    label={field.title}
                    stacked
                    isDisabled={isGreyedOut}
                    introMsg={field.intro}
                    placeholder="Enter a value..."
                    isClearable={!field.required}
                    labelKey="name"
                    valueKey="value"
                    options={items}
                    value={items.find((option) => option.value === value)}
                    onChange={(newValue) => {
                      const _parentField =
                        parentField ||
                        secondaryFields.find(
                          (f) => f.fieldId === field.hiddenByFieldId
                        );

                      updateForm(field.fieldId, newValue?.value, {
                        salesforceFieldId,
                        fieldTitle: field.title,
                        required: field.required,
                        valueName: items.find(
                          (i) => i.value === newValue?.value
                        )?.name,
                        parentField: _parentField
                          ? {
                              id: _parentField.fieldId,
                              hidesWhenChecked: (
                                _parentField as EntryFormCheckboxField
                              ).hiddenFields?.hideFieldsWhenChecked,
                              checkedByDefault: (
                                _parentField as EntryFormCheckboxField
                              ).checkedByDefault,
                            }
                          : undefined,
                      });
                    }}
                  >
                    <>
                      {field.includeOther && value === "other" && (
                        <Input
                          id={`${field.fieldId}_other`}
                          name={`${field.fieldId}_other`}
                          label="Other:"
                          placeholder="Enter a value..."
                          value={
                            fieldValues
                              ? fieldValues.find(
                                  (f) => f.fieldId === field.fieldId
                                )?.otherValue
                              : undefined
                          }
                          onChange={(e) => {
                            const _parentField = secondaryFields.find(
                              (f) => f.fieldId === field.hiddenByFieldId
                            );

                            updateForm(field.fieldId, "other", {
                              salesforceFieldId,
                              fieldTitle: field.title,
                              required: field.required,
                              otherValue: e.target.value,
                              salesforceOtherFieldId:
                                field.salesforceOtherFieldId,
                              parentField: _parentField
                                ? {
                                    id: _parentField.fieldId,
                                    hidesWhenChecked: (
                                      _parentField as EntryFormCheckboxField
                                    ).hiddenFields?.hideFieldsWhenChecked,
                                    checkedByDefault: (
                                      _parentField as EntryFormCheckboxField
                                    ).checkedByDefault,
                                  }
                                : undefined,
                            });
                          }}
                          className={styles.otherField}
                        />
                      )}
                      {field.footnote && (
                        <StyledMarkdown className={styles.introMsg}>
                          {field.footnote}
                        </StyledMarkdown>
                      )}
                    </>
                  </SelectBox>
                )}
              </React.Fragment>
            );
          case "checkbox":
            const hiddenFieldsToRender: EntryFormField[] = [];

            if (field.hiddenFields?.ids) {
              field.hiddenFields.ids.forEach((id) => {
                const hiddenField = secondaryFields.find(
                  (f) => f.fieldId === id
                );

                if (hiddenField) {
                  hiddenFieldsToRender.push(hiddenField);
                }
              });
            }

            // Greyed out fields should always be visible
            let showHiddenFields = field.hiddenFields?.state === "greyedOut";
            let disableHiddenFields = true;

            if (
              // If the hidden fields should show when checkbox is checked
              (!field.hiddenFields?.hideFieldsWhenChecked &&
                (value === true ||
                  (typeof value !== "boolean" && field.checkedByDefault))) ||
              // If the hidden fields should show when it's not checked
              (field.hiddenFields?.hideFieldsWhenChecked &&
                (value === false ||
                  (typeof value !== "boolean" && !field.checkedByDefault)))
            ) {
              showHiddenFields = true;
              disableHiddenFields = false;
            }

            return (
              <React.Fragment key={field.fieldId}>
                <Divider />
                {field.heading && (
                  <h4 style={{ marginBottom: 12 }}>{field.heading}</h4>
                )}
                <Checkbox
                  id={field.fieldId}
                  label={field.title}
                  introMsg={field.intro}
                  required={field.required}
                  checked={
                    typeof value === "boolean"
                      ? value
                      : !!field.checkedByDefault
                  }
                  onChange={(e) =>
                    updateForm(field.fieldId, e.target.checked, {
                      salesforceFieldId,
                      fieldTitle: field.title,
                      required: field.required,
                    })
                  }
                >
                  <>
                    {field.footnote && (
                      <StyledMarkdown className={styles.introMsg}>
                        {field.footnote}
                      </StyledMarkdown>
                    )}
                    {showHiddenFields && hiddenFieldsToRender.length > 0 && (
                      <EntryFormSecondaryFields
                        entry={entry}
                        secondaryFields={hiddenFieldsToRender}
                        updateForm={updateForm}
                        parentField={field}
                        isDisabled={disableHiddenFields}
                      />
                    )}
                  </>
                </Checkbox>
              </React.Fragment>
            );
          case "table":
            const { maxTableFields, tableFields } = field;

            let tableRows = maxTableFields || 6;

            if (!tableFields) return null;

            if (!tableFields[0] || !tableFields[1]) return null;

            const header = (
              <tr className={classNames({ [styles.disabled]: isGreyedOut })}>
                <td>
                  {tableFields[0].tableFieldName}{" "}
                  {tableFields[0].tableFieldRequired && (
                    <span className={styles.required}>*</span>
                  )}
                </td>
                <td>
                  {tableFields[1].tableFieldName}{" "}
                  {tableFields[1].tableFieldRequired && (
                    <span className={styles.required}>*</span>
                  )}
                </td>
              </tr>
            );

            let body: React.ReactElement[] = [];

            for (let i = 0; i < tableRows; i++) {
              if (tableFields[0].tableFieldId && tableFields[1].tableFieldId) {
                const col0Key = tableFields[0].tableFieldId;
                const col1Key = tableFields[1].tableFieldId;

                const tableValues =
                  (value as {
                    [key: string]: {
                      required?: boolean;
                      value: string;
                      salesforceTableFieldId?: string;
                    };
                  }[]) || [];

                let salesforceTableFieldId1 = "";
                let salesforceTableFieldId2 = "";

                if (
                  tableFields[0].salesforceTableFieldIdPrefix ||
                  tableFields[0].salesforceTableFieldIdSuffix
                ) {
                  salesforceTableFieldId1 = `${
                    tableFields[0].salesforceTableFieldIdPrefix
                  }${i + 1}${tableFields[0].salesforceTableFieldIdSuffix}`;
                }

                if (
                  tableFields[1].salesforceTableFieldIdPrefix ||
                  tableFields[1].salesforceTableFieldIdSuffix
                ) {
                  salesforceTableFieldId2 = `${
                    tableFields[1].salesforceTableFieldIdPrefix
                  }${i + 1}${tableFields[1].salesforceTableFieldIdSuffix}`;
                }

                body.push(
                  <tr key={`${col0Key}-${i}`}>
                    <td>
                      <Input
                        id={`${col0Key}-${i}`}
                        name={`${col0Key}-${i}`}
                        disabled={isGreyedOut}
                        value={
                          tableValues[i] && tableValues[i][col0Key]
                            ? tableValues[i][col0Key].value
                            : undefined
                        }
                        placeholder={`Add ${
                          tableFields[0].tableFieldName || "value"
                        }...`}
                        onChange={(e) => {
                          if (col0Key) {
                            const newTableValues = _.cloneDeep(tableValues);

                            if (
                              e.target.value === "" &&
                              (!newTableValues[i] ||
                                !newTableValues[i][col1Key] ||
                                !newTableValues[i][col1Key].value)
                            ) {
                              // Splice the row out of the values without replacing it
                              newTableValues.splice(i, 1);
                            } else {
                              // Assign a value to the other column in the row
                              // This is so it can be validated as required
                              newTableValues.splice(i, 1, {
                                [col0Key]: {
                                  required: tableFields[0].tableFieldRequired,
                                  value: e.target.value,
                                  salesforceTableFieldId:
                                    salesforceTableFieldId1,
                                },
                                [col1Key]: {
                                  required:
                                    newTableValues[i] &&
                                    newTableValues[i][col1Key] &&
                                    typeof newTableValues[i][col1Key]
                                      ?.required === "boolean"
                                      ? newTableValues[i][col1Key]?.required
                                      : tableFields[1].tableFieldRequired,
                                  value:
                                    (newTableValues[i] &&
                                      newTableValues[i][col1Key]?.value) ||
                                    "",
                                  salesforceTableFieldId:
                                    salesforceTableFieldId2,
                                },
                              });
                            }

                            const _parentField =
                              parentField ||
                              secondaryFields.find(
                                (f) => f.fieldId === field.hiddenByFieldId
                              );

                            updateForm(field.fieldId, newTableValues, {
                              fieldTitle: field.title,
                              required: tableFields[0].tableFieldRequired,
                              parentField: _parentField
                                ? {
                                    id: _parentField.fieldId,
                                    hidesWhenChecked: (
                                      _parentField as EntryFormCheckboxField
                                    ).hiddenFields?.hideFieldsWhenChecked,
                                    checkedByDefault: (
                                      _parentField as EntryFormCheckboxField
                                    ).checkedByDefault,
                                  }
                                : undefined,
                            });
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        key={`${col1Key}-${i}`}
                        id={`${col1Key}-${i}`}
                        name={`${col1Key}-${i}`}
                        disabled={isGreyedOut}
                        value={
                          tableValues[i] && tableValues[i][col1Key]
                            ? tableValues[i][col1Key].value
                            : undefined
                        }
                        placeholder={`Add ${
                          tableFields[1].tableFieldName || "value"
                        }...`}
                        onChange={(e) => {
                          if (col1Key) {
                            const newTableValues = _.cloneDeep(tableValues);

                            if (
                              e.target.value === "" &&
                              (!newTableValues[i] ||
                                !newTableValues[i][col0Key] ||
                                !newTableValues[i][col0Key].value)
                            ) {
                              // Splice the row out of the values without replacing it
                              newTableValues.splice(i, 1);
                            } else {
                              // Assign a value to the other column in the row
                              // This is so it can be validated as required
                              newTableValues.splice(i, 1, {
                                [col0Key]: {
                                  required:
                                    newTableValues[i] &&
                                    newTableValues[i][col0Key] &&
                                    typeof newTableValues[i][col0Key]
                                      ?.required === "boolean"
                                      ? newTableValues[i][col0Key]?.required
                                      : tableFields[0].tableFieldRequired,
                                  value:
                                    (newTableValues[i] &&
                                      newTableValues[i][col0Key]?.value) ||
                                    "",
                                  salesforceTableFieldId:
                                    salesforceTableFieldId1,
                                },
                                [col1Key]: {
                                  required: tableFields[1].tableFieldRequired,
                                  value: e.target.value,
                                  salesforceTableFieldId:
                                    salesforceTableFieldId2,
                                },
                              });
                            }

                            const _parentField =
                              parentField ||
                              secondaryFields.find(
                                (f) => f.fieldId === field.hiddenByFieldId
                              );

                            updateForm(field.fieldId, newTableValues, {
                              fieldTitle: field.title,
                              required: tableFields[1].tableFieldRequired,
                              parentField: _parentField
                                ? {
                                    id: _parentField.fieldId,
                                    hidesWhenChecked: (
                                      _parentField as EntryFormCheckboxField
                                    ).hiddenFields?.hideFieldsWhenChecked,
                                    checkedByDefault: (
                                      _parentField as EntryFormCheckboxField
                                    ).checkedByDefault,
                                  }
                                : undefined,
                            });
                          }
                        }}
                      />
                    </td>
                  </tr>
                );
              }
            }

            if (!header || !body) return null;

            return (
              <React.Fragment key={field.fieldId}>
                {!parentField && <Divider />}
                {field.title && (
                  <h4
                    className={classNames(styles.tableFieldTitle, {
                      [styles.disabled]: isGreyedOut,
                    })}
                  >
                    {field.title}
                    {!isGreyedOut && (
                      <span className={styles.required}> *</span>
                    )}
                  </h4>
                )}
                {field.intro && (
                  <StyledMarkdown className={styles.introMsg}>
                    {field.intro}
                  </StyledMarkdown>
                )}
                <div id={field.fieldId} className={styles.formTable}>
                  <table>
                    <thead>{header}</thead>
                    <tbody>{body}</tbody>
                  </table>
                </div>
                {field.footnote && (
                  <StyledMarkdown className={styles.introMsg}>
                    {field.footnote}
                  </StyledMarkdown>
                )}
              </React.Fragment>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default EntryFormSecondaryFields;
