import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AccountContext } from "./account";
import { AwardsYearDetails } from "../api/types/awards-years";
import { getAwardsYearCategories, getCurrentYear } from "../api/awards-years";
import { AwardsCategoryPublicDetails } from "../api/types/awards-categories";
import { isAdmin } from "../utils";

// Function to normalize dates to only consider the date part (ignoring the time)
const normalizeDate = (date: string | Date) => {
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0);
  return normalized;
};

interface AwardsContextProps {
  currentYear: AwardsYearDetails | null;
  setCurrentYear: React.Dispatch<
    React.SetStateAction<AwardsYearDetails | null>
  >;
  currentYearCategories: AwardsCategoryPublicDetails[];
  setCurrentYearCategories: React.Dispatch<
    React.SetStateAction<AwardsCategoryPublicDetails[]>
  >;
  noNewEntries: boolean | null;
}

const initialValue: AwardsContextProps = {
  currentYear: null,
  setCurrentYear: () => {},
  currentYearCategories: [],
  setCurrentYearCategories: () => {},
  noNewEntries: null,
};

export const AwardsContext = createContext<AwardsContextProps>(initialValue);

interface AccountProviderProps {
  children?: React.ReactNode;
}

const AwardsProvider = ({ children }: AccountProviderProps) => {
  const { account } = useContext(AccountContext);

  const [currentYear, setCurrentYear] = useState<AwardsYearDetails | null>(
    null
  );
  const [currentYearCategories, setCurrentYearCategories] = useState<
    AwardsCategoryPublicDetails[]
  >([]);

  useEffect(() => {
    const fetchCurrentAwardsYear = async () => {
      try {
        const { currentYear: _currentYear } = await getCurrentYear();

        setCurrentYear({ ..._currentYear });
      } catch {
        console.error("Error fetching entrant details");
      }
    };

    if (!currentYear && account.loggedIn && account.uid) {
      fetchCurrentAwardsYear();
    }
  }, [account]);

  useEffect(() => {
    const fetchCurrentYearCategories = async () => {
      try {
        const { categories: _currentYearCategories } =
          await getAwardsYearCategories(currentYear!.year);

        setCurrentYearCategories([..._currentYearCategories]);
      } catch {
        console.error("Error fetching entrant details");
      }
    };

    if (currentYear) {
      fetchCurrentYearCategories();
    }
  }, [currentYear]);

  // Check if within the entry period
  const noNewEntries = useMemo(() => {
    if (!currentYear) return null;

    // Admins should always be able to edit entries
    if (isAdmin(account)) return false;

    const entriesOpenDate = normalizeDate(currentYear.entries_open_date);
    const entriesCloseDate = normalizeDate(currentYear.entries_close_date);
    // const entriesCloseDate = normalizeDate("2024-06-05");

    const today = normalizeDate(new Date(Date.now()));

    // Do not allow entries before open date
    if (today < entriesOpenDate) {
      return true;
    }

    if (today > entriesCloseDate) {
      const { entrantDetails } = account;

      // Allow for entry extension
      if (entrantDetails?.entry_extension) {
        const extensionDate = normalizeDate(
          new Date(entrantDetails.entry_extension)
        );

        if (today < extensionDate) {
          return false;
        }
      }

      return true;
    }

    // Current date is within correct period
    return false;
  }, [currentYear]);

  return (
    <AwardsContext.Provider
      value={{
        currentYear,
        setCurrentYear,
        currentYearCategories,
        setCurrentYearCategories,
        noNewEntries,
      }}
    >
      {children}
    </AwardsContext.Provider>
  );
};

export default AwardsProvider;
