import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import moment from "moment";
import _ from "lodash";
import classNames from "classnames";

import {
  completeEntry,
  deleteEntry,
  markEntryAsIncomplete,
  markEntryAsPaid,
  updateResultByID,
} from "../api/awards-entries";
import {
  AwardsEntryResponseFull,
  AwardsEntryUpdateResult,
} from "../api/types/awards-entries";
import {
  AwardsYearEntriesQueryParams,
  EntriesFilterOptions,
  EntrySortParams,
} from "../api/types/awards-years";
import tick from "../assets/svgs/tick-circle.svg";
import SiteWrapper from "../components/SiteWrapper";
import { Column, Container, Row } from "../components/layout";
import { FormButtonRow } from "../components/form";
import Input from "../components/input";
import SelectBox from "../components/select-box";
import Button, {
  AddFilesButton,
  DeleteButton,
  EditButton,
} from "../components/button";
import { Modal } from "../components/modal";
import Table from "../components/Table";
import { AdminContext } from "../context/admin";
import { AwardsContext } from "../context/awards";
import useWindow from "../hooks/useWindow";
import * as styles from "../styles/AdminManageEntriesPage.module.scss";

interface EntryModalProps {
  entry: AwardsEntryResponseFull;
  onSuccess: () => Promise<void>;
  closeModal: () => void;
}

const PayEntryModal = ({ entry, onSuccess, closeModal }: EntryModalProps) => {
  const [payError, setPayError] = useState(false);

  return (
    <Modal className={styles.modal} onClose={closeModal}>
      <>
        <h3>Are you sure?</h3>
        <p>
          You are about to mark the entry{" "}
          <strong>
            #{entry.entry_id} "{entry.title}"
          </strong>
          , created by <strong>{entry.entrant_email}</strong>, as paid.
        </p>
        <p>The entrant will be able to complete their entry.</p>
        <p>
          You should only do this if you can confirm the invoice has been paid
          in Xero.
        </p>
        <FormButtonRow className={styles.modalSubmitButtons}>
          <Button buttonType="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            buttonType="primary"
            onClick={async () => {
              try {
                await markEntryAsPaid(entry.entry_id);

                await onSuccess();

                closeModal();
              } catch {
                setPayError(true);
              }
            }}
          >
            Mark as paid
          </Button>
        </FormButtonRow>
        {payError && (
          <p className={styles.modalError}>
            Failed to mark entry as paid. Please try again later.
          </p>
        )}
      </>
    </Modal>
  );
};

const DeleteEntryModal = ({
  entry,
  onSuccess,
  closeModal,
}: EntryModalProps) => {
  const [deleteInput, setDeleteInput] = useState("");
  const [deleteError, setDeleteError] = useState(false);

  return (
    <Modal className={styles.modal} onClose={closeModal}>
      <>
        <h3 className={styles.modalWarning}>WARNING</h3>
        <p>
          You are about to delete entry{" "}
          <strong>
            #{entry.entry_id} "{entry.title}"
          </strong>
          , created by <strong>{entry.entrant_email}</strong>.
        </p>
        <p>
          <strong>
            It will be permanently deleted. This action is irreversible.
          </strong>
        </p>
        <p>Type 'permanently delete' in the input below to confirm.</p>
        <Input
          value={deleteInput}
          placeholder="permanently delete"
          onChange={(e) => setDeleteInput(e.target.value)}
        />
        <FormButtonRow className={styles.modalSubmitButtons}>
          <Button buttonType="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className={styles.modalSubmitButton}
            buttonType="primary"
            disabled={deleteInput !== "permanently delete"}
            onClick={async () => {
              try {
                await deleteEntry(entry.entry_id);

                await onSuccess();

                closeModal();
              } catch {
                setDeleteError(true);
              }
            }}
          >
            Confirm delete
          </Button>
        </FormButtonRow>
        {deleteError && (
          <p className={styles.modalError}>
            Failed to delete entry. Please try again later.
          </p>
        )}
      </>
    </Modal>
  );
};

const CompleteEntryModal = ({
  entry,
  onSuccess,
  closeModal,
}: EntryModalProps) => {
  const [completeError, setCompleteError] = useState(false);

  return (
    <Modal className={styles.modal} onClose={closeModal}>
      <>
        <h3>Mark as complete</h3>
        <p>
          You are about to mark the entry{" "}
          <strong>
            #{entry.entry_id} "{entry.title}"
          </strong>
          , created by <strong>{entry.entrant_email}</strong>, as fully
          submitted.
        </p>
        <p>
          The entrant will no longer be able to edit the entry and it will be
          visible to judges for the entry category. This action is reversible.
        </p>
        <FormButtonRow className={styles.modalSubmitButtons}>
          <Button buttonType="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            buttonType="primary"
            onClick={async () => {
              try {
                await completeEntry(entry.entry_id);

                await onSuccess();

                closeModal();
              } catch {
                setCompleteError(true);
              }
            }}
          >
            Confirm
          </Button>
        </FormButtonRow>
        {completeError && (
          <p className={styles.modalError}>
            Failed to update entry. Please try again later.
          </p>
        )}
      </>
    </Modal>
  );
};

const IncompleteEntryModal = ({
  entry,
  onSuccess,
  closeModal,
}: EntryModalProps) => {
  const [completeError, setCompleteError] = useState(false);

  return (
    <Modal className={styles.modal} onClose={closeModal}>
      <>
        <h3>Mark as incomplete</h3>
        <p>
          You are about to mark the entry{" "}
          <strong>
            #{entry.entry_id} "{entry.title}"
          </strong>
          , created by <strong>{entry.entrant_email}</strong>, as incomplete.
        </p>
        <p>
          It will no longer be available for judging, but the entrant will be
          able to edit it. This action is reversible.
        </p>
        <FormButtonRow className={styles.modalSubmitButtons}>
          <Button buttonType="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            buttonType="primary"
            onClick={async () => {
              try {
                await markEntryAsIncomplete(entry.entry_id);

                await onSuccess();

                closeModal();
              } catch {
                setCompleteError(true);
              }
            }}
          >
            Confirm
          </Button>
        </FormButtonRow>
        {completeError && (
          <p className={styles.modalError}>
            Failed to update entry. Please try again later.
          </p>
        )}
      </>
    </Modal>
  );
};

const entryColumnHelper = createColumnHelper<AwardsEntryResponseFull>();

export default function AdminManageEntriesPage() {
  const [isEditMode, setEditMode] = useState(false);
  const { currentYear } = useContext(AwardsContext);
  const { categories, getEntries } = useContext(AdminContext);

  const _window = useWindow();

  // Allows email filtering via URL
  const initialSearch = new URL(_window.location.href).searchParams.get(
    "email"
  );

  const [queryConfig, setQueryConfig] = useState<AwardsYearEntriesQueryParams>(
    {}
  );
  const [fetchStatus, setFetchStatus] = useState<
    "loading" | "loaded" | "error"
  >("loading");
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  const [entries, setEntries] = useState<AwardsEntryResponseFull[]>([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [actionModal, setActionModal] = useState<{
    modalType: "pay" | "delete" | "complete" | "incomplete";
    entry: AwardsEntryResponseFull;
  } | null>(null);

  // Initial fetch
  useEffect(() => {
    const fetchEntries = async (year: number) => {
      try {
        setFetchStatus("loading");

        const { entries: _entries, total_count } = await getEntries(
          year,
          initialSearch ? { search: initialSearch } : undefined
        );

        setFetchStatus("loaded");
        setEntries(_entries);
        setTotalEntries(total_count);
      } catch {
        setFetchStatus("error");
      }
    };

    if (currentYear) {
      fetchEntries(currentYear.year);
    }
  }, [initialSearch, categories, currentYear]);

  // Set search term to match URL search
  useEffect(() => {
    setCurrentSearchTerm(initialSearch || "");
    setQueryConfig((curr) => ({
      ...curr,
      search: initialSearch || undefined,
    }));
  }, [initialSearch]);

  const refetchEntries = useCallback(
    async (year: number, queryParams?: AwardsYearEntriesQueryParams) => {
      try {
        setFetchStatus("loading");

        const { entries: refetchedEntries } = await getEntries(
          year,
          queryParams
        );

        setFetchStatus("loaded");
        setEntries(refetchedEntries);
      } catch {
        setFetchStatus("error");
      }
    },
    []
  );

  const updateResult = useCallback(
    async (entry_id: number, details: AwardsEntryUpdateResult) => {
      try {
        await updateResultByID(entry_id, details);

        if (currentYear) {
          refetchEntries(currentYear.year);
        }
      } catch (e) {
        // @ts-ignore
        const { message } = e;
        if (message) {
          console.error(message);
        }
      }
    },
    [currentYear]
  );

  const updateSort = useCallback(
    (sortId: EntrySortParams) => {
      if (!currentYear) return;

      const newQueryConfig: AwardsYearEntriesQueryParams = {
        ...queryConfig,
        page: 1,
        sort: sortId,
        sortOrder:
          sortId !== queryConfig.sort || queryConfig.sortOrder === "ASC"
            ? "DESC"
            : "ASC",
      };

      setQueryConfig(newQueryConfig);

      refetchEntries(currentYear.year, newQueryConfig);
    },
    [currentYear, queryConfig, refetchEntries]
  );

  const columnDef = useMemo(() => {
    const columns: ColumnDef<AwardsEntryResponseFull, any>[] = [
      entryColumnHelper.accessor("entry_id", {
        header: () => (
          <div
            className={classNames(styles.idColumn, styles.sortableCol)}
            onClick={() => updateSort("entry_id")}
          >
            ID
            {(!queryConfig.sort || queryConfig.sort === "entry_id") &&
              `${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 50,
      }),
      entryColumnHelper.accessor("title", {
        header: () => (
          <div
            className={styles.sortableCol}
            onClick={() => updateSort("title")}
          >
            Name
            {queryConfig.sort === "title" &&
              ` ${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 200,
        cell: ({ row }) => {
          const {
            original: { entry_id, title, created_at, entrant_email },
          } = row;

          return (
            <>
              <Button
                buttonType="link"
                href={`/view-entry/${entry_id}`}
                className={styles.entryTitle}
              >
                {title}
              </Button>
              <span className={styles.subDetails}>{entrant_email}</span>
              <br />
              {created_at && (
                <span className={styles.subDetails}>
                  {moment(new Date(created_at)).format("Do MMM YYYY")}
                </span>
              )}
            </>
          );
        },
      }),
      entryColumnHelper.accessor("category_title", {
        header: () => (
          <div
            className={styles.sortableCol}
            onClick={() => updateSort("category")}
          >
            Category
            {queryConfig.sort === "category" &&
              ` ${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 200,
      }),
      entryColumnHelper.accessor("last_updated", {
        header: () => (
          <div
            className={styles.sortableCol}
            onClick={() => updateSort("last_updated")}
          >
            Last updated
            {queryConfig.sort === "last_updated" &&
              ` ${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
          </div>
        ),
        size: 160,
        cell: ({
          row: {
            original: { last_updated },
          },
        }) =>
          last_updated ? (
            moment(new Date(last_updated)).format("DD/MM/YYYY")
          ) : (
            <span className={styles.subDetails}>N/A</span>
          ),
      }),
      entryColumnHelper.accessor("invoice_sent", {
        header: () => <div className={styles.centeredColumn}>Invoiced</div>,
        size: 120,
        cell: ({
          row: {
            original: { invoice_sent },
          },
        }) => (
          <>
            {invoice_sent && (
              <img className={styles.tickIcon} src={tick} alt="Yes" />
            )}
          </>
        ),
      }),
      entryColumnHelper.accessor("entry_files_count", {
        header: () => <div className={styles.centeredColumn}>Has files</div>,
        size: 120,
        cell: ({
          row: {
            original: { entry_files_count },
          },
        }) => (
          <>
            {entry_files_count > 0 && (
              <img className={styles.tickIcon} src={tick} alt="Yes" />
            )}
          </>
        ),
      }),
      entryColumnHelper.accessor("is_paid", {
        header: () => <div className={styles.centeredColumn}>Paid</div>,
        size: 120,
        cell: ({
          row: {
            original: { is_paid },
          },
        }) => (
          <>
            {is_paid && (
              <img className={styles.tickIcon} src={tick} alt="Yes" />
            )}
          </>
        ),
      }),
      entryColumnHelper.accessor("is_submitted", {
        header: () => <div className={styles.centeredColumn}>Completed</div>,
        size: 120,
        cell: ({
          row: {
            original: { is_submitted },
          },
        }) => (
          <>
            {is_submitted && (
              <img className={styles.tickIcon} src={tick} alt="Yes" />
            )}
          </>
        ),
      }),
    ];

    if (isEditMode) {
      const actionColumns: ColumnDef<AwardsEntryResponseFull, any>[] = [
        entryColumnHelper.accessor("is_shortlisted", {
          header: () => (
            <div
              className={styles.sortableCol}
              // TODO: Add filter option
              // onClick={() => updateSort("is_shortlisted")}
            >
              Shortlisted
              {(!queryConfig.sort || queryConfig.sort === "is_shortlisted") &&
                `${queryConfig.sortOrder === "ASC" ? " 🔼" : " 🔽"}`}
            </div>
          ),
          size: 120,
          cell: ({
            row: {
              original: { entry_id, is_shortlisted },
            },
          }) => (
            <Toggle
              key={`${entry_id}${is_shortlisted}`}
              defaultChecked={is_shortlisted}
              icons={false}
              onChange={(e) => {
                updateResult(entry_id, {
                  is_shortlisted: !is_shortlisted,
                });
              }}
            />
          ),
        }),
        entryColumnHelper.accessor("is_nominated", {
          header: "Nominated",
          size: 120,
          cell: ({
            row: {
              original: { entry_id, is_nominated },
            },
          }) => (
            <Toggle
              key={`${entry_id}${is_nominated}`}
              defaultChecked={is_nominated}
              icons={false}
              onChange={(e) => {
                updateResult(entry_id, {
                  is_nominated: !is_nominated,
                });
              }}
            />
          ),
        }),
        entryColumnHelper.accessor("is_commended", {
          header: "Commended",
          size: 120,
          cell: ({
            row: {
              original: { entry_id, is_commended },
            },
          }) => (
            <Toggle
              key={`${entry_id}${is_commended}`}
              defaultChecked={is_commended}
              icons={false}
              onChange={(e) => {
                updateResult(entry_id, {
                  is_commended: !is_commended,
                });
              }}
            />
          ),
        }),
        entryColumnHelper.accessor("is_winner", {
          header: "Winner",
          size: 120,
          cell: ({
            row: {
              original: { entry_id, is_winner },
            },
          }) => (
            <Toggle
              key={`${entry_id}${is_winner}`}
              defaultChecked={is_winner}
              icons={false}
              onChange={(e) => {
                updateResult(entry_id, {
                  is_winner: !is_winner,
                });
              }}
            />
          ),
        }),
      ];

      columns.splice(4, 0, ...actionColumns);

      columns.push(
        entryColumnHelper.display({
          id: "actions",
          header: "Actions",
          size: 200,
          cell: ({ row: { original: entry } }) => {
            const {
              entry_id,
              invoice_sent,
              is_paid,
              is_submitted,
              entry_files_count,
            } = entry;

            return (
              <div className={styles.actionButtons}>
                {is_submitted ? (
                  <Button
                    onClick={() =>
                      setActionModal({
                        modalType: "incomplete",
                        entry,
                      })
                    }
                  >
                    Mark as incomplete
                  </Button>
                ) : (
                  <>
                    <AddFilesButton
                      blueIcon={false}
                      className={styles.addFilesButton}
                      href={`/uploader/${entry_id}`}
                    />
                    <EditButton href={`/edit-entry/${entry_id}`} />
                    <DeleteButton
                      onClick={() =>
                        setActionModal({
                          modalType: "delete",
                          entry,
                        })
                      }
                    />
                    {invoice_sent && !is_paid && (
                      <Button
                        style={{ width: "100%" }}
                        onClick={() =>
                          setActionModal({
                            modalType: "pay",
                            entry,
                          })
                        }
                      >
                        Mark as paid
                      </Button>
                    )}
                    {is_paid && entry_files_count > 0 && (
                      <Button
                        style={{ width: "100%" }}
                        onClick={() =>
                          setActionModal({
                            modalType: "complete",
                            entry,
                          })
                        }
                      >
                        Mark as complete
                      </Button>
                    )}
                  </>
                )}
              </div>
            );
          },
        })
      );
    }

    return columns;
  }, [isEditMode, queryConfig]);

  const filterOptions = useMemo(() => {
    return [
      { value: "invoice_sent", label: "Invoiced" },
      { value: "is_paid", label: "Paid" },
      { value: "is_submitted", label: "Submitted" },
      { value: "has_files", label: "Has files" },
    ];
  }, []);

  if (!categories || !currentYear?.year) {
    return null;
  }

  return (
    <>
      <SiteWrapper hideStickyNav>
        <Container>
          <Row>
            <Column gridCols={12}>
              <FormButtonRow className={styles.tableControls}>
                <form
                  className={styles.searchContainer}
                  onSubmit={async (e) => {
                    e.preventDefault();

                    let newSearchTerm = currentSearchTerm;

                    if (
                      currentSearchTerm !== "" &&
                      currentSearchTerm === queryConfig.search
                    ) {
                      newSearchTerm = "";
                      setCurrentSearchTerm(newSearchTerm);
                    }

                    const newQueryConfig = {
                      ...queryConfig,
                      search: newSearchTerm || "",
                    };

                    setQueryConfig(newQueryConfig);

                    const { entries: _entries, total_count } = await getEntries(
                      currentYear.year,
                      newQueryConfig
                    );

                    setEntries(_entries);
                    setTotalEntries(total_count);
                  }}
                >
                  <Input
                    className={styles.tableSearch}
                    dark
                    placeholder="Enter search term"
                    value={currentSearchTerm}
                    onChange={(e) => setCurrentSearchTerm(e.target.value)}
                  />
                  <Button
                    buttonType="tertiary"
                    className={styles.searchButton}
                    // @ts-ignore
                    submit
                    disabled={currentSearchTerm === "" && !queryConfig.search}
                  >
                    {currentSearchTerm !== "" &&
                    currentSearchTerm === queryConfig.search
                      ? "Clear"
                      : "Search"}
                  </Button>
                  <p>{totalEntries} entries</p>
                </form>
                <div className={styles.editModeToggle}>
                  <label>Edit entries</label>
                  <Toggle
                    defaultChecked={isEditMode}
                    icons={false}
                    onChange={(e) => {
                      setEditMode((mode) => !mode);
                    }}
                  />
                </div>
                <div className={styles.filterDropdowns}>
                  <SelectBox
                    className={styles.filterDropdown}
                    dark
                    isClearable
                    placeholder="Filter by status"
                    value={filterOptions.find(
                      ({ value }) => value === queryConfig.filter
                    )}
                    options={filterOptions}
                    onChange={async (newValue) => {
                      const newQueryConfig = {
                        ...queryConfig,
                        page: 1,
                        filter: newValue
                          ? (newValue.value as EntriesFilterOptions)
                          : undefined,
                      };

                      setQueryConfig(newQueryConfig);

                      const { entries: _entries, total_count } =
                        await getEntries(currentYear.year, newQueryConfig);

                      setEntries(_entries);
                      setTotalEntries(total_count);
                    }}
                  />
                  <SelectBox
                    className={styles.filterDropdown}
                    dark
                    isClearable
                    valueKey="awards_category_id"
                    labelKey="title"
                    placeholder="Filter by category"
                    value={categories[currentYear.year].find(
                      (category) =>
                        category.awards_category_id === queryConfig.category
                    )}
                    options={categories[currentYear.year]}
                    onChange={async (newValue) => {
                      const newQueryConfig = {
                        ...queryConfig,
                        page: 1,
                        category: newValue
                          ? newValue.awards_category_id
                          : undefined,
                      };

                      setQueryConfig(newQueryConfig);

                      const { entries: _entries, total_count } =
                        await getEntries(currentYear.year, newQueryConfig);

                      setEntries(_entries);
                      setTotalEntries(total_count);
                    }}
                  />
                </div>
              </FormButtonRow>
              <Table
                tableClassName={classNames(styles.entriesTable, {
                  [styles.isEditTable]: isEditMode,
                })}
                getRowClassName={(row) =>
                  classNames(styles.tableRow, {
                    [styles.completedEntry]: row.is_submitted,
                  })
                }
                getCellClassName={(cell) =>
                  classNames({
                    [styles.idColumn]: cell.column.id === "entry_id",
                  })
                }
                loading={fetchStatus === "loading"}
                error={fetchStatus === "error"}
                data={entries}
                columns={columnDef}
                growColumnIndex={1}
              />
              {totalEntries > entries.length && (
                <Button
                  buttonType="tertiary"
                  style={{ display: "block", margin: "16px auto 0" }}
                  onClick={async () => {
                    const newQueryConfig = {
                      ...queryConfig,
                      page: queryConfig.page ? queryConfig.page + 1 : 2,
                    };

                    setQueryConfig(newQueryConfig);

                    const { entries: nextEntries } = await getEntries(
                      currentYear.year,
                      newQueryConfig
                    );

                    setEntries((curr) => [...curr, ...nextEntries]);
                  }}
                >
                  Load more
                </Button>
              )}
            </Column>
          </Row>
        </Container>
      </SiteWrapper>
      {actionModal && (
        <>
          {actionModal.modalType === "pay" && (
            <PayEntryModal
              entry={actionModal.entry}
              closeModal={() => setActionModal(null)}
              onSuccess={() => refetchEntries(currentYear.year)}
            />
          )}
          {actionModal.modalType === "delete" && (
            <DeleteEntryModal
              entry={actionModal.entry}
              closeModal={() => setActionModal(null)}
              onSuccess={() => refetchEntries(currentYear.year)}
            />
          )}
          {actionModal.modalType === "complete" && (
            <CompleteEntryModal
              entry={actionModal.entry}
              closeModal={() => setActionModal(null)}
              onSuccess={() => refetchEntries(currentYear.year)}
            />
          )}
          {actionModal.modalType === "incomplete" && (
            <IncompleteEntryModal
              entry={actionModal.entry}
              closeModal={() => setActionModal(null)}
              onSuccess={() => refetchEntries(currentYear.year)}
            />
          )}
        </>
      )}
    </>
  );
}
